/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import CloseIcon from "mdi-react/CloseIcon";
import Spinner from "../global/Spinner";

import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import AddTaskModal from "../tasks/AddTaskModal";
import { URLS } from "../urls";
import AllTypeTasks from "../tasks/AllTypeTasksClient";
import styled from "styled-components";

import PlusIcon from "mdi-react/PlusIcon";
import AccountGroup from "mdi-react/AccountGroupIcon";

import "../../assets/scss/projects.scss";
import "../../assets/scss/new-ui.scss";

import {
  getProjectById,
  removeProjectUser,
} from "../../services/projectServices";
import { getClients } from "../../services/clientServices";
import { getTeamsICreated } from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";
import { getTasksByProjectId } from "../../services/taskServices";

import { toggleMoreProjectViewOptions } from "../../store/actions/projectActions";
import {
  AppRegExp,
  calculateFileSize,
  calculateProjectProgress,
  GetFileIcon,
  isProjectLeaderOrCreatedBy,
  microsoftOfficeFileExtensions,
  removeFirstCharacter,
  setFileUrl,
  showToast,
  truncateFileName,
} from "../task-update/utils";
import FileModal from "../task-update/modals/FileModal";
import { cloneDeep } from "lodash-es";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import {
  PerformanceIcon,
  TasksIcon,
  TeamsIcon,
  ViewPerformanceIcon,
  ViewTasksIcon,
} from "../topbar/Icons";
import PencilIcon from "mdi-react/PencilIcon";
import DialogModal from "../task-update/modals/DialogModal";
import ProjectModal from "../task-update/modals/ProjectModal";
import { TaskIcon } from "../task-update/components/Icons";
import TaskModal from "../task-update/modals/TaskModal";
import eventBus from "../../helper/EventBus";

class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    const cloudDriveUrl = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
    const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
    const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;

    const { account_type } = props.user;

    this.state = {
      project: {
        name: "",
        client: null,
        startDate: "",
        endDate: "",
        cost: 0,
        duration: 0,
        durationType: "Hours",
        priority: "High",
        projectLeader: [],
        team: [],
        description: "",
        files: [],
        status: "Working",
        createdBy: null,
      },
      clientInfo: {
        companyName: "",
      },
      editProject: "",
      completedTaskCount: 0,
      inCompletedTaskCount: 0,
      underReviewedTaskCount: 0,
      projectTasks: [],
      completedTask: [],
      inCompletedTask: [],
      underReviewedTask: [],
      clientList: [],
      employeesList: [],
      isAddTaskModalOpen: false,
      isLoadingProjectView: true,
      isEditModalOpen: false,
      isAddLeaderModalOpen: false,
      isAddMemberModalOpen: false,
      projectId: "",
      activeTab: "all_tasks",
      cloudDriveUrl,
      watcherServerUrl,
      cloudDriveFrontEnd,
      fileToOpen: "",
      selectedUser: "",
      isRemoving: false,
      typeOfUserToRemove: "",
      account_type,
      showFileModal: false,
      indexInView: 0,
      imageFiles: [],
      projectEditStage: 1,
      taskEditStage: 1,
    };
  }

  editProject = (_id) => {
    this.setState({ editProject: _id });
  };

  getEditProjectDataFromId = (id) => {
    const { projects } = this.state;
    // return projects.find(item => id === item._id)
    return this.state.project;
  };

  async componentDidMount() {
    try {
      const fullUrl = window.location.pathname.split("/");

      if (fullUrl && !fullUrl[2]) {
        window.location.assign("/error");
      }

      const id = fullUrl[2];
      const token = this.props.token;

      const projectResponse = await getProjectById(token, id);

      //Redirect if project doesn't exist
      if (!projectResponse || projectResponse.error) {
        window.location.assign("/error");
      }

      const [clientsResponse, teamsResponse, usersResponse, tasksResponse] =
        await Promise.all([
          getClients(token),
          getTeamsICreated(token),
          getCompanyUsers(token),
          getTasksByProjectId(token, id),
        ]);

      const tasks = tasksResponse.tasks || [];
      const completedTask = tasks.filter((task) => {
        return task.status === "COMPLETE";
      });
      const completedTaskCount = completedTask.length;
      const inCompletedTask = tasks.filter((task) => {
        return task.status === "INCOMPLETE";
      });
      const inCompletedTaskCount = inCompletedTask.length;
      const underReviewedTask = tasks.filter((task) => {
        return task.status === "UNDER_REVIEW";
      });
      const underReviewedTaskCount = underReviewedTask.length;

      const imageFiles = [
        ...cloneDeep(projectResponse.project.filesData),
      ].filter(
        (file) =>
          AppRegExp.isImageFullPath(`.${file.extension}`) ||
          AppRegExp.isImageFullPath(`${file?.ext}`)
      );

      this.setState({
        project: projectResponse.project,
        clientInfo: projectResponse.clientInfo,
        clientList: clientsResponse.clients || [],
        teams: teamsResponse.teams || [],
        employeesList: usersResponse.users || [],
        isLoadingProjectView: false,
        completedTask,
        inCompletedTask,
        underReviewedTask,
        completedTaskCount,
        inCompletedTaskCount,
        underReviewedTaskCount,
        projectTasks: tasks,
        projectId: id,
        imageFiles,
      });
      this.forceUpdate();
    } catch (error) {
      console.log(error);
    }
  }

  updateProjectsList = (_project, isProjectModalOpen) => {
    if (_project) {
      this.setState({
        project: _project,
      });
      if (isProjectModalOpen) return;
      const { isEditModalOpen } = this.state;
      if (isEditModalOpen) {
        this.handleModal("editProject");
      }
    }
  };

  handleModal = (modal, options = {}) => {
    if (modal === "addTask") {
      const isAddTaskModalOpen = !this.state.isAddTaskModalOpen;
      this.setState({ isAddTaskModalOpen });
    } else if (modal === "editProject") {
      const isEditModalOpen = !this.state.isEditModalOpen;
      this.setState({
        isEditModalOpen,
        projectEditStage: options.projectEditStage,
      });
    }
  };

  handleTaskCreated = ({ task }) => {
    let projectTasks = this.state.projectTasks;
    if (task) {
      projectTasks = [task, ...projectTasks];
    }
    this.setState({ projectTasks });
    this.handleModal("addTask");
  };

  handleTab = (activeTab) => {
    this.setState({ activeTab });
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  isCreatedBy = (createdBy) => {
    let value;
    const userId = this.props.user._id;
    if (createdBy && createdBy._id && userId) {
      if (createdBy._id === userId) {
        value = true;
      }
    }
    return value;
  };

  toggleMoreOptions = () => {
    let isMoreProjectViewOptions = !this.props.isMoreProjectViewOptions;
    this.props.toggleMoreProjectViewOptions(isMoreProjectViewOptions);
  };

  handleFileToView = (file) => {
    const { watcherServerUrl } = this.state;
    this.setState({
      fileToOpen: watcherServerUrl + "/files/" + file._id,
    });
  };

  handleMoreOptions = (e) => {
    const canCloseMoreProjectViewOptions = e.target.closest(
      ".project-view-more-options"
    )
      ? true
      : false;
    if (!canCloseMoreProjectViewOptions) {
      this.props.toggleMoreProjectViewOptions(false);
    }
  };

  handleRemoveUser = async ({ selectedUser, typeOfUserToRemove }) => {
    if (
      await DialogModal({
        title: "Remove User",
        description: `Are you sure, you want to remove <b>${selectedUser.name.first} ${selectedUser.name.last}</b> ?`,
        type: "warning",
        confirmMessage: "Yes, remove",
      })
    ) {
      this.setState({ isRemoving: true });
      const token = this.props.token;
      let { project } = this.state;

      if (typeOfUserToRemove) {
        let users = project[typeOfUserToRemove];

        users = users.filter((user) => user._id !== selectedUser._id);

        project[typeOfUserToRemove] = users;

        const projectId = project._id;

        removeProjectUser(
          token,
          projectId,
          selectedUser,
          typeOfUserToRemove
        ).then((response) => {
          if (response.success) {
            this.setState({ project });
            showToast({
              message: {
                title: "User removed",
              },
              type: "success",
            });
          }
        });
      }
    }
  };

  resetDeleteModal = (e) => {
    if (e && e.target !== e.currentTarget) return;
    this.setState({
      selectedUser: "",
      isRemoving: false,
      typeOfUserToRemove: "",
    });
  };

  handleCloseFileModal = (e) => {
    if (e.target !== e.currentTarget) return;

    this.setState({
      fileToOpen: "",
    });
  };

  handleShowFile = (file) => {
    const { watcherServerUrl, cloudDriveFrontEnd } = this.state;
    file.cloud_id = file?.cloud_id ? file.cloud_id : file._id;
    file.originalName = file?.originalName ? file.originalName : file.name;
    file.extension = file?.extension
      ? file.extension
      : removeFirstCharacter(file.ext);

    if (file.extension.startsWith(".")) {
      file.extension = file.extension.substring(1);
    }

    if (AppRegExp.isImageFullPath(`.${file.extension}`)) {
      const foundFileIndex = this.state.imageFiles.findIndex(
        (fileEl) => fileEl._id === file._id
      );
      if (foundFileIndex !== -1) {
        this.setState({
          ...this.state,
          indexInView: foundFileIndex,
          showFileModal: true,
        });
      }
    } else if (file.extension === "pdf") {
      const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}`;
      eventBus.dispatch("onViewPdf", { fileUrl, ...file });
    } else if (microsoftOfficeFileExtensions.includes(file.extension)) {
      const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
      window.open(fileUrl, "_blank");
    } else {
      const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}?view`;
      window.open(fileUrl, "_blank");
    }
  };

  arrangeFileData = (files) => {
    return cloneDeep(files).map((file) => {
      return {
        ...file,
        cloud_id: file?.cloud_id ? file.cloud_id : file._id,
        originalName: file?.originalName ? file.originalName : file.name,
        extension: file?.extension ? file.extension : file.ext,
      };
    });
  };

  render() {
    const {
      project,
      editProject,
      projectTasks,
      completedTaskCount,
      inCompletedTaskCount,
      underReviewedTaskCount,
      clientList,
      employeesList,
      clientInfo,
      teams,
      isEditModalOpen,
      isAddTaskModalOpen,
      isLoadingProjectView,
      projectId,
      activeTab,
      cloudDriveUrl,
      fileToOpen,
      selectedUser,
      isRemoving,
      isAddMemberModalOpen,
      isAddLeaderModalOpen,
      account_type,
      projectEditStage,
      taskEditStage,
    } = this.state;
    const { isMoreProjectViewOptions, hasAccountExpired } = this.props;
    const editProjectData = this.getEditProjectDataFromId(editProject);
    // console.log("editProject", editProject, "editProjectData", editProjectData);
    const progress =
      /* completedTaskCount + inCompletedTaskCount + underReviewedTaskCount > 0
        ? (
            (completedTaskCount * 100) /
            (completedTaskCount + inCompletedTaskCount + underReviewedTaskCount)
          ).toFixed(2)
        : 0; */ calculateProjectProgress(projectTasks);
    const allProjectMembers = project
      ? [...project.team, ...project.projectLeader]
      : [];

    return (
      <section onClick={(e) => this.handleMoreOptions(e)}>
        <Container>
          <div className="main-wrapper">
            <Topbar />
            <div className="d-flex new-wrapper sleek-scrollbar">
              <Sidebar activeSideBar="projects" />
              {/*<!-- Page Wrapper -->*/}
              <div className="page-wrapper p-0">
                <div className="page-top-bar">
                  <a href="/">{project.name}</a>

                  <div className="col d-flex align-items-center justify-content-end header-actions">
                    <Link
                      className="btn action-btn"
                      to={`/tasks-board?projectId=${project._id}&m=f`}
                      //title="View in board"
                    >
                      <ViewTasksIcon /> <span>View Tasks</span>
                    </Link>

                    <Link
                      className="btn action-btn"
                      to={`/project-performance/${project._id}`}
                      // title="Performance"
                    >
                      <ViewPerformanceIcon /> <span>Performance Detail</span>
                    </Link>
                  </div>

                  {isProjectLeaderOrCreatedBy(project, this.props.user) ? (
                    <button
                      onClick={() => this.handleModal("editProject")}
                      disabled={hasAccountExpired}
                      className="btn btn-outline-primary main-btn inner p-0 px-md-3 py-md-2 mr-3 "
                    >
                      <span className="d-none d-md-block">Edit Project</span>
                      <PencilIcon className="d-block d-md-none" />
                    </button>
                  ) : null}

                  {!isLoadingProjectView &&
                  !hasAccountExpired &&
                  isProjectLeaderOrCreatedBy(project, this.props.user) ? (
                    <Dropdown alignRight>
                      <Dropdown.Toggle
                        as="button"
                        bsPrefix={`btn btn-primary main-btn inner p-0 px-md-3 py-md-2`}
                      >
                        <PlusIcon />
                        <span className="d-none d-md-inline">Add</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        bsPrefix={`rounded bg-white w-100 shadow general-menu general-dropdown-menu`}
                        alignRight
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => this.handleModal("addTask")}
                        >
                          <TaskIcon />
                          Task
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            this.handleModal("editProject", {
                              projectEditStage: 3,
                            })
                          }
                        >
                          <AccountGroup />
                          Members
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                </div>

                {/*<!-- Page Content -->*/}
                <div className="content container-fluid bg-white">
                  {/*<!-- Page Title -->*/}
                  <div className="row flex justify-content-between align-items-center">
                    <div className="g-crumb px-3">
                      <Link to={`${URLS.dashboard}`}>Home</Link>{" "}
                      <ChevronRightIcon class="active" />{" "}
                      <Link to={`${URLS.projects}`}>Projects</Link>
                      <ChevronRightIcon class="active" />{" "}
                      <a href="#" class="active">
                        {project.name}
                      </a>
                    </div>

                    <div className="d-flex align-items-center col-3 justify-content-between">
                      {/* <PrinterIcon
											className="print-icon"
											data-toggle="modal"
											data-target="#preview_project"
										/> */}
                      {/*this.isCreatedBy(project.createdBy) &&
                    !hasAccountExpired ? (
                      <span
                        className="create-btn"
                        data-toggle="modal"
                        data-target="#edit_project"
                        onClick={() => this.handleModal("editProject")}
                      >
                        Edit Project
                      </span>
                    ) : null*/}
                    </div>
                  </div>

                  {isLoadingProjectView ? (
                    <Spinner />
                  ) : (
                    <div className="row">
                      <div className="col-lg-8 col-xl-9">
                        <div className="card shadow-none border-none pb-4 mb-0">
                          <div className="card-body p-0">
                            <div className="project-title">
                              <h5 className="card-title">{project.name}</h5>

                              <div className="content-com d-flex flex-column justify-content-between py-2 pb-1">
                                <span className="d-flex mb-2">
                                  <CheckCircleIcon />
                                  <span className="ml-1">
                                    {completedTaskCount} Tasks Completed
                                  </span>
                                </span>
                                <span>
                                  <FileDocumentIcon />
                                  <span className="ml-1">
                                    {inCompletedTaskCount +
                                      underReviewedTaskCount}{" "}
                                    Tasks Opened
                                  </span>
                                </span>
                              </div>
                            </div>
                            <p
                              className="text-sm m-0"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {project.description}
                            </p>
                          </div>
                        </div>
                        <div className="card shadow-none border-none">
                          <div className="card-body px-0">
                            <div className="d-flex justify-content-between">
                              <span className="card-title m-b-20">
                                Uploaded files
                              </span>
                              {project.project_folder_id ? (
                                <a
                                  className="text-primary"
                                  href={`${cloudDriveUrl}/${project.project_folder_id}`}
                                >
                                  All Files
                                </a>
                              ) : null}
                            </div>

                            {project.filesData.length ? (
                              <ul className="files-list">
                                {project.filesData.map((file, index) => {
                                  return (
                                    <li key={index}>
                                      <div
                                        className="files-cont p-cursor text-sm d-flex align-items-center"
                                        onClick={() =>
                                          this.handleShowFile(file)
                                        }
                                      >
                                        {AppRegExp.isImageFullPath(
                                          `${file.originalName}.${file.extension}`
                                        ) ? (
                                          <div className="image-holder">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                              }}
                                              alt=""
                                              src={setFileUrl(file)}
                                              dsrc="https://images.unsplash.com/photo-1629581477374-9237dc216942?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMjIyMzQ2Ng&ixlib=rb-1.2.1&q=80&w=1080"
                                            />
                                          </div>
                                        ) : (
                                          <GetFileIcon
                                            file={{
                                              ...file,
                                              extension: file?.extension
                                                ? file.extension
                                                : removeFirstCharacter(
                                                    file.ext
                                                  ),
                                            }}
                                          />
                                        )}

                                        <div className="files-info px-0 pl-3">
                                          <span className="file-name text-ellipsis">
                                            <a
                                              // href={`${cloudDriveUrl}/${file.cloud_id}`}
                                              data-toggle="modal"
                                              data-target="#view_file"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              {`${truncateFileName(file)}`}
                                            </a>
                                          </span>
                                          <div className="file-size text-xs">
                                            {calculateFileSize(file.size)}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <div className="d-flex justify-content-center">
                                No file
                              </div>
                            )}
                          </div>
                        </div>

                        {projectTasks && projectTasks.length ? (
                          <div className="project-task">
                            <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0 bg-white d-block d-md-flex">
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    activeTab === "all_tasks"
                                      ? "p-active-task"
                                      : ""
                                  }`}
                                  href="#all_tasks"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                  onClick={() => this.handleTab("all_tasks")}
                                >
                                  All Tasks
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    activeTab === "pending_tasks"
                                      ? "p-active-task"
                                      : ""
                                  }`}
                                  href="#pending_tasks"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                  onClick={() =>
                                    this.handleTab("pending_tasks")
                                  }
                                >
                                  Pending Tasks
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    activeTab === "under_reviewed_tasks"
                                      ? "p-active-task"
                                      : ""
                                  }`}
                                  href="#under_reviewed_tasks"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                  onClick={() =>
                                    this.handleTab("under_reviewed_tasks")
                                  }
                                >
                                  Under Review Tasks
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    activeTab === "completed_tasks"
                                      ? "p-active-task"
                                      : ""
                                  }`}
                                  href="#completed_tasks"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                  onClick={() =>
                                    this.handleTab("completed_tasks")
                                  }
                                >
                                  Completed Tasks
                                </a>
                              </li>
                            </ul>
                            <div className="mb-4 md-md-0">
                              <AllTypeTasks
                                projectTasks={projectTasks}
                                project={project}
                                key={projectTasks.length}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex flex-column align-items-center justify-content-center m-t-20 card shadow-none">
                            <img
                              src="../../assets/svg/undraw_Organizing_projects_0p9a.svg"
                              className="empty-svg m-t-20"
                              alt="..."
                            />
                            <h4
                              className="mt-5 m-b-20"
                              style={{ fontSize: "15px" }}
                            >
                              No Task found
                            </h4>
                          </div>
                        )}
                      </div>

                      <div
                        className="col-lg-4 col-xl-3"
                        style={{ fontSize: "12px" }}
                      >
                        <div className="card">
                          <div
                            className="card-body border rounded"
                            style={{ padding: "0.8rem" }}
                          >
                            <h2 className="card-title m-b-15">
                              Project details
                            </h2>
                            <table className="table table-borderless project-details-table">
                              <tbody>
                                {clientInfo && (
                                  <tr>
                                    <td>Client:</td>
                                    <td className="text-right">
                                      {clientInfo.companyName || "..."}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Cost:</td>
                                  <td className="text-right">
                                    {project.cost || "..."}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Duration:</td>
                                  <td className="text-right">
                                    {(project.duration || "...") +
                                      " " +
                                      (project.durationType || "...")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Start date:</td>
                                  <td className="text-right">
                                    {project.startDate || "..."}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Deadline:</td>
                                  <td className="text-right">
                                    {project.endDate || "..."}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Priority:</td>
                                  <td className="text-right">
                                    <div className="btn-group">
                                      {project.priority === "High" ? (
                                        <a>
                                          <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                          High
                                        </a>
                                      ) : project.priority === "Medium" ? (
                                        <a>
                                          <i className="fa fa-dot-circle-o text-primary"></i>{" "}
                                          Normal
                                        </a>
                                      ) : (
                                        <a>
                                          <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                          Low{" "}
                                        </a>
                                      )}
                                      {/* <a href="#" className="badge badge-danger dropdown-toggle" data-toggle="dropdown">Highest </a>
																<div className="dropdown-menu dropdown-menu-right">
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Highest priority</a>
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> High priority</a>
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-primary"></i> Normal priority</a>
																	<a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success"></i> Low priority</a>
																</div> */}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Created by:</td>
                                  <td className="text-right">
                                    <a>
                                      {project.createdBy
                                        ? project.createdBy.name.first +
                                          " " +
                                          project.createdBy.name.last
                                        : null}
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Status:</td>
                                  <td className="text-right">
                                    {project.status}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <p className="m-b-5">
                              Progress{" "}
                              <span className="text-success float-right">
                                {progress + "%"}
                              </span>
                            </p>
                            <div className="progress progress-xs mb-0">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                data-toggle="tooltip"
                                title={progress.toString() + "%"}
                                style={{ width: progress.toString() + "%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="card project-user border">
                          <div className="card-body p-3">
                            <div className="d-flex justify-content-between align-content-center">
                              <span
                                style={{ marginBottom: "10px" }}
                                className="card-title"
                              >
                                Leaders
                              </span>
                            </div>

                            {project.projectLeader &&
                            project.projectLeader.length ? (
                              <ul className="list-box assigned-to-container">
                                {project.projectLeader.map(
                                  (employee, index) => {
                                    let name;
                                    if (employee.name.first) {
                                      name =
                                        employee.name.first +
                                        " " +
                                        employee.name.last;
                                    } else {
                                      name = employee.name;
                                    }

                                    return (
                                      <li key={index} className="mb-2">
                                        <div className="d-flex align-items-center member-detail-row">
                                          <span className="avatar">
                                            {employee.photo ? (
                                              <img
                                                alt="..."
                                                src={
                                                  URLS.backendStatic +
                                                  "/" +
                                                  employee.photo
                                                }
                                              />
                                            ) : (
                                              <span className="av-alternative">
                                                {this.setEmployeeInitials(
                                                  employee
                                                )}
                                              </span>
                                            )}
                                          </span>
                                          <span
                                            className="mt-2"
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              maxLines: 1,
                                            }}
                                          >
                                            <span className="block name">
                                              {this.setEmployeeName(employee)}
                                            </span>
                                            <span className="username">
                                              @{employee.username}
                                            </span>
                                          </span>
                                          {this.isCreatedBy(
                                            project.createdBy
                                          ) ? (
                                            <CloseIcon
                                              style={{ marginLeft: "auto" }}
                                              className="remove-user-icon"
                                              onClick={() =>
                                                this.handleRemoveUser({
                                                  selectedUser: employee,
                                                  typeOfUserToRemove:
                                                    "projectLeader",
                                                })
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : (
                              <span
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                }}
                              >
                                None found
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="card project-user border">
                          <div className="card-body p-3">
                            <div className="d-flex justify-content-between align-content-center">
                              <span
                                style={{ marginBottom: "10px" }}
                                className="card-title"
                              >
                                Team
                              </span>
                            </div>
                            {project.team && project.team.length ? (
                              <ul className="list-box assigned-to-container">
                                {project.team.map((employee, index) => {
                                  let name;
                                  if (employee.name.first) {
                                    name =
                                      employee.name.first +
                                      " " +
                                      employee.name.last;
                                  } else {
                                    name = employee.name;
                                  }

                                  return (
                                    <li key={index} className="mb-2">
                                      <div className="d-flex align-items-center member-detail-row">
                                        <span className="avatar">
                                          {employee.photo ? (
                                            <img
                                              alt="..."
                                              src={
                                                URLS.backendStatic +
                                                "/" +
                                                employee.photo
                                              }
                                            />
                                          ) : (
                                            <span className="av-alternative">
                                              {this.setEmployeeInitials(
                                                employee
                                              )}
                                            </span>
                                          )}
                                        </span>
                                        <span
                                          className="mt-2"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxLines: 1,
                                          }}
                                        >
                                          <span className="block name">
                                            {this.setEmployeeName(employee)}
                                          </span>
                                          <span className="username">
                                            @{employee.username}
                                          </span>
                                        </span>
                                        {this.isCreatedBy(project.createdBy) ? (
                                          <CloseIcon
                                            style={{ marginLeft: "auto" }}
                                            className="remove-user-icon"
                                            onClick={() =>
                                              this.handleRemoveUser({
                                                selectedUser: employee,
                                                typeOfUserToRemove: "team",
                                              })
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <span
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                }}
                              >
                                None found
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/*!isLoadingProjectView && !hasAccountExpired ? (
                  // && account_type != 1
                  <span
                    onClick={() => this.toggleMoreOptions()}
                    className="fab project-view-more-options"
                  >
                    {isMoreProjectViewOptions ? <CloseIcon /> : <PlusIcon />}
                  </span>
                ) : null*/}
                {/*<!-- /Page Content -->*/}

                {/*isMoreProjectViewOptions ? (
                  <div className="more-options project-view-more-options">
                    <span
                      data-toggle="modal"
                      data-target="#add_task_modal"
                      onClick={() => this.handleModal("addTask")}
                    >
                      Add Task
                    </span>
                    {this.isCreatedBy(project.createdBy) ? (
                      <span
                        data-toggle="modal"
                        data-target="#assign_leader"
                        onClick={() => this.handleModal("addLeader")}
                      >
                        Add Project Leader
                      </span>
                    ) : null}
                    <span
                      data-toggle="modal"
                      data-target="#assign_member"
                      onClick={() => this.handleModal("addMember")}
                    >
                      Add Project Member
                    </span>
                  </div>
                    ) : null*/}

                {/* <div id="preview_project" className="modal custom-modal fade" role="dialog">
								<div className="modal-dialog modal-dialog-centered print-preview-modal">
								<div className="modal-content print-preview-content">
									<div className="m-h-between">
									<h5 className="modal-title">Print Project</h5>
									<div>
										<span className="continue-print-btn">Continue</span>
										<CloseIcon onClick={this.handleCloseModal} />
									</div>
									</div>
									<div className="modal-body"></div>
								</div>
								</div>
							</div>
							*/}

                {/*<div
                  id="view_file"
                  className="modal custom-modal fade"
                  role="dialog"
                  onClick={(e) => this.handleCloseFileModal(e)}
                >
                  <div className="modal-dialog modal-dialog-centered print-preview-modal">
                    <div className="modal-content print-preview-content">
                      <iframe
                        title="file viewer"
                        src={fileToOpen}
                        description=""
                        style={{
                          height: "100%",
                          width: "100%",
                          border: "none",
                        }}
                      />
                    </div>
                  </div>
                      </div>*/}

                {/*
                <div
                  className="modal custom-modal fade"
                  id="remove_user"
                  role="dialog"
                  onClick={(e) => this.resetDeleteModal(e)}
                >
                  <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                      <div className="modal-body">
                        {!isRemoving ? (
                          <>
                            <div className="form-header">
                              <h3>Remove User</h3>
                              <p>Are you sure want to remove this user ?</p> 
                              {selectedUser ? (
                                <div className="mt-4 mb-4 d-flex justify-content-center font-weight-bold">
                                  {this.setEmployeeName(selectedUser)}
                                </div>
                              ) : null}
                            </div>
                            <div className="modal-btn delete-action mt-4">
                              <div className="row mt-4">
                                <div className="col-6">
                                  <span
                                    className="btn btn-primary delete-btn"
                                    onClick={() => this.handleRemoveUser()}
                                  >
                                    Remove
                                  </span>
                                </div>
                                <div className="col-6">
                                  <span
                                    data-dismiss="modal"
                                    className="btn btn-primary cancel-btn"
                                  >
                                    Cancel
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{ height: "400px" }}
                          >
                            <Spinner spinnerHeight="50px" />
                            <span
                              style={{ textAlign: "center", marginTop: "30px" }}
                            >
                              Removing User....
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                */}

                {/*isAddMemberModalOpen && (
                  <AddMemberModal
                    team={employeesList}
                    project={project}
                    updateProjectsList={this.updateProjectsList}
                    closeModal={() => this.handleModal("addMember")}
                  />
                )*/}

                {/*isAddLeaderModalOpen && (
                  <AddLeaderModal
                    team={employeesList}
                    project={project}
                    updateProjectsList={this.updateProjectsList}
                    closeModal={() => this.handleModal("addLeader")}
                  />
                )*/}

                {isEditModalOpen && (
                  <ProjectModal
                    showProjectModal={isEditModalOpen}
                    mode="edit"
                    selectedProject={editProjectData}
                    projectUpdated={({ project, isProjectModalOpen }) =>
                      this.updateProjectsList(project, isProjectModalOpen)
                    }
                    clientList={clientList}
                    teamsICreated={teams}
                    employeesList={employeesList}
                    projectEditStage={projectEditStage || 1}
                    setShowProjectModal={() => this.handleModal("editProject")}
                  />
                )}

                {isAddTaskModalOpen && (
                  <TaskModal
                    showTaskModal={isAddTaskModalOpen}
                    selectedList={project.list[0]}
                    selectedProject={project}
                    team={allProjectMembers}
                    projectTasks={projectTasks}
                    setShowTaskModal={() => this.handleModal("addTask")}
                    taskCreated={this.handleTaskCreated}
                    taskEditStage={taskEditStage}
                    setTaskEditStage={(stage) =>
                      this.setState({ taskEditStage: 1 })
                    }
                  />
                )}

                {this.state.showFileModal && (
                  <FileModal
                    indexInView={this.state.indexInView}
                    showFileModal={this.state.showFileModal}
                    setShowFileModal={(isOpen) =>
                      this.setState({ ...this.state, showFileModal: isOpen })
                    }
                    files={this.arrangeFileData(project.filesData)}
                    hideAsComment={true}
                  />
                )}
              </div>
              {/*<!-- /Page Wrapper -->*/}
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
    isMoreProjectViewOptions: state.projectReducer.isMoreProjectViewOptions,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMoreProjectViewOptions: (value) =>
      dispatch(toggleMoreProjectViewOptions(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectView));

const Container = styled.div`
  .fab {
    position: fixed;
    bottom: 15px;
    right: 15px;
    background-color: #022b72;
    border: 1px solid #022b72;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      height: 20px;
      width: 20px;
    }
  }

  .more-options {
    position: fixed;
    bottom: 60px;
    right: 15px;
    background: #fff;
    border-radius: 4px;
    border: 1px #f1f1f1 solid;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 2px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 10px;
    width: 200px;
    font-family: Inter, Roboto, Arial, sans-serif;
    // border-bottom-right-radius: 70px;

    > div:nth-child(1) {
      display: flex;
      margin-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;

      > span {
        font-size: 14px;
        font-weight: bold;
        opacity: 0.7;
      }

      svg {
        height: 20px;
        width: 20px;
        margin-left: auto;
        opacity: 0.7;
      }
    }

    > span {
      cursor: default;
      padding: 7px 15px;
      font-size: 14px;
      user-select: none;
    }

    > span:hover {
      background-color: rgba(2, 43, 114, 0.8);
      color: #fff;
    }
  }

  .content-com {
    width: 10.25rem;

    &:first-child {
    }

    &:nth-child(3) {
      margin-top: -0.6rem;
    }

    span {
      color: #788898;
      font-size: 0.875rem !important;
      font-weight: 500;
      line-height: 1.0625rem;
      white-space: nowrap;
      display: flex;
      align-items: center;

      svg {
        display: inline;
        width: 14px;
        height: 14px;
      }
    }
  }
`;
