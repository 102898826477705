import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Checkbox,
  fullName,
  setCaretPosition,
  usernameFromText,
  createMarkup,
  linkify,
  escapeHTML,
} from "../utils";
import "../../../assets/scss/todo.scss";
import { TrashIconSmall } from "./Icons";
import { Form, ProgressBar } from "react-bootstrap";
import PencilIcon from "mdi-react/PencilIcon";
import CloseIcon from "mdi-react/CloseIcon";
import Mention from "./Mention";
import { flatMap } from "lodash";
import eventBus from "../../../helper/EventBus";
import AtIcon from "mdi-react/AtIcon";

function TodoItem({
  el,
  index,
  checkTodo,
  hideChecked,
  removeTodo,
  editTodo,
  usersList,
  user,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState("");
  const [showUsersList, setShowUsersList] = useState(false);

  const commentRef = useRef();

  useEffect(() => {
    if (isEdit) {
      if (commentRef.current) {
        //  resize
        commentRef.current.style.height = "";
        commentRef.current.style.height =
          commentRef.current.scrollHeight + 2 + "px";

        commentRef.current.select();
      }
    }
  }, [isEdit]);

  const handleEdit = () => {
    const text = document.createElement("div");
    text.innerHTML = el.text;
    setValue(text.textContent);
    setIsEdit(!isEdit);
  };

  const resizeInput = (e) => {
    e.target.style.height = "";
    e.target.style.height = e.target.scrollHeight + 2 + "px";
  };

  const handleSelectUser = (user) => {
    let comment = commentRef.current.value;
    const selectionStart = commentRef.current.selectionStart;
    const currentSelection =
      selectionStart === comment.length + 1
        ? selectionStart - 1
        : selectionStart;
    const firstTextBatch = comment.substring(0, currentSelection);
    const secondTextBatch = comment.substring(
      currentSelection,
      comment.length + 1
    );

    const username = `@${fullName(user.name)} `;
    let textToReplace = comment
      .substring(0, currentSelection)
      .split(/\s+/)
      .pop();
    let newFirstTextBatch;
    newFirstTextBatch = firstTextBatch.replace(
      new RegExp(`${textToReplace}$`),
      username
    );
    // }
    const newText = newFirstTextBatch + secondTextBatch;
    setValue(newText);
    setShowUsersList(false);
    setTimeout(() => {
      setCaretPosition(commentRef.current, selectionStart + username.length);
    }, 500);
  };

  const handleOnChange = (e) => {
    resizeInput(e);

    const text = e.target.value;
    const currentSelection = e.target.selectionStart;
    let currentText = text != "" ? text.substring(0, currentSelection) : text;
    const lastWord = currentText
      .replaceAll(/\r?\n|\r/g, " ")
      .split(" ")
      .splice(-1)[0];

    if (lastWord.startsWith("@")) {
      setShowUsersList(true);
      setTimeout(() => {
        eventBus.dispatch(
          "onSearchUsersInMention",
          lastWord.replaceAll("@", "")
        );
      }, 0);
    } else {
      setShowUsersList(false);
    }

    setValue(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { text } = await usernameFromText({
      text: linkify(escapeHTML(value)),
      members: flatMap(usersList),
    });
    setIsEdit(false);
    editTodo(index, text);
  };

  const todoClick = (e) => {
    const userId = e.target.getAttribute("data-id");
    if (userId && userId !== user._id) eventBus.dispatch("open-chat", userId);
  };

  return (
    <div
      className={`todo-item no-select ${
        hideChecked && el.checked ? "d-none" : ""
      } ${isEdit ? "is-edit" : ""}`}
    >
      <label className="m-0 p-0">
        <Checkbox
          checked={el.checked}
          onChange={(e) => checkTodo(index, e.target.checked)}
        />
      </label>
      <span className="flex-grow-1 text">
        {!isEdit ? (
          <span
            /*onClick={(e) => checkTodo(index, !el.checked)}*/
            onClick={(e) => todoClick(e)}
            style={el.checked ? { textDecoration: "line-through" } : {}}
            dangerouslySetInnerHTML={createMarkup(el.text)}
          ></span>
        ) : (
          <>
            {showUsersList && (
              <div className="position-relative">
                <Mention
                  usersList={usersList}
                  hideSearchInput={true}
                  inputFocus={false}
                  handleSelectUser={handleSelectUser}
                  style={{ position: "absolute", bottom: "1rem" }}
                  listStyle={{
                    maxHeight: `${10 + Math.min(index, 3) * 2.5}rem`,
                  }}
                />
              </div>
            )}
            <form className="add-todo" onSubmit={(e) => onSubmit(e)}>
              <textarea
                ref={commentRef}
                rows={1}
                placeholder="Edit To-do"
                autoComplete="off"
                value={value}
                onChange={(e) => handleOnChange(e)}
                autoFocus
                required
                className="form-control overflow-hidden border-0 edit-textarea"
                spellCheck={false}
              ></textarea>
              <div className="d-flex justify-content-between mt-3">
                <div>
                  <button className="btn btn-primary mr-3">Save</button>
                  <button
                    onClick={() => setIsEdit(false)}
                    className="btn btn-light text-dark"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>

                <div>
                  <button
                    onClick={() => setShowUsersList((oldValue) => !oldValue)}
                    className="btn btn-sm text-secondary"
                    title="Mention"
                    type="button"
                  >
                    <AtIcon />
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </span>
      {!isEdit && (
        <div className="d-flex align-items-center">
          <button
            onClick={() => handleEdit()}
            className="btn action mr-2 edit"
            title="Edit"
          >
            {!isEdit ? (
              <PencilIcon title="Edit" />
            ) : (
              <CloseIcon title="Close" />
            )}
          </button>
          <button
            onClick={(e) => removeTodo(index)}
            className="btn  action del-btn"
            title="Delete"
          >
            <TrashIconSmall />
          </button>
        </div>
      )}
    </div>
  );
}

export function TodoList({
  user,
  showProgress,
  todos = [],
  addTodo,
  checkTodo,
  removeTodo,
  selectAll,
  style,
  canMarkTodo,
  disabled = false,
  editTodo,
  usersList = [],
}) {
  const commentRef = useRef();

  const [showAddForm, setShowAddForm] = useState(false);
  const [todoText, setTodoText] = useState("");
  const [textareaHeight, setTextareaHeight] = useState("");
  const [hideChecked, setHideChecked] = useState(false);
  const [showUsersList, setShowUsersList] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { text, mentionedUsers } = await usernameFromText({
      text: linkify(escapeHTML(todoText)),
      members: flatMap(usersList),
    });

    addTodo(text, mentionedUsers);
    setTodoText("");
  };

  const resizeInput = (e) => {
    e.target.style.height = "";
    e.target.style.height = e.target.scrollHeight + 2 + "px";
  };

  const handleSelectUser = (user) => {
    let comment = commentRef.current.value;
    const selectionStart = commentRef.current.selectionStart;
    const currentSelection =
      selectionStart === comment.length + 1
        ? selectionStart - 1
        : selectionStart;
    const firstTextBatch = comment.substring(0, currentSelection);
    const secondTextBatch = comment.substring(
      currentSelection,
      comment.length + 1
    );

    const username = `@${fullName(user.name)} `;
    let textToReplace = comment
      .substring(0, currentSelection)
      .split(/\s+/)
      .pop();
    let newFirstTextBatch;
    newFirstTextBatch = firstTextBatch.replace(
      new RegExp(`${textToReplace}$`),
      username
    );
    // }
    const newText = newFirstTextBatch + secondTextBatch;
    setTodoText(newText);
    setShowUsersList(false);
    setTimeout(() => {
      setCaretPosition(commentRef.current, selectionStart + username.length);
    }, 500);
  };

  const handleOnChange = (e) => {
    resizeInput(e);
    const text = e.target.value;
    const currentSelection = e.target.selectionStart;
    let currentText = text != "" ? text.substring(0, currentSelection) : text;
    const lastWord = currentText
      .replaceAll(/\r?\n|\r/g, " ")
      .split(" ")
      .splice(-1)[0];

    if (lastWord.startsWith("@")) {
      setShowUsersList(true);
      setTimeout(() => {
        eventBus.dispatch(
          "onSearchUsersInMention",
          lastWord.replaceAll("@", "")
        );
      }, 0);
    } else {
      setShowUsersList(false);
    }
    setTodoText(e.target.value);
  };

  const completionRate = useCallback((todos) => {
    let total = todos.length;
    let completed = todos.filter((el) => el.checked).length;

    return Math.round((completed / total) * 100);
  }, []);

  return (
    <div className="todo">
      {showProgress ? (
        todos.length ? (
          <div className="todo-progress d-flex align-items-center w-100 mb-3">
            <span>{completionRate(todos)}%</span>
            <ProgressBar
              className="flex-grow-1 bar"
              now={completionRate(todos)}
            />
            <button
              onClick={() => setHideChecked((val) => !val)}
              className="btn text-primary btn-sm"
            >
              {hideChecked ? "Show" : "Hide"} Checked To-do
            </button>
          </div>
        ) : (
          <p className="title mb-2">Track task with a to-do list</p>
        )
      ) : (
        <p className="title mb-2">Track task with a to-do list</p>
      )}

      <div style={disabled ? { pointerEvents: "none" } : {}}>
        {todos && todos.length > 2 && !disabled ? (
          <>
            <label className={`todo-item no-select m-0`}>
              <label className="m-0 p-0">
                <Checkbox
                  checked={todos && todos.every((el) => el.checked)}
                  onChange={(e) => selectAll(e.target.checked)}
                />
              </label>
              <span className="flex-grow-1 text">Select All</span>
            </label>
            <hr className="my-1" />
          </>
        ) : null}
        {todos.map((el, index) => (
          <div key={index}>
            <TodoItem
              el={el}
              index={index}
              checkTodo={checkTodo}
              hideChecked={hideChecked}
              removeTodo={removeTodo}
              editTodo={editTodo}
              usersList={usersList}
              user={user}
            />
          </div>
        ))}
      </div>

      {!disabled ? (
        <div className="mt-2 add-todo">
          {!showAddForm ? (
            <button
              onClick={() => setShowAddForm(true)}
              className="btn btn-outline-primary border-0"
            >
              + Add To-do
            </button>
          ) : (
            <>
              {showUsersList && (
                <div className="position-relative">
                  <Mention
                    usersList={usersList}
                    hideSearchInput={true}
                    inputFocus={false}
                    handleSelectUser={handleSelectUser}
                    style={{ position: "absolute", bottom: "1rem" }}
                    listStyle={{
                      maxHeight: `${10 + Math.min(todos.length, 3) * 2.5}rem`,
                    }}
                  />
                </div>
              )}
              <form
                key={todos?.length}
                className="mt-3"
                onSubmit={(e) => onSubmit(e)}
              >
                <textarea
                  ref={commentRef}
                  style={{ height: textareaHeight }}
                  rows={1}
                  placeholder="Add To-do"
                  value={todoText}
                  autoComplete="off"
                  autoFocus
                  required
                  onChange={(e) => handleOnChange(e)}
                  spellCheck={false}
                  className="form-control overflow-hidden"
                ></textarea>

                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <button className="btn btn-primary mr-3">Add</button>
                    <button
                      onClick={() => setShowAddForm(false)}
                      className="btn btn-light text-dark"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>

                  <div>
                    <button
                      onClick={() => setShowUsersList((oldValue) => !oldValue)}
                      className="btn btn-sm text-secondary"
                      title="Mention"
                      type="button"
                    >
                      <AtIcon />
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
}
