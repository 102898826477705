import { connect } from "react-redux";
import { saveSettings } from "../../../store/actions/boardSettingsActions";
import BoardSettings from "./Board-settings";

const mapStateToProps = (state) => ({
  settings: state.boardSettingsReducer,
  user: state.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveSettings: (x) => dispatch(saveSettings(x)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardSettings);
