/* eslint-disable jsx-a11y/img-redundant-alt */
import { compareAsc, compareDesc, format } from "date-fns";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  AppRegExp,
  calculateFileSize,
  Checkbox,
  cloudDriveBackend,
  downloadFile,
  FileMore,
  formatFileSize,
  fullName,
  GetFileIcon,
  microsoftOfficeFileExtensions,
  progressTheme,
  reader,
  setFileUrl,
  showToast,
  styleHelper,
  truncateFileName,
  watcherServerUrl,
} from "../utils";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import Avatar from "./Avatar";
import DownloadIcon from "mdi-react/DownloadIcon";
import { Dropdown, Spinner } from "react-bootstrap";
import uploadClient from "../../../helper/uploadClient";
import { socket } from "../../../sockets";
import {
  CheckIcon,
  ClapIcon,
  FireIcon,
  HeartIcon,
  ReactionToggle,
  ReUploadIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from "./Icons";
import eventBus from "../../../helper/EventBus";
import { updateFileName } from "../../../services/userServices";
import Mention from "./../components/Mention";
import { LockIcon } from "../../topbar/Icons";
import AtIcon from "mdi-react/AtIcon";
import EmoticonHappyOutlineIcon from "mdi-react/EmoticonHappyOutlineIcon";
import ProgressAlert from "mdi-react/ClockTimeNineOutlineIcon";
import EmojiPicker from "./EmojiPicker";
import { connect } from "react-redux";
import {
  retryUploadFile,
  uploadFile,
} from "../../../store/actions/uploadFileActions";
import { toArray } from "lodash-es";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { useLongPress } from "../custom-hooks";
import { isEmpty, slice, uniq } from "lodash";
import EditCommentForm from "./EditCommentForm";
import EditCommentReplyForm from "./EditCommentReplyForm";

function ReactionResolver({ reaction }) {
  //console.log(reaction);
  switch (reaction) {
    case "heart":
      return <HeartIcon />;
    case "thumbsUp":
      return <ThumbsUpIcon />;
    case "thumbsDown":
      return <ThumbsDownIcon />;
    case "check":
      return <CheckIcon />;
    case "clap":
      return <ClapIcon />;
    case "fire":
      return <FireIcon />;
    default:
      return <ThumbsUpIcon />;
  }
}

function ReactionChooseDropdown({
  taskId,
  comment,
  reply,
  userId,
  updateComment,
  updateCommentReply,
}) {
  const reactToComment = (reactionType) => {
    if (comment) {
      // Update before going to server
      if (!comment.reactionsCount) {
        const lastCommentReaction = comment?.lastReactions
          ? comment.lastReactions[comment.lastReactions.length - 1]
          : null;

        let lastCommentReactions = comment.lastReactions
          ? [lastCommentReaction, reactionType]
          : [reactionType];
        updateComment({
          comment,
          updatedContent: {
            lastReactions: lastCommentReactions,
          },
        });
      }
    } else {
      if (!reply.reactionsCount) {
        const lastReplyReaction = reply?.lastReactions
          ? reply.lastReactions[reply.lastReactions.length - 1]
          : null;

        let lastReactions = reply.lastReactions
          ? [lastReplyReaction, reactionType]
          : [reactionType];
        updateCommentReply({
          reply,
          updatedContent: {
            lastReactions,
          },
        });
      }
    }

    socket.emit(
      "fx:react-to-comment",
      {
        taskId,
        commentId: comment ? comment._id : reply._id,
        reactionType,
        userId,
      },
      (response) => {
        const lastReactions = response.lastReactions;
        const { reactionsCount } = response.reactions;

        if (comment) {
          /*const lastCommentReaction = comment?.lastReactions
            ? comment.lastReactions[comment.lastReactions.length - 1]
            : null;

          let lastCommentReactions = comment.lastReactions
            ? [lastCommentReaction, reactionType]
            : [reactionType];*/
          updateComment({
            comment,
            updatedContent: {
              reactionsCount,
              lastReactions,
            },
          });
        } else {
          /*const lastReplyReaction = reply?.lastReactions
            ? reply.lastReactions[reply.lastReactions.length - 1]
            : null;

          let lastReactions = reply.lastReactions
            ? [lastReplyReaction, reactionType]
            : [reactionType];*/
          updateCommentReply({
            reply,
            updatedContent: { reactionsCount, lastReactions },
          });
        }
      }
    );
  };

  return (
    <Dropdown alignRight drop="up">
      <Dropdown.Toggle bsPrefix={`reaction-toggle`}>
        <ReactionToggle />
      </Dropdown.Toggle>
      <Dropdown.Menu
        bsPrefix={"reaction-menu"}
        popperConfig={{
          strategy: "fixed",
        }}
      >
        <Dropdown.Item
          onClick={() => reactToComment("thumbsUp")}
          as="button"
          title="Thumbs up"
        >
          <ThumbsUpIcon />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => reactToComment("thumbsDown")}
          as="button"
          title="Thumbs down"
        >
          <ThumbsDownIcon />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => reactToComment("clap")}
          as="button"
          title="Clap"
        >
          <ClapIcon />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => reactToComment("heart")}
          as="button"
          title="Heart"
        >
          <HeartIcon />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => reactToComment("fire")}
          as="button"
          title="Fire"
        >
          <FireIcon />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => reactToComment("check")}
          as="button"
          title="Check"
        >
          <CheckIcon />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function UserReactions({ comment, taskId, user }) {
  const [activeTab, setActiveTab] = useState("all");
  const [tabs, setTabs] = useState([]);
  const [users, setUsers] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);

  const getAllUsers = (users) => {
    return [
      ...(users.thumbsUp
        ? users.thumbsUp.map((el) => ({ ...el, icon: <ThumbsUpIcon /> }))
        : []),
      ...(users.thumbsDown
        ? users.thumbsDown.map((el) => ({ ...el, icon: <ThumbsDownIcon /> }))
        : []),
      ...(users.heart
        ? users.heart.map((el) => ({ ...el, icon: <HeartIcon /> }))
        : []),
      ...(users.clap
        ? users.clap.map((el) => ({ ...el, icon: <ClapIcon /> }))
        : []),
      ...(users.check
        ? users.check.map((el) => ({ ...el, icon: <CheckIcon /> }))
        : []),
      ...(users.fire
        ? users.fire.map((el) => ({ ...el, icon: <FireIcon /> }))
        : []),
    ];
  };

  const getUsersInTab = (reactionType) => {
    if (isEmpty(users)) return;
    if (reactionType === "all") {
      setFilteredUsers(getAllUsers(users));
    } else {
      setFilteredUsers(
        [...users[reactionType]].map((el) => ({
          ...el,
          icon: <ReactionResolver reaction={reactionType} />,
        }))
      );
    }

    /* socket.emit(
      "fx:react-users",
      {
        taskId,
        commentId: comment._id,
        reactionType: "all",
      },
      (response) => {}
    );*/
  };

  useEffect(() => {
    getUsersInTab(activeTab);
  }, [activeTab]);

  const init = () => {
    socket.emit(
      "fx:react-all",
      {
        taskId,
        commentId: comment._id,
      },
      (response) => {
        if (response.reactions) {
          setTabs([
            {
              title: "Thumbs Up",
              reactionType: "thumbsUp",
              icon: <ThumbsUpIcon />,
              count: response.reactions.thumbsUpCount,
            },
            {
              title: "Thumbs Down",
              reactionType: "thumbsDown",
              icon: <ThumbsDownIcon />,
              count: response.reactions.thumbsDownCount,
            },
            {
              title: "Clap",
              reactionType: "clap",
              icon: <ClapIcon />,
              count: response.reactions.clapCount,
            },
            {
              title: "Heart",
              reactionType: "heart",
              icon: <HeartIcon />,
              count: response.reactions.heartCount,
            },
            {
              title: "Fire",
              reactionType: "fire",
              icon: <FireIcon />,
              count: response.reactions.fireCount,
            },
            {
              title: "Check",
              reactionType: "check",
              icon: <CheckIcon />,
              count: response.reactions.checkCount,
            },
          ]);
        }

        if (response.users) {
          setUsers(response.users);
          setFilteredUsers(getAllUsers(response.users));
          setActiveTab("all");
        }
      }
    );
  };

  const onToggle = (isOpen) => {
    if (isOpen) init();
  };

  const openChat = (userId) => {
    if (userId && userId !== user._id) eventBus.dispatch("open-chat", userId);
  };

  return (
    <div className="user-reactions">
      {comment.reactionsCount ? (
        <Dropdown onToggle={onToggle} bsPrefix="user-reactions-dropdown">
          <Dropdown.Toggle variant="light" bsPrefix="user-reactions-toggle">
            {comment.lastReactions
              ? uniq(comment.lastReactions).map((el, index, arr) => (
                  <React.Fragment key={index}>
                    {comment.reactionsCount === 1 &&
                    index === 0 &&
                    arr.length > 1 ? (
                      ""
                    ) : (
                      <span className="emoji-holder">
                        <ReactionResolver reaction={el} />
                      </span>
                    )}
                  </React.Fragment>
                ))
              : ""}

            <span>{comment.reactionsCount}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix={"user-reactions-menu"}
            popperConfig={{
              strategy: "fixed",
            }}
          >
            <div className="tabs">
              <button
                className={`btn tab ${activeTab === "all" ? "checked" : ""}`}
                onClick={() => setActiveTab("all")}
              >
                All <span>({comment.reactionsCount})</span>
              </button>

              {tabs.map((el, i) => (
                <React.Fragment key={i}>
                  {el.count ? (
                    <button
                      className={`btn tab ${
                        activeTab === el.reactionType ? "checked" : ""
                      }`}
                      onClick={() => setActiveTab(el.reactionType)}
                    >
                      {el.icon} <span>({el.count})</span>
                    </button>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
            <div className="content">
              {filteredUsers.map((user, i) => (
                <React.Fragment key={i}>
                  <Dropdown.Item
                    as={"button"}
                    className="user-item"
                    onClick={() => openChat(user._id)}
                  >
                    <Avatar user={user} />
                    <span className="flex-grow-1">{fullName(user.name)}</span>
                    <span>{user.icon}</span>
                  </Dropdown.Item>
                </React.Fragment>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </div>
  );
}

const CommentFile = ({
  commentFile,
  fileId,
  index,
  type,
  commentId,
  token,
  taskId,
  comment,
  showCommentFiles,
  selectedProject,
  updateCommentFiles,
  updateCommentReplyFiles,
  isShared,
  retryUploadFile,
  uploadFile,
  setAsCover,
}) => {
  const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
  const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
  // let firstUpdate = useRef(true);
  const [file, setFile] = useState(commentFile);

  const _file = useRef(commentFile);
  const _setFile = (data) => {
    _file.current = data;
    setFile(data);
  };

  useEffect(() => {
    _setFile(commentFile);
  }, [commentFile]);

  const addFileToComment = (file) => {
    if (styleHelper.isMobile) {
      eventBus.dispatch("setCurrentTab", "conversations");
    }
    eventBus.dispatch("add-file-to-comment", { file, type });
  };

  /*
  useEffect(() => {
    if (firstUpdate.current) {
      _setFile({ ...commentFile });
      if (commentFile?.file) {
        uploadClient.addEventListener("progress", (pro) => {
          // console.log(pro.id === commentFile.id);
          if (pro.id === commentFile.id) {
            let percentage = Math.floor((pro.current / pro.size) * 100);
            _setFile({
              ...file,
              loading: false,
              progress: percentage,
              sizeUploaded: calculateFileSize(pro.current),
            });
          }
        });
        uploadClient.addEventListener("socket-error", (err) => {
          if (file?.file) {
            _setFile({
              ...file,
              error: true,
            });
          }
        });
        eventBus.on("socket-disconnected", () => {
          if (file?.file) {
            _setFile({
              ...file,
              error: true,
            });
          }
        });
        eventBus.on("socket-error", () => {
          if (file?.file) {
            _setFile({
              ...file,
              error: true,
            });
          }
        });
        uploadClient.addEventListener("canceled", (id) => {
          if (id === commentFile.id) {
            eventBus.dispatch("remove-comment-file", {
              fileId: id,
              commentId: comment._id,
              parentCommentId: comment.parentCommentId,
              type,
            });
          }
        });
        uploadFile();
      } else {
      }
      firstUpdate.current = false;
      return () => {
        uploadClient.removeEventListener("progress", () => {});
        uploadClient.removeEventListener("socket-error", () => {});
        // eventBus.remove("socket-disconnected", () => {});
        // eventBus.remove("socket-error", () => {});
      };
    }
  }, [commentFile]);
  */

  /*
  const saveFileToCollaboration = ({ commentFile, comment, file }) => {
    return new Promise((resolve, reject) => {
      if (socket.connected) {
        socket.emit(
          type === "comment"
            ? "fx:add-comment-file"
            : "fx:add-comment-reply-file",
          {
            ...commentFile,
            token,
          },
          async (response) => {
            if (response.success) {
              if (updateCommentReplyFiles) {
                updateCommentReplyFiles({
                  file: commentFile,
                  commentId: comment.parentCommentId,
                  commentReplyId: commentId,
                  oldFileId: file.id,
                });
              }
              if (updateCommentFiles) {
                updateCommentFiles({
                  file: commentFile,
                  commentId,
                  oldFileId: file.id,
                });
              }
              console.log("save to collab");
              _setFile({
                ...commentFile,
              });
              resolve(response);
            }
          }
        );
      } else {
        reject();
      }
    });
  };
  */

  /*
  const retryUpload = async () => {
    try {
      _setFile({
        ..._file.current,
        loading: true,
        error: false,
      });
      let stage = file.stage;
      if (stage === 1) {
        const body = {
          name: _file.current.file.name,
          size: _file.current.file.size,
          current_directory_id: _file.current.folder_id,
          id: _file.current.cloud_id,
          type: _file.current.file.type,
          token,
        };
        const cloudRes = await updateFileName(body);
        if (cloudRes.data) {
          _setFile({
            ..._file.current,
            stage: 2,
            error: false,
          });

          const savedFile = await saveFileToCollaboration({
            commentFile: {
              originalName: _file.current.originalName,
              uniqueName: _file.current.uniqueName,
              extension: _file.current.extension,
              size: _file.current.size,
              commentId,
              cloud_id: _file.current.cloud_id,
              taskId,
            },
            file: _file.current,
            comment,
          });
          if (savedFile?.success) {
            _setFile({
              ..._file.current,
              stage: 3,
            });
          } else {
            throw new Error("");
          }
        } else {
          throw new Error("");
        }
      } else if (stage === 2) {
        // save to collaboration
        const savedFile = await saveFileToCollaboration({
          commentFile: {
            originalName: _file.current.originalName,
            uniqueName: _file.current.uniqueName,
            extension: _file.current.extension,
            size: _file.current.size,
            commentId,
            cloud_id: _file.current.cloud_id,
            taskId,
          },
          file: _file.current,
          comment,
        });
        if (savedFile?.success) {
          _setFile({
            ..._file.current,
            stage: 3,
          });
        } else {
          throw new Error("");
        }
      } else if (typeof stage === "undefined") {
        await uploadFile();
      }
    } catch (err) {
      console.log("Upload client error: - ", err);
      _setFile({
        ..._file.current,
        error: true,
      });
    } finally {
      _setFile({
        ..._file.current,
        loading: false,
      });
    }
  };
  */

  /*
  const uploadFile = async () => {
    _setFile({
      ..._file.current,
      loading: true,
      error: false,
    });

    try {
      const folder_id =
        selectedProject && selectedProject?.task_folder_id
          ? selectedProject?.task_folder_id
          : "";

      const res = await uploadClient.upload(file.file, {
        folder_id:
          process.env.REACT_APP_ENV === "production"
            ? folder_id
            : "5f351216c56f413004f6d9fe",

        id: file.id,
      });

      console.log("file res", res);

      if (res) {
        const { cloud_id } = res;
        _setFile({
          ..._file.current,
          stage: 1,
          folder_id,
          cloud_id,
        });
        const body = {
          name: _file.current.file.name,
          size: _file.current.file.size,
          current_directory_id: folder_id,
          id: cloud_id,
          type: _file.current.file.type,
          token,
        };
        //Rename file using it's id
        //if (process.env.REACT_APP_ENV === "production") {
        const cloudRes = await updateFileName(body);
        // console.log("cloud", cloudRes);

        if (cloudRes.data) {
          // save files in backend
          _setFile({
            ...file,
            stage: 2,
          });

          const savedFile = await saveFileToCollaboration({
            commentFile: {
              originalName: _file.current.originalName,
              uniqueName: _file.current.uniqueName,
              extension: _file.current.extension,
              size: _file.current.size,
              commentId,
              cloud_id,
              taskId,
            },
            file: _file.current,
            comment,
          });
          if (savedFile?.success) {
            _setFile({
              ..._file.current,
              stage: 3,
            });
          } else {
            throw new Error("");
          }
        } else {
          throw new Error("");
        }
      }
    } catch (err) {
      const uploadError = uploadClient.getError();
      if (uploadError && uploadError.limited_space) {
        //Make Toast to alert user that space is limited
        showToast({
          message: {
            title: "Cloud Space exhausted",
          },
          type: "error",
        });
      }
      if (uploadError) {
        showToast({
          message: {
            title: "Unable to upload file",
            description: uploadError?.message,
          },
          type: "error",
        });
        console.log("error", uploadError);
      }

      console.log("Upload client error: - ", err);
      _setFile({
        ..._file.current,
        error: true,
      });
    }
  };
  */

  const handleShowCommentFiles = () => {
    if (!file.file) {
      if (["googleFiles", "dropboxFiles"].includes(type)) {
        return window.open(file.url ? file.url : file.link, "_blank");
      } else if (AppRegExp.isImageFullPath(`.${file.extension}`)) {
        showCommentFiles({
          files: comment.files.filter((file) =>
            AppRegExp.isImageFullPath(`.${file.extension}`)
          ),
          indexInView: index,
        });
      } else if (file.extension === "pdf") {
        const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}`;
        eventBus.dispatch("onViewPdf", { fileUrl, ...file });
      } else if (microsoftOfficeFileExtensions.includes(file.extension)) {
        const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
        window.open(fileUrl, "_blank");
      } else {
        const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}?view`;
        window.open(fileUrl, "_blank");
      }
    }
  };

  // TODO - listen for socket connect and save file details

  if (isShared) {
    return (
      <div className="file-other d-flex justify-content-between align-items-center mb-2">
        <div
          className="d-flex align-items-center p-cursor"
          title="Private file"
        >
          <LockIcon className="lock-icon" />
          <div className="d-flex flex-column file-details ml-2">
            <span>
              {truncateFileName({
                ...file,
                maxLength: 15,
                startLimit: 7,
                endLimit: -5,
              })}
            </span>
            <span>{file.size}</span>
          </div>
        </div>
      </div>
    );
  } else if (
    AppRegExp.isImageFullPath(`${file.originalName}.${file.extension}`) &&
    !["googleFiles", "dropboxFiles"].includes(type)
  ) {
    return (
      <div
        className="file-img mb-2 p-cursor"
        style={
          {
            /*...(file.isDelete ? { pointerEvents: "none", opacity: "0.5" } : {}),*/
          }
        }
      >
        <div
          onClick={() => file?.cloud_id && handleShowCommentFiles()}
          className="overlay"
        ></div>
        {file.file ? (
          <span className="image-action text-secondary">
            {file.loading ? (
              <Spinner animation="border" variant="secondary" size="sm" />
            ) : (
              <>
                {file?.progress && file?.file && !file.error ? (
                  <span
                  /* onClick={() =>
                      uploadClient.dispatchEvent("cancel", file.id)
                    }*/
                  >
                    <Progress
                      type="circle"
                      percent={file.progress}
                      strokeWidth={10}
                      width={32}
                      symbolClassName="cancel"
                      theme={progressTheme}
                    />
                  </span>
                ) : null}
                {file.error ? (
                  <ReUploadIcon
                    onClick={() => {
                      retryUploadFile(file.id);
                      uploadFile([{ ...file }]);
                    }}
                  />
                ) : null}
              </>
            )}
          </span>
        ) : (
          <span className="image-action text-secondary download-holder">
            {!file?.file ? (
              <FileMore
                file={file}
                handleShowFile={handleShowCommentFiles}
                downloadFile={downloadFile}
                addFileToComment={addFileToComment}
                setAsCover={setAsCover}
              />
            ) : null}
          </span>
        )}
        <img
          onClick={() => handleShowCommentFiles()}
          alt={`${file.originalName}.${file.extension}`}
          className="w-100"
          src={
            file?.file && !file.cloud_id
              ? URL.createObjectURL(file.file)
              : setFileUrl(file)
          }
        />
      </div>
    );
  } else {
    return (
      <div
        className="file-other d-flex justify-content-between align-items-center mb-2"
        style={{
          ...(file.error ? { background: "#FFFAFA" } : {}),
          /*...(file.isDelete ? { pointerEvents: "none", opacity: "0.5" } : {}),*/
        }}
      >
        <div
          className="d-flex align-items-center p-cursor"
          onClick={() => handleShowCommentFiles()}
        >
          <GetFileIcon file={file} type={type} />
          <div className="d-flex flex-column file-details">
            <span>
              {truncateFileName({
                ...file,
                maxLength: 15,
                startLimit: 7,
                endLimit: -5,
              })}
            </span>
            {file.error ? (
              <span style={file.error ? { color: "#FC6D6C" } : {}}>
                Upload failed
              </span>
            ) : (
              <span>
                {file?.progress && file?.file
                  ? `${file.sizeUploaded} of `
                  : null}
                {/*file.isDelete ? "Deleted" :*/ formatFileSize(file.size)}
              </span>
            )}
          </div>
        </div>

        {/*
          <Progress
            type="circle"
            percent={79}
            strokeWidth={10}
            width={32}
            symbolClassName="cancel"
            theme={progressTheme}
          />
                */}

        {file.error ? (
          <ReUploadIcon
            onClick={() => {
              retryUploadFile(file.id);
              uploadFile([{ ...file }]);
            }}
          />
        ) : null}

        {!file?.file ? (
          <FileMore
            file={file}
            handleShowFile={handleShowCommentFiles}
            downloadFile={downloadFile}
            addFileToComment={addFileToComment}
            setAsCover={setAsCover}
          />
        ) : null}

        {file.loading ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : (
          <>
            {file?.progress &&
            file?.file &&
            !file.error &&
            (!file?.status || !file.createdAt) ? (
              <span>
                <Progress
                  type="circle"
                  percent={file.progress}
                  strokeWidth={10}
                  width={32}
                  symbolClassName="cancel"
                  theme={progressTheme}
                />
              </span>
            ) : null}
          </>
        )}
      </div>
    );
  }
};

const Comment = React.memo(
  ({
    comment,
    setCommentToReply,
    addUsernameToInput,
    isReply,
    getReplies,
    setShowReplies,
    taskId,
    showCommentFiles,
    selectedProject,
    isShared,
    updateCommentFiles,
    updateCommentReplyFiles,
    deleteComment,
    deleteCommentReply,
    saveEditedComment,
    projectUsersList,
    saveEditedCommentReply,
    user,
    token,
    loadMessages,
    fileProgress,
    retryUploadFile,
    uploadFile,
    globalComments,
    handleSelectedComment,
    setAsCover,
    massSelectedComments,
    setMassSelectedComments,
    handleMassSelectedComments,
    updateComment,
    updateCommentReply,
  }) => {
    let replyRefs = useRef({});

    const [editedComment, setEditedComment] = useState("");
    const [editedCommentReply, setEditedCommentReply] = useState("");
    const [editCommentMode, setEditCommentMode] = useState(false);
    // const [refHolder, setRefHolder] = useState(null);
    const [currentCommentReplyToEdit, setCurrentCommentReplyToEdit] = useState(
      {}
    );
    const [showUsersList, setShowUsersList] = useState(false);
    const [showUsersListReply, setShowUsersListReply] = useState(false);

    const [showEmoji, setShowEmoji] = useState(false);
    const [showEmojiReply, setShowEmojiReply] = useState(false);
    const [files, setFiles] = useState([]);

    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
    };

    const onLongPress = () => {
      // alert("long");
    };

    const onClickCustom = () => {
      //  console.log('click is triggered')
    };
    const longPressEvent = useLongPress(
      onLongPress,
      onClickCustom,
      defaultOptions
    );

    useEffect(() => {
      // sort files
      setFiles([
        ...[
          ...toArray(fileProgress).filter(
            (file) =>
              comment.id &&
              file.tempCommentId === comment.id &&
              !comment.files.find((fileEl) => fileEl.cloud_id === file.cloud_id)
          ),
          ...comment.files,
        ],
      ]);
      return () => {};
    }, [fileProgress, comment]);

    const getReplyFiles = ({ fileProgress, replyFiles, reply }) => {
      return [
        ...[
          ...toArray(fileProgress).filter(
            (file) =>
              reply.id &&
              file.tempCommentReplyId === reply.id &&
              !replyFiles.find((fileEl) => fileEl.cloud_id === file.cloud_id)
          ),
          ...replyFiles,
        ],
      ];
    };

    const getReplyComments = ({ replies, globalComments, parentCommentId }) => {
      // console.log("global", toArray(globalComments), parentCommentId);

      return [
        ...toArray(globalComments).filter(
          (commentEl) =>
            commentEl.parentCommentId === parentCommentId &&
            !replies.find((replyEl) => replyEl._id === commentEl._id)
        ),
        ...replies,
      ];
    };

    const formatDate = (date) => {
      if (!date) return "";
      return format(new Date(date), "MMM dd 'at' p");
    };

    const createMarkup = (html) => {
      return { __html: html };
    };

    const editComment = () => {
      const text = document.createElement("div");
      text.innerHTML = comment.comment;
      setEditedComment(text.textContent);
      setEditCommentMode(true);
    };

    const editCommentReply = ({ reply }) => {
      const text = document.createElement("div");
      text.innerHTML = reply.comment;
      setEditedCommentReply(text.textContent);
      setCurrentCommentReplyToEdit({ ...reply });
    };

    useLayoutEffect(() => {
      if (currentCommentReplyToEdit?._id && replyRefs?.current) {
        resizeInputRef(replyRefs.current[currentCommentReplyToEdit._id]);
        focusRef(replyRefs.current[currentCommentReplyToEdit._id]);
      }
    }, [currentCommentReplyToEdit, replyRefs]);

    /*  useLayoutEffect(() => {
      if (editCommentMode) {
        resizeInputRef(refHolder.current);
        focusRef(refHolder.current);
      }
    }, [editCommentMode, refHolder]); */

    const resizeInputRef = (el) => {
      // setTimeout(() => {
      el.style.height = "";
      el.style.height = el.scrollHeight + 10 + "px";
      // }, 500);
    };

    const focusRef = (el) => {
      // setTimeout(() => {
      el.focus();
      el.select();
      // }, 500);
    };

    const resizeInput = (e) => {
      e.target.style.height = "";
      e.target.style.height = e.target.scrollHeight + "px";
    };

    const saveTheEditedComment = () => {
      setEditCommentMode(false);
      saveEditedComment({
        comment,
        newText: editedComment,
      });
    };

    const saveTheEditedCommentReply = () => {
      saveEditedCommentReply({
        reply: currentCommentReplyToEdit,
        newText: editedCommentReply,
      });
      setCurrentCommentReplyToEdit({});
    };

    const setCaretPosition = (textareaRef, caretPos) => {
      if (textareaRef != null) {
        if (textareaRef.createTextRange) {
          const range = textareaRef.createTextRange();
          range.move("character", caretPos);
          range.select();
        } else {
          if (textareaRef.selectionStart) {
            textareaRef.focus();
            textareaRef.setSelectionRange(caretPos, caretPos - 1);
          } else textareaRef.focus();
        }
      }
    };

    const handleSelectUser = ({ user, textareaRef, usage }) => {
      const comment = usage === "comment" ? editedComment : editedCommentReply;
      const commentRef = textareaRef;

      const selectionStart = commentRef.current.selectionStart;
      const currentSelection =
        selectionStart === comment.length + 1
          ? selectionStart - 1
          : selectionStart;
      const firstTextBatch = comment.substring(0, currentSelection);
      const secondTextBatch = comment.substring(
        currentSelection,
        comment.length + 1
      );

      const username = `@${fullName(user.name)} `;
      const textToReplace = comment
        .substring(0, currentSelection)
        .split(/\s+/)
        .pop();

      let newFirstTextBatch;

      /*if (textToReplace !== "@") {
        newFirstTextBatch =
          comment.length && !firstTextBatch.endsWith(" ")
            ? `${firstTextBatch} ${username}`
            : `${firstTextBatch}${username}`;
      } else {
        newFirstTextBatch = firstTextBatch.replace(
          new RegExp(`${textToReplace}$`),
          username
        );
      }*/

      newFirstTextBatch = firstTextBatch.replace(
        new RegExp(`${textToReplace}$`),
        username
      );

      const newText = newFirstTextBatch + secondTextBatch;

      if (usage === "comment") {
        setEditedComment(newText);
        setShowUsersList(false);
      } else {
        setEditedCommentReply(newText);
        setShowUsersListReply(false);
      }

      setTimeout(() => {
        setCaretPosition(commentRef.current, selectionStart + username.length);
      }, 500);
    };

    const handleOnchange = ({ e, textareaRef, usage }) => {
      const commentRef = textareaRef;
      const text = e.target.value;

      const currentSelection = commentRef.current.selectionStart;
      let currentText = text != "" ? text.substring(0, currentSelection) : text;
      const lastWord = currentText
        .replaceAll(/\r?\n|\r/g, " ")
        .split(" ")
        .splice(-1)[0];

      if (usage === "comment") {
        if (lastWord.startsWith("@")) {
          //  console.log(lastWord);
          setShowUsersList(true);
          setTimeout(() => {
            eventBus.dispatch(
              "onSearchUsersInMention",
              lastWord.replaceAll("@", "")
            );
          }, 0);
        } else {
          setShowUsersList(false);
        }
        setEditedComment(text);
      } else {
        if (lastWord.startsWith("@")) {
          //  console.log(lastWord);
          setShowUsersListReply(true);
          setTimeout(() => {
            eventBus.dispatch(
              "onSearchUsersInMention",
              lastWord.replaceAll("@", "")
            );
          }, 0);
        } else {
          setShowUsersListReply(false);
        }
        setEditedCommentReply(text);
      }
    };

    const selectEmoji = ({ emoji, textareaRef, usage }) => {
      const comment = usage === "comment" ? editedComment : editedCommentReply;
      const commentRef = textareaRef;
      const selectionStart = commentRef.current.selectionStart;
      const currentSelection =
        selectionStart === comment.length + 1
          ? selectionStart - 1
          : selectionStart;
      let firstTextBatch = comment.substring(0, currentSelection);
      let secondTextBatch = comment.substring(
        currentSelection,
        comment.length + 1
      );

      firstTextBatch =
        comment.length && !firstTextBatch.endsWith(" ")
          ? `${firstTextBatch} `
          : firstTextBatch;
      secondTextBatch =
        comment.length && !secondTextBatch.startsWith(" ")
          ? ` ${secondTextBatch}`
          : secondTextBatch;
      const newText = `${firstTextBatch}${emoji.native}${secondTextBatch}`;

      if (usage === "comment") {
        setShowEmoji(false);
        setEditedComment(newText);
      } else {
        setShowEmojiReply(false);
        setEditedCommentReply(newText);
      }
      if (commentRef?.current) {
        // commentRef.current.focus();
      }
    };

    const canLoadMore = ({ isReply, comment }) => {
      return (
        !isReply &&
        comment.showReplies &&
        comment.repliesCount > comment.taskCommentReplies?.length
      );
    };

    const setCommentToQuote = (comment) => {
      eventBus.dispatch("setCommentToQuote", { comment });
    };

    const getQuotedComment = (comment) => {
      return comment.quotedReplyId
        ? comment.quotedReplyId
        : comment.quotedCommentId
        ? comment.quotedCommentId
        : null;
    };

    const commentClick = (e) => {
      const userId = e.target.getAttribute("data-id");
      if (userId && userId !== user._id) eventBus.dispatch("open-chat", userId);
    };

    return (
      <div className={`d-flex  ${isReply ? "mb-0" : "mb-3"}`} data-item="true">
        {!isEmpty(massSelectedComments) &&
        comment.employeeId._id === user._id ? (
          <label className="mt-2 mr-3">
            <Checkbox
              checked={massSelectedComments.includes(comment?._id)}
              onChange={(e) =>
                handleMassSelectedComments(e.target.checked, comment?._id)
              }
            />
          </label>
        ) : null}

        <Avatar width={40} height={40} user={comment.employeeId} zIndex={1} />
        <div className="message-box-holder position-relative">
          <div
            className="message-box bg-white position-relative"
            id={comment._id}
          >
            <div className="sender-details d-flex justify-content-between">
              <p className="username">
                {comment?.employeeId?.name.first}{" "}
                {comment?.employeeId?.name.last}
              </p>

              <p className="created-at d-flex align-items-center">
                {formatDate(comment.createdAt)}{" "}
                <span
                  className={`waiting ml-1 ${
                    comment.updatedAt ? "invisible" : ""
                  }`}
                >
                  <ProgressAlert />
                </span>
              </p>
            </div>

            {getQuotedComment(comment) ? (
              <div
                className="quote"
                onClick={() =>
                  handleSelectedComment(
                    { _id: getQuotedComment(comment)._id },
                    "notification"
                  )
                }
              >
                <div className="d-flex justify-content-between align-items-center h-100">
                  <div className="content">
                    <p className="quote-username">
                      {getQuotedComment(comment)?.employeeId?.name.first}{" "}
                      {getQuotedComment(comment)?.employeeId?.name.last}{" "}
                    </p>

                    <p
                      onClick={(e) => commentClick(e)}
                      className="comment"
                      dangerouslySetInnerHTML={createMarkup(
                        getQuotedComment(comment)._id
                          ? getQuotedComment(comment).comment
                          : getQuotedComment(comment).commentMarkUp
                      )}
                    ></p>
                  </div>

                  {getQuotedComment(comment)?.files?.length ? (
                    <div className="image-holder">
                      <>
                        {AppRegExp.isImageFullPath(
                          `${getQuotedComment(comment).files[0].originalName}.${
                            getQuotedComment(comment).files[0].extension
                          }`
                        ) ? (
                          <img
                            src={setFileUrl(getQuotedComment(comment).files[0])}
                            dsrc="https://images.unsplash.com/photo-1629581477374-9237dc216942?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMjIyMzQ2Ng&ixlib=rb-1.2.1&q=80&w=1080"
                            alt={`${
                              getQuotedComment(comment).files[0].originalName
                            }.${getQuotedComment(comment).files[0].extension}`}
                            className="w-100"
                          />
                        ) : (
                          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                            <GetFileIcon
                              file={getQuotedComment(comment).files[0]}
                              className={`icon-ga`}
                              style={{
                                transform: "scale(1.5) !important",
                              }}
                            />
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            ) : null}

            {!editCommentMode ? (
              <p
                onClick={(e) => commentClick(e)}
                className="comment"
                dangerouslySetInnerHTML={createMarkup(
                  comment._id ? comment.comment : comment.commentMarkUp
                )}
              ></p>
            ) : (
              <EditCommentForm
                {...{
                  editCommentMode,
                  resizeInput,
                  handleOnchange,
                  saveTheEditedComment,
                  setEditCommentMode,
                  editedComment,
                  showUsersList,
                  setShowUsersList,
                  showEmoji,
                  selectEmoji,
                  setShowEmoji,
                  projectUsersList,
                  handleSelectUser,
                  resizeInputRef,
                  focusRef,
                }}
              />
            )}

            <div className="comment-files">
              {files
                ?.sort((a, b) => a?.sortIndex - b?.sortIndex)
                .map((file, index) => (
                  <CommentFile
                    key={file.id ? file.id : file.cloud_id}
                    index={index}
                    fileId={file.id}
                    token={token}
                    commentFile={file}
                    taskId={taskId}
                    commentId={comment._id}
                    comment={{ ...comment, files: [...files] }}
                    type={"comment"}
                    selectedProject={selectedProject}
                    showCommentFiles={showCommentFiles}
                    updateCommentFiles={updateCommentFiles}
                    isShared={isShared}
                    retryUploadFile={retryUploadFile}
                    uploadFile={uploadFile}
                    setAsCover={setAsCover}
                  />
                ))}

              {comment?.googleFiles &&
                comment.googleFiles.map((file, index) => (
                  <CommentFile commentFile={file} type="googleFiles" />
                ))}
              {comment?.dropboxFiles &&
                comment.dropboxFiles.map((file, index) => (
                  <CommentFile commentFile={file} type="dropboxFiles" />
                ))}
            </div>

            {comment.updatedAt && (
              <div className="toggle-holders">
                <ReactionChooseDropdown
                  taskId={taskId}
                  comment={comment}
                  userId={user._id}
                  updateComment={updateComment}
                />
                {/* -- */}
                <Dropdown>
                  <Dropdown.Toggle bsPrefix={`comment-toggle`}>
                    <ChevronUpIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    bsPrefix={"comment-menu"}
                    popperConfig={{
                      strategy: "fixed",
                    }}
                  >
                    <Dropdown.Item
                      as="button"
                      onClick={() => setCommentToQuote(comment)}
                    >
                      Quote
                    </Dropdown.Item>
                    {!isShared && (
                      <Dropdown.Item
                        as="button"
                        onClick={() => {
                          setCommentToReply({
                            ...comment,
                            taskCommentReplies: [],
                          });
                          addUsernameToInput(
                            `@${fullName(comment.employeeId.name)} `
                          );
                          if (!comment.showReplies)
                            setShowReplies({
                              isShow: true,
                              commentId: comment._id,
                            });
                        }}
                      >
                        Reply
                      </Dropdown.Item>
                    )}
                    {comment.employeeId._id === user._id && (
                      <>
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            handleMassSelectedComments(true, comment?._id)
                          }
                        >
                          Select Message
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            editComment();
                          }}
                        >
                          Edit Message
                        </Dropdown.Item>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item
                          as="button"
                          onClick={() =>
                            deleteComment({
                              commentId: comment._id,
                            })
                          }
                        >
                          Delete Message
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <UserReactions user={user} comment={comment} taskId={taskId} />
          </div>

          {!isReply && comment._id ? (
            <>
              {/*!isShared && (
                <span
                  className="mr-3 p-cursor"
                  onClick={() => {
                    setCommentToReply({ ...comment, taskCommentReplies: [] });
                    addUsernameToInput(`@${comment.employeeId.username} `);
                    if (!comment.showReplies)
                      setShowReplies({ isShow: true, commentId: comment._id });
                  }}
                >
                  Reply
                </span>
                )*/}
              {comment.repliesCount ? (
                <span
                  className="p-cursor mr-3"
                  onClick={() => {
                    setShowReplies({
                      isShow: !comment.showReplies,
                      commentId: comment._id,
                    });
                  }}
                >
                  {comment.showReplies && comment?.taskCommentReplies
                    ? "Hide Replies"
                    : "Show Replies"}{" "}
                  {`(${comment.repliesCount ? comment.repliesCount : 0})`}
                </span>
              ) : null}

              {/*comment.employeeId._id === user._id && (
                <>
                  <span
                    className="p-cursor mr-3"
                    onClick={() => {
                      editComment();
                    }}
                  >
                    Edit
                  </span>
                  <span
                    className="p-cursor mr-3"
                    onClick={() =>
                      deleteComment({
                        commentId: comment._id,
                      })
                    }
                  >
                    Delete{" "}
                  </span>
                </>
                  )*/}
            </>
          ) : null}

          {!isReply && comment?.newerReplyMessagesCount ? (
            <button
              className="block btn btn-sm btn-outline-light text-secondary mb-2 mt-3 font-weight-bold"
              onClick={() =>
                loadMessages({
                  type: "newer",
                  commentType: "reply",
                  replys: comment?.taskCommentReplies,
                  parentCommentId: comment._id,
                })
              }
            >
              load newer conversations
            </button>
          ) : null}

          {!isReply && comment.showReplies && comment?.taskCommentReplies
            ? getReplyComments({
                replies: comment?.taskCommentReplies,
                globalComments,
                parentCommentId: comment._id,
              })
                .sort((a, b) =>
                  compareDesc(
                    new Date(a.createdAt).getTime(),
                    new Date(b.createdAt).getTime()
                  )
                )
                .map((reply, index) => (
                  <div key={`${index}-${reply?._id}`} className="reply">
                    <div className="d-flex">
                      {!isEmpty(massSelectedComments) &&
                      reply.employeeId._id === user._id ? (
                        <label className="mt-1 mr-3">
                          <Checkbox
                            checked={massSelectedComments.includes(reply?._id)}
                            onChange={(e) =>
                              handleMassSelectedComments(
                                e.target.checked,
                                reply?._id
                              )
                            }
                          />
                        </label>
                      ) : null}
                      <Avatar
                        width={28}
                        height={28}
                        user={reply.employeeId}
                        zIndex={1}
                      />
                      <div>
                        <div
                          className="message-box bg-white position-relative"
                          id={reply._id ? reply._id : reply.id}
                        >
                          <div className="sender-details d-flex justify-content-between">
                            <p className="username">
                              {reply.employeeId.name.first}{" "}
                              {reply.employeeId.name.last}
                            </p>
                            <p className="created-at">
                              {formatDate(reply.createdAt)}{" "}
                              <span
                                className={`waiting ${
                                  reply.updatedAt ? "invisible" : ""
                                }`}
                              >
                                <ProgressAlert />
                              </span>
                            </p>
                          </div>

                          {currentCommentReplyToEdit?._id !== reply._id ||
                          (reply.id && !reply._id) ? (
                            <p
                              onClick={(e) => commentClick(e)}
                              className="comment"
                              dangerouslySetInnerHTML={createMarkup(
                                reply._id ? reply.comment : reply.commentMarkUp
                              )}
                            ></p>
                          ) : null}

                          {reply._id &&
                          currentCommentReplyToEdit?._id === reply._id ? (
                            <EditCommentReplyForm
                              {...{
                                reply,
                                currentCommentReplyToEdit,
                                replyRefs,
                                handleOnchange,
                                resizeInput,
                                saveTheEditedCommentReply,
                                setCurrentCommentReplyToEdit,
                                editedCommentReply,
                                setShowUsersListReply,
                                setShowEmojiReply,
                                EmoticonHappyOutlineIcon,
                                handleSelectUser,
                                setShowUsersList,
                                showUsersListReply,
                                showEmojiReply,
                                projectUsersList,
                                selectEmoji,
                                resizeInputRef,
                                focusRef,
                              }}
                            />
                          ) : null}

                          <div className="comment-files">
                            {getReplyFiles({
                              fileProgress,
                              replyFiles: reply.files,
                              reply,
                            })
                              ?.sort((a, b) => a?.sortIndex - b?.sortIndex)
                              .map((file, index) => (
                                <CommentFile
                                  key={index}
                                  index={index}
                                  token={token}
                                  fileId={file.id}
                                  commentFile={file}
                                  taskId={taskId}
                                  comment={{
                                    ...reply,
                                    files: getReplyFiles({
                                      fileProgress,
                                      replyFiles: reply.files,
                                      reply,
                                    }),
                                  }}
                                  commentId={reply._id}
                                  type={"reply"}
                                  selectedProject={selectedProject}
                                  showCommentFiles={showCommentFiles}
                                  updateCommentReplyFiles={
                                    updateCommentReplyFiles
                                  }
                                  isShared={isShared}
                                  retryUploadFile={retryUploadFile}
                                  uploadFile={uploadFile}
                                />
                              ))}
                            {reply?.googleFiles &&
                              reply.googleFiles.map((file, index) => (
                                <CommentFile
                                  commentFile={file}
                                  type="googleFiles"
                                />
                              ))}
                            {reply?.dropboxFiles &&
                              reply.dropboxFiles.map((file, index) => (
                                <CommentFile
                                  commentFile={file}
                                  type="dropboxFiles"
                                />
                              ))}
                          </div>

                          {!isShared && reply._id ? (
                            <div className="toggle-holders">
                              <ReactionChooseDropdown
                                taskId={taskId}
                                reply={reply}
                                userId={user._id}
                                updateCommentReply={updateCommentReply}
                              />
                              <Dropdown>
                                <Dropdown.Toggle bsPrefix={`comment-toggle`}>
                                  <ChevronUpIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  bsPrefix={"comment-menu"}
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => setCommentToQuote(reply)}
                                  >
                                    Quote
                                  </Dropdown.Item>
                                  {!isShared && (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => {
                                        setCommentToReply({
                                          ...reply,
                                          _id: comment._id,
                                          taskCommentReplies: [],
                                        });
                                        addUsernameToInput(
                                          `@${fullName(reply.employeeId.name)} `
                                        );
                                        if (!comment.showReplies)
                                          setShowReplies({
                                            isShow: true,
                                            commentId: comment._id,
                                          });
                                      }}
                                    >
                                      Reply
                                    </Dropdown.Item>
                                  )}
                                  {reply.employeeId._id === user._id && (
                                    <>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() =>
                                          handleMassSelectedComments(
                                            true,
                                            reply?._id
                                          )
                                        }
                                      >
                                        Select Message
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() =>
                                          editCommentReply({
                                            reply,
                                          })
                                        }
                                      >
                                        Edit Message
                                      </Dropdown.Item>
                                      <Dropdown.Divider></Dropdown.Divider>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() =>
                                          deleteCommentReply({
                                            commentId: comment._id,
                                            commentReplyId: reply._id,
                                            taskId,
                                          })
                                        }
                                      >
                                        Delete Message
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          ) : null}

                          <UserReactions
                            user={user}
                            comment={reply}
                            taskId={taskId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            : null}

          {!isReply && comment?.olderReplyMessagesCount ? (
            <button
              className="block btn btn-sm btn-outline-light text-secondary mb-2 mt-3 font-weight-bold"
              onClick={() =>
                loadMessages({
                  type: "older",
                  commentType: "reply",
                  replys: comment?.taskCommentReplies,
                  parentCommentId: comment._id,
                })
              }
            >
              load older conversations
            </button>
          ) : null}
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
  fileProgress: state.uploadFileReducer.fileProgress,
  globalComments: state.commentReducer.globalComments,
});

const mapDispatchToProps = (dispatch) => {
  return {
    retryUploadFile: (fileId) => dispatch(retryUploadFile(fileId)),
    uploadFile: (files) => dispatch(uploadFile(files)),
    // setShowLoginModal: (isShown) => dispatch(setShowLoginModal(isShown)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
