import CloseIcon from "mdi-react/CloseIcon";
import React, { useEffect, useState, useRef } from "react";
import { socket } from "../../../sockets";
import { useDebounce } from "../custom-hooks";
import { createMarkup, formatDateOnly } from "../utils";
import Avatar from "./Avatar";
import { SearchIcon } from "./Icons";

export default function SearchWindow({
  showSearch,
  setShowSearch,
  handleSelectedComment,
  selectedTask,
}) {
  let searchStringRef = useRef(null);
  const [searchedComments, setSearchedComments] = useState([]);
  const [searchString, setSearchString] = useState("");
  const debouncedSearchString = useDebounce(searchString, 500);

  const searchComments = (searchString) => {
    socket.emit(
      "fx:search-comments",
      {
        taskId: selectedTask._id,
        searchString: searchString,
      },
      (response) => {
        if (response?.success) {
          setSearchedComments([...response.replys, ...response.comments]);
          // console.log(response.replys);
        }
      }
    );
  };

  useEffect(() => {
    if (debouncedSearchString) {
      searchComments(debouncedSearchString);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (!showSearch) {
      setSearchString("");
      setSearchedComments([]);
    } else {
      setTimeout(() => {
        if (searchStringRef.current) {
          searchStringRef.current.focus();
        }
      }, 900);
    }
  }, [showSearch, searchStringRef]);

  const searchRegex = (searchString, comment) => {
    const text = document.createElement("div");
    text.innerHTML = comment;
    let commentText = text.textContent;

    let exp = new RegExp(`${searchString}`, "ig");
    return commentText.replaceAll(
      exp,
      `<mark style="padding: 0;">${searchString}</mark>`
    );
  };

  return (
    <div
      className={`search-window position-absolute ${
        showSearch ? "show" : "hide"
      }`}
    >
      <div className="search-area d-flex justify-content-between align-items-center">
        <button className="btn" onClick={() => setShowSearch(false)}>
          <CloseIcon />
        </button>
        <div className="holder d-flex justify-content-between">
          <input
            placeholder="Search..."
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            ref={searchStringRef}
          />
          <SearchIcon />
        </div>
      </div>

      {searchedComments.length ? (
        <p>{searchedComments.length} messages found</p>
      ) : null}

      <div className="search-results mr-1">
        {searchedComments.map((comment) => {
          return (
            <div
              className={`result d-flex align-items-center p-cursor ${
                comment.comment ? "" : "d-none"
              } `}
              onClick={(e) => handleSelectedComment(comment)}
            >
              <div>
                <Avatar user={comment?.employeeId} width={40} height={40} />
              </div>
              <div className="flex-grow-1 pr-2">
                <div className="d-flex justify-content-between align-items-center user-details">
                  <span className="name">{`${comment.employeeId.name.first} ${comment.employeeId.name.last}`}</span>
                  <span className="time">
                    {formatDateOnly(comment.createdAt, true)}
                  </span>
                </div>
                <p
                  className="message m-0"
                  dangerouslySetInnerHTML={createMarkup(
                    searchRegex(searchString, comment.comment)
                  )}
                ></p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
