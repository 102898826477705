import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import $ from 'jquery';
import '../../assets/css/jobgrade.css'
import { URLS } from '../urls';

export default class AddJobGradeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobGrade: {
                name: '',
                description: '',
                salary: {
                    basic: 0,
                    housing: 0,
                    medical: 0,
                    transport: 0,
                    utility: 0,
                    paidLeave: 0,
                    gratuity: 0,
                    offshoreAllowances: 0,
                    transitAllowances: 0,
                    overTimeAllowances: 0,
                }
            },
        };
    }

    updateJobGradeName = (event) => {
        const { jobGrade } = this.state;
        jobGrade.name = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeDescription = (event) => {
        const { jobGrade } = this.state;
        jobGrade.description = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryBasic = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.basic = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryHousing = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.housing = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryMedical = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.medical = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryTransport = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.transport = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryUtility = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.utility = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryPaidLeave = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.paidLeave = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryGratuity = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.gratuity = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryOffshoreAllowances = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.offshoreAllowances = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryTransitAllowances = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.transitAllowances = event.target.value;
        this.setState({
            jobGrade
        });
    };
    updateJobGradeSalaryOverTimeAllowances = (event) => {
        const { jobGrade } = this.state;
        jobGrade.salary.overTimeAllowances = event.target.value;
        this.setState({
            jobGrade
        });
    };
    submitJob = (e) => {
        e.preventDefault();
        console.log('in submit');
        const { jobGrade } = this.state;
        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendJobGrades}/add`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ jobGrade: jobGrade }),
        }).then(res => res.json())
            .then((result) => {
                if(result.success){
                    this.props.updateJobGradesList(result.jobGrade);
                    this.props.changeShowJobState();
                }else{
                    this.props.changeShowJobState();
                    const  e = Error(result.err);
                    console.log(e);
                }
            }).catch(err => {
                alert("Error new Job Grade not added");
        });

    };

    cancelJob = (e) => {
        e.preventDefault();
        console.log('in cancel');
        this.props.changeShowJobState();
        // $('#add_job').hide();
    };

    render() {
        const { jobGrade } = this.state;
        return (
            <div id='add_job' style={
                this.props.isShow ?
                {
                    width: '50%',
                    position: 'fixed',
                    left: '50%',
                    marginLeft: '-25%',
                    top: '20px',
                    zIndex: '2000',
                    display: 'block',
                    backgroundColor: '#eaecef',
                    height: '100%',
                    borderRadius: '10px'
                }:{
                        width: '50%',
                        position: 'fixed',
                        left: '50%',
                        marginLeft: '-25%',
                        top: '20px',
                        zIndex: '2000',
                        display: 'none',
                        backgroundColor: '#eaecef',
                        height: '100%',
                        borderRadius: '10px'
                    }
            }>
                <Form onSubmit={this.submitJob} style={
                    {
                        overflowY: 'scroll',
                        maxHeight: '100%',
                        // padding: '50px 30px',
                        borderRadius: '10px'
                    }
                }>
                    <Form.Group as={Row} style={{
                        backgroundColor: '#5378b2',
                        alignItems: 'left',
                        justifyContent: 'left',
                        fontSize: '16px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        height:'60px',
                        borderRadius: '10px 10px 0 0',
                        padding : '10px 15px',
                        marginLeft: '0',
                        marginRight: '0',
                        display : 'block'
                    }}>
                        <span style={{display:'inline-block', height:'40px',lineHeight:'40px',float:'left'}}>Create New Job Grade</span>
                        <span style={{display:'inline-block', height:'40px',float:'right',padding: '2px 10px'}}>
                            <button className='btn btn-info' style={
                                {
                                    padding:'2px 10px',
                                    background: '#ffffff',
                                    color: '#000000',
                                    borderRadius: '20px',
                                    border: 'none'
                                }
                            } onClick={this.cancelJob}>x</button>
                        </span>
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0', 'padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Job Grade
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control value={jobGrade.name} onChange={this.updateJobGradeName} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0', 'padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Description
                        </Form.Label>
                        <Col sm={6}>
                            <textarea className='form-control' rows='5' value={jobGrade.description}
                                onChange={this.updateJobGradeDescription} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{
                        backgroundColor: '#5378b2',
                        alignItems: 'left',
                        justifyContent: 'left',
                        fontSize: '16px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        height:'40px',
                        padding : '10px 15px',
                        marginLeft: '0',
                        marginRight: '0',
                        display : 'block'
                    }}>
                        Job Grade Salary Settings
                    </Form.Group>

                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0', 'padding-left': '10px'}}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Basic
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.basic} onChange={this.updateJobGradeSalaryBasic} />
                        </Col>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Housing
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.housing} onChange={this.updateJobGradeSalaryHousing} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0','padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Medical
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.medical} onChange={this.updateJobGradeSalaryMedical} />
                        </Col>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Transport
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.transport}
                                onChange={this.updateJobGradeSalaryTransport} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0','padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Utility
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.utility} onChange={this.updateJobGradeSalaryUtility} />
                        </Col>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Paid Leave
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.paidLeave}
                                onChange={this.updateJobGradeSalaryPaidLeave} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0','padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={{ span: 2, offset: 6 }}>
                            Gratuity
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.gratuity}
                                onChange={this.updateJobGradeSalaryGratuity} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{
                        backgroundColor: '#5378b2',
                        alignItems: 'left',
                        justifyContent: 'left',
                        fontSize: '16px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        height:'40px',
                        padding : '10px 15px',
                        marginLeft: '0',
                        marginRight: '0',
                        display : 'block'
                    }}>
                        Other Allowance
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0', 'padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Offshore Allowance
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.offshoreAllowances}
                                onChange={this.updateJobGradeSalaryOffshoreAllowances} />
                        </Col>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            Transit Allowance
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.transitAllowances}
                                onChange={this.updateJobGradeSalaryTransitAllowances} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ marginLeft: '0', marginRight: '0', 'padding-left': '10px' }}>
                        <Form.Label style={{ fontSize: '14px' }} column sm={2}>
                            OverTime Allowance
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control value={jobGrade.salary.overTimeAllowances}
                                onChange={this.updateJobGradeSalaryOverTimeAllowances} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{marginBottom:'30px', marginLeft: '0', marginRight: '0',paddingRight: '15px', justifyContent: 'flex-end'}}>
                        <button style={{borderRadius : '7px',backgroundColor:'#5378b2', border:'none'}} className='btn btn-primary submit-btn'>Create Now</button>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}