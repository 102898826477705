import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import $ from "jquery";
import { URLS } from "../urls";
import Datepicker from "react-datetime";
import moment from "moment";
import "../../../src/assets/css/date-picker.css";

export default class EmployeeTraining extends Component {
  constructor(props) {
    super(props);
    this.setStateData = this.setStateData.bind(this);
    this.state = {
      training: {
        employee: null,
        courseName: "",
        trainingInstitute: "",
        startDate: "",
        endDate: "",
        trainingDescription: "",
        trainingStatus: "",
        certificate: "",
      },
      trainings: [],
      editTraining: {
        employee: null,
        courseName: "",
        trainingInstitute: "",
        startDate: "",
        endDate: "",
        trainingDescription: "",
        trainingStatus: "",
        certificate: "",
      },
    };
  }
  componentDidMount() {
    const token = "dummy token";
    const id = window.location.pathname.split("/")[3];
    fetch(`${URLS.backendTrainings}/trainings/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          trainings: result.trainings,
        });
      });
  }

  updateTrainingCourseName = (e) => {
    let value = e.target.value;
    const { training } = this.state;
    training.courseName = value;
    this.setState(training);
  };
  updateTrainingTrainingInstitute = (e) => {
    let value = e.target.value;
    const { training } = this.state;
    training.trainingInstitute = value;
    this.setState(training);
  };
  updateTrainingStartDate = (e) => {
    let value = e.target ? e.target.value : moment(e).format("DD/MM/YYYY");
    const { training } = this.state;
    training.startDate = value;
    this.setState(training);
  };
  updateTrainingEndDate = (e) => {
    let value = e.target ? e.target.value : moment(e).format("DD/MM/YYYY");
    const { training } = this.state;
    training.endDate = value;
    this.setState(training);
  };
  updateTrainingTrainingDescription = (e) => {
    let value = e.target.value;
    const { training } = this.state;
    training.trainingDescription = value;
    this.setState(training);
  };
  updateTrainingTrainingStatus = (e) => {
    let value = e.target.value;
    const { training } = this.state;
    training.trainingStatus = value;
    this.setState(training);
  };
  updateTrainingCertificate = (e) => {
    let value = e.target.value;
    const { training } = this.state;
    training.certificate = value;
    this.setState(training);
  };
  updateEditTrainingCourseName = (e) => {
    let value = e.target.value;
    const { editTraining } = this.state;
    editTraining.courseName = value;
    this.setState(editTraining);
  };
  updateEditTrainingTrainingInstitute = (e) => {
    let value = e.target.value;
    const { editTraining } = this.state;
    editTraining.trainingInstitute = value;
    this.setState(editTraining);
  };
  updateEditTrainingStartDate = (e) => {
    let value = e.target ? e.target.value : moment(e).format("DD/MM/YYYY");
    const { editTraining } = this.state;
    editTraining.startDate = value;
    this.setState(editTraining);
  };
  updateEditTrainingEndDate = (e) => {
    let value = e.target ? e.target.value : moment(e).format("DD/MM/YYYY");
    const { editTraining } = this.state;
    editTraining.endDate = value;
    this.setState(editTraining);
  };
  updateEditTrainingTrainingDescription = (e) => {
    let value = e.target.value;
    const { editTraining } = this.state;
    editTraining.trainingDescription = value;
    this.setState(editTraining);
  };
  updateEditTrainingTrainingStatus = (e) => {
    let value = e.target.value;
    const { editTraining } = this.state;
    editTraining.trainingStatus = value;
    this.setState(editTraining);
  };
  updateEditTrainingCertificate = (e) => {
    let value = e.target.value;
    const { editTraining } = this.state;
    editTraining.certificate = value;
    this.setState(editTraining);
  };

  setStateData = (idx) => (evt) => {
    const { trainings } = this.state;
    this.setState({
      editTraining: trainings[idx],
    });
  };

  handleTrainingsSubmit = (e) => {
    e.preventDefault();
    const id = window.location.pathname.split("/")[3];
    const token = "dummy token";
    const { training } = this.state;
    training.employee = id;
    fetch(`${URLS.backendTrainings}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "some-header": "yes",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ training: training }),
    })
      .then((res) => res.json())
      .then((result) => {
        const training = {
          employee: null,
          courseName: "",
          trainingInstitute: "",
          startDate: "",
          endDate: "",
          trainingDescription: "",
        };
        const { trainings } = this.state;
        this.setState({
          training: training,
          trainings: trainings.concat(result.training),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleEditTrainingsSubmit = (e) => {
    e.preventDefault();
    const token = "dummy token";
    const { editTraining } = this.state;
    fetch(`${URLS.backendTrainings}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "some-header": "yes",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ training: editTraining }),
    })
      .then((res) => res.json())
      .then((result) => {
        const editTraining = result.training;
        const { trainings } = this.state;
        for (let i = 0; i < trainings.length; i++) {
          if (trainings[i]._id === editTraining._id) {
            trainings[i] = editTraining;
            break;
          }
        }
        this.setState({
          trainings: trainings,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { training, trainings, editTraining } = this.state;
    return (
      <div className="content container-fluid">
        <div className="row">
          <div className="col-auto text-right float-right ml-auto m-b-30">
            <a
              href=""
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_employee_training"
            >
              <i className="fa fa-plus" /> Add New Training
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped custom-table mb-0">
                <thead>
                  <tr>
                    <th>Course ame</th>
                    <th>Training Institute</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th className="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {trainings.length > 0
                    ? trainings.map((empTraining, idx) => {
                        return (
                          <tr className="holiday-completed">
                            <td>{empTraining.courseName}</td>
                            <td>{empTraining.trainingInstitute}</td>
                            <td>{empTraining.startDate}</td>
                            <td>{empTraining.endDate}</td>
                            <td>{empTraining.trainingDescription}</td>
                            <td>{empTraining.trainingStatus}</td>
                            <td>
                              <div
                                className="dropdown profile-action"
                                style={{ position: "relative" }}
                              >
                                <a
                                  href=""
                                  className="action-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="material-icons">more_vert</i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <a
                                    className="dropdown-item"
                                    href=""
                                    data-toggle="modal"
                                    data-target="#edit_employee_training"
                                    onClick={this.setStateData(idx)}
                                  >
                                    <i className="fa fa-pencil m-r-5" /> Edit
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href=""
                                    data-toggle="modal"
                                    data-target="#delete_employee"
                                  >
                                    <i className="fa fa-trash-o m-r-5" /> Delete
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          id="edit_employee_training"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Scheduled Training</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Course Name<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={editTraining.courseName}
                            onChange={this.updateEditTrainingCourseName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Training Institution
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={editTraining.trainingInstitute}
                            onChange={this.updateEditTrainingTrainingInstitute}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">Start Date</label>
                        <div className="input-group">
                          <div classname="datePicker">
                            <i
                              class="fa fa-calendar fa-xs  calendar-icon-2"
                              aria-hidden="true"
                            ></i>
                            <Datepicker
                              value={editTraining.startDate}
                              closeOnSelect={true}
                              onChange={this.updateEditTrainingStartDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">End Date</label>
                        <div className="input-group">
                          <div classname="datePicker">
                            <i
                              class="fa fa-calendar fa-xs calendar-icon-2"
                              aria-hidden="true"
                            ></i>
                            <Datepicker
                              value={editTraining.endDate}
                              closeOnSelect={true}
                              onChange={this.updateEditTrainingEndDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Training Description
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={editTraining.trainingDescription}
                            onChange={
                              this.updateEditTrainingTrainingDescription
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Training Status<span className="text-danger">*</span>
                        </label>

                        <Form.Control
                          as="select"
                          value={editTraining.trainingStatus}
                          onChange={this.updateEditTrainingTrainingStatus}
                        >
                          <option>Select</option>
                          <option value="PENDING">Pending</option>
                          <option value="COMMENCED">Commenced</option>
                          <option value="COMPLETED">Completed</option>
                        </Form.Control>
                      </div>
                    </div>
                  </div>
                  {editTraining.trainingStatus === "COMPLETED" && (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Certificates upload
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              className="form-control"
                              onChange={this.updateEditTrainingCertificate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#edit_employee_training"
                      onClick={this.handleEditTrainingsSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          id="add_employee_training"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Schedule New Training</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Course Name<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={training.courseName}
                            onChange={this.updateTrainingCourseName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Training Institution
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={training.trainingInstitute}
                            onChange={this.updateTrainingTrainingInstitute}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">Start Date</label>
                        <div className="input-group">
                          <div classname="datePicker">
                            <i
                              class="fa fa-calendar fa-xs calendar-icon-2"
                              aria-hidden="true"
                            ></i>
                            <Datepicker
                              value={training.startDate}
                              closeOnSelect={true}
                              onChange={this.updateTrainingStartDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">End Date</label>
                        <div className="input-group">
                          <div classname="datePicker">
                            <i
                              class="fa fa-calendar fa-xs calendar-icon-2"
                              aria-hidden="true"
                            ></i>
                            <Datepicker
                              value={training.endDate}
                              closeOnSelect={true}
                              onChange={this.updateTrainingEndDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Training Description
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={training.trainingDescription}
                            onChange={this.updateTrainingTrainingDescription}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Training Status<span className="text-danger">*</span>
                        </label>

                        <Form.Control
                          as="select"
                          value={training.trainingStatus}
                          onChange={this.updateTrainingTrainingStatus}
                        >
                          <option>Select</option>
                          <option value="PENDING">Pending</option>
                          <option value="COMMENCED">Commenced</option>
                          <option value="COMPLETED">Completed</option>
                        </Form.Control>
                      </div>
                    </div>
                  </div>
                  {training.trainingStatus === "COMPLETED" && (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Certificates upload
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              className="form-control"
                              onChange={this.updateTrainingCertificate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      data-toggle="modal"
                      data-target="#add_employee_training"
                      onClick={this.handleTrainingsSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
