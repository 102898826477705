import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';

export default class PerfomanceTaskChart extends Component {
	constructor(props) {
		super(props);

		const data = props && props.data ? props.data : {};
		this.state = {
			data,
		};
	}

	render() {
		const { data } = this.state;
		return (
			<PieChart width={120} height={120}>
				<Pie
					dataKey="tasks"
					startAngle={240}
					endAngle={
						data.Completed && data.Completed[0]['tasks'] ? 240 - data.Completed[0]['tasks'] * 3.6 : 240
					}
					data={data.Completed && data.Completed}
					cx={60}
					cy={60}
					outerRadius={55}
					innerRadius={50}
					fill="#23B654"
				/>
				<Pie
					dataKey="tasks"
					startAngle={240}
					endAngle={data.UnderReview && data.UnderReview[0]['tasks'] ? 240 - data.UnderReview[0]['tasks'] * 3.6 : 240}
					data={data.UnderReview && data.UnderReview}
					cx={60}
					cy={60}
					outerRadius={45}
					innerRadius={40}
					fill="#a4ae15"
				/>
				<Pie
					dataKey="tasks"
					startAngle={240}
					endAngle={data.Active && data.Active[0]['tasks'] ? 240 - data.Active[0]['tasks'] * 3.6 : 240}
					data={data.Active && data.Active}
					cx={60}
					cy={60}
					outerRadius={35}
					innerRadius={30}
					fill="#0000ff"
				/>
				<Pie
					dataKey="tasks"
					startAngle={240}
					endAngle={data.Overdue && data.Overdue[0]['tasks'] ? 240 - data.Overdue[0]['tasks'] * 3.6 : 240}
					data={data.Overdue && data.Overdue}
					cx={60}
					cy={60}
					outerRadius={25}
					innerRadius={20}
					fill="#e40b0b"
				/>
			</PieChart>
		);
	}
}
