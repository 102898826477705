import * as Actions from "../actions";

const initialState = {
  isAddTaskModalOpen: true,
  isMoreProjectViewOptions: false
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.TOGGLE_ADD_TASK_MODAL: {
      return {
        ...state,
        isAddTaskModalOpen: !state.isAddTaskModalOpen,
      };
    }
    case Actions.TOGGLE_MORE_PROJECT_VIEW_OPTIONS:{
      const isMoreProjectViewOptions = action.payload;
      return {
        ...state,
        isMoreProjectViewOptions
      }
    }
    default: {
      return state;
    }
  }
};

export default projectReducer;
