import CloseIcon from "mdi-react/CloseIcon";
import ChevronLeftCircle from "mdi-react/ChevronLeftCircleOutlineIcon";
import ChevronRightCircle from "mdi-react/ChevronRightCircleOutlineIcon";
import React, { useState, useEffect } from "react";

import { Modal, Button, Form, Image, Carousel } from "react-bootstrap";
import testImageOne from "../../../assets/test-img/test-image-4.png";
import "../../../assets/scss/fileModal.scss";
import {
  ChevronLeftOutlineMobile,
  ChevronRightOutlineMobile,
  CommentIcon,
  DownloadIcon,
  DownloadMobileIcon,
  LeftIcon,
  RightIcon,
} from "../components/Icons";
import PageLayoutHeaderIcon from "mdi-react/PageLayoutHeaderIcon";
import { AppRegExp, downloadFile } from "../utils";
import eventBus from "../../../helper/EventBus";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function FileModal({
  files,
  showFileModal,
  setShowFileModal,
  indexInView = 0,
  setAsCover,
  canSeeChat,
  hideAsComment,
  isShared,
}) {
  const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
  const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
  const [index, setIndex] = useState(indexInView);

  const handleSelect = (selectedIndex) => {
    if (selectedIndex < 0) {
      setIndex(files.length - 1);
    } else if (selectedIndex > files.length - 1) {
      setIndex(0);
    } else {
      setIndex(selectedIndex);
    }
  };

  const addFileToComment = (file) => {
    eventBus.dispatch("add-file-to-comment", { file });
  };

  return (
    <>
      <Modal
        show={showFileModal}
        onHide={() => {
          setShowFileModal(false);
          setIndex(0);
        }}
        dialogClassName="file-modal"
        backdropClassName="global-backdrop file-backdrop remove max-z-index-2"
        aria-labelledby="example-custom-modal-styling-title"
        animation={false}
      >
        <Modal.Header bsPrefix="modal-header">
          <span className="filename">
            {files[index]?.originalName}.{files[index]?.extension}
          </span>

          <button id="close-btn" onClick={() => setShowFileModal(false)}>
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="file-holder w-100">
            <div className="controls">
              {index != 0 ? (
                <LeftIcon
                  onClick={() => handleSelect(index - 1)}
                  className="p-cursor"
                />
              ) : (
                <span></span>
              )}

              {index < files.length - 1 ? (
                <RightIcon
                  onClick={() => handleSelect(index + 1)}
                  className="p-cursor"
                />
              ) : (
                <span></span>
              )}
            </div>

            <div className="file mt-3">
              <div className="h-100 btn-holder d-none d-md-flex justify-content-end">
                <button
                  id="close-btn"
                  title="close"
                  onClick={() => setShowFileModal(false)}
                >
                  <CloseIcon />
                </button>
              </div>

              <Carousel controls={false} indicators={false} activeIndex={index}>
                {files.map((file) => (
                  <Carousel.Item key={file._id}>
                    <TransformWrapper>
                      <TransformComponent>
                        {
                          <Image
                            src={`${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}?view`}
                            alt={`${file.originalName}.${file.extension}`}
                          />
                        }
                        {/*
                          <Image
                            src={`https://source.unsplash.com/random?q=${file.cloud_id}&t=${file._id}`}
                          />
                        */}
                      </TransformComponent>
                    </TransformWrapper>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>

          <div className="actions">
            <span className="filename">
              {files[index]?.originalName}.{files[index]?.extension}
            </span>

            <div className="d-flex justify-content-center">
              <button
                className="btn download"
                onClick={() => downloadFile(files[index])}
              >
                <DownloadIcon /> <span>Download</span>
              </button>

              {!isShared && (
                <>
                  {!hideAsComment ? (
                    <button
                      className="btn comment"
                      onClick={() => addFileToComment(files[index])}
                    >
                      <CommentIcon /> <span>Comment</span>
                    </button>
                  ) : null}

                  {setAsCover && (
                    <button
                      className="btn text-underline"
                      onClick={() => setAsCover(files[index])}
                    >
                      <PageLayoutHeaderIcon
                        style={{ transform: "rotate(180deg)" }}
                      />
                      <span>Make Cover</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <DownloadMobileIcon onClick={() => downloadFile(files[index])} />
          <div className="mobile-controls">
            <ChevronLeftOutlineMobile
              onClick={() => {
                if (index != 0) handleSelect(index - 1);
              }}
            />
            <ChevronRightOutlineMobile
              onClick={() => {
                if (index < files.length - 1) handleSelect(index + 1);
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
