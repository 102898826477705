import React, { useState } from "react";
import { Promise as bluebird } from "bluebird";
import ToastUI from "./components/ToastUI";
import { toast, Slide } from "react-toastify";
import image1 from "../../assets/board-default-images/img1.svg";
import image2 from "../../assets/board-default-images/img2.svg";
import image3 from "../../assets/board-default-images/img3.svg";
import image4 from "../../assets/board-default-images/img4.svg";
import image5 from "../../assets/board-default-images/img5.svg";
import image6 from "../../assets/board-default-images/img6.svg";
import image7 from "../../assets/board-default-images/img7.svg";
import image8 from "../../assets/board-default-images/img8.svg";
import image9 from "../../assets/board-default-images/img9.svg";
import image10 from "../../assets/board-default-images/img10.svg";
import image11 from "../../assets/board-default-images/img11.svg";

import image001 from "../../assets/board-default-images/new-bg-images/img001.jpg";
import image002 from "../../assets/board-default-images/new-bg-images/img002.jpg";
import image003 from "../../assets/board-default-images/new-bg-images/img003.jpg";
import image004 from "../../assets/board-default-images/new-bg-images/img004.jpg";
import image005 from "../../assets/board-default-images/new-bg-images/img005.jpg";
import image006 from "../../assets/board-default-images/new-bg-images/img006.jpg";
import image007 from "../../assets/board-default-images/new-bg-images/img007.jpg";
import image008 from "../../assets/board-default-images/new-bg-images/img008.jpg";
import image009 from "../../assets/board-default-images/new-bg-images/img009.jpg";
import image010 from "../../assets/board-default-images/new-bg-images/img010.jpg";
import image011 from "../../assets/board-default-images/new-bg-images/img011.jpg";
import image012 from "../../assets/board-default-images/new-bg-images/img012.jpg";
import image013 from "../../assets/board-default-images/new-bg-images/img013.jpg";

import image001T from "../../assets/board-default-images/new-bg-images/thumbnail/img001.jpg";
import image002T from "../../assets/board-default-images/new-bg-images/thumbnail/img002.jpg";
import image003T from "../../assets/board-default-images/new-bg-images/thumbnail/img003.jpg";
import image004T from "../../assets/board-default-images/new-bg-images/thumbnail/img004.jpg";
import image005T from "../../assets/board-default-images/new-bg-images/thumbnail/img005.jpg";
import image006T from "../../assets/board-default-images/new-bg-images/thumbnail/img006.jpg";
import image007T from "../../assets/board-default-images/new-bg-images/thumbnail/img007.jpg";
import image008T from "../../assets/board-default-images/new-bg-images/thumbnail/img008.jpg";
import image009T from "../../assets/board-default-images/new-bg-images/thumbnail/img009.jpg";
import image010T from "../../assets/board-default-images/new-bg-images/thumbnail/img010.jpg";
import image011T from "../../assets/board-default-images/new-bg-images/thumbnail/img011.jpg";
import image012T from "../../assets/board-default-images/new-bg-images/thumbnail/img012.jpg";
import image013T from "../../assets/board-default-images/new-bg-images/thumbnail/img013.jpg";

import { v4 as uuidv4 } from "uuid";

import {
  ImageIcon,
  PdfIcon,
  UnknownFile,
  VideoIcon,
  DocIcon,
  XLSIcon,
  PPTIcon,
  CheckboxIcon,
  UncheckedboxIcon,
  ZipIcon,
  TextIcon,
  FolderIcon,
  DropboxIcon,
  GoogleDriveIcon,
} from "./components/Icons";
import FileIcon from "mdi-react/FileIcon";
import moment from "moment";
import { format } from "date-fns";
import { months } from "moment";
import replaceAsync from "string-replace-async";
import { URLS } from "../urls";
import eventBus from "./../../helper/EventBus";
import { isNumber } from "lodash-es";
import { isEmpty, isFinite } from "lodash";

import { Dropdown } from "react-bootstrap";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { socket } from "../../sockets";

export const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
export const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
export const cloudDriveBackend = process.env.REACT_APP_CLOUD_DRIVE_BACKEND;

export const darkTaskColors = [
  { title: "", colorCode: "#ff9b44" },
  { title: "", colorCode: "#4caf50" },
  { title: "", colorCode: "#3F96D1" },
  { title: "", colorCode: "#ef5350" },
  { title: "", colorCode: "#8e81e2" },
  { title: "", colorCode: "#FFB147" },
  { title: "", colorCode: "#40D886" },
  { title: "", colorCode: "#42a5f5" },
  { title: "", colorCode: "#FC6D6C" },
  { title: "", colorCode: "#7460ee" },
];

export const lightTaskColors = [
  // { title: "", colorCode: "#F5F6F8" },
  { title: "", colorCode: "#FFFFFF", border: "#A7B9CB" },
  { title: "", colorCode: "#FFF9B1" },
  { title: "", colorCode: "#F5D128" },
  { title: "", colorCode: "#FF9D48" },
  { title: "", colorCode: "#D5F692" },
  { title: "", colorCode: "#C9DF56" },
  { title: "", colorCode: "#93D275" },
  { title: "", colorCode: "#67C6C0" },
  { title: "", colorCode: "#FFCEE0" },
  { title: "", colorCode: "#EA94BB" },
  { title: "", colorCode: "#D3D2FF" },
  { title: "", colorCode: "#F16C7F" },
  { title: "", colorCode: "#A6CCF5" },
  { title: "", colorCode: "#6CD8FA" },
  { title: "", colorCode: "#7B92FF" },
  { title: "", colorCode: "#9346E9" },
];

export const AppRegExp = {
  image: /^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  imageFullPath: /.*\.(gif|jpe?g|bmp|png)$/,
  doc: /.*\.(doc?x|rtf)$/,
  ppt: /.*\.(ppt?x)$/,
  xls: /.*\.(xls|xlsx|csv)$/,
  audio: /.*\.(mp3|aac)$/,
  video:
    /.*\.(webm|mpg|mo2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|avchd|mkv)$/,
  zip: /.*\.(zip|rar)$/,
  text: /.*\.(txt)$/,

  isEmail(text) {
    return AppRegExp.email.test(text.toLowerCase());
  },
  isImage(text) {
    return AppRegExp.image.test(text.toLowerCase());
  },
  isImageFullPath(text) {
    return AppRegExp.imageFullPath.test(text.toLowerCase());
  },
  isVideo(text) {
    return AppRegExp.video.test(text.toLowerCase());
  },
  isDoc(text) {
    return AppRegExp.doc.test(text.toLowerCase());
  },
  isPpt(text) {
    return AppRegExp.ppt.test(text.toLowerCase());
  },
  isXls(text) {
    return AppRegExp.xls.test(text.toLowerCase());
  },
  isZip(text) {
    return AppRegExp.zip.test(text.toLowerCase());
  },
  isText(text) {
    return AppRegExp.text.test(text.toLowerCase());
  },
};

export function showToast({ message, type, autoClose = 4500 }) {
  return toast.success(<ToastUI message={message} type={type} />, {
    autoClose,
  });
}

export function GetFileIcon({ file, style, onClick, className, type = null }) {
  file.extension = file.extension ? file.extension : file.ext ? file.ext : "";
  return (
    <span className="position-relative">
      {file.extension?.toLowerCase() === "pdf" ? (
        <PdfIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isDoc(`.${file.extension.toLowerCase()}`) ? (
        <DocIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isImageFullPath(`.${file.extension.toLowerCase()}`) ? (
        <ImageIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isXls(`.${file.extension.toLowerCase()}`) ? (
        <XLSIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isPpt(`.${file.extension.toLowerCase()}`) ? (
        <PPTIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isVideo(`.${file.extension.toLowerCase()}`) ? (
        <VideoIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isZip(`.${file.extension.toLowerCase()}`) ? (
        <ZipIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : AppRegExp.isText(`.${file.extension.toLowerCase()}`) ? (
        <TextIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : file.is_dir ? (
        <FolderIcon
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      ) : (
        <UnknownFile
          className={`file-icon ${className} `}
          style={style}
          onClick={onClick}
        />
      )}

      {type === "googleFiles" || type === "dropboxFiles" ? (
        <span
          class="position-absolute p-0 bg-white rounded-circle d-flex align-items-center justify-content-center"
          style={{
            bottom: "0.25rem",
            right: "0.25rem",
            width: "1.25rem",
            height: "1.25rem",
            minWidth: "1.25rem",
          }}
        >
          {type === "googleFiles" ? (
            <GoogleDriveIcon width="16" height="16" />
          ) : (
            <DropboxIcon width="16" height="16" />
          )}
        </span>
      ) : null}
    </span>
  );
}

export function truncateFileName(
  {
    filename,
    originalName,
    name,
    extension,
    maxLength = 9,
    startLimit = 3,
    endLimit = -2,
    ext,
  },
  settings
) {
  let fname = filename ? filename : originalName ? originalName : name;
  let _ext = extension ? `.${extension}` : ext ? ext : "";
  return fname && fname.length > maxLength
    ? fname.substr(0, startLimit) + ".." + fname.substr(endLimit) + _ext
    : `${fname}${_ext}`;
}

export function getFileDetails({ file }) {
  const name = file.name;
  const lastDot = name.lastIndexOf(".");
  const filename = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);
  let size = file.size;
  if (size < 1024) {
    size = size + " B";
  } else if (size >= 1024 && size < 1024 * 1024) {
    size = (size / 1024).toFixed(2) + " KB";
  } else {
    size = (size / 1024 / 1024).toFixed(2) + " MB";
  }

  return {
    extension: ext,
    filename,
    size,
  };
}

export function isBaseGrey(color) {
  if (!color) return false;
  return !(
    color === "" ||
    color.toLowerCase() === "#ffffff" ||
    color.toLowerCase() === "#fff"
  );
}

export function validationStartDate(currentDate) {
  return currentDate.isAfter(moment(new Date()).add(-1, "days"));
}

export function formatSlashedDate(date, year = false) {
  try {
    if (!date) return "...";
    date = date.split("/");
    // console.log(date);
    const newDate = new Date(date[2], date[1] - 1, date[0]);
    return format(newDate, `MMM dd ${year ? "yyyy" : ""}`);
  } catch (err) {
    return "...";
  }
}

export function dateFormat(date) {
  let d = date.split('T')
  return d[0]
}

export function dateTimeFormat(date) {
  return moment(date).format('LLLL')
}

export function checkExpiration(date) {
  return moment(new Date()) > moment(date)
}

export function formatDateOnly(date, year = false) {
  if (!date) return "...";
  const newDate = new Date(date);
  return format(newDate, `MMM dd ${year ? "yyyy" : ""}`);
}

export function pixelToRem(px) {
  return `${px / 16}rem`;
}

export const photos = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
];

export const newPhotos = [
  image001,
  image002,
  image003,
  image004,
  image005,
  image006,
  image007,
  image008,
  image009,
  image010,
  image011,
  image012,
  image013,
];

export const newPhotosThumbnail = [
  image001T,
  image002T,
  image003T,
  image004T,
  image005T,
  image006T,
  image007T,
  image008T,
  image009T,
  image010T,
  image011T,
  image012T,
  image013T,
];

export const imageThumbnail = [
  `${URLS.backendPublic}/board/bg-images/thumbnail/img001.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img002.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img003.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img004.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img005.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img006.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img007.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img008.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img009.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img010.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img011.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img012.jpg`,
  `${URLS.backendPublic}/board/bg-images/thumbnail/img013.jpg`,
];

export const patternThumbnail = [];

export function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.setAttribute("download", name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadFile(file) {
  if ((file.url || file.link) && !file?.cloud_id) {
    return window.open(file.url ? file.url : file.link, "_blank");
  }
  downloadURI(
    `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName
    }.${file.extension.toLowerCase()}?download`,
    `${file.originalName}.${file.extension.toLowerCase()}`
  );
}

export function reader(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

export const styleHelper = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  isMobile: window.matchMedia("(max-width: 960px )").matches,
};

export function CopyToClipboard({
  text,
  successText = "",
  successDescription = "",
}) {
  const textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(textarea);
  showToast({
    message: {
      title: successText ? successText : "Copied to clipboard",
      description: successDescription,
    },
    type: "success",
  });
}

export function Checkbox(props) {
  return (
    <span>
      <input type="checkbox" className="d-none" {...props} />
      {props.checked ? (
        <CheckboxIcon {...props} />
      ) : (
        <UncheckedboxIcon {...props} />
      )}
    </span>
  );
}

export function getQueryParams(url) {
  let qParams = {};
  // create a binding tag to use a property called search
  let anchor = document.createElement("a");
  // assign the href URL of the anchor tag
  anchor.href = url;
  // search property returns URL query string
  let qStrings = anchor.search.substring(1);
  let params = qStrings.split("&");
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split("=");
    qParams[pair[0]] = decodeURIComponent(pair[1]);
  }
  return qParams;
}

export function getUniqueListBy(arr, key) {
  //console.log(arr);
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const createMarkup = (html) => {
  return { __html: html };
};

export const formatDate = (date) => {
  if (!date) return "";
  return format(new Date(date), "MMM dd 'at' p");
};

export const calculateFileSize = (fileSize) => {
  if (isNaN(fileSize)) return fileSize;
  let size = fileSize;
  if (size < 1024) {
    size = size + " B";
  } else if (size >= 1024 && size < 1024 * 1024) {
    size = (size / 1024).toFixed(2) + " KB";
  } else {
    size = (size / 1024 / 1024).toFixed(2) + " MB";
  }
  return size;
};

export function linkify(text) {
  /*const Regex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi; */

  /* const urlRegex =
    /(https?\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#]?[\w-]+)*\/?/gm; */

  const urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  return text.replace(urlRegex, function (url) {
    url = url.trim();
    let link = /^https?\:\/\//.test(url) ? url : `https://${url}`;
    return `<a href="${link}" target="blank">${url}</a>`;
  });
}

export function fullName(name) {
  if (!name) return "";
  return typeof name === "object" && Object.keys(name).length
    ? `${name.first} ${name.last}`
    : typeof name === "string"
      ? name
      : "";
}

/*let sequence = Promise.resolve();
let seq =
  (fn) =>
  (...args) =>
    (sequence = sequence.then(() => fn(...args)));*/

export async function usernameFromText({ text, members = [] }) {
  return new Promise(async (resolve) => {
    let mentionedUsers = [];
    members
      .reduce((acc, curr) => {
        return acc.then((res) => {
          console.log(acc, curr, res);
          return new Promise(async (resolve) => {
            const replace = `@${fullName(curr.name)}`;
            let exp = new RegExp(replace, "gi");

            const text = await replaceAsync(res, exp, async (username) => {
              let html = username;
              const user = members.find(
                (userEl) => fullName(userEl.name) === username.substring(1)
              );

              if (user) {
                html = `<span title="${fullName(
                  user.name
                )}" class="username-tag" data-id="${user._id}">@${fullName(
                  user.name
                )}</span>`;
                mentionedUsers.push(user._id);
              }
              return html.toString();
            });

            resolve(text);
          });
        });
      }, Promise.resolve(text))
      .then((res) => {
        return resolve({ text: res, mentionedUsers });
      });
  });
}

export function escapeHTML(str) {
  return (
    str
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      // .replace(/\//g, "&#x2F;")
      // .replace(/\\/g, "&#x5C;")
      .replace(/`/g, "&#96;")
  );
}

export function setFileUrl(file) {
  return `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName
    }.${file.extension.toLowerCase()}?view`;
}

export function removeFirstCharacter(text) {
  if (!text) return;
  const textWithoutFirstChar = text.substring(1);
  return textWithoutFirstChar;
}

export function openPageSideBar() {
  eventBus.dispatch("openPageSideBar", null);
}

export const isProjectLeaderOrCreatedBy = (selectedProject, authuser) => {
  try {
    //  is  Company Admin or SuperAdmin
    const isAdminCompanyUser =
      (authuser.isAdmin || authuser.isSuperAdmin) &&
      authuser?.account_type === 2;
    const leaders =
      selectedProject && selectedProject.projectLeader
        ? selectedProject.projectLeader
        : [];
    return (
      leaders.find((leader) => leader._id === authuser._id) ||
      selectedProject.createdBy === authuser._id ||
      selectedProject.createdBy?._id === authuser._id ||
      isAdminCompanyUser
    );
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const calculateProjectProgress = (tasks = []) => {
  let projectProgress = tasks?.length
    ? tasks?.reduce((acc, cur) => {
      if (cur?.status === "AWAITING") {
        acc += 0;
      } else if (cur?.status === "INCOMPLETE") {
        acc += 25;
      } else if (cur?.status === "UNDER_REVIEW") {
        acc += 50;
      } else if (cur?.status === "COMPLETE") {
        acc += 100;
      }
      return acc;
    }, 0)
    : 0;

  const numberOfTasks = tasks?.length || 0;
  projectProgress = (projectProgress / (numberOfTasks * 100)) * 100;
  projectProgress = projectProgress || 0;
  projectProgress =
    typeof projectProgress === "number" && projectProgress !== 0
      ? Math.floor(projectProgress)
      : projectProgress;

  return projectProgress;
};

export const formatFileSize = (fileSize) => {
  let size = fileSize;
  //console.log(isFinite(size), size);
  if (isFinite(size)) {
    // console.log(size);
    if (size < 1024) {
      size = size + " B";
    } else if (size >= 1024 && size < 1024 * 1024) {
      size = (size / 1024).toFixed(2) + " KB";
    } else {
      size = (size / 1024 / 1024).toFixed(2) + " MB";
    }
  }
  return size;
};

export const imagePreloader = (path) => {
  return new Promise(function (resolve, reject) {
    // Create a new image from JavaScript
    var image = new Image();
    // Bind an event listener on the load to call the `resolve` function
    image.onload = resolve;
    // If the image fails to be downloaded, we don't want the whole system
    // to collapse so we `resolve` instead of `reject`, even on error
    image.onerror = resolve;
    // Apply the path as `src` to the image so that the browser fetches it
    image.src = path;
  });
};

export function waitFor(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const allProjectMembers = (
  selectedProject,
  options = {
    includeInvited: false,
    separate: false,
  }
) => {
  let leaders = selectedProject?.projectLeader
    ? selectedProject?.projectLeader.map((user) => {
      return { ...user, isLeader: true };
    })
    : [];

  let team = selectedProject?.team
    ? selectedProject?.team.filter(
      (el) => !leaders.find((user) => user._id === el._id)
    )
    : [];

  let leadersToJoin = [];
  let membersToJoin = [];

  if (options.includeInvited) {
    if (selectedProject.membersToJoin) {
      leadersToJoin = selectedProject.membersToJoin
        .filter((el) => el.purpose === "projectLeader")
        .map((el) => ({
          ...el.userId,
          isInvited: true,
        }));
      membersToJoin = selectedProject.membersToJoin
        .filter((el) => el.purpose === "team")
        .map((el) => ({
          ...el.userId,
          isInvited: true,
        }));

      leaders = [...leaders, ...leadersToJoin];
      team = [...team, ...membersToJoin];
    }

    if (selectedProject.teamEmails) {
      team = [
        ...team,
        ...selectedProject.teamEmails.map((el) => ({
          name: el,
          email: el,
          _id: uuidv4(),
          isInvited: true,
        })),
      ];
    }

    if (selectedProject.projectLeaderEmails) {
      leaders = [
        ...leaders,
        ...selectedProject.projectLeaderEmails.map((el) => ({
          name: el,
          email: el,
          _id: uuidv4(),
          isInvited: true,
        })),
      ];
    }
  }

  if (options.separate) {
    return {
      leaders: getUniqueListBy([...leaders], "_id"),
      team: getUniqueListBy([...team], "_id"),
    };
  }

  return getUniqueListBy([...leaders, ...team], "_id");
};

export const allTaskMembers = (
  task,
  options = {
    includeInvited: false,
    separate: false,
  }
) => {
  let membersToJoin = [];
  let emailsToJoin = [];
  let taskMembers = task?.assignedTo ? task.assignedTo : [];

  if (taskMembers && typeof taskMembers[0] === "string") {
    taskMembers = taskMembers.map((el) => ({ _id: el }));
  }

  if (options.includeInvited) {
    membersToJoin = task?.membersToJoin
      ? task.membersToJoin.map((el) => ({
        _id: uuidv4(),
        ...el,
        isInvited: true,
      }))
      : [];

    if (task?.taskEmails) {
      emailsToJoin = task.taskEmails.map((el) => ({
        name: el,
        email: el,
        _id: uuidv4(),
        isInvited: true,
      }));
    }

    if (options.separate) {
      return {
        assignedTo: getUniqueListBy(
          [...taskMembers, ...membersToJoin, ...emailsToJoin],
          "_id"
        ),
      };
    }
  }

  return getUniqueListBy(
    [...taskMembers, ...membersToJoin, ...emailsToJoin],
    "_id"
  );
};

export const stopEventPropagationTry = (event) => {
  if (event.target === event.currentTarget) {
    event.stopPropagation();
  }
};

export const progressTheme = {
  success: {
    symbol: "100%",
    trailColor: "#A7B9CB",
    color: "#3F96D1",
  },
  active: {
    // symbol: "✖",
    trailColor: "#A7B9CB",
    color: "#3F96D1",
  },
  default: {
    // symbol: "✖",
    trailColor: "#A7B9CB",
    color: "#3F96D1",
  },
};

export function FileMore({
  file,
  handleShowFile,
  downloadFile,
  addFileToComment,
  setAsCover,
}) {
  return (
    <Dropdown alignRight drop="up">
      <Dropdown.Toggle bsPrefix={`btn btn-light p-0 border-none file-more`}>
        <DotsVerticalIcon />
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={`menu`} alignRight>
        <Dropdown.Item as="button" onClick={() => handleShowFile(file)}>
          View
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => downloadFile(file)}>
          Download
        </Dropdown.Item>

        <Dropdown.Item as="button" onClick={() => addFileToComment(file)}>
          Comment
        </Dropdown.Item>

        {AppRegExp.isImageFullPath(`${file.originalName}.${file.extension}`) &&
          file.cloud_id ? (
          <Dropdown.Item as="button" onClick={() => setAsCover(file)}>
            Set as cover
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function setCaretPosition(textareaRef, caretPos) {
  // console.log(textareaRef);
  if (textareaRef != null) {
    if (textareaRef.createTextRange) {
      const range = textareaRef.createTextRange();
      range.move("character", caretPos);
      range.select();
    } else {
      if (textareaRef.selectionStart) {
        textareaRef.focus();
        textareaRef.setSelectionRange(caretPos, caretPos - 1);
      } else textareaRef.focus();
    }
  }
}

export const resizeInput = (e) => {
  e.target.style.height = "";
  e.target.style.height = e.target.scrollHeight + 2 + "px";
  // scrollToBottom(thread);
};

export const checkIfAreFriends = (users = []) => {
  return new Promise((resolve, reject) => {
    socket.emit("fx:are-friends", { users }, (response) => {
      try {
        if (response?.areFriends) {
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (err) {
        reject(false);
      }
    });
  });
};

export const microsoftOfficeFileExtensions = [
  "doc",
  "docx",
  "one",
  "xlsx",
  "xls",
  "xlsm",
  "ppt",
  "pptx",
  "pps",
  "ppsx",
  "ecf",
  "pub",
  "xps",
  "accdb",
  "accdt",
  "onepkg",
  "pst",
  "csv",
];
