import React, { Component } from 'react';

export default class DeleteProjectModal extends Component {
	handleCloseModal = (e, wrapper) => {
		if (wrapper) {
			if (e.target !== e.currentTarget) return;
		}

		this.props.closeModal();
	};

	render() {
		const { selectedProject, canDeleteProjectFolder } = this.props;
		return (
			<div
				className="modal custom-modal fade"
				id="delete_project"
				role="dialog"
				onClick={(e) => this.handleCloseModal(e, 'wrapper')}
			>
				<div className="modal-dialog modal-dialog-centered modal-sm">
					<div className="modal-content">
						<div className="modal-body">
							<div className="form-header">
								<h4>Delete Project</h4>
								{/* <span>Are you sure you want to delete?</span> */}
								{selectedProject ? (
									<div style={{ marginTop: '20px', fontWeight: 'bold', fontSize: '14px' }}>
										{selectedProject.name}
									</div>
								) : null}
							</div>
							<div
								className="d-flex justify-content-center align-items-center mb-4"
								style={{ fontSize: '13px', color: '#808080' }}
							>
								<input
									onChange={() => this.props.toggleCanDeleteProjectFolder()}
									type="checkbox"
									checked={canDeleteProjectFolder}
									className="mr-2"
								/>
								<span
									onClick={() => this.props.toggleCanDeleteProjectFolder()}
									style={{ cursor: 'default' }}
								>
									Delete Project Folder
								</span>
							</div>
							<div className="modal-btn delete-action">
								<div className="row">
									<div className="col-6">
										<span
											className="btn btn-primary delete-btn"
											onClick={() => this.props.handleDeleteProject()}
										>
											Delete
										</span>
									</div>
									<div className="col-6">
										<span data-dismiss="modal" className="btn btn-primary cancel-btn">
											Cancel
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
