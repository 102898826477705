import { commentTypes } from "../actions/commentActions";

const INITIAL_STATE = {
  globalComments: {
    // format will be like below
    // 1: {
    //   id: 1,
    //   filesToUploadCount: 0,
    //   taskId: '',
    //   token: '',
    //   files: []
    // },
  },
};

const commentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case commentTypes.SET_GLOBAL_COMMENT:
      return {
        ...state,
        globalComments: {
          ...state.globalComments,
          ...action.payload,
        },
      };

    case commentTypes.SUCCESS_SEND_COMMENT:
      return {
        ...state,
        globalComments: {
          ...state.globalComments,
          [action.payload.id]: {
            ...state.globalComments[action.payload.id],
            ...action.payload.data,
            status: 1,
            error: 0,
            loading: 0,
          },
        },
      };

    case commentTypes.SET_COMMENT_FILES_UPLOADED:
      return {
        ...state,
        globalComments: {
          ...state.globalComments,
          [action.payload.id]: {
            ...state.globalComments[action.payload.id],
            files: [
              ...state.globalComments[action.payload.id].files,
              action.payload.data,
            ],
          },
        },
      };

    case commentTypes.REDUCE_UPLOAD_COUNT: {
      let currentFilesToUploadCount =
        state.globalComments[action.payload.id].filesToUploadCount;
      return {
        ...state,
        globalComments: {
          ...state.globalComments,
          [action.payload]: {
            ...state.globalComments[action.payload.id],
            filesToUploadCount: currentFilesToUploadCount - 1,
          },
        },
      };
    }

    case commentTypes.REMOVE_COMMENT:
      const { [action.payload]: remove, ...rest } = state.globalComments;
      return {
        ...state,
        globalComments: {
          ...rest,
        },
      };

    default:
      return state;
  }
};

export default commentReducer;
