import React from "react";
import { URLS } from "../urls";

export default class photoListName extends React.Component {
  constructor(props) {
    super(props);
    this.removeFormList = this.removeFormList.bind(this);
    this.state = {
      value: [],
    };
  }
  componentWillReceiveProps(props) {
    if (props.value.length) {
      this.setState({
        value: props.value,
      });
    }
  }
  
  removeFormList = (item) => (e) => {
    e.preventDefault();
    this.props.removeFormList(item);
  };
  render() {
    const value = this.props.value;
    
    return value.length ? (
      <div>
        {value.map((item, index) => {
          let name;
          if (item.name.first) {
            name = item.name.first + " " + item.name.last;
          } else {
            name = item.name;
          }
          if (item.photo)
            return (
              <div
                style={{
                  display: "inline-block",
                  marginRight: "8px",
                  marginBottom: "8px",
                  textAlign: "center",
                  position: "relative",
                  padding: "5px 10px",
                  backgroundColor: "#eeeeee",
                }}
                key={index}
              >
                <button
                  className="close"
                  aria-label="Close"
                  style={{ right: "-5px", top: "-5px" }}
                  onClick={this.removeFormList(item)}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div style={{ display: "inline" }}>
                  <a href="#" title={name} className="avatar">
                    <img
                      src={URLS.backendStatic + "/" + item.photo}
                      alt={"picokdone"}
                    />
                  </a>
                </div>

                <div style={{ display: "block" }}>{name}</div>
              </div>
            );
          else
            return (
              <a href="#" title={name} className="avatar" key={index}>
                <img
                  src="/assets/img/profiles/avatar-03.jpg"
                  alt={"picokdone"}
                />
              </a>
            );
        })}
      </div>
    ) : (
      <div></div>
    );
  }
}
