import { URLS } from "../components/urls";
import axios from "axios";

export function getCompanyUsers(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendUsers}/getCompanyUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export function getUsersForPerformance(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendUsers}/getUsersForPerformance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export function getNotificationsCount(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendNotifications}/getNotificationsCount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export function getUser(token, userId) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendUsers}/getUser/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export function updateFileName(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${URLS.backendCloudDrive}/upload-update`, params)
      .then((result) => {
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function searchUserByEmail(searchQuery, token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendUsers}/searchUserByEmail/${searchQuery}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function searchUserByName(searchQuery, token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendUsers}/searchUserByName/${searchQuery}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getReferralLink(token) {
  return new Promise((resolve, reject) => {
    fetch(`${URLS.backendUsers}/getReferralLink`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendInvite({ token, url, email, isPublic, userId }) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email, public: isPublic, userId }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
