import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CloseIcon from "mdi-react/CloseIcon";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import RefreshIcon from "mdi-react/RefreshIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import "../../../assets/scss/uploadto.scss";
import {
  BoardBackArrowIcon,
  EmptyAttachments,
  FolderIcon,
  UploadBtnIcon,
} from "../components/Icons";
import eventBus from "../../../helper/EventBus";
import { showToast } from "../utils";
import { socket } from "../../../sockets";
import { isEmpty, truncate } from "lodash";
import { randomNumber } from "../../../utils";
import { createFolder } from "../../../services/taskServices";
import { connect } from "react-redux";

function UploadToModal({ rootFolderId, initialMode = "upload", token, user }) {
  // get folders in root folder

  const initialPagination = {
    next: { page: 1, limit: 1000 },
  };
  const [folderName, setFolderName] = useState("");
  const [mode, setMode] = useState(initialMode); // folder || upload
  const [show, setShow] = useState(false);
  const [folders, setFolders] = useState([]);
  const [crumbs, setCrumbs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pagination, setPagination] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsSubmitting(true);
      const response = await createFolder({
        token,
        folder_name: folderName,
        parent_id: isEmpty(crumbs)
          ? rootFolderId
          : crumbs[crumbs.length - 1]._id,
      });

      if (response.success) {
        setFolderName("");
        // setFolders([{ _id: response.folder_id, name: folderName }, ...folders]);
        setMode("upload");
        getFolders({
          parentFolderId: response.folder_id,
          next: initialPagination.next,
          addNewFolder: true,
        });

        // dispatch to attachments
      } else {
        throw new Error("");
      }

      // Dev mode
      /* eventBus.dispatch("addNewFolder", {
        _id: randomNumber(),
        name: folderName,
        is_dir: true,
      });*/

      setFolderName("");
    } catch (err) {
      console.log(err);
      showToast({
        message: {
          title: "Unable to create folder",
        },
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadHere = () => {
    eventBus.dispatch("selectedFolderToUpload", crumbs[crumbs.length - 1]);
    setShow(false);
  };

  const getFoldersSocket = ({
    page,
    limit,
    parentFolderId,
    searchTerm = "",
    searchType = "",
  }) => {
    return new Promise((resolve, reject) => {
      socket.emit(
        "fx:get-task-folders",
        {
          page,
          limit,
          parentFolderId,
          is_dir: true,
          searchTerm,
          searchType,
        },
        async (response) => {
          resolve(response);
        }
      );
    });
  };

  const getFolders = async ({
    parentFolderId,
    next = initialPagination.next,
    addNewFolder = false,
  }) => {
    try {
      setIsLoading(true);
      const { page, limit } = next;
      const response = await getFoldersSocket({
        page,
        limit,
        parentFolderId,
      });

      if (response.success) {
        /* setPagination({
            ...response.data.pagination,
          });*/
        if (!response.data.parentFolder) {
          showToast({
            message: {
              title: "Folder was not found",
            },
            type: "error",
          });
          throw new Error();
        }
        const crumbIndex = crumbs.findIndex(
          (el) => el._id === response.data.parentFolder._id
        );

        if (crumbIndex === -1) {
          setCrumbs([...crumbs, response.data.parentFolder]);
        } else {
          setCrumbs([...crumbs.slice(0, crumbIndex + 1)]);
        }

        setFolders([
          ...(page === 1 ? [] : [...folders]),
          ...response.data.childFolders,
        ]);

        if (addNewFolder) {
          eventBus.dispatch("addNewFolder", response.data.parentFolder);
        }
      } else {
        showToast({
          message: {
            title: "Unable to get Files",
          },
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFolders({
      parentFolderId: rootFolderId,
    });
    return () => {};
  }, []);

  const retry = () => {
    if (crumbs)
      getFolders({
        parentFolderId: crumbs[crumbs.length - 1]._id,
      });
  };

  eventBus.useCustomEventListener("toggleUploadToModal", () => setShow(!show));

  return (
    <Modal
      dialogClassName={`upload-to`}
      backdropClassName={`global-backdrop max-z-index-2`}
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      enforceFocus={false}
      centered={true}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header bsPrefix="modal-header d-flex align-items-center py-3">
          <div className="d-flex align-items-center">
            {mode === "folder" ? (
              <button
                onClick={() => setMode("upload")}
                id="close-btn"
                className="mr-3"
                title="Back"
                type="button"
              >
                <ArrowLeftIcon />
              </button>
            ) : null}
            <h3>
              {" "}
              {mode === "folder" ? "Create New Folder" : "Upload to..."}{" "}
            </h3>
          </div>

          <button id="close-btn" type="button" onClick={() => setShow(false)}>
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body bsPrefix="modal-body pt-0">
          <div
            className={`create-folder-form ${
              mode !== "folder" ? "d-none" : ""
            } `}
          >
            <Form.Group controlId="folderName">
              <Form.Label>
                Folder Name<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="folderName"
                placeholder="Enter folder name"
                value={folderName}
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
                autoFocus
                required
                autoComplete="off"
              />
            </Form.Group>
          </div>

          <div className="crumbs px-2 mb-2">
            <span>Home</span>
            {crumbs.map((crumb, i) => (
              <span
                key={i}
                onClick={() =>
                  getFolders({
                    parentFolderId: crumb._id,
                  })
                }
                className="p-cursor"
              >
                <ChevronRightIcon />{" "}
                {truncate(crumb?.name, {
                  length: 15,
                })}
              </span>
            ))}
          </div>
          <div className={`files-holder ${mode}`}>
            {!isLoading && isEmpty(folders) ? (
              <div className="h-100 d-flex flex-column justify-content-center align-items-center empty">
                <EmptyAttachments />
                <span className="mt-3 d-block">No folders found</span>
                <button
                  onClick={() => retry()}
                  className="btn btn-sm btn-light text-underline p-1"
                >
                  <RefreshIcon /> Refresh...
                </button>
              </div>
            ) : (
              folders.map((folder, i) => (
                <button
                  key={i}
                  onClick={() =>
                    getFolders({
                      parentFolderId: folder._id,
                    })
                  }
                  className="btn w-100 file"
                  type="button"
                >
                  <FolderIcon />

                  <span>
                    {truncate(folder.name, {
                      length: 40,
                    })}
                  </span>
                </button>
              ))
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          bsPrefix={`modal-footer d-flex p-4 ${
            mode === "folder"
              ? "justify-content-around"
              : "justify-content-between"
          } `}
        >
          {mode === "folder" ? (
            <button
              type="submit"
              className="btn btn-primary py-2 px-3 btn-c save-folder"
            >
              {isSubmitting ? (
                "Please wait..."
              ) : (
                <>
                  <PlusIcon /> Create New Folder
                </>
              )}
            </button>
          ) : (
            <>
              <button
                onClick={() => setMode("folder")}
                type="button"
                className="btn btn-outline-primary py-2 px-3 btn-c"
              >
                <PlusIcon /> New Folder
              </button>
              <button
                onClick={() => uploadHere()}
                type="button"
                className="btn btn-primary py-2 px-3 btn-c"
              >
                <UploadBtnIcon />
                Upload Here
              </button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadToModal);
