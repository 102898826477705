import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Login from "./Login";
import { URLS } from "../urls.js";

class LandingPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="account-page" style={{ minHeight: "100vh" }}>
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to={`${URLS.login}`}>
                  <img src="assets/img/logo-blue.png" alt="Fexspace - Collaboration" />
                </Link>
              </div>
              <Login />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LandingPage);
