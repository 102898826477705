import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import CloseIcon from 'mdi-react/CloseIcon';

import { searchUserByName } from "../../services/userServices";

import { URLS } from "../urls";

import "../../assets/scss/global.scss";

class InviteModal extends Component {
  constructor(props) {
    super(props);

    const { account_type } = props.user;

    this.state = {};
  }

  handleCloseModal = (e, wrapper) => {
    if (wrapper) {
      if (e.target !== e.currentTarget) return;
    }
    this.props.closeModal();
  };

  render() {
    return (
      <div
        id="invite_modal"
        className="modal custom-modal fade"
        role="dialog"
        onClick={(e) => this.handleCloseModal(e, "wrapper")}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="m-h-between">
              <h5 className="modal-title">Invite</h5>
              <CloseIcon onClick={(e) => this.handleCloseModal(e)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InviteModal));
