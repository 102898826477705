import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import MoreIcon from "mdi-react/DotsHorizontalIcon";
import AccountClockIcon from "mdi-react/AccountClockIcon";
import "../../assets/scss/tasks.scss";
import "../../assets/css/project.css";
import "../../assets/scss/new-ui.scss";
import { URLS } from "../urls";
import {
  TeamCalendarIcon,
  TeamUsersIcon,
} from "../task-update/components/Icons";
import { size } from "lodash";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dateInFormat(date) {
    date = date.split("T")[0];
    return date;
  }

  setEmployeeName = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  isCreatedBy = () => {
    let value;
    const { team, user } = this.props;
    const userId = user._id;
    const owner = team.createdBy;

    if (owner._id) {
      value = owner._id == userId ? true : false;
    } else if (owner) {
      value = owner == userId ? true : false;
    }

    return value;
  };

  hasPersonalAccountMemberAccepted = (employee) => {
    /*let value;
    const { team, user } = this.props;
    const membersToJoin = team.membersToJoin;
    const account_type = user.account_type;

    if (account_type === 2) {
      value = true;
    } else {
      let isFound = membersToJoin.find(
        (memberToJoin) => memberToJoin.userId == employee._id
      );
      if (isFound) {
        if (isFound.hasAccepted) {
          value = true;
        }
      } else {
        value = true;
      }
    }

    return value;*/ return true;
  };

  render() {
    const { team, tasks, projects, hasAccountExpired } = this.props;

    return (
      <div className="col-lg-4 col-sm-6 col-12 col-md-4 col-xl-3 pj-card bg-none p-0">
        <div className="card-box project-box border-none m-0 p-3">
          {this.isCreatedBy() && !hasAccountExpired ? (
            <div className="dropdown profile-action">
              <a
                href=""
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <MoreIcon />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  className="dropdown-item"
                  onClick={this.props.onEdit.bind(this, team)}
                >
                  <i className="fa fa-pencil m-r-5"></i> Edit
                </button>
                <button
                  className="dropdown-item text-danger"
                  onClick={this.props.onDelete.bind(this, team._id)}
                >
                  <i className="fa fa-trash-o m-r-5"></i> Delete
                </button>
              </div>
            </div>
          ) : null}
          <h4 className="widget-title mb-2">
            <Link to={`${URLS.teamView}/${team._id}`}>{team.name}</Link>
          </h4>
          <div className="widget-subtitle d-flex justify-content-between mb-3">
            <span className="text-muted">
              <TeamCalendarIcon className="mr-1" /> Created:{" "}
              {this.dateInFormat(team.createdAt)}
            </span>
            <span className="text-muted">
              <TeamUsersIcon className="mr-1" />
              {size(team.members) + size(team.leaders)}{" "}
              {size(team.members) + size(team.leaders) ? "Member" : "Members"}{" "}
            </span>
          </div>
          <div className="widget-subtitle mb-2">
            <span className="text-xs">
              <span className="text-warning mr-1">⬤</span>
              {projects && projects[team._id] && projects[team._id]["open"]}
            </span>{" "}
            <span className="text-muted">Open Projects, </span>
            <span className="text-xs">
              {projects &&
                projects[team._id] &&
                projects[team._id]["completed"]}
            </span>{" "}
            <span className="text-muted">Completed Projects</span>
          </div>
          <div className="widget-subtitle mb-2">
            <span className="text-xs">
              <span className="text-success mr-1">⬤</span>
              {tasks && tasks[team._id] && tasks[team._id]["open"]}
            </span>{" "}
            <span className="text-muted">Open Tasks, </span>
            <span className="text-xs">
              {tasks && tasks[team._id] && tasks[team._id]["completed"]}
            </span>{" "}
            <span className="text-muted">Completed Tasks</span>
          </div>
          <hr className="my-3" />
          <p className="custom-max-lines-3 text-sm">
            {team.description || "..."}
          </p>

          {team.leaders && team.leaders.length ? (
            <div className="widget-subbody mb-4">
              <div className="widget-subbody-header mb-2">Leaders</div>

              <div>
                <div className="task-img-collection-update">
                  {team.leaders.map((employee, index) => {
                    if (index < 3) {
                      return (
                        <div className="avatars">
                          <span
                            className="avatar"
                            title={this.setEmployeeName(employee)}
                          >
                            {employee.photo ? (
                              <img
                                src={URLS.backendStatic + "/" + employee.photo}
                              />
                            ) : (
                              <span className="av-alternative">
                                {this.setEmployeeInitials(employee)}
                              </span>
                            )}
                            {!this.hasPersonalAccountMemberAccepted(
                              employee
                            ) ? (
                              <AccountClockIcon className="account-clock-icon" />
                            ) : null}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          ) : null}

          {team.members && team.members.length ? (
            <div className="widget-subbody mt-2">
              <div className="widget-subbody-header mb-2">Members</div>

              <div className="">
                <div className="task-img-collection-update">
                  {team.members.map((employee, index) => {
                    if (index < 3) {
                      return (
                        <div className="avatars">
                          <span
                            className="avatar"
                            title={this.setEmployeeName(employee)}
                          >
                            {employee.photo ? (
                              <img
                                src={URLS.backendStatic + "/" + employee.photo}
                              />
                            ) : (
                              <span className="av-alternative">
                                {this.setEmployeeInitials(employee)}
                              </span>
                            )}
                            {!this.hasPersonalAccountMemberAccepted(
                              employee
                            ) ? (
                              <AccountClockIcon className="account-clock-icon" />
                            ) : null}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

export default connect(mapStateToProps, null)(withRouter(Team));
