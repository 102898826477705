import React, { Component } from "react";
import styled from "styled-components";
import { URLS } from "../urls";

export default class Loader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Wrapper>
        <div className="dv-active-loader">
          <img
            src={URLS.landingPage + "/assets/img/logo-fff.svg"}
            alt="Fexspace - Logo"
            width="250"
          />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .dv-active-loader {
    background-color: #012e54;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
