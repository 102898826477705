import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import HSBar from "react-horizontal-stacked-bar-chart";
import moment from "moment";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import Spinner from "../global/Spinner";
import Project from "../projects/Project";
import AllTypeTasksClient from "../tasks/AllTypeTasksClient";
import EditTeamModal from "../teams/EditTeamModal";
import PencilIcon from "mdi-react/PencilIcon";
import { socket } from "../../sockets";
import "../../assets/scss/global.scss";
import "../../assets/scss/teams.scss";
import { URLS } from "../urls";
import {
  getTeamById,
  getDateWiseCompletedTasksByTeamId,
  getProjectsByTeamId,
  getTasksByTeamId,
} from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";

import CompletionTaskChart from "./CompletionTaskChart";
import PerfomanceTaskChart from "./PerfomanceTaskChart";
import { removeModalElements } from "../../utils";
import CloseIcon from "mdi-react/CloseIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import TeamChart from "./TeamChart";
import PrinterIcon from "mdi-react/PrinterIcon";

class TeamView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {},
      editTeam: "",
      projects: {},
      tasks: {},
      completedTasks: {},
      completionRate: {},
      activeTasks: {},
      overDueTasks: {},
      underReviewTasks: {},
      perfomanceTaskData: {
        Assigned: [{ type: "Assigned", tasks: 0 }],
        Completed: [{ type: "Completed", tasks: 0 }],
        Active: [{ type: "Active", tasks: 0 }],
        UnderReview: [{ type: "UnderReview", tasks: 0 }],
        Overdue: [{ type: "Overdue", tasks: 0 }],
      },
      completionTaskData: [],
      isLoading: true,
      activeView: "members",
      teamProjects: [],
      teamTasks: [],
      companyUsers: [],
    };
  }

  selectClient = (_id) => {
    this.setState({ selectedProjectId: _id });
  };

  editProject = async (project) => {
    await this.setState({ editProjectData: project });
  };

  handleModal = () => {
    const isEditModalOpen = !this.state.isEditModalOpen;
    this.setState({ isEditModalOpen });
  };

  async componentDidMount() {
    const fullUrl = window.location.pathname.split("/");

    if (fullUrl && !fullUrl[2]) {
      window.location.assign("/error");
    }

    const teamId = fullUrl[2];
    const token = this.props.token;

    const teamResponse = await getTeamById(token, teamId);

    //Redirect if team doesn't exist
    if (!teamResponse || teamResponse.error) {
      window.location.assign("/error");
    }

    let {
      projects,
      team,
      tasks,
      completedTasks,
      activeTasks,
      overDueTasks,
      underReviewTasks,
      perfomanceTaskData,
      completionTaskData,
      teamProjects,
      teamTasks,
      companyUsers,
    } = this.state;

    const [
      dateWiseTasksResponse,
      projectsResponse,
      teamTasksResponse,
      companyResponse,
    ] = await Promise.all([
      getDateWiseCompletedTasksByTeamId(token, teamId),
      getProjectsByTeamId(token, teamId),
      getTasksByTeamId(token, teamId),
      getCompanyUsers(token),
    ]);

    team = teamResponse && teamResponse.team ? teamResponse.team : {};
    completionTaskData =
      dateWiseTasksResponse && dateWiseTasksResponse.team
        ? dateWiseTasksResponse.tasks
        : [];
    teamProjects =
      projectsResponse && projectsResponse.projects
        ? projectsResponse.projects
        : [];
    teamTasks =
      teamTasksResponse && teamTasksResponse.tasks
        ? teamTasksResponse.tasks
        : [];
    companyUsers =
      companyResponse && companyResponse.users ? companyResponse.users : [];

    if (
      projectsResponse &&
      projectsResponse.projects &&
      Object.entries(team).length &&
      Array.isArray(teamTasks)
    ) {
      const pl = projectsResponse.projects.length;
      projects["open"] = pl;
      projects["complete"] = 0;

      const teamParticipants = [...team.members, ...team.leaders];

      for (let participant of teamParticipants) {
        const id = participant._id;
        projects[participant._id] = pl;

        tasks[id] = 0;
        completedTasks[id] = 0;
        underReviewTasks[id] = 0;
        activeTasks[id] = 0;
        overDueTasks[id] = 0;

        for (let task of teamTasks) {
          if (task.assignedTo.find((element) => element._id === id)) {
            tasks[id] += 1;
            // if (task.status === 'INCOMPLETE') {
            // 	activeTasks[id] += 1;
            // } else if (task.status === 'UNDER_REVIEW') {
            // 	underReviewTasks[id] += 1;
            // } else if (task.status === 'COMPLETE') {
            // 	completedTasks[id] += 1;
            // }

            // if (task.completeDate && task.status !== 'COMPLETE') {
            // 	const diff = moment(new Date(), 'DD/MM/YYYY').diff(
            // 		moment(task.dueDate, 'DD/MM/YYYY'),
            // 		'days'
            // 	);
            // 	if (diff > 0) overDueTasks[id] += 1;
            // }

            if (task.status === "AWAITING" || task.status === "INCOMPLETE") {
              if (task.dueDate) {
                const dt = new Date();
                const diff = moment(dt, "DD/MM/YYYY").diff(
                  moment(task.dueDate, "DD/MM/YYYY"),
                  "days"
                );
                if (diff > 0) {
                  overDueTasks[id] += 1;
                } else {
                  activeTasks[id] += 1;
                }
              } else {
                activeTasks[id] += 1;
              }
            } else if (task.status === "UNDER_REVIEW") {
              underReviewTasks[id] += 1;
            } else if (task.status === "COMPLETE") {
              completedTasks[id] += 1;
            }
          }
        }
      }
    }

    tasks["total"] = 0;
    tasks["complete"] = 0;
    tasks["active"] = 0;
    tasks["under_review"] = 0;
    tasks["overdue"] = 0;

    console.log("teamTasks", teamTasks);

    if (teamTasks && teamTasks.length) {
      teamTasks.forEach((task) => {
        tasks["total"] += 1;
        let assignedTo = task.assignedTo;
        if (assignedTo && Array.isArray(assignedTo)) {
          if (task.status === "AWAITING" || task.status === "INCOMPLETE") {
            if (task.dueDate) {
              const dt = new Date();
              const diff = moment(dt, "DD/MM/YYYY").diff(
                moment(task.dueDate, "DD/MM/YYYY"),
                "days"
              );
              if (diff > 0) {
                tasks["overdue"] += 1;
              } else {
                tasks["active"] += 1;
              }
            } else {
              tasks["active"] += 1;
            }
          } else if (task.status === "UNDER_REVIEW") {
            tasks["under_review"] += 1;
          } else if (task.status === "COMPLETE") {
            tasks["complete"] += 1;
          }
        }
      });

      if (tasks["total"]) {
        perfomanceTaskData = {
          Completed: [
            {
              type: "Completed",
              tasks: parseFloat(
                ((tasks["complete"] * 100) / tasks["total"]).toFixed(2)
              ),
            },
          ],
          Active: [
            {
              type: "Active",
              tasks: parseFloat(
                ((tasks["active"] * 100) / tasks["total"]).toFixed(2)
              ),
            },
          ],
          UnderReview: [
            {
              type: "UnderReview",
              tasks: parseFloat(
                ((tasks["under_review"] * 100) / tasks["total"]).toFixed(2)
              ),
            },
          ],
          Overdue: [
            {
              type: "Overdue",
              tasks: parseFloat(
                ((tasks["overdue"] * 100) / tasks["total"]).toFixed(2)
              ),
            },
          ],
        };
      }
    }

    this.setState({
      team,
      completionTaskData,
      projects,
      tasks,
      completedTasks,
      activeTasks,
      overDueTasks,
      underReviewTasks,
      perfomanceTaskData,
      teamProjects: teamProjects,
      teamTasks: teamTasks,
      isLoading: false,
      companyUsers,
    });

    // this.handleTeamEventsReceived();
  }

  handleTeamEventsReceived = () => {
    socket.on("fx-team-updated", ({ team }) => {
      if (team) {
      }
    });
  };

  dateInFormat(date) {
    if (date) date = date.split("T")[0];
    return date;
  }

  setEmployeeName = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleActiveView = (activeView) => {
    this.setState({ activeView });
  };

  updateTeamsList = (team) => {
    if (team) {
      this.setState({ team });
    }
    this.handleModal();
  };

  isTeamCreator = (createdBy) => {
    const userId = this.props.user._id;
    let value;
    if (createdBy && createdBy._id && userId) {
      if (createdBy._id === userId) {
        value = true;
      }
    }
    return value;
  };

  render() {
    const {
      team,
      projects,
      tasks,
      completedTasks,
      overDueTasks,
      activeTasks,
      underReviewTasks,
      perfomanceTaskData,
      completionTaskData,
      isLoading,
      activeView,
      teamProjects,
      teamTasks,
      isEditModalOpen,
      companyUsers,
    } = this.state;

    const allTeamMembers =
      team && team.members && team.leaders
        ? [...team.members, ...team.leaders]
        : [];
    const { hasAccountExpired } = this.props;

    return (
      <div className="main-wrapper">
        <Topbar />
        <div className="d-flex new-wrapper sleek-scrollbar">
          <Sidebar activeSideBar="teams" />

          <div className="page-wrapper p-0 bg-white">
            <div className="page-top-bar">
              <a href="/">{team.name}</a>

              <div className="hide-on-print absolute d-flex justify-content-end">
                <button
                  onClick={() => this.handleModal()}
                  disabled={hasAccountExpired || isLoading}
                  className="btn btn-outline-primary main-btn inner p-0 px-md-3 py-md-2 mr-3"
                >
                  <span className="d-none d-md-block">Edit Team</span>
                  <PencilIcon className="d-block d-md-none" />
                </button>

                <button
                  className="btn btn-outline-primary"
                  onClick={() => window.print()}
                >
                  <PrinterIcon />
                </button>
              </div>
            </div>

            <div className="g-crumb p-3">
              <Link to={`${URLS.dashboard}`}>Home</Link>{" "}
              <ChevronRightIcon class="active" />{" "}
              <Link to={`${URLS.teams}`}>Teams</Link>
              <ChevronRightIcon class="active" />{" "}
              <a href="#" class="active">
                {team.name}
              </a>
            </div>

            {isLoading ? (
              <Spinner spinnerHeight="380px" />
            ) : (
              <div className="row w-100 mobile-100-vw  bg-white">
                <div className="team-view-layout col-lg-8 col-xl-9 py-3">
                  <div className="team-view-header" style={{ display: "none" }}>
                    <span>{team.name}</span>
                    <div>
                      <div>
                        <div>
                          <span>{tasks["open"] || 0}</span>
                          <span>Tasks Open</span>
                        </div>
                        ,
                        <div>
                          <span>{tasks["complete"] || 0}</span>
                          <span>Task(s) Completed</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>{projects["open"] || 0}</span>
                          <span>Project(s) Open</span>
                        </div>
                        ,
                        <div>
                          <span>{projects["complete"] || 0}</span>
                          <span>Project(s) Completed</span>
                        </div>
                      </div>
                    </div>
                    {this.isTeamCreator(team.createdBy) &&
                    !hasAccountExpired ? (
                      <div>
                        <span
                          data-toggle="modal"
                          data-target="#edit_team"
                          onClick={() => this.handleModal()}
                        >
                          <PencilIcon
                            style={{ height: "20px", width: "20px" }}
                          />
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="card shadow-none border-none mb-0">
                    <div className="card-body px-3 px-4 py-0">
                      <div className="project-title">
                        <h5 className="card-title mb-3">{team.name}</h5>

                        <div className="content-com d-flex flex-column justify-content-between py-2 pb-1 text-sm text-muted">
                          <span className="d-flex mb-2">
                            <span className="text-warning mr-1">⬤</span>
                            <span className="ml-1">
                              {tasks["open"] || 0}
                              &nbsp; Tasks Opened, &nbsp;
                              {tasks["complete"] || 0} Tasks Completed
                            </span>
                          </span>
                          <span>
                            <span className="text-success mr-1">⬤</span>
                            <span className="ml-1">
                              {projects["open"] || 0}
                              &nbsp; Projects Opened, &nbsp;
                              {projects["complete"] || 0} Projects Completed
                            </span>
                          </span>
                        </div>
                      </div>
                      <p className="text-sm my-3">{team.description}</p>
                    </div>
                  </div>
                  <div className="team-view-body sleek-scrollbar m-3 mr-md-0">
                    <div style={{ display: "none" }}>
                      <div>
                        <span className="header-title">Team Description</span>
                        <span className="sleek-scrollbar custom-max-lines-5">
                          {team.description || "No description"}
                        </span>
                      </div>
                      <div>
                        <span className="header-title">Leaders</span>
                        <div className="team-leaders">
                          <div className="team-leaders-content">
                            {team.leaders.map((employee, index) => {
                              if (index < 6) {
                                return (
                                  <div className="avatars" key={index}>
                                    <span
                                      className="avatar"
                                      title={this.setEmployeeName(employee)}
                                    >
                                      {employee.photo ? (
                                        <img
                                          src={
                                            URLS.backendStatic +
                                            "/" +
                                            employee.photo
                                          }
                                        />
                                      ) : (
                                        <span className="av-alternative">
                                          {this.setEmployeeInitials(employee)}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                );
                              }
                            })}
                            {team.leaders.length > 6 ? (
                              <span className="team-leaders-length">
                                &amp; {team.leaders.length - 6}{" "}
                                {team.leaders.length - 6
                                  ? team.leaders.length - 6 > 1
                                    ? "others"
                                    : "other"
                                  : null}
                              </span>
                            ) : null}

                            <div className="assigned-to-others-layout">
                              {team.leaders.map((employee, index) => {
                                return (
                                  <div>
                                    {employee.photo ? (
                                      <img
                                        src={
                                          URLS.backendStatic +
                                          "/" +
                                          employee.photo
                                        }
                                      />
                                    ) : (
                                      <span className="sp-initials">
                                        {this.setEmployeeInitials(employee)}
                                      </span>
                                    )}
                                    <span>
                                      {this.setEmployeeName(employee)}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="team-created-by-name">
                          <span>Created By:</span>
                          <span>{this.setEmployeeName(team.createdBy)}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span className="header-title">
                            Tasks Performance
                          </span>
                          <div>
                            <div>
                              <span className="bg-completed-legend"></span>
                              <span>Completed</span>
                              <span>
                                (
                                {tasks["total"]
                                  ? (
                                      (tasks["complete"] * 100) /
                                      tasks["total"]
                                    ).toFixed(0)
                                  : 0}
                                %)
                              </span>
                            </div>
                            <div>
                              <span className="bg-under-review-legend"></span>
                              <span>Under Review</span>
                              <span>
                                (
                                {tasks["total"]
                                  ? (
                                      (tasks["under_review"] * 100) /
                                      tasks["total"]
                                    ).toFixed(0)
                                  : 0}
                                %)
                              </span>
                            </div>
                            <div>
                              <span className="bg-active-legend"></span>
                              <span>Active</span>
                              <span>
                                (
                                {tasks["total"]
                                  ? (
                                      (tasks["active"] * 100) /
                                      tasks["total"]
                                    ).toFixed(0)
                                  : 0}
                                %)
                              </span>
                            </div>
                            <div>
                              <span className="bg-overdue-legend"></span>
                              <span>Overdue</span>
                              <span>
                                (
                                {tasks["total"]
                                  ? (
                                      (tasks["overdue"] * 100) /
                                      tasks["total"]
                                    ).toFixed(0)
                                  : 0}
                                %)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <PerfomanceTaskChart data={perfomanceTaskData} />
                        </div>
                      </div>
                    </div>
                    <div className="team-view-tab border-bottom mt-0">
                      <span
                        className={`${
                          activeView === "members" ? "task-active-view" : ""
                        }`}
                        onClick={() => this.handleActiveView("members")}
                      >
                        Members
                      </span>
                      <span
                        className={`${
                          activeView === "projects" ? "task-active-view" : ""
                        }`}
                        onClick={() => this.handleActiveView("projects")}
                      >
                        Projects
                      </span>
                      <span
                        className={`${
                          activeView === "tasks" ? "task-active-view" : ""
                        }`}
                        onClick={() => this.handleActiveView("tasks")}
                      >
                        Tasks
                      </span>
                    </div>
                    {activeView === "members" && (
                      <div className="team-view-members">
                        <div>
                          <span>Members</span>
                          <span>Projects</span>
                          <span>Tasks</span>
                          <span>Completed Tasks</span>
                          <span>Progress</span>
                        </div>

                        <div>
                          {allTeamMembers && allTeamMembers.length ? (
                            allTeamMembers.map((member) => {
                              const id = member._id;
                              return (
                                <div>
                                  {/* <a href={`/member-view/${team._id}/${member._id}`}> */}
                                  <a>
                                    {member && member.photo ? (
                                      <img
                                        src={
                                          URLS.backendStatic +
                                          "/" +
                                          member.photo
                                        }
                                      />
                                    ) : (
                                      <span className="av-alternative2">
                                        {this.setEmployeeInitials(member)}
                                      </span>
                                    )}
                                    <span>{this.setEmployeeName(member)}</span>
                                  </a>
                                  <span>{projects[id] || 0}</span>
                                  <span>{tasks[id] || 0}</span>
                                  <span>{completedTasks[id] || 0}</span>
                                  {completedTasks[id] ||
                                  underReviewTasks[id] ||
                                  activeTasks[id] ||
                                  overDueTasks[id] ? (
                                    (() => {
                                      let data = [];

                                      data =
                                        completedTasks[id] > 0
                                          ? [
                                              ...data,
                                              ...[
                                                {
                                                  // name: "Completed",
                                                  value: completedTasks[id],
                                                  color: "#40D886",
                                                },
                                              ],
                                            ]
                                          : data;

                                      data =
                                        activeTasks[id] > 0
                                          ? [
                                              ...data,
                                              ...[
                                                {
                                                  //  name: "Active",
                                                  value: activeTasks[id],
                                                  color: "#3F96D1",
                                                },
                                              ],
                                            ]
                                          : data;

                                      data =
                                        underReviewTasks[id] > 0
                                          ? [
                                              ...data,
                                              ...[
                                                {
                                                  // name: "Under Review",
                                                  value: underReviewTasks[id],
                                                  color: "#FACF61",
                                                },
                                              ],
                                            ]
                                          : data;

                                      data =
                                        overDueTasks[id] > 0
                                          ? [
                                              ...data,
                                              ...[
                                                {
                                                  // name: "Overdue",
                                                  value: overDueTasks[id],
                                                  color: "#FC6C6C",
                                                },
                                              ],
                                            ]
                                          : data;

                                      data = data.map((el, i, arr) => {
                                        let value = parseInt(
                                          (el.value /
                                            arr
                                              .map((el) => el.value)
                                              .reduce((p, c) => p + c)) *
                                            100
                                        );
                                        return {
                                          ...el,
                                          value,
                                          description: `${value}%`,
                                        };
                                      });

                                      return (
                                        <span className="hs-bar-container">
                                          <HSBar
                                            showTextIn
                                            height={24}
                                            data={data}
                                          />
                                        </span>
                                      );
                                    })()
                                  ) : (
                                    <span style={{ fontWeight: "12px" }}>
                                      No Task assigned
                                    </span>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div className="d-flex justify-content-center team-view-none">
                              <h5>No Task Found</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {activeView === "projects" && (
                      <div className="team-view-projects">
                        {teamProjects.length ? (
                          teamProjects.map((project) => {
                            let projectTeam = [];
                            let projectLeader = [];
                            if (project.team && project.team.length) {
                              projectTeam = project.team.map((employee) => {
                                let name = employee.name.first;
                                name += employee.name.middle
                                  ? " " + employee.name.middle
                                  : "";
                                name += employee.name.last
                                  ? " " + employee.name.last
                                  : "";
                                const id = employee._id;
                                const image = employee.photo;
                                return { name: name, id: id, image: image };
                              });
                            }
                            if (
                              project.projectLeader &&
                              project.projectLeader.length
                            ) {
                              projectLeader = project.projectLeader.map(
                                (employee) => {
                                  let name = employee.name.first;
                                  name += employee.name.middle
                                    ? " " + employee.name.middle
                                    : "";
                                  name += employee.name.last
                                    ? " " + employee.name.last
                                    : "";
                                  const id = employee._id;
                                  const image = employee.photo;
                                  return {
                                    name: name,
                                    id: id,
                                    image: image,
                                  };
                                }
                              );
                            }
                            return (
                              <Project
                                key={project._id}
                                project={project}
                                projectTeamList={projectTeam}
                                projectLeaderList={projectLeader}
                                onEdit={this.editProject}
                                onDelete={this.selectClient}
                                isMoreHidden={true}
                              />
                            );
                          })
                        ) : (
                          <div className="team-view-none">
                            <h5>No Project Found</h5>
                          </div>
                        )}
                      </div>
                    )}
                    {activeView === "tasks" && (
                      <div className="team-view-tasks">
                        {teamTasks && teamTasks.length ? (
                          <div>
                            <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  href="#all_tasks"
                                  data-toggle="tab"
                                  aria-expanded="true"
                                >
                                  All Tasks
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#pending_tasks"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Pending Tasks
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  href="#completed_tasks"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Completed Tasks
                                </a>
                              </li>
                            </ul>
                            <AllTypeTasksClient projectTasks={teamTasks} />
                          </div>
                        ) : (
                          <div className="team-view-none">
                            <h5>No Task Found</h5>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="col-lg-4 col-xl-3 ml-4 ml-md-0"
                  style={{ fontSize: "12px" }}
                >
                  <div className="card project-user border">
                    <div className="card-body p-3">
                      <div className="d-flex justify-content-between align-content-center">
                        <span className="card-title mb-2">
                          Overall Progress
                        </span>
                      </div>

                      <div>
                        <TeamChart tasks={tasks} />
                        <span
                          style={{
                            marginTop: "-8.5rem",
                            marginRight: "calc(100% - 14rem)",
                            fontSize: "1.5rem",
                            fontWeight: "600",
                          }}
                          className="d-block text-center"
                        >
                          {tasks["total"]
                            ? (
                                (tasks["complete"] * 100) /
                                tasks["total"]
                              ).toFixed(0)
                            : 0}
                          %
                        </span>
                      </div>

                      <div
                        style={{
                          marginTop: "5rem",
                          marginLeft: "3rem",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <div className="d-flex text-success">
                          <span className="bg-completed-legend d-block mr-2">
                            ⬤
                          </span>
                          <span>
                            Completed (
                            {tasks["total"]
                              ? (
                                  (tasks["complete"] * 100) /
                                  tasks["total"]
                                ).toFixed(0)
                              : 0}
                            %)
                          </span>
                        </div>
                        <div className="d-flex text-warning">
                          <span className="bg-under-review-legend d-block mr-2">
                            ⬤
                          </span>
                          <span>
                            Under Review (
                            {tasks["total"]
                              ? (
                                  (tasks["under_review"] * 100) /
                                  tasks["total"]
                                ).toFixed(0)
                              : 0}
                            %)
                          </span>
                        </div>
                        <div className="d-flex text-info">
                          <span className="bg-active-legend d-block mr-2">
                            ⬤
                          </span>
                          <span>
                            Active (
                            {tasks["total"]
                              ? (
                                  (tasks["active"] * 100) /
                                  tasks["total"]
                                ).toFixed(0)
                              : 0}
                            %)
                          </span>
                        </div>
                        <div className="d-flex text-danger">
                          <span className="bg-overdue-legend d-block mr-2">
                            ⬤
                          </span>
                          <span>
                            Overdue (
                            {tasks["total"]
                              ? (
                                  (tasks["overdue"] * 100) /
                                  tasks["total"]
                                ).toFixed(0)
                              : 0}
                            %)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card project-user border">
                    <div className="card-body p-3">
                      <div className="d-flex justify-content-between align-content-center">
                        <span
                          style={{ marginBottom: "10px" }}
                          className="card-title"
                        >
                          Leaders
                        </span>
                      </div>

                      {team.leaders && team.leaders.length ? (
                        <ul className="list-box assigned-to-container">
                          {team.leaders.map((employee, index) => {
                            let name;
                            if (employee.name.first) {
                              name =
                                employee.name.first + " " + employee.name.last;
                            } else {
                              name = employee.name;
                            }

                            return (
                              <li key={index} className="mb-3">
                                <div className="d-flex align-items-center member-detail-row">
                                  <span className="avatar">
                                    {employee.photo ? (
                                      <img
                                        alt="..."
                                        src={
                                          URLS.backendStatic +
                                          "/" +
                                          employee.photo
                                        }
                                      />
                                    ) : (
                                      <span className="av-alternative">
                                        {this.setEmployeeInitials(employee)}
                                      </span>
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxLines: 1,
                                    }}
                                  >
                                    <span className="block name">
                                      {this.setEmployeeName(employee)}
                                    </span>
                                    <span className="username">
                                      @{employee.username}
                                    </span>
                                  </span>
                                  {false ? (
                                    <CloseIcon
                                      style={{ marginLeft: "auto" }}
                                      className="remove-user-icon"
                                      onClick={() => {}}
                                    />
                                  ) : null}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <span
                          style={{
                            display: "block",
                            textAlign: "center",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          None found
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="card project-user border">
                    <div className="card-body p-3">
                      <div className="d-flex justify-content-between align-content-center">
                        <span
                          style={{ marginBottom: "10px" }}
                          className="card-title"
                        >
                          Created By
                        </span>
                      </div>
                      <ul className="list-box assigned-to-container">
                        <li className="mb-3">
                          <div className="d-flex align-items-center member-detail-row">
                            <span className="avatar">
                              {team.createdBy.photo ? (
                                <img
                                  alt="..."
                                  src={
                                    URLS.backendStatic +
                                    "/" +
                                    team.createdBy.photo
                                  }
                                />
                              ) : (
                                <span className="av-alternative">
                                  {this.setEmployeeInitials(team.createdBy)}
                                </span>
                              )}
                            </span>
                            <span
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxLines: 1,
                              }}
                            >
                              <span className="block name">
                                {this.setEmployeeName(team.createdBy)}
                              </span>
                              <span className="username">
                                @{team.createdBy.username}
                              </span>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {isEditModalOpen && (
          <EditTeamModal
            isEditModalOpen={isEditModalOpen}
            mode="edit"
            team={team}
            employeesList={companyUsers}
            updateTeamsList={this.updateTeamsList}
            closeModal={() => this.handleModal()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    token: state.userReducer.token,
    hasAccountExpired: state.userReducer.hasAccountExpired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamView));
