import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

const COLORS = ["#3F96D1", "#0253cc", "#80e3ff", "#81b3fe"];
const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        dy={8}
        style={{ fontSize: "30px", fontWeight: "bold" }}
        textAnchor="middle"
        fill="#1f1f1f"
      >
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy + 20}
        dy={8}
        style={{ fontSize: "24px" }}
        textAnchor="middle"
        fill="#1f1f1f"
      >
        {value}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius - 8}
        outerRadius={innerRadius - 4}
        fill={fill}
      />
    </g>
  );
};

export default class PieChartModel extends PureComponent {
  state = {
    activeIndex: 0,
    data: [
      { name: "Teams", value: 0 },
      { name: "Projects", value: 0 },
      { name: "Tasks", value: 0 },
    ],
  };
  componentDidMount() {
    const data = this.props.data;

    this.setState({
      data,
    });
  }
  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { data } = this.state;
    return (
      <PieChart width={445} height={320}>
        <Pie
          activeIndex={this.state.activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx={200}
          cy={120}
          innerRadius={70}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={this.onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
