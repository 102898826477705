import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import CloseIcon from "mdi-react/CloseIcon";
import React, { useCallback, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { format } from "date-fns";
import "../../../assets/scss/taskDetail.scss";
import Conversations from "../components/Conversations";
import Avatar from "../components/Avatar";
import { useEffect } from "react";
import { socket } from "../../../sockets";
import { connect } from "react-redux";
import Attachments from "../components/Attachments";
import { formatSlashedDate, showToast, styleHelper } from "../utils";
import styled from "styled-components";
import { setShowLoginModal } from "../../../store/actions";
import eventBus from "../../../helper/EventBus";
import { v4 as uuidv4 } from "uuid";
import { isUserInProjectApi } from "../../../services/projectServices";
import { createRootFolder } from "../../../services/taskServices";
// ... other imports requires
import watch from "redux-watch";
import store from "../../../store";
import { ShareBg } from "../../topbar/Icons";
import UploadToModal from "./UploadToModal";
import Spinner from "./../../global/Spinner";
import { TodoList } from "../components/TodoList";
import { TaskTodoList } from "../components/TaskTodoList";
import CustomerScheduleList from "../components/CustomerScheduleList";
import ContactPersonList from "../components/ContactPersonList";
import QuotationList from "../components/QuotationList";

function TaskDetailsModal({
  showTaskDetailsModal,
  setShowTaskDetailsModal,
  selectedTask,
  selectedList,
  selectedProject,
  user,
  handleClearUnread,
  setAsCover,
  teamsIBelongTo,
  showUsersList,
  projectUsersList,
  isShared,
  token,
  setShowLoginModal,
  list,
  moveTaskToList,
  isProjectLeaderOrCreatedBy,
  setShowConversationForm,
  setShowUploadQuotation,
  handleSelectUser,
  Quotes
}) {
  // const [show, setShow] = useState(false);
  const [currentTab, setCurrentTab] = useState("conversations");
  const [currentSubTab, setCurrentSubTab] = useState("todo");
  const [usersList, setUsersList] = useState({});
  // const [componentKey, setComponentKey] = useState(uuidv4());
  const [hasClickedJoin, setHasClickedJoin] = useState(false);
  const [hasBeenAccepted, setHasBeenAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rootFolderId, setRootFolderId] = useState(null);

  eventBus.useCustomEventListener("setCurrentTab", setCurrentTab);

  useEffect(() => {
    setUsersList({
      assignedTo: [...(selectedTask.assignedTo ? selectedTask.assignedTo : [])],
    });
    return () => { };
  }, [selectedTask, projectUsersList, user]);

  /*const formatDate = (date) => {
    if (!date) return "...";
    date = date.split("/");
    // console.log(date);
    const newDate = new Date(date[2], date[1], date[0]);
    return format(newDate, "MMM dd yyyy");
  };*/

  useEffect(() => {
    clearUnreadTaskComment(selectedTask);
    return () => { };
  }, [selectedTask]);

  const clearUnreadTaskComment = (selectedTask) => {
    // if (selectedTask.unreadCount) {
    socket.emit(
      "fx:clear-unread-task-comment",
      {
        taskId: selectedTask._id,
        userId: user._id,
      },
      (response) => {
        if (response.success) {
          handleClearUnread(response);
        }
      }
    );
    // }
  };

  const joinTask = () => {
    socket.emit(
      "request-to-join-task",
      {
        projectId: selectedTask.project,
        taskId: selectedTask._id,
        token,
      },
      (response) => {
        if (response.success) {
          showToast({
            message: {
              title: "You request to join this task has been sent",
            },
            type: "info",
          });
          setHasClickedJoin(true);
        } else {
          showToast({
            message: {
              title: "Unable to join Project",
            },
            type: "error",
          });
        }
      }
    );
  };

  const handleTaskJoin = () => {
    let ready = false;
    if (Object.keys(user).length) {
      // request to join project
      setInterval(async () => {
        if (ready) return;
        const res = await isUserInProjectApi({
          projectId: selectedTask.project,
          userId: user._id,
        });
        if (res.project) {
          ready = true;
          setHasBeenAccepted(true);
          showToast({
            message: {
              title: "Your request to join this task has been accept",
            },
            type: "success",
          });
        }
        // console.log("timeout", res);
      }, 5000);

      if (
        selectedTask.assignedTo?.find((assigned) => assigned._id === user._id)
      ) {
        showToast({
          message: {
            title: "Your are already part of this task",
          },
          type: "info",
        });
        return;
      }

      joinTask();
    } else {
      // login
      setShowLoginModal(true);
    }
  };

  const canSeeChat = (selectedProject, selectedTask) => {
    // const { project, teamsIBelongTo } = data;
    const project = selectedProject;
    let isVisible = false;
    const { _id } = user;
    let { createdBy, assignedTo } = selectedTask;
    const projectLeader =
      project && project?.projectLeader ? project?.projectLeader : [];

    const isAssignedTo = assignedTo?.find((assigned) => assigned._id === _id);
    const isProjectLeader = projectLeader?.find((leader) => leader._id === _id);
    const teamForSelectedProject = teamsIBelongTo?.find(
      (team) => team._id === project?.teamId
    );
    let isTeamLeader;
    if (teamForSelectedProject) {
      const teamLeaders = teamForSelectedProject?.leaders
        ? teamForSelectedProject?.leaders
        : [];
      isTeamLeader = teamLeaders?.find((leader) => leader._id === _id);
    }

    if (createdBy === _id || isAssignedTo || isProjectLeader || isTeamLeader) {
      isVisible = true;
    }

    return isVisible;
  };

  const handleAccepted = useCallback(
    (res) => {
      console.log(res);
      if (res?.success && res.taskId === selectedTask._id) {
        setHasBeenAccepted(true);
        showToast({
          message: {
            title: "Your request to join this task has been accept",
          },
          type: "success",
        });
      }
    },
    [selectedTask]
  );

  useEffect(() => {
    socket.on(`fx:request-to-join-task-accepted`, handleAccepted);

    return () => {
      socket.off(`fx:request-to-join-task-accepted`, handleAccepted);
    };
  }, [handleAccepted]);

  useEffect(() => {
    setUp();

    return () => { };
  }, []);

  const setUp = async () => {
    //console.log("mounted", selectedTask);
    const isDev = process.env.REACT_APP_ENV !== "production";
    try {
      if (isDev) {
        setRootFolderId(
          selectedProject.project_folder_id
            ? selectedProject.project_folder_id
            : "5f351216c56f413004f6d9fe"
        );
        setIsLoading(false);
      } /* else if (selectedTask.folder_id) {
        setRootFolderId(selectedTask.folder_id);
        setIsLoading(false);
      }*/ else {
        // create root folder - check if previous rootfolder was deleted
        const response = await createRootFolder({
          token,
          taskId: selectedTask._id,
        });

        // console.log(response);
        if (response.success && response.folder_id) {
          setRootFolderId(response.folder_id);
          setIsLoading(false);
        } else {
          throw new Error("");
        }
      }
    } catch (err) {
      console.log(err);
      if (!isShared) {
        setShowTaskDetailsModal(false);
      } else {
        // to login page
      }
      showToast({
        message: {
          title: "Unable to setup Task folder",
        },
        type: "error",
      });
    }
  };

  return (
    <>
      <Modal
        show={showTaskDetailsModal}
        onHide={() => {
          if (!isShared) setShowTaskDetailsModal(false);
        }}
        dialogClassName="task-detail-content"
        backdropClassName={`global-backdrop remove ${isShared ? "bg-dark-blue" : ""
          }`}
        aria-labelledby="example-custom-modal-styling-title"
        centered={false}
        animation={false}
        enforceFocus={false}
      >
        {isLoading ? (
          <div
            className="disabled-share d-flex justify-content-center align-items-center w-100 h-100 position-relative"
            style={{ background: "#ffffff", minHeight: "inherit" }}
          >
            <ShareBg className="bg" />
            <div className="content text-center">
              <Spinner spinnerHeight={`3.5rem`} />
              <p className="h3 mb-3">Please wait...</p>
            </div>
          </div>
        ) : isShared &&
          !selectedProject?.sharable?.enabled &&
          !canSeeChat(selectedProject, selectedTask) &&
          !hasBeenAccepted ? (
          <div
            className="disabled-share d-flex justify-content-center align-items-center w-100 h-100 position-absolute rounded"
            style={{ background: "#ffffff" }}
          >
            <ShareBg className="bg" />
            <div className="content text-center">
              <img
                alt=""
                src="../../assets/svg/undraw_Organizing_projects_0p9a.svg"
                className="empty-svg my-3"
              />
              <p className="h3 mb-3">This project is private</p>
              <button
                onClick={() => handleTaskJoin()}
                disabled={hasClickedJoin}
                className="btn btn-primary btn-lg"
              >
                {hasClickedJoin ? "Awaiting Response..." : "Request to join"}
              </button>
            </div>
          </div>
        ) : (
          rootFolderId && (
            <>
              <Modal.Header bsPrefix="modal-header">
                <div id="info">
                  <p
                    className="task-status d-flex align-items-center"
                    style={{
                      color: selectedList.color,
                      background: `${selectedList.color}33`,
                    }}
                  >
                    {selectedList.title}
                  </p>

                  {
                    <Dropdown
                      alignRight
                      style={
                        isProjectLeaderOrCreatedBy() ||
                          selectedTask.assignedTo.includes(user._id) ||
                          selectedTask.createdBy === user._id ||
                          selectedTask.assignedTo.find(
                            (userEl) => userEl._id === user._id
                          )
                          ? {}
                          : { visibility: "hidden" }
                      }
                    >
                      <Dropdown.Toggle
                        bsPrefix={`btn p-0 toggle-btn`}
                        style={{
                          background: "none",
                          border: "none",
                          zIndex: 10000,
                        }}
                      >
                        <span className="action">
                          <CheckCircleOutlineIcon />
                          Move Task
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        bsPrefix={`task-menu bg-white move-menu`}
                        alignRight
                      >
                        <p className="p-2 px-3 m-0 font-weight-bold">
                          Move to...
                        </p>
                        {list.map((listItem) => (
                          <span key={listItem.status}>
                            {listItem.status !== selectedTask.status && (
                              <Dropdown.Item
                                as="button"
                                style={{ color: "#788898" }}
                                onClick={() => {
                                  moveTaskToList({
                                    taskToMove: {
                                      ...selectedTask,
                                      status: listItem.status,
                                    },
                                    oldStatus: selectedTask.status,
                                    newStatus: listItem.status,
                                    saveToServer: true,
                                  });
                                }}
                              >
                                {listItem.title}
                              </Dropdown.Item>
                            )}
                          </span>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                  {isShared && (
                    <>
                      {!canSeeChat(selectedProject, selectedTask) &&
                        !hasBeenAccepted ? (
                        <button
                          className="btn btn-primary ml-3"
                          onClick={() => handleTaskJoin()}
                          disabled={hasClickedJoin}
                        >
                          {hasClickedJoin ? "Awaiting Response..." : "Join"}
                        </button>
                      ) : (
                        <a
                          className="btn btn-primary ml-3"
                          href={`/tasks-board?projectId=${selectedTask.project}&taskId=${selectedTask._id}&m=f`}
                        >
                          Go to Board
                        </a>
                      )}
                    </>
                  )}
                </div>

                <button
                  id="close-btn"
                  onClick={() => {
                    if (!isShared) setShowTaskDetailsModal(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </Modal.Header>
              <Modal.Body bsPrefix="modal-body">
                <div className="nav-tabs d-flex d-md-none">
                  <div
                    className={`tab ${currentTab === "details" && "active"}`}
                    onClick={() => setCurrentTab("details")}
                  >
                    Details
                  </div>
                  <div
                    className={`tab ${currentTab === "conversations" && "active"
                      }`}
                    onClick={() => setCurrentTab("conversations")}
                  >
                    Conversation
                  </div>
                </div>
                <div className="holder d-flex">
                  <div
                    className={`border-right ${currentTab === "details" ? "d-block" : "d-none"
                      } d-md-block`}
                  >
                    <div className="info">
                      <div className="task-header">
                        <div className="title">
                          <h1>{selectedTask.taskName}</h1>
                          <p>
                            Product of Interest: {selectedTask?.product_of_interest}
                          </p>
                        </div>

                        <div className="members">
                          <div className="d-flex">
                            {selectedTask?.assignedTo &&
                              selectedTask?.assignedTo
                                .slice(0, 3)
                                .map((user, index) => (
                                  <React.Fragment key={index}>
                                    <Avatar
                                      key={index}
                                      width={40}
                                      height={40}
                                      user={user}
                                      zIndex={10 - index}
                                    // onClick={() => handleSelectUser(user)}
                                    />
                                  </React.Fragment>
                                ))}

                            {selectedTask?.assignedTo &&
                              selectedTask?.assignedTo.length > 3 ? (
                              <Avatar
                                width={40}
                                height={40}
                                isCounter={true}
                                count={selectedTask?.assignedTo.length - 3}
                                zIndex={1}
                                usersList={usersList}
                                showUsersList={(e, users) =>
                                  showUsersList(e, users, { ignoreTop: true })
                                }
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="desc row">
                        <div className="col-md-7">
                          <p>{selectedTask.taskDetail}</p>
                          <div className="my-2">
                            <h5 className="mb-0"><strong>Company service description:</strong></h5>
                            <p>{selectedTask?.company_service_desc}</p>
                          </div>
                          {selectedTask?.company_website_address ? (
                            <p>
                              <strong>Website:</strong>&nbsp;
                              <span>{selectedTask?.company_website_address}</span>
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-5 text-center">
                          <button
                            onClick={() => {
                              setShowConversationForm(true)
                            }}
                            className="btn btn-outline-primary border-0"
                            style={{ 'font-size': '12px', 'font-weight': 'bold', 'padding': '12px 12px' }}
                          >
                            Create Conversation
                          </button>
                        </div>
                      </div>

                      <div className="nav-tabs d-flex w-100 mb-4">
                        <div
                          className={`tab ${currentSubTab === "todo" && "active"
                            }`}
                          onClick={() => setCurrentSubTab("todo")}
                        >
                          To-do
                        </div>
                        <div
                          className={`tab ${currentSubTab === "quotation" && "active"}`}
                          onClick={() => setCurrentSubTab("quotation")}
                        >
                          Quotations
                        </div>
                        <div
                          className={`tab ${currentSubTab === "schedules" && "active"}`}
                          onClick={() => setCurrentSubTab("schedules")}
                        >
                          Schedules
                        </div>
                        <div
                          className={`tab ${currentSubTab === "attachment" && "active"
                            }`}
                          onClick={() => setCurrentSubTab("attachment")}
                        >
                          Attachments
                        </div>
                        <div
                          className={`tab ${currentSubTab === "contact" && "active"
                            }`}
                          onClick={() => setCurrentSubTab("contact")}
                        >
                          Contact
                        </div>
                      </div>

                      <div>
                        {
                          currentSubTab === "todo"
                          &&
                          <TaskTodoList
                            selectedTask={selectedTask}
                            usersList={selectedTask.assignedTo}
                            user={user}
                            list={list}
                            canMarkTodo={() =>
                              canSeeChat(selectedProject, selectedTask)
                            }
                            moveTaskToList={moveTaskToList}
                          />
                        }
                        {
                          currentSubTab === 'schedules'
                          &&
                          <CustomerScheduleList selectedTask={selectedTask} usersList={selectedTask.assignedTo} />
                        }
                        {
                          currentSubTab === 'quotation'
                          &&
                          <QuotationList selectedTask={selectedTask} usersList={selectedTask.assignedTo} setShowUploadQuotation={setShowUploadQuotation} Quotes={Quotes} />
                        }
                        {
                          currentSubTab === 'contact'
                          &&
                          <ContactPersonList companyName={selectedTask.taskName} selectedTask={selectedTask} />
                        }
                        {
                          currentSubTab === 'attachment'
                          &&
                          <Attachments
                            rootFolderId={rootFolderId}
                            selectedTask={selectedTask}
                            selectedProject={selectedProject}
                            setAsCover={setAsCover}
                            canSeeChat={() =>
                              canSeeChat(selectedProject, selectedTask)
                            }
                            isShared={isShared}
                          // key={componentKey}
                          />
                        }
                      </div>
                    </div>
                  </div>

                  <Conversations
                    currentTab={currentTab}
                    selectedTask={selectedTask}
                    selectedProject={selectedProject}
                    setAsCover={setAsCover}
                    teamsIBelongTo={teamsIBelongTo}
                    clearUnreadTaskComment={clearUnreadTaskComment}
                    canSeeChat={() => canSeeChat(selectedProject, selectedTask)}
                    projectUsersList={selectedTask.assignedTo}
                    isShared={isShared}
                    rootFolderId={rootFolderId}
                  />
                </div>
              </Modal.Body>
            </>
          )
        )}
      </Modal>
      {rootFolderId && <UploadToModal rootFolderId={rootFolderId} />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShowLoginModal: (isShown) => dispatch(setShowLoginModal(isShown)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsModal);
