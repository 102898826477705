export const TOGGLE_ADD_TASK_MODAL = "TOGGLE_ADD_TASK_MODAL";
export const TOGGLE_MORE_PROJECT_VIEW_OPTIONS = "TOGGLE_MORE_PROJECT_VIEW_OPTIONS";

export function toggleAddTaskModal() {
  return {
    type: TOGGLE_ADD_TASK_MODAL,
  };
}

export function toggleMoreProjectViewOptions(value) {
  return {
    type: TOGGLE_MORE_PROJECT_VIEW_OPTIONS,
    payload: value
  };
}
