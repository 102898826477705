import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datetime";
import CloseIcon from "mdi-react/CloseIcon";

import Spinner from "../global/Spinner";

import { updateTask } from "../../services/taskServices";
import { searchUserByName } from "../../services/userServices";
import { debounce } from "../../utils";

import { URLS } from "../urls";
import { ColorPicker } from "./ColorPicker";

import "../../assets/scss/global.scss";
import "../../assets/scss/assignTask.scss";
import "../../assets/scss/switchButton.scss";

import "../../../src/assets/css/bootstrap-datetimepicker.min.css";

class EditTaskModal extends Component {
  constructor(props) {
    super(props);

    const { account_type } = props.user;

    let team = props.team || [];
    let selectedTask = props.selectedTask || [];
    let assignedTo = props.selectedTask ? props.selectedTask.assignedTo : [];

    if (team && assignedTo) {
      team = team.map((member) => {
        if (assignedTo.find((assigned) => assigned._id === member._id)) {
          member.isChecked = true;
        } else {
          member.isChecked = false;
        }
        return member;
      });
    }

    this.state = {
      submitText: "Edit Task",
      employee: null,
      projectId: props.projectId,
      newTask: props.selectedTask || {},
      newFile: {
        employeeId: null,
        employeeImage: "",
        employeeName: "",
        date: "",
        time: "",
        file: {
          message: "",
          original: "",
          unique: "",
          ext: "",
          size: "",
        },
      },
      fileObject: null,
      taskFilter: "ALL",
      projects: [],
      assignedTo,
      tasks: props.projectTasks || [],
      userWiseTasks: {},
      selectedTask: null,
      teams: {},
      team,
      teamClone: team,
      selectedMembers: assignedTo,
      clientList: [],
      employeesList: [],
      showUserWise: false,
      leadersForNotification: {},
      isSubmitWait: false,
      projectTeamList: [],
      isTaskDetailsVisible: true,
      isAssignToUsersVisible: false,
      hasFilledTaskDetails: true,
      emailToInvite: "",
      isCreating: false,
      account_type,
      canAddToCalendar: false,
      pageTitle: "Edit Task",
      newProjectMembers: [],
      newTaskMembers: [],
      membersToRemove: [],
      colors: [
        "bg-task-default",
        "bg-default",
        "bg-dark",
        "bg-primary",
        "bg-info",
        "bg-success",
        "bg-warning",
        "bg-danger",
        "bg-indigo",
        "bg-teal",
        "bg-green",
        "bg-blue",
        "bg-violet",
        "bg-flamingo",
        "bg-pink",
        "bg-westeria",
        "bg-tomato",
        "bg-orange",
        "bg-cocoa",
        "bg-board-grey",
        "bg-board-black",
        "bg-beige",
      ],
      colorPickerShow: false,
      selectedColor: this.props.selectedTask?.userColor
        ? this.props.selectedTask?.userColor
        : "#fff",
    };
  }

  componentDidMount() {}

  searchForTask = (searchParam) => {
    const token = this.props.token;
    let { teamClone } = this.state;

    if (searchParam) {
      searchUserByName(searchParam, token).then((response) => {
        if (response.users) {
          let usersToAdd = response.users;

          // Reset usersToAdd that are already members of the team displayed on the UI
          usersToAdd = usersToAdd.map((userToAdd) => {
            const member = teamClone.find(
              (currentMember) => currentMember.email === userToAdd.email
            );
            if (member) {
              if (member.isChecked) {
                member.isChecked = true;
              }
              if (member.status === "addAsProjectMember") {
                member.status = "";
              }

              userToAdd = member;
            }
            return userToAdd;
          });

          this.setState({ team: usersToAdd });
        }
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const searchParam = e.target.value.trim();
    let { teamClone } = this.state;

    if (searchParam) {
      debounce(this.searchForTask(searchParam), 500);
    } else {
      this.setState({ team: teamClone });
    }
  };

  validateNewTask = () => {
    let isValid = false;
    const { newTask } = this.state;
    const { taskName, taskDetail, dueDate } = newTask;
    if (taskName && taskName.trim().length > 1) {
      isValid = true;
    }
    return isValid;
  };

  validationStartDate = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };

  validationDueDate = (currentDate) => {
    const { newTask } = this.state;
    return currentDate.isAfter(
      moment(newTask.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  updateNewTaskStartDate = (event) => {
    const { newTask } = this.state;
    newTask.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      newTask,
    });
  };

  updateNewTaskDueDate = (event) => {
    const { newTask } = this.state;
    newTask.dueDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      newTask,
    });
  };

  updateNewTaskName = (event) => {
    const { newTask } = this.state;
    newTask.taskName = event.target.value;
    const hasFilledTaskDetails = this.validateNewTask();
    this.setState({
      newTask,
      hasFilledTaskDetails,
    });
  };

  updateNewTaskDetail = (event) => {
    const { newTask } = this.state;
    newTask.taskDetail = event.target.value;
    this.setState({
      newTask,
    });
  };

  updateNewTaskAssignedTo = (event) => {
    const { newTask } = this.state;
    newTask.assignedTo = event.target.value;
    this.setState({
      newTask,
    });
  };

  handleCloseModal = (e, wrapper) => {
    if (wrapper) {
      if (e.target !== e.currentTarget) return;
    }
    let { teamClone, team } = this.state;

    if (teamClone && teamClone.length) {
      teamClone.map((member) => {
        if (member.isChecked) {
          member.isChecked = false;
        }
        return member;
      });
      team = teamClone;
    }
    this.setState({ teamClone, team });
    this.props.closeModal();
  };

  updateTeam = (params) => {
    const { newTask, team } = this.state;

    const projectTeamList = team.filter((ar) =>
      params.find((rm) => rm._id === ar._id)
    );
    newTask.assignedTo = projectTeamList.map((ar) => ar._id);
    this.setState({
      newTask,
      projectTeamList,
    });
  };

  handleToggleLayout = (layout) => {
    let { isAssignToUsersVisible, isTaskDetailsVisible, pageTitle } =
      this.state;
    if (layout === "taskDetails") {
      pageTitle = "Edit Task";
      isAssignToUsersVisible = false;
      isTaskDetailsVisible = true;
    } else if (layout === "users") {
      pageTitle = "Assign Task";
      isAssignToUsersVisible = true;
      isTaskDetailsVisible = false;
    }
    this.setState({ isAssignToUsersVisible, isTaskDetailsVisible, pageTitle });
  };

  handleSwitch = (e) => {
    const showOnlySelected = e.target.checked;
    let { team, teamClone } = this.state;

    if (showOnlySelected) {
      team = teamClone.filter((member) => member.isChecked === true);
    } else {
      team = teamClone;
    }
    this.setState({ team });
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleSelectedMember = (member) => {
    let {
      selectedMembers,
      teamClone,
      team,
      newProjectMembers,
      newTaskMembers,
      membersToRemove,
    } = this.state;
    const isChecked = member.isChecked ? true : false;
    const memberId = member._id;

    // If user was rendered through search bar
    if (member.status === "addAsProjectMember") {
      if (!isChecked) {
        teamClone.push(member);
        newProjectMembers.push(memberId);
      } else {
        teamClone = teamClone.filter((member) => member._id !== memberId);
        newProjectMembers = newProjectMembers.filter(
          (member) => member !== memberId
        );
      }
    }

    teamClone.map((member) => {
      if (member._id === memberId) {
        member.isChecked = !isChecked;
      }
      return member;
    });

    team.map((member) => {
      if (member._id === memberId) {
        member.isChecked = !isChecked;
      }
      return member;
    });

    if (!isChecked) {
      selectedMembers.push(memberId);
      const isAssignedTo = this.isAssignedTo(member);
      if (isAssignedTo) {
        membersToRemove = membersToRemove.filter(
          (member) => member !== memberId
        );
      } else {
        newTaskMembers.push(memberId);
      }
    } else {
      selectedMembers = selectedMembers.filter(
        (selectedMember) => selectedMember !== memberId
      );
      const isAssignedTo = this.isAssignedTo(member);
      if (isAssignedTo) {
        membersToRemove.push(memberId);
      } else {
        newTaskMembers = newTaskMembers.filter((member) => member !== memberId);
      }
    }

    this.setState({
      selectedMembers,
      teamClone,
      team,
      newProjectMembers,
      newTaskMembers,
      membersToRemove,
    });
  };

  isAssignedTo = (employee) => {
    const { assignedTo } = this.state;
    const isFound = assignedTo.find(
      (assigned) => assigned._id === employee._id
    );

    return isFound;
  };

  handleInvite = (e) => {
    let { emailToInvite } = this.state;
    emailToInvite = e.target.value.trim();

    this.setState({ emailToInvite });
  };

  determineTextColour = (color) => {
    // alert(color);
    const isGrey = ["#ffffff", "beige", "#fff"];
    const isBlack = ["#ffc107"];
    return isGrey.includes(color)
      ? ""
      : isBlack.includes(color)
      ? "#000000"
      : "#ffffff";
  };

  handleUpdateTask = () => {
    this.setState({ isCreating: true });

    const {
      selectedMembers,
      newTask,
      teamClone,
      team,
      canAddToCalendar,
      newProjectMembers,
      newTaskMembers,
      membersToRemove,
      projectId,
    } = this.state;
    newTask.assignedTo = selectedMembers;
    const token = this.props.token;

    teamClone.map((member) => {
      if (member.isChecked) {
        member.isChecked = false;
      }
      return member;
    });
    team.map((member) => {
      if (member.isChecked) {
        member.isChecked = false;
      }
      return member;
    });
    this.setState({ teamClone, team });

    newTask.color = this.state.selectedColor;
    newTask.textColor = this.determineTextColour(this.state.selectedColor);
    newTask.userColor = this.state.selectedColor;
    newTask.userTextColor = this.determineTextColour(this.state.selectedColor);

    const data = {
      token,
      task: newTask,
      newTaskMembers,
      membersToRemove,
      canAddToCalendar,
      newProjectMembers,
      projectId,
    };

    updateTask(data).then((response) => {
      if (response.task) {
        this.props.taskUpdated(response);
      }
    });
  };

  handleCanAddToCalendar = () => {
    let { canAddToCalendar } = this.state;
    if (canAddToCalendar) {
      canAddToCalendar = false;
    } else {
      canAddToCalendar = true;
    }
    this.setState({ canAddToCalendar });
  };

  changeColor = (color) => {
    this.setState({
      ...this.state,
      selectedColor: color,
    });
  };

  render() {
    const {
      projects,
      activeProjectId,
      newTask,
      tasks,
      selectedTask,
      teams,
      clientList,
      employeesList,
      newComment,
      newFile,
      taskFilter,
      userWiseTasks,
      showUserWise,
      isSubmitWait,
      submitText,
      team,
      projectTeamList,
      isTaskDetailsVisible,
      isAssignToUsersVisible,
      hasFilledTaskDetails,
      user,
      isCreating,
      account_type,
      selectedMembers,
      canAddToCalendar,
      pageTitle,
      colors,
      colorPickerShow,
      selectedColor,
    } = this.state;

    return (
      <div
        id="edit_task_modal"
        className="modal custom-modal fade project-view-more-options"
        role="dialog"
        onClick={(e) => this.handleCloseModal(e, "wrapper")}
      >
        <div className="modal-dialog modal-dialog-centered modal-add-task">
          <div className="modal-content">
            <div className="m-h-between">
              <h5 className="modal-title">{pageTitle}</h5>
              <CloseIcon
                onClick={(e) => this.handleCloseModal(e)}
                data-toggle="modal"
                data-target="#edit_task_modal"
              />
            </div>
            {!isCreating ? (
              <div className="add-task-content">
                {isTaskDetailsVisible && (
                  <div className="dv-ts-details">
                    <div className="form-group row px-4 mt-4">
                      <div className="col-sm-12">
                        <div
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Task Name
                        </div>
                        <input
                          className="form-control fm-input1"
                          id="new-task"
                          placeholder="Enter new task here. . ."
                          required
                          value={newTask.taskName}
                          onChange={this.updateNewTaskName}
                        />
                      </div>
                    </div>
                    <div className="form-group row px-4 mt-4">
                      <div className="col-sm-12">
                        <div
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Task Detail
                        </div>
                        <textarea
                          className="form-control fm-input1 task-detail"
                          id="new-task"
                          placeholder="Describe what the task is about. . ."
                          required
                          value={newTask.taskDetail}
                          onChange={this.updateNewTaskDetail}
                          style={{ height: "80px" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-group row px-4 mt-4">
                      <div className="col-sm-6">
                        <div
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Start Date
                        </div>
                        <DatePicker
                          value={newTask.startDate}
                          closeOnSelect={true}
                          required={true}
                          onChange={this.updateNewTaskStartDate}
                          // isValidDate={this.validationStartDate}
                          className="w-100"
                        />
                      </div>
                      <div className="col-sm-6">
                        <div
                          className="form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Due Date
                        </div>
                        <DatePicker
                          value={newTask.dueDate}
                          closeOnSelect={true}
                          required={true}
                          onChange={this.updateNewTaskDueDate}
                          isValidDate={this.validationDueDate}
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div
                      className="form-group row flex align-items-center mx-2"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="col-sm-6">
                        <div class="color-dropdown">
                          <button
                            class="btn btn-light text-left"
                            onMouseOver={() => {
                              this.setState({
                                ...this.state,
                                colorPickerShow: true,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({
                                ...this.state,
                                colorPickerShow: false,
                              });
                            }}
                          >
                            <div className="d-flex flex-row align-items-center">
                              <span>Color</span>
                              <div
                                className="color-circle"
                                style={{ backgroundColor: selectedColor }}
                              ></div>
                            </div>
                          </button>
                          <div
                            className={!colorPickerShow ? `d-none` : ``}
                            onMouseOver={() => {
                              this.setState({
                                ...this.state,
                                colorPickerShow: true,
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({
                                ...this.state,
                                colorPickerShow: false,
                              });
                            }}
                          >
                            <div className="position-absolute">
                              <ColorPicker
                                checkedColor={this.state.selectedColor}
                                changeColor={(color) => this.changeColor(color)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-sm-6"
                        style={{ fontSize: "13px", color: "#808080" }}
                      >
                        <input
                          type="checkbox"
                          className="mr-2"
                          checked={canAddToCalendar}
                          onChange={() => this.handleCanAddToCalendar()}
                        />
                        <span
                          onClick={() => this.handleCanAddToCalendar()}
                          style={{ cursor: "default" }}
                        >
                          Add to calendar
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {isAssignToUsersVisible && (
                  <div className="dv-ts-users">
                    <div className="input-group my-4">
                      <input
                        placeholder="Search to assign"
                        className="form-control search-input spec-input mx-4"
                        type="text"
                        onChange={(e) => this.handleSearch(e)}
                      />
                    </div>

                    <div className="users-list sleek-scrollbar u-list">
                      {team && team.length ? (
                        team.map((member, index) => (
                          <div
                            className="user-info"
                            key={index}
                            onClick={() => this.handleSelectedMember(member)}
                          >
                            {member.photo ? (
                              <img
                                className="user-desc"
                                src={URLS.backendStatic + "/" + member.photo}
                              />
                            ) : (
                              <div className="user-desc user-init">
                                {this.setEmployeeInitials(member)}
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "5px",
                              }}
                            >
                              <span>{this.setEmployeeName(member)}</span>
                              <span style={{ fontSize: "13px", color: "#888" }}>
                                {member.email}
                              </span>
                            </div>
                            <input
                              type="checkbox"
                              value={member._id}
                              checked={
                                member.isChecked ? member.isChecked : false
                              }
                            />
                          </div>
                        ))
                      ) : (
                        <div className="none-found">None found</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="row flex justify-content-end mr-4 add-task-controls">
                  {isAssignToUsersVisible && (
                    <span
                      className="back"
                      onClick={() => this.handleToggleLayout("taskDetails")}
                    >
                      Back
                    </span>
                  )}

                  {isAssignToUsersVisible && selectedMembers.length ? (
                    <div className="add-task-dv-switch">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={(e) => this.handleSwitch(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                      <span className="sh-only">Show only selected</span>
                    </div>
                  ) : null}

                  <span
                    className="cancel"
                    onClick={(e) => this.handleCloseModal(e)}
                  >
                    Cancel
                  </span>

                  {isTaskDetailsVisible && (
                    <span
                      className={`${
                        hasFilledTaskDetails ? "next" : "in-active"
                      }`}
                      onClick={() => this.handleToggleLayout("users")}
                    >
                      Next
                    </span>
                  )}

                  {isAssignToUsersVisible && (
                    <span className="add" onClick={this.handleUpdateTask}>
                      Update
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className="spinner-layout">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditTaskModal));
