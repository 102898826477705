import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Spinner from "../global/Spinner";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import "../../assets/scss/global.scss";
import "../../assets/scss/performance.scss";
import { URLS } from "../urls";
import { getUsersForPerformance } from "../../services/userServices";
import moment from "moment";
import { compareAsc, compareDesc, isAfter } from "date-fns";
import PrinterIcon from "mdi-react/PrinterIcon";
import { openPageSideBar, showToast } from "../task-update/utils";
import TextIcon from "mdi-react/TextIcon";

class Performance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      employeesClone: [],
      projects: {},
      tasks: {},
      completedTasks: {},
      completionRate: {},
      isLoadingPerformance: true,
      startDate: "",
      endDate: "",
    };
  }

  async componentDidMount() {
    const { token, user } = this.props;
    let { employees } = this.state;
    const usersResponse = await getUsersForPerformance(token);

    employees = usersResponse && usersResponse.users ? usersResponse.users : [];

    employees.forEach((employee) => {
      this.fetchProjectsByEmployeeId(employee._id);
      this.fetchTasksByEmployeeId(employee._id);
    });
    this.setState({
      employees,
      employeesClone: employees,
      isLoadingPerformance: false,
    });
  }

  setStartDate = (e) => {
    this.setState({
      ...this.state,
      startDate: e.target.value,
    });

    this.state.employees.forEach((employee) => {
      // this.fetchProjectsByEmployeeId(employee._id);
      this.fetchTasksByEmployeeId(employee._id);
    });
  };

  setEndDate = (e) => {
    this.setState({
      ...this.state,
      endDate: e.target.value,
    });

    this.state.employees.forEach((employee) => {
      // this.fetchProjectsByEmployeeId(employee._id);
      this.fetchTasksByEmployeeId(employee._id);
    });
  };

  fetchProjectsByEmployeeId = (employeeId) => {
    const token = this.props.token;
    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let { projects } = this.state;
        projects[employeeId] = result.projects.length;
        this.setState({
          projects,
        });
      });
  };

  fetchTasksByEmployeeId = (employeeId) => {
    const token = this.props.token;
    fetch(`${URLS.backendTasks}/tasks/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let {
          tasks,
          completedTasks,
          projects,
          completionRate,
          startDate,
          endDate,
        } = this.state;

        let projectsInTask = {};

        if (startDate && endDate) {
          if (
            ![0, 1].includes(compareAsc(new Date(endDate), new Date(startDate)))
          ) {
          }

          result.tasks = result.tasks.filter((task) => {
            return (
              [0, 1].includes(
                compareAsc(new Date(task.createdAt), new Date(startDate))
              ) &&
              [0, 1].includes(
                compareDesc(new Date(task.createdAt), new Date(endDate))
              )
            );
          });
        }

        tasks[employeeId] = result.tasks.length;
        const completed = result.tasks.filter((task) => {
          /*let date = task.completeDate;
          if (date && startDate && endDate) {
            date = date.split("/");
            const completeDate = new Date(date[2], date[1] - 1, date[0]);
            // console.log(completeDate, task.completeDate);

            return (
              task.status === "COMPLETE" &&
              [0, 1].includes(
                compareAsc(new Date(completeDate), new Date(startDate))
              ) &&
              [0, 1].includes(
                compareDesc(new Date(completeDate), new Date(endDate))
              )
            );
          }
          return task.status === "COMPLETE" && date;*/

          return task.status === "COMPLETE" && task.completeDate;
        });

        result.tasks.forEach((task) => {
          projectsInTask[task.project] = task.project;
        });

        let delay = 0;
        if (completed.length) {
          for (let i = 0; i < completed.length; i++) {
            let item = completed[i];
            const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );

            if (diff > 0) delay += diff;
          }
        }

        completedTasks[employeeId] = completed.length;
        completionRate[employeeId] = completed.length
          ? (completed.length / (completed.length + delay * 0.1)) * 100
          : 0;

        if (startDate && endDate) {
          projects[employeeId] = Object.keys(projectsInTask).length;
          this.setState({
            tasks,
            completedTasks,
            completionRate,
            projects,
          });
        } else {
          this.setState({
            tasks,
            completedTasks,
            completionRate,
          });
        }
      });
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value.trim();
    let { employees, employeesClone } = this.state;

    if (value) {
      employees = employeesClone.filter((employee) => {
        let name;
        if (employee.name.first) {
          name = employee.name.first + " " + employee.name.last;
        } else {
          name = employee.name;
        }
        if (name.toLowerCase().includes(value)) {
          return employee;
        }
      });
    } else {
      employees = employeesClone;
    }
    this.setState({
      employees,
    });
  };

  render() {
    const {
      employees,
      projects,
      tasks,
      completedTasks,
      completionRate,
      isLoadingPerformance,
      startDate,
      endDate,
    } = this.state;
    return (
      <section>
        <div className="main-wrapper">
          <Topbar />
          <div className="d-flex new-wrapper sleek-scrollbar">
            <Sidebar activeSideBar="performance" />

            <div className="page-wrapper p-0">
              <div className="page-top-bar">
                <div className="d-flex d-md-none">
                  <button
                    className="btn p-0 mr-2"
                    onClick={() => openPageSideBar()}
                  >
                    <TextIcon />
                  </button>
                  <a>Performance</a>
                </div>
                {!isLoadingPerformance && (
                  <div className="col-sm-6 col-md-12 row align-items-center d-none d-md-flex">
                    <div className="form-group col-md-3 d-flex align-items-center mb-0">
                      <label className="mr-2">
                        <small>From</small>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => this.setStartDate(e)}
                      />
                    </div>

                    <div className="form-group col-md-3 d-flex align-items-center mb-0">
                      <label className="mr-1">
                        <small>To</small>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => this.setEndDate(e)}
                      />
                    </div>

                    <div className="form-group col-md-5 mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => this.handleSearch(e)}
                      />
                    </div>

                    <div className="form-group col-md-1 mb-0">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => window.print()}
                      >
                        <PrinterIcon />
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="content container-fluid">
                {isLoadingPerformance ? (
                  <Spinner />
                ) : (
                  <div className="performance-holder">
                    <div
                      className={`${
                        employees.length && "performance-section"
                      } `}
                    >
                      {employees.length ? (
                        <div className="performance-header">
                          <span>Member</span>
                          <span>Projects</span>
                          <span>Tasks</span>
                          <span>Completed Tasks</span>
                          <span>Progress</span>
                          <span>Completion Rate</span>
                        </div>
                      ) : null}
                      <div
                        className={`${
                          employees.length && "perfomance-body"
                        } sleek-scrollbar`}
                      >
                        {employees.length ? (
                          employees.map((employee) => {
                            const employeeId = employee._id;
                            const progress =
                              tasks[employeeId] > 0
                                ? (
                                    (completedTasks[employeeId] * 100) /
                                    tasks[employeeId]
                                  ).toFixed(0)
                                : 0;
                            const rate = completionRate[employeeId]
                              ? completionRate[employeeId].toFixed(0)
                              : 0;

                            return (
                              <div
                                key={employee._id}
                                className="performance-single-row"
                              >
                                <div>
                                  {employee.photo ? (
                                    <img
                                      alt={employee.photo}
                                      src={
                                        URLS.backendStatic +
                                        "/" +
                                        employee.photo
                                      }
                                    />
                                  ) : (
                                    <span className="av-alternative">
                                      {this.setEmployeeInitials(employee)}
                                    </span>
                                  )}
                                  <span>{this.setEmployeeName(employee)}</span>
                                </div>
                                <div>{projects[employeeId]}</div>
                                <div>{tasks[employeeId]}</div>
                                <div>{completedTasks[employeeId]}</div>
                                <div>
                                  <Progress
                                    className="performance-progress"
                                    percent={progress}
                                    status={
                                      progress < 20
                                        ? "error"
                                        : progress === 100
                                        ? "success"
                                        : ""
                                    }
                                  />
                                </div>
                                <div>
                                  <Progress
                                    className="performance-progress"
                                    percent={rate}
                                    status={
                                      rate < 20
                                        ? "error"
                                        : progress === 100
                                        ? "success"
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="no-performance">
                            <img src="../../assets/svg/project.svg" />
                            <h3>
                              <b>None Found</b>
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="row">
                    <div className="col-md-12">
                      <div>
                        <table className="table table-striped custom-table mb-0 datatable">
                          <thead>
                            <tr>
                              <th style={{ width: "80px" }}>#</th>
                              <th>Employee</th>
                              <th>Projects</th>
                              <th>Tasks</th>
                              <th>Completed Tasks</th>
                              <th>Progress</th>
                              <th>Completion Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employees.length
                              ? employees.map((employee, index) => {
                                  const employeeId = employee._id;
                                  const image = employee.emergencyInfo.image;
                                  const progress =
                                    tasks[employeeId] > 0
                                      ? (
                                          (completedTasks[employeeId] * 100) /
                                          tasks[employeeId]
                                        ).toFixed(2)
                                      : 0;
                                  const rate = completionRate[employeeId]
                                    ? completionRate[employeeId].toFixed(2)
                                    : 0;
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <Link
                                          to={`${URLS.profile}/${employeeId}`}
                                        >
                                          <span className="avatar">
                                            <img
                                              alt=""
                                              src={
                                                image
                                                  ? URLS.backendStatic +
                                                    "/" +
                                                    image
                                                  : "assets/img/profiles/avatar-09.jpg"
                                              }
                                            />
                                          </span>
                                          {employee.personalInfo.name.first +
                                            " " +
                                            employee.personalInfo.name.middle +
                                            " " +
                                            employee.personalInfo.name
                                              .last}{" "}
                                        </Link>
                                      </td>
                                      <td>{projects[employeeId]}</td>
                                      <td>{tasks[employeeId]}</td>
                                      <td>{completedTasks[employeeId]}</td>
                                      <td>
                                        <div className="widget-subbody">
                                          <div className="widget-subbody-header">
                                            Progress{" "}
                                            <span class="text-success float-right">
                                              {progress.toString() + "%"}
                                            </span>
                                          </div>
                                          <div class="progress progress-xs mb-0">
                                            <div
                                              class="progress-bar bg-success"
                                              role="progressbar"
                                              data-toggle="tooltip"
                                              title={progress.toString() + "%"}
                                              style={{ width: progress + "%" }}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="widget-subbody">
                                          <div className="widget-subbody-header">
                                            Rate{" "}
                                            {parseFloat(rate) >= 95 ? (
                                              <span class="text-success float-right">
                                                {rate.toString() + "%"}
                                              </span>
                                            ) : parseFloat(rate) >= 75 ? (
                                              <span class="text-primary float-right">
                                                {rate.toString() + "%"}
                                              </span>
                                            ) : parseFloat(rate) >= 50 ? (
                                              <span class="text-warning float-right">
                                                {rate.toString() + "%"}
                                              </span>
                                            ) : (
                                              <span class="text-danger float-right">
                                                {rate.toString() + "%"}
                                              </span>
                                            )}
                                          </div>
                                          <div class="progress progress-xs mb-0">
                                            {parseFloat(rate) >= 95 ? (
                                              <div
                                                class="progress-bar bg-success"
                                                role="progressbar"
                                                data-toggle="tooltip"
                                                title={rate.toString() + "%"}
                                                style={{ width: rate + "%" }}
                                              ></div>
                                            ) : parseFloat(rate) >= 75 ? (
                                              <div
                                                class="progress-bar bg-primary"
                                                role="progressbar"
                                                data-toggle="tooltip"
                                                title={rate.toString() + "%"}
                                                style={{ width: rate + "%" }}
                                              ></div>
                                            ) : parseFloat(rate) >= 50 ? (
                                              <div
                                                class="progress-bar bg-warning"
                                                role="progressbar"
                                                data-toggle="tooltip"
                                                title={rate.toString() + "%"}
                                                style={{ width: rate + "%" }}
                                              ></div>
                                            ) : (
                                              <div
                                                class="progress-bar bg-danger"
                                                role="progressbar"
                                                data-toggle="tooltip"
                                                title={rate.toString() + "%"}
                                                style={{ width: rate + "%" }}
                                              ></div>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}
                  </div>
                )}
              </div>
              {/*<!-- /Page Content -->*/}
            </div>
            {/*<!-- /Page Wrapper -->*/}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Performance));
