/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, useRef } from "react";
import { InvitedBadge, LeaderBadge } from "../../topbar/Icons";
import { URLS } from "../../urls";
import { checkIfAreFriends, fullName, pixelToRem } from "../utils";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import { Overlay, Popover } from "react-bootstrap";
import eventBus from "../../../helper/EventBus";
import { connect } from "react-redux";
// import Mention from "./Mention";
import { socket } from "../../../sockets";

function Avatar({
  user,
  width = 32,
  height = 32,
  zIndex,
  isCounter = false,
  count,
  showUsersList,
  usersList,
  usage = "default",
  badgeStyle = {},
  showLeader = true,
  onClick,
  displayBadge = true,
  authUser,
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [friends, setFriends] = useState(false);

  try {
    const handleClick = (event) => {
      setShow(!show);
      // setTarget(event.target);
    };
    /*const openProfile = (user) => {
    if (user?.username)
      window.location.href = `${process.env.REACT_APP_MESSENGER_FRONTEND}/profile/${user.username}`;
  };*/

    const openChat = () => {
      if (user?._id && user._id !== authUser._id) {
        eventBus.dispatch("open-chat", user._id);
      }
    };

    const friendsCheck = async () => {
      setFriends(await checkIfAreFriends([authUser._id, user._id]));
    };

    useEffect(() => {
      if (show && !isCounter) {
        friendsCheck();
      }
    }, [show]);

    return (
      <span
        className={`position-relative d-block profile-image-holder ${
          user?.username ? "p-cursor" : ""
        }`}
        style={{ zIndex, width: pixelToRem(width), height: pixelToRem(height) }}
        onClick={(e) => (onClick ? onClick(e) : handleClick(e))}
      >
        {!isCounter && (
          <Overlay
            target={target.current}
            placement="bottom"
            show={show}
            rootClose={true}
            onHide={() => setShow(false)}
          >
            <Popover className="avatar-menu">
              {authUser?._id !== user?._id && (
                <button
                  disabled={!friends}
                  onClick={() => openChat()}
                  className="btn item"
                >
                  Message {fullName(user.name)}
                </button>
              )}
              <a
                className="btn item"
                href={`${process.env.REACT_APP_MESSENGER_FRONTEND}/profile/${user.username}`}
              >
                View Profile
              </a>
            </Popover>
          </Overlay>
        )}

        {!isCounter ? (
          user && user.photo ? (
            <img
              ref={target}
              src={`${URLS.backendStatic}/${user.photo}`}
              alt=""
              style={{
                width: pixelToRem(width),
                height: pixelToRem(height),
                zIndex,
                minWidth: pixelToRem(width),
              }}
              className="user-image rounded-circle"
              title={`${user?.name?.first} ${user?.name?.last} (${user.username})`}
            />
          ) : (
            <span
              ref={target}
              style={{
                width: pixelToRem(width),
                height: pixelToRem(height),
                zIndex,
                color: "#788898",
                minWidth: pixelToRem(width),
              }}
              className="user-image rounded-circle d-flex align-item-center justify-content-center"
              title={`${fullName(user.name)} ${
                user.username ? `(${user.username})` : ""
              } `}
            >
              {fullName(user.name)?.substr(0, 1).toUpperCase()}
            </span>
          )
        ) : (
          <>
            <span
              style={{
                width: pixelToRem(width),
                height: pixelToRem(height),
                zIndex,
                fontSize: "0.8rem",
                color: "#788898",
                minWidth: pixelToRem(width),
              }}
              className="user-image rounded-circle p-cursor"
              title={`+${count} others`}
              onClick={(e) => showUsersList(e, { ...usersList })}
            >
              +{count}
            </span>
          </>
        )}

        <span className="avatar-badge">
          {displayBadge &&
            (user?.isInvited ? (
              <span title="Invited">
                <InvitedBadge
                  //  className="invited-icon-small"
                  /*
            style={{
              display: "block",
              position: "absolute",
              bottom: "-3px",
              right: "-5px",
              color: "#5D696F",
              ...(usage === "mobile"
                ? {
                    right: "4px",
                    bottom: "2px",
                  }
                : {}),
              ...badgeStyle,
            }}*/ usage={usage}
                  style={{
                    width: "1rem !important",
                    /*   position: "absolute",
                  bottom: "1px",
                  right: "-1.2rem",
                  ...(usage === "mobile"
                    ? {
                        right: "4px",
                        bottom: "2px",
                      }
                    : {}),
                  ...badgeStyle, */
                  }}
                />
              </span>
            ) : user?.isLeader && showLeader ? (
              <span title={`This member is a project leader`}>
                <LeaderBadge
                  usage={usage}
                  /* style={{
                  width: "1rem !important",
                  position: "absolute",
                  bottom: "1px",
                  right: "-1.2rem",
                  ...(usage === "mobile"
                    ? {
                        right: "4px",
                        bottom: "2px",
                      }
                    : {}),
                  ...badgeStyle,
                }} */
                />
              </span>
            ) : null)}
        </span>
      </span>
    );
  } catch (err) {
    console.log(err);
    return null;
  }
}

const mapStateToProps = (state) => ({
  authUser: state.userReducer.user,
  token: state.userReducer.token,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
