import * as Actions from "../actions";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const initialState = {
  token: cookies.get("token") || null,
  user: {},
  notificationsCount: 0,
  chatCount: 0,
  hasLoginSessionExpired: false,
  isLoading: true,
  usersLoggedIn: [],
  hasAccountExpired: false,
  showLoginModal: false,
  invex_domain: ''
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOG_IN: {
      const userInfo = action.payload;
      return {
        ...state,
        token: userInfo.token,
      };
    }
    case Actions.LOG_OUT: {
      cookies.remove("token");
      return {
        token: "",
      };
    }
    case Actions.POPULATE_USER: {
      const user = action.payload.user;
      const token = cookies.get("token");

      return {
        ...state,
        user,
        token,
        isLoading: false,
      };
    }
    case Actions.POPULATE_NOTIFICATIONS: {
      let { num, type } = action.payload;
      let { notificationsCount, chatCount } = state;
      if (type === "notification") {
        notificationsCount = num;
      } else if (type === "chat") {
        chatCount = num;
      }

      return {
        ...state,
        notificationsCount,
        chatCount,
      };
    }
    case Actions.SET_TOKEN: {
      const token = action.payload;
      return {
        ...state,
        token,
      };
    }
    case Actions.UPDATE_ACCOUNT_EXPIRATION: {
      const hasAccountExpired = action.payload;
      return {
        ...state,
        hasAccountExpired,
      };
    }
    case Actions.HAS_ONBOARDED: {
      return {
        ...state,
        user: {
          ...state.user,
          hasOnboarded: true,
        },
      };
    }
    case Actions.HAS_PERSONALISED: {
      return {
        ...state,
        user: {
          ...state.user,
          hasPersonalised: true,
        },
      };
    }
    case Actions.INVEX_DOMAIN: {
      return {
        ...state,
        invex_domain: action.payload,
      };
    }
    case Actions.SHOW_LOGIN_MODAL: {
      return {
        ...state,
        showLoginModal: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
