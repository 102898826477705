import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { formatDateOnly } from "../utils";
import { BoardColorCheckedIcon, ProjectIcon, SearchIcon } from "./Icons";

export default function ProjectSelector({
  projects = [],
  handleSelectProject,
  ProjectSelectorRef,
  showProjectSelector,
  selectedProject,
}) {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  const handleFilterProject = (projects, value = "") => {
    setFilteredProjects(
      projects.filter((el) =>
        el.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    handleFilterProject(projects, value);
  }, [projects, value]);

  useEffect(() => {
    if (showProjectSelector && inputRef && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [showProjectSelector]);

  return (
    <div
      id="project-selector"
      ref={ProjectSelectorRef}
      className={`${showProjectSelector ? "" : "d-none"}`}
    >
      {projects && projects.length > 5 ? (
        <div className="mb-3">
          <div className="d-flex align-items-center mx-4 rounded search">
            <SearchIcon className="ml-3" />
            <Form.Control
              ref={inputRef}
              value={value}
              className="border-0"
              placeholder="Search..."
              autoFocus
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
      ) : null}
      <div>
        {filteredProjects.map((project, index) => (
          <label
            key={project._id}
            className="project-label w-100"
            title={project.name}
          >
            <input
              className="d-none"
              type="radio"
              value={project._id}
              name="project-to-select"
              onChange={(e) => handleSelectProject(e.target.value)}
            />
            <div className="image-holder d-flex align-items-center justify-content-center mr-3">
              {project._id !== selectedProject._id ? (
                <ProjectIcon style={{ transform: "scale(1.2)" }} />
              ) : (
                <BoardColorCheckedIcon
                  className="shadow-lg rounded-circle border-secondary"
                  color="#3F96D1"
                />
              )}
            </div>

            <div>
              <p className="project-name">{project.name}</p>

              <p className="created-at">
                {formatDateOnly(project.createdAt, true)}
              </p>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
}
