import React, { Component, createRef, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";
import styled from "styled-components";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import BellIcon from "mdi-react/BellIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";
import MenuIcon from "mdi-react/MenuIcon";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import InviteModal from "./InviteModal";
import { URLS } from "../urls";
import { getReferralLink } from "../../services/userServices";
import {
  toggleRightSideBar,
  toggleProfileInfo,
  toggleErp,
  toggleToastMessage,
  toggleInviteModal,
} from "../../store/actions/topBarActions";
import { logOut, populateNotifications } from "../../store/actions/userActions";
import eventBus from "../../helper/EventBus";
import Avatar from "../task-update/components/Avatar";
import {
  CreatePageIcon,
  DashboardIcon,
  DownloadOutlineIcon,
  FeedbackIcon,
  FexMobileLogo,
  GridIcon,
  HelpIcon,
  InviteIcon,
  LogOut,
  PagesIcon,
  ReferIcon,
  Settings,
  TasksIcon,
  TourIcon,
  SchedulesIcon,
  MeetingIcon,
  ConversationsIcon,
  TeamIcon,
  FeedIcon,
  NotiBellIcon,
  NotiChatIcon,
  NotiNoteIcon,
  NotiInviteIcon,
  MenuIcons,
  InvexCheckCircle,
  InvexFavouriteChart,
  InvexPieChart,
  DocumentText,
  InvexSendSquare,
  InvexMoneyRecieve,
  InvexBox,
  InvexMoneyTick
} from "./Icons";
import { socket } from "../../sockets";
import { CopyToClipboard, styleHelper } from "../task-update/utils";
import { DriveIcon, ProjectSlideIcon } from "../task-update/components/Icons";
import links from './roles.json'

const cookies = new Cookies()

class Topbar extends Component {
  constructor(props) {
    super(props);

    this.navRef = createRef();
    this.erpRef = createRef();
    // this.setNavRef = this.setNavRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    const CLOUD_DRIVE_FRONTEND = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
    const COLLABORATION_FRONTEND = process.env.REACT_APP_MESSENGER_FRONTEND;

    this.state = {
      CLOUD_DRIVE_FRONTEND,
      COLLABORATION_FRONTEND,
      showSettings: false,
      isInviteModalVisible: true,
      isNavMenuOpen: false,
      pages: [],
      showPages: false,
    };
  }

  componentDidMount() {
    console.log("top bar mounted");
    const token = this.props.token;
    eventBus.on("toggleNav", (data) => {
      this.handleNavMenu();
    });
    this.getCompanyPages({
      token,
    });
    document.addEventListener("mousedown", this.handleClickOutside);
    eventBus.on("handleRightSideBarDisplay", this.handleRightSideBarDisplay);
  }

  /*componentDidUpdate(prevProps) {
    console.log(prevProps.user, this.props.user);
  }
  */

  invextoken = cookies.get('invextoken')

  componentWillUnmount() {
    eventBus.remove("couponApply");
    eventBus.remove(
      "handleRightSideBarDisplay",
      this.handleRightSideBarDisplay
    );
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    // console.log(this.navRef);
    // console.log(this.navRef)
    // console.log(this.erpRef)
    // console.log(event.target)
    if (this.navRef?.current && !this.navRef.current.contains(event.target)) {
      // console.log('pepep')
      const displayProfileInfo = this.props.displayProfileInfo;
      if (displayProfileInfo) {
        this.props.toggleProfileInfo(!displayProfileInfo);
      }
    }

    if (this.erpRef?.current && !this.erpRef.current.contains(event.target)) {
      //ggg
      const displayErp = this.props.displayErp;
      // console.log('oooo')
      if (displayErp) {
        // console.log('ueueu')
        this.props.toggleErp(!displayErp);
      }
    }

    /*if (this.state.isNavMenuOpen) {
      this.setState({
        ...this.state,
        isNavMenuOpen: false,
      });
    }
    */
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  getCompanyPages({ token }) {
    socket.emit("get-company-pages", { token }, (err, response) => {
      if (response && response.success) {
        this.setState({
          ...this.state,
          pages: response.pages,
        });
      }
    });
  }

  getDateDiff = (date) => {
    let createDate = moment(date);
    let now = moment(new Date());
    let diff = now.diff(createDate);
    diff = parseInt(diff / 1000);
    if (diff < 60) {
      return diff + " seconds ago";
    }
    diff = parseInt(diff / 60);
    if (diff < 60) {
      if (diff === 1) return "a min ago";
      else return diff + " mins ago";
    }

    diff = parseInt(diff / 60);
    if (diff < 24) {
      if (diff === 1) return "an hour ago";
      else return diff + " hours ago";
    }

    diff = parseInt(diff / 24);
    if (diff < 30) {
      if (diff === 1) return "a day ago";
      else return diff + " days ago";
    }

    diff = parseInt(diff / 30);
    if (diff === 1) return "a month ago";
    else return diff + " months ago";
  };

  handleRightSideBarDisplay = (type) => {
    this.props.toggleRightSideBar(type);
  };

  handleLogOut = async () => {
    const token = this.props.token;
    const { CLOUD_DRIVE_FRONTEND } = this.state;
    fetch(`${URLS.backendUsers}/logOut`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then(async (result) => {
        const cookies = new Cookies();
        cookies.remove("token");
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
        });
        const url = CLOUD_DRIVE_FRONTEND + "/login";
        window.location.replace(url);
      });
  };

  setUserName = (username) => {
    let name;
    if (username && username.first) {
      name = username.first + " " + username.last;
    }
    return name;
  };

  setUserInitials = (username) => {
    let name;
    if (username && username.first) {
      name = username.first.charAt(0);
      //  + ' ' + username.last.charAt(0);
    }
    return name;
  };

  handleDisplayProfileInfo = (user) => {
    if (user) {
      const displayProfileInfo = !this.props.displayProfileInfo;
      this.props.toggleProfileInfo(displayProfileInfo);
    }
  };

  toggleSettings = () => {
    const showSettings = !this.state.showSettings;
    this.setState({ showSettings });
  };

  togglePages = () => {
    const showPages = !this.state.showPages;
    this.setState({ showPages });
  };

  navigateTo = (url) => {
    const { CLOUD_DRIVE_FRONTEND } = this.state;
    const fullUrl = CLOUD_DRIVE_FRONTEND + "/" + url;
    window.location.assign(fullUrl);
  };

  handleInviteLink = () => {
    this.props.toggleProfileInfo(false);
    this.props.toggleInviteModal(true);
  };

  handleErpLink = () => {
    const displayErp = !this.props.displayErp;
    this.props.toggleErp(displayErp);
  }

  handleReferralLink = async () => {
    const token = this.props.token;
    const { CLOUD_DRIVE_FRONTEND } = this.state;
    await getReferralLink(token)
      .then((response) => {
        if (response && response.success) {
          CopyToClipboard({
            text: `${CLOUD_DRIVE_FRONTEND}/create-account?ref=${response.referral_link}`,
          });
        }
      })
      .catch((err) => { });
  };

  handleNavMenu = () => {
    let isNavMenuOpen = !this.state.isNavMenuOpen;
    this.setState({ isNavMenuOpen });
  };

  startTour = () => {
    eventBus.dispatch("start-tour", {});
  };



  render() {
    const {
      COLLABORATION_FRONTEND,
      CLOUD_DRIVE_FRONTEND,
      showSettings,
      showPages,
      isInviteModalVisible,
      isNavMenuOpen,
      introJsOptions,
      pages,
    } = this.state;
    const { notificationsCount, chatCount, user, displayProfileInfo, displayErp, usage } =
      this.props;
    const {
      photo,
      name,
      email,
      account_type,
      isAdmin,
      isSuperAdmin,
      hasOnboarded,
      current_plan,
      invex_domain,
      hasCrm,
      hasInvex,
      invex_api_url,
      department
    } = user;

    return (
      <>
        <Wrapper
          style={{
            backgroundColor: "#012E54",
            zIndex: styleHelper.isMobile
              ? "10001"
              : usage !== "board"
                ? "1004"
                : "3",
            position: "relative",
          }}
        >
          <div
            className="header"
            style={{ height: usage !== "board" ? "4.6rem" : "" }}
          >
            <a
              href="https://app.fexspace.com/"
              className="header-left px-0"
              style={{ marginTop: usage !== "board" ? "0.9rem" : "" }}
            >
              <img
                src={URLS.landingPage + "/assets/img/logo-fff.svg"}
                alt="Fexspace Logo"
                className="logo"
                style={{ objectFit: "contain" }}
              />
            </a>
            <div className="d-flex align-items-center d-md-none">
              <GridIcon
                onClick={() => this.handleNavMenu()}
                className="grid-icon"
              />
              <a href={COLLABORATION_FRONTEND} title="dashboard">
                <FexMobileLogo />
              </a>
            </div>

            <div className="d-flex align-items-center">
              <div
                className={`${isNavMenuOpen ? "overlay" : "d-none"}`}
                onClick={() => this.handleNavMenu()}
              ></div>
              <nav
                className={`nav-menu  nav-menu-mobile ${isNavMenuOpen ? "" : "nav-menu-hidden"
                  } m-0`}
              >
                <a
                  className="d-md-none"
                  href="https://app.fexspace.com/"
                  style={{ height: "4.25rem" }}
                >
                  <img
                    src={URLS.landingPage + "/assets/img/logo-blue.png"}
                    alt="Fexspace Logo"
                    className="logo m-0"
                    style={{ objectFit: "contain" }}
                  />
                </a>

                <a href={COLLABORATION_FRONTEND} id="dashboard">
                  <DashboardIcon />
                  <span className="hv-effect">Dashboard</span>
                </a>
                <a href={CLOUD_DRIVE_FRONTEND} id="drive">
                  <DriveIcon />
                  <span className="hv-effect">Drive</span>
                </a>
                <a
                  href="/projects"
                  id="projects"
                  className={`${window.location.pathname === "/projects" ? "active" : ""
                    }`}
                >
                  <ProjectSlideIcon />
                  <span className={`hv-effect`}>Projects</span>
                </a>

                <a
                  href="/tasks-board"
                  id="task"
                  className={`${["/tasks-board", "/tasks"].includes(
                    window.location.pathname
                  )
                    ? "active"
                    : ""
                    }`}
                >
                  <TasksIcon />
                  <span className={`hv-effect`}>Tasks</span>
                </a>
                {hasCrm ? (
                  <a
                    href="/crm"
                    id="task"
                    className={`${["/crm"].includes(
                      window.location.pathname
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <ConversationsIcon />
                    <span className={`hv-effect`}>CRM</span>
                  </a>
                ) : ""}
                <a
                  href={COLLABORATION_FRONTEND + "/calendar/month"}
                  id="schedules"
                >
                  <SchedulesIcon />
                  <span className="hv-effect">Schedules</span>
                </a>
                <a href={COLLABORATION_FRONTEND + "/m/meetings"} id="meeting">
                  <MeetingIcon />
                  <span className="hv-effect">Meetings</span>
                </a>
                <a
                  href={COLLABORATION_FRONTEND + "/m/chats"}
                  id="conversations"
                >
                  <ConversationsIcon />
                  <span className="hv-effect">Conversations</span>
                </a>
                <a
                  href="/teams"
                  id="team"
                  className={`${["/teams"].includes(window.location.pathname)
                    ? "active"
                    : ""
                    }`}
                >
                  <TeamIcon /> <span className="hv-effect">Teams</span>
                </a>
                {/* <a href="/tasks-board">
              <span className="hv-effect">Task</span>
            </a> */}
                <a href={COLLABORATION_FRONTEND + "/explore"} id="feed">
                  <FeedIcon />{" "}
                  <span className="hv-effect">
                    {account_type === 2 ? "Feed" : "Community"}
                    {/* <span className="span-beta">Beta</span> */}
                  </span>
                </a>
              </nav>
            </div>

            <div className="d-flex justify-content-end user-actions">
              <div className="">
                <div className="custom-tooltip-container">
                  <NotiBellIcon
                    className="c-top-icon"
                    style={{
                      position: "relative",
                    }}
                    onClick={() => {
                      this.handleRightSideBarDisplay("notification");
                    }}
                  />
                  <span
                    className="custom-tooltiptext"
                    style={{ left: "-38px" }}
                  >
                    Notification
                  </span>
                </div>

                {notificationsCount ? (
                  <span
                    className="notifications-count"
                    onClick={() => {
                      this.handleRightSideBarDisplay("notification");
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {notificationsCount}
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="">
                <div className="custom-tooltip-container">
                  <NotiChatIcon
                    onClick={() => {
                      this.handleRightSideBarDisplay("chat");
                    }}
                  />

                  <span
                    className="custom-tooltiptext"
                    style={{ left: "-35px" }}
                  >
                    Messenger
                  </span>
                </div>
                {chatCount ? (
                  <span
                    className="notifications-count"
                    onClick={() => {
                      this.handleRightSideBarDisplay("chat");
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {chatCount}
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="">
                <div className="custom-tooltip-container">
                  <NotiNoteIcon
                    className="c-top-icon"
                    onClick={() => {
                      this.handleRightSideBarDisplay("note");
                    }}
                  />
                  <span
                    className="custom-tooltiptext"
                    style={{ left: "-15px" }}
                  >
                    Note
                  </span>
                </div>
              </div>
              <div className="">
                <div className="custom-tooltip-container">
                  <NotiInviteIcon
                    className="c-top-icon"
                    style={{
                      position: "relative",
                    }}
                    onClick={() => this.handleInviteLink()}
                  />
                  <span className="custom-tooltiptext" style={{ left: "0px" }}>
                    Invite
                  </span>
                </div>
              </div>
              <div ref={this.ErpRef}>
                <div className="custom-tooltip-container data-erp"
                  onClick={() => this.handleErpLink()}
                >
                  {hasInvex ? (<MenuIcons
                    className="p-cursor"
                    style={{
                      position: "relative",
                      cursor: "pointer"
                    }}
                  //  onClick={() => this.handleErpLink()}
                  />) : ""}
                  <span className="custom-tooltiptext" style={{ left: "-30px", width: "80px" }}>
                    ERP Tools
                  </span>
                </div>
                {displayErp ? (
                  <div
                    className="display-more data-profile px-1 mt-3 sleek-scrollbar white-scroll erp-menu"
                    id="user-menu"
                  >
                    <h4>ERP Tools</h4>
                    {
                      isSuperAdmin ?
                        (
                          <ul>
                            {
                              links.map((link, index) => (
                                <li key={index}>
                                  <h5>{link.name}</h5>
                                  <div className="menu-grid">
                                    {
                                      link.roles.map((role, i) => (
                                        <a key={i} href={invex_domain + '/auth?invextoken=' + this.invextoken + '&redirect=' + invex_domain + role.link + '&route=' + invex_api_url} target="_blank">
                                          <span>
                                            <InvexMoneyTick />
                                          </span>
                                          <span>
                                            {role.name}
                                          </span>
                                        </a>
                                      ))
                                    }
                                  </div>
                                </li>
                              ))
                            }
                          </ul>
                        ) : (
                          <ul>
                            {
                              links.map((link, index) => (
                                link.name == department ?
                                  <li key={index}>
                                    <h5>{link.name}</h5>
                                    <div className="menu-grid">
                                      {
                                        link.roles.map((role, i) => (
                                          <a key={i} href={invex_domain + '/auth?invextoken=' + this.invextoken + '&redirect=' + invex_domain + role.link + '&route=' + invex_api_url} target="_blank">
                                            <span>
                                              <InvexMoneyTick />
                                            </span>
                                            <span>
                                              {role.name}
                                            </span>
                                          </a>
                                        ))
                                      }
                                    </div>
                                  </li>
                                  : ''
                              ))
                            }
                          </ul>
                        )
                    }
                  </div>
                ) : null}
              </div>
              <div ref={this.NavRef}>
                <div
                  className="dv-user-detail data-profile d-flex"
                  style={{ zIndex: "10000000" }}
                  onClick={(user) => this.handleDisplayProfileInfo(user)}
                >
                  {user.name && (
                    <Avatar
                      user={user}
                      width={42}
                      height={42}
                      onClick={() => { }}
                    />
                  )}
                </div>

                {displayProfileInfo ? (
                  <div
                    className="display-more data-profile px-3 mt-3 sleek-scrollbar white-scroll"
                    id="user-menu"
                  >
                    <div className="user-details flex-column p-0">
                      <Avatar user={user} width={67} height={67} />
                      <p className="name">
                        {user.name.first} {user.name.last}
                      </p>

                      <p className="email">{user.email}</p>

                      <a
                        className="btn btn-white border-white mt-1 text-primary"
                        href={`https://cloud.fexspace.com/update-profile`}
                      >
                        Update Profile
                      </a>
                    </div>

                    <div className="mb-2">
                      <a
                        className="btn btn-outline-primary w-100 download"
                        href="https://fexspace.com/downloads/Fexspace-client-Setup.exe"
                      >
                        <DownloadOutlineIcon />{" "}
                        <span className="ml-2">Download File Sync App</span>
                      </a>
                    </div>

                    {user.account_type !== 1 && user.isAdmin ? (
                      <div className="dv-st">
                        <a
                          className="txt-gray"
                          href={`https://cloud.fexspace.com/create-page`}
                        >
                          <CreatePageIcon />
                          <span className="text">Create Page</span>
                        </a>
                      </div>
                    ) : null}

                    {user.account_type !== 1 && user.isAdmin ? (
                      <>
                        <div
                          onClick={() => this.togglePages()}
                          className="dv-st display-flex justify-content-between"
                        >
                          <span>
                            <PagesIcon />

                            <span className="text">Pages</span>
                          </span>
                          {!showPages ? (
                            <ChevronRightIcon className="dt-icon" />
                          ) : (
                            <ChevronDownIcon className="dt-icon" />
                          )}
                        </div>

                        {showPages
                          ? pages.map((page, index) => {
                            return (
                              <div key={index} className="dv-all-settings">
                                <a
                                  href={`https://app.fexspace.com/company/${page.url}`}
                                  className="text-dark"
                                >
                                  <ArrowRightIcon className="sgs-icon" />{" "}
                                  <span>{page.name}</span>
                                </a>
                              </div>
                            );
                          })
                          : null}
                      </>
                    ) : null}

                    <div
                      onClick={() => this.toggleSettings()}
                      className="dv-st display-flex justify-content-between"
                    >
                      <span>
                        <Settings />{" "}
                        <span className="text ml-5"> Settings</span>
                      </span>
                      {!showSettings ? (
                        <ChevronRightIcon className="dt-icon" />
                      ) : (
                        <ChevronDownIcon className="dt-icon" />
                      )}
                    </div>
                    {showSettings ? (
                      <div className="dv-all-settings">
                        <div
                          onClick={() =>
                            this.navigateTo("create-public-folder")
                          }
                        >
                          <ArrowRightIcon className="sgs-icon" />{" "}
                          <span>Create Public Folder</span>
                        </div>
                        {account_type === 2 && isSuperAdmin ? (
                          <div
                            onClick={() => this.navigateTo("create-department")}
                          >
                            <ArrowRightIcon className="sgs-icon" />{" "}
                            <span>All Department</span>
                          </div>
                        ) : null}
                        {account_type === 2 && isSuperAdmin ? (
                          <div
                            onClick={() =>
                              this.navigateTo("group-folder-request")
                            }
                          >
                            <ArrowRightIcon className="sgs-icon" />{" "}
                            <span>Group Folder Request</span>
                          </div>
                        ) : null}
                        {account_type === 2 && isSuperAdmin ? (
                          <div onClick={() => this.navigateTo("all-users")}>
                            <ArrowRightIcon className="sgs-icon" />{" "}
                            <span>All Users</span>
                          </div>
                        ) : null}
                        <div onClick={() => this.navigateTo("my-plan")}>
                          <ArrowRightIcon className="sgs-icon" />{" "}
                          <span>My Plan</span>
                        </div>
                        {account_type === 1 || isSuperAdmin ? (
                          <div
                            onClick={() => this.navigateTo("payments-billing")}
                          >
                            <ArrowRightIcon className="sgs-icon" />{" "}
                            <span>Payments &amp; Billing</span>
                          </div>
                        ) : null}
                        <div onClick={() => this.navigateTo("usage-quota")}>
                          <ArrowRightIcon className="sgs-icon" />{" "}
                          <span>Usage Quota</span>
                        </div>
                        <div onClick={() => this.navigateTo("update-profile")}>
                          <ArrowRightIcon className="sgs-icon" />{" "}
                          <span>Update Profile</span>
                        </div>
                      </div>
                    ) : null}
                    <div
                      onClick={() => this.handleInviteLink()}
                      className="dv-st"
                    >
                      <span>
                        <InviteIcon />
                        <span className="text">Invite People</span>
                      </span>
                    </div>

                    <div className="dv-st">
                      <a
                        className="txt-gray"
                        href="https://fexspace.com/feedback"
                      >
                        <FeedbackIcon />
                        <span className="text"> Send a Feedback</span>
                      </a>
                    </div>

                    <div
                      onClick={() => this.handleReferralLink()}
                      className="dv-st"
                    >
                      <span>
                        <ReferIcon />
                        <span className="text">Copy Referral Link</span>
                      </span>
                    </div>

                    <div className="dv-st">
                      <a className="txt-gray" href="https://fexspace.com/faq">
                        <HelpIcon /> <span className="text">Help</span>
                      </a>
                    </div>

                    <div onClick={() => this.startTour()} className="dv-st">
                      <span>
                        <TourIcon />
                        <span className="text">Take a Tour</span>
                      </span>
                    </div>

                    {current_plan === 1 ? (
                      <div onClick={() => this.navigateTo("payments-billing")}>
                        <span className="btn-upgrade">Upgrade to Pro</span>
                      </div>
                    ) : null}

                    <div className="py-2 p-0"></div>

                    <div
                      onClick={() => this.handleLogOut()}
                      style={{ cursor: "pointer" }}
                      className="dv-st border-top py-4"
                    >
                      <LogOut />
                      <span className="text" style={{ color: "#D20505" }}>
                        Logout
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSmallChatVisible: state.topBarReducer.isSmallChatVisible,
    token: state.userReducer.token,
    user: state.userReducer.user,
    hasCrm: state.userReducer.user.hasCrm,
    hasInvex: state.userReducer.user.hasInvex,
    notificationsCount: state.userReducer.notificationsCount,
    chatCount: state.userReducer.chatCount,
    displayProfileInfo: state.topBarReducer.displayProfileInfo,
    displayErp: state.topBarReducer.displayErp
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRightSideBar: (value) => dispatch(toggleRightSideBar(value)),
    logOut: () => dispatch(logOut()),
    populateNotifications: (num, type) =>
      dispatch(populateNotifications(num, type)),
    toggleProfileInfo: (value) => dispatch(toggleProfileInfo(value)),
    toggleErp: (value) => dispatch(toggleErp(value)),
    toggleToastMessage: (value) => dispatch(toggleToastMessage(value)),
    toggleInviteModal: (value) => dispatch(toggleInviteModal(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));

const Wrapper = styled.div`
  .header {
    // font-family: "CircularStd" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    // height: 8.5rem;
    height: 4.5rem;
    padding: 0 2rem;
    display: flex;
    position: relative;
    z-index: 3;
    // width: 86rem;
    margin: auto;
    color: #111111;
    box-shadow: none;

    .logo {
      width: 7.3rem;
      height: 2.5rem;
    }

    .user-actions {
      flex-grow: 1;
    }
  }

  .header-left {
    margin-top: 0.9rem;
    width: 7.3rem;
    margin-right: 1.5rem;
  }

  .nav-menu {
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    margin: auto;
    margin-top: 0rem;
    // width: 56rem;
    width: 54.875rem;
    line-height: 0;

    a {
      height: 2.625rem;
      display: flex;
      align-items: center;
    }

    > a {
      color: #788898;
      margin-right: 0px;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      font-weight: 500;

      line-height: 0;

      svg {
        display: none;
      }
    }

    .hv-effect {
      // padding: 10px 15px;
      // position: relative;
      line-height: 0;
    }

    .active,
    a:hover {
      color: #fff;
      background: #133862;
      border-radius: 100px;
      -webkit-transition-property: none;
      -moz-transition-property: none;
      -o-transition-property: none;
      transition-property: none;
      transition: none;
    }

    .span-beta {
      background: #a00606;
      color: #fff;
      border-radius: 5px;
      padding: 1px 2px;
      position: absolute;
      font-size: 11px;
      margin-top: 10px;
      margin-left: 2px;
      line-height: 1.5;
    }
  }

  .notification-length {
    background-color: red !important;
  }

  .small_chat {
    position: fixed;
    top: 60px;
    right: 0;
    height: 100%;
    width: 350px;
    background-color: #fff;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px,
      rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
  }

  .small_chat_visible {
    transform: translateX(0%);
    z-index: 1000;
  }

  .small_chat_hidden {
    transform: translateX(120%);
  }

  .dv-user-detail {
  }

  .sp-initials {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #e2e8f0;
    color: #718096;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }

  .sp-image {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    /*margin-left: 40px;
    margin-right: 15px;
    */
    cursor: pointer;
  }

  .sp-username {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    color: #fff;
    font-weight: normal !important;
    cursor: default;
    font-size: 13px !important;
    cursor: pointer;
  }

  .display-more {
    position: fixed;
    // right: 40px;
    right: 2rem;
    top: 2.65rem;
    width: 246px;
    background-color: #fff;
    z-index: 1000000;
    box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.133),
      0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11);
    font-weight: normal !important;
    font-size: 13px;
    color: #111111;
    display: flex;
    flex-direction: column;
    user-select: none;

    > div {
      display: flex;
    }

    > div:nth-child(1) {
      align-items: center;
      padding: 5px 10px 10px;

      .account-circle {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }

      span:nth-child(2) {
        margin-left: 3px;
      }
    }

    .dv-st {
      cursor: pointer;
      font-weight: 500;

      &:hover,
      svg:hover,
      a:hover {
        color: #3f96d1;
      }

      &:first-child {
        display: flex;
        align-items: center;
      }

      .text {
        margin-left: 0.6rem !important;
      }
    }

    .dv-st.border-top {
      border-top: 1px solid #a7b9cb !important;
    }

    > div:not(:nth-child(1)):not(:nth-child(2)):not(.dv-all-settings) {
      justify-content: start;
      padding: 10px;
      // border-top: 1px solid #f1f1f1;
      align-items: center;

      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    > div:nth-child(2) {
      justify-content: space-between;
      padding: 0;
      align-items: center;
    }

    .dt-email {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
      font-size: 0.875rem;
      font-weight: normal;
    }

    .txt-gray {
      color: #111111;
    }

    .dt-icon {
      height: 16px;
      width: 16px;
    }

    .sgs-icon {
      height: 13px;
      width: 13px;
    }

    > .dv-all-settings {
      flex-direction: column;
      padding-left: 10px;

      > div,
      > a {
        border-bottom: 1px solid #f1f1f1;
        padding: 10px;
        width: 100%;
        cursor: pointer;

        > span:nth-child(2) {
          padding-left: 7px;
        }
      }
    }

    .btn-upgrade {
      background-color: #012e54;
      width: 100%;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      padding: 10px 0;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .messenger-icon {
    height: 19px;
    width: 19px;
    object-fit: contain;
    cursor: pointer;
  }

  .c-top-icon {
    height: 1.4rem;
    width: 1.4rem;
    color: #fff;
    cursor: pointer;
  }

  .margin-left-15 {
    margin-left: 15px;

    padding-top: 8px;
  }

  .notifications-count {
    color: #fff;
    background-color: red;
    position: absolute;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    margin-left: 10px;
  }

  .custom-tooltip-container {
    position: relative;
    display: inline;
  }

  .custom-tooltip-container .custom-tooltiptext {
    visibility: hidden;
    background-color: #596a73;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 2px 5px;
    position: absolute;
    z-index: 1;
    top: 25px;
    opacity: 0;
    transition: opacity 0.5s, padding 0.3s;
    font-size: 12px;
  }

  .custom-tooltip-container:hover .custom-tooltiptext {
    visibility: visible;
    opacity: 1;
    padding: 5px 10px;
  }

  .mobile-menu {
    color: #fff;
    display: none;
  }

  .user-actions {
    // margin-top: 1rem;
    gap: 1rem;
    align-items: center;
  }

  #user-menu {
    box-shadow: 0px 5px 44px 0px #b2bdd357;
    border-radius: 0.25rem;
    // height: 90vh;
    height: fit-content;
    max-height: 90vh;
    overflow: auto;
    width: 17.5rem;
    font-size: 0.875rem !important;
    padding-top: 1.875rem;
    // padding-bottom: 1rem;

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar {
      width: 0.5rem !important;
      height: 0.5rem !important;
    }

    .btn {
      font-size: 0.875rem !important;
      font-weight: 600;
      padding: 0.875rem 0;
      border-radius: 0.5rem;
      width: 100%;
    }

    .user-details {
      p {
        margin: 0;
        font-size: 1rem;
        line-height: 140%;
      }

      .name {
        margin-top: 0.895rem;
        font-weight: 600;
        color: #111111;
      }
      .email {
        font-weight: 400;
        color: #4b5458 !important;
      }
    }

    .download {
      margin-top: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 960px) {
    .header {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem 0 1rem;
    }
    .grid-icon {
      margin-right: 1.125rem;
    }
    .user-actions {
      justify-content: start !important;
      flex-grow: initial !important;
    }
    .custom-tooltip-container {
      // width: 100%;
    }
    .display-more {
      right: 10px;
    }
  }

  @media (max-width: 960px) {
    .overlay {
      background-color: #0e1d2f8a;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .header-left {
      display: none;
    }

    .nav-menu {
      all: unset;
      z-index: 2;
      transition: all 0.35s;
      transition-timing-function: ease;
    }

    .nav-menu-mobile {
      display: flex;
      flex-direction: column;
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      border-radius: 1px;
      box-shadow: 0px 5px 44px 0px #b2bdd357;
      width: 255px;
      align-items: flex-start;
      padding: 10px;
      border-raduis: 0.25rem;
      height: 100vh;
      padding: 0;
      box-shadow: rgba(209, 219, 228, 0.22) 10px 4px 13px;
      a {
        padding: 0 1.5rem;
        height: 3rem;
        margin: 0;
        display: flex;
        align-items: center;
        color: #5d696f;
        font-size: 0.875rem;
        width: 100%;
        gap: 1.125rem;
        border-radius: 0;
        font-weight: 600;

        &:hover {
          background: unset;
          color: initial;
        }

        svg {
          display: unset;
          color: rgba(167, 185, 203);
        }
      }

      .active {
        border-radius: 0;
        background: #f2f7fc;
        color: rgb(16, 46, 81);

        svg {
          color: #3f96d1;
        }
      }
    }

    .nav-menu-hidden {
      left: -100vw;
    }

    .mobile-menu {
      display: block;
      margin: auto auto auto 10px;
    }
  }
`;
