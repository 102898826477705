export const LOG_OUT = "LOG_OUT";
export const LOG_IN = "LOG_IN";
export const POPULATE_USER = "POPULATE_USER";
export const POPULATE_NOTIFICATIONS = "POPULATE_NOTIFICATIONS";
export const SET_TOKEN = "SET_TOKEN";
export const UPDATE_ACCOUNT_EXPIRATION = "UPDATE_ACCOUNT_EXPIRATION";
export const HAS_ONBOARDED = "HAS_ONBOARDED";
export const HAS_PERSONALISED = "HAS_PERSONALISED";
export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const INVEX_DOMAIN = "INVEX_DOMAIN";

export function logOut() {
  return {
    type: LOG_OUT,
  };
}

export function logIn(user) {
  return {
    type: LOG_IN,
    payload: user,
  };
}

export function populateUser(user, shouldUpdateToken) {
  return {
    type: POPULATE_USER,
    payload: { user, shouldUpdateToken },
  };
}

export function populateNotifications(num, type) {
  return {
    type: POPULATE_NOTIFICATIONS,
    payload: { num, type },
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

export function updateAccountExpiration(hasAccountExpired) {
  return {
    type: UPDATE_ACCOUNT_EXPIRATION,
    payload: hasAccountExpired,
  };
}

export function setOnboarding() {
  return {
    type: HAS_ONBOARDED,
  };
}

export function setPersonalised() {
  return {
    type: HAS_PERSONALISED,
  };
}

export function setInvexDomain(domain) {
  return {
    type: INVEX_DOMAIN,
    payload:domain
  };
}

export function setShowLoginModal(isShown) {
  return {
    type: SHOW_LOGIN_MODAL,
    payload: isShown,
  };
}
