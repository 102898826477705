import AtIcon from "mdi-react/AtIcon";
import React, { useRef, useEffect } from "react";
import { Overlay, Popover } from "react-bootstrap";
import EmojiPicker from "./EmojiPicker";
import Mention from "./Mention";

const EditCommentReplyForm = React.memo(
  ({
    reply,
    currentCommentReplyToEdit,
    replyRefs,
    handleOnchange,
    resizeInput,
    saveTheEditedCommentReply,
    setCurrentCommentReplyToEdit,
    editedCommentReply,
    setShowUsersListReply,
    setShowEmojiReply,
    EmoticonHappyOutlineIcon,
    handleSelectUser,
    setShowUsersList,
    showUsersListReply,
    showEmojiReply,
    projectUsersList,
    selectEmoji,
    resizeInputRef,
    focusRef,
  }) => {
    useEffect(() => {
      if (replyRefs?.current[reply._id]) {
        setTimeout(() => {
          resizeInputRef(replyRefs.current[reply._id]);
          focusRef(replyRefs.current[reply._id]);
        }, 0);
      }
    }, [replyRefs.current]);

    const emojiTarget = useRef(null);
    const mentionTarget = useRef(null);

    return (
      <div className={`edit-box mt-1`}>
        <textarea
          ref={(el) => (replyRefs.current[reply._id] = el)}
          className="border p-2 text-dark rounded reply-textarea"
          value={editedCommentReply}
          style={{ fontSize: "0.875rem" }}
          onChange={(e) => {
            handleOnchange({
              e,
              textareaRef: {
                current: replyRefs.current[reply._id],
              },
              usage: "reply",
            });
          }}
          onInput={(e) => resizeInput(e)}
          onFocus={(e) => resizeInput(e)}
          spellCheck={false}
          autoFocus
        ></textarea>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              className="btn btn-sm btn-primary mr-2"
              onClick={() => {
                saveTheEditedCommentReply();
              }}
            >
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setCurrentCommentReplyToEdit(null);
              }}
            >
              ✖
            </button>
          </div>

          <div className="flex utils">
            <button
              ref={mentionTarget}
              className="btn p-0 mr-2 p-cursor"
              onClick={() => {
                setShowUsersListReply(!showUsersListReply);
                setShowEmojiReply(false);
              }}
            >
              <AtIcon />
            </button>

            <button
              ref={emojiTarget}
              className="btn p-0 mr-2 p-cursor"
              onClick={() => {
                setShowEmojiReply(!showEmojiReply);
                setShowUsersList(false);
              }}
            >
              <EmoticonHappyOutlineIcon />
            </button>
          </div>
        </div>

        <>
          <Overlay
            target={mentionTarget.current}
            show={showUsersListReply}
            // placement="top"
            rootClose={true}
            onHide={() => setShowUsersListReply(false)}
          >
            <Popover className="border-0">
              <Mention
                hideTitle={true}
                hideSearchInput={true}
                key={projectUsersList}
                setShowUsersList={setShowUsersListReply}
                handleSelectUser={(user) =>
                  handleSelectUser({
                    user,
                    textareaRef: {
                      current: replyRefs.current[reply._id],
                    },
                    usage: "reply",
                  })
                }
                inputFocus={false}
                usersList={projectUsersList}
                style={{
                  position: "relative",
                  maxWidth: "75vw",
                }}
                listStyle={{
                  maxHeight: "10rem",
                  background: "#ffffff",
                }}
              />
            </Popover>
          </Overlay>

          <Overlay
            target={emojiTarget.current}
            show={showEmojiReply}
            placement="top"
            rootClose={true}
            onHide={() => setShowEmojiReply(false)}
          >
            <Popover className="border-0">
              <EmojiPicker
                setShowEmoji={setShowEmojiReply}
                selectEmoji={(emoji) =>
                  selectEmoji({
                    emoji,
                    textareaRef: {
                      current: replyRefs.current[reply._id],
                    },
                    usage: "reply",
                  })
                }
              />
            </Popover>
          </Overlay>
        </>
      </div>
    );
  }
);

export default EditCommentReplyForm;
