import React, { useState, useEffect } from "react";
import { URLS } from "../urls";

import FileIcon from "mdi-react/FileIcon";
import FileWordIcon from "mdi-react/FileWordBoxIcon";
import FileExcelIcon from "mdi-react/FileExcelBoxIcon";
import FilePdfIcon from "mdi-react/FilePdfBoxIcon";
import LoadIcon from "mdi-react/ClockTimeThreeIcon";

import "../../assets/scss/singleComment.scss";
import { socket } from "../../sockets";
import shortid from "shortid";

export default function SingleComment({
  comment,
  handleOpenFileViewer,
  setEmployeeInitials,
  setCommentToReply,
  setComment,
  hideActions,
  addCommentReply,
  hideReply,
  hideShowReplies,
  isParent,
}) {
  const [showReplies, setShowReplies] = useState(false);

  const getReplies = (comment) => {
    socket.emit(
      "fx:task-get-comment-replies",
      { parentCommentId: comment._id },
      async (response) => {
        // console.log(response);
        if (response && response.success) {
          addCommentReply({
            commentId: comment._id,
            taskCommentReplies: response.taskCommentReplies,
          });
        }
      }
    );
  };

  return (
    <div className={`single-comment ${isParent ? "mt-3" : ""}`}>
      <div>
        {comment.employeeId && comment.employeeId.photo ? (
          <img src={URLS.backendStatic + "/" + comment.employeeId.photo} />
        ) : (
          <span className="sp-initials">
            {setEmployeeInitials(comment.employeeName)}
          </span>
        )}

        <div>
          <div>
            <span>{comment.employeeName}</span>
            <div className="text-secondary">
              <span>{comment.date}</span>
              <span>{comment.time}</span>
            </div>
          </div>

          {comment.files && comment.files.length
            ? comment.files.map((file, index) =>
                file.extension === "link" ? (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href={file.uniqueName}
                      target="blank"
                      className="file-info-text"
                    >
                      {file.uniqueName}
                    </a>
                  </div>
                ) : file.cloud_id ? (
                  <div
                    data-toggle="modal"
                    data-target="#view_file"
                    onClick={() => handleOpenFileViewer(file)}
                    style={{
                      cursor: file.cloud_id ? "pointer" : "default",
                    }}
                    className="file-detail-flex"
                  >
                    {file.extension.includes("pdf") ? (
                      <FilePdfIcon className="file-pdf-icon" />
                    ) : file.extension.includes("doc") ? (
                      <FileWordIcon className="file-word-icon" />
                    ) : file.extension.includes("xls") ? (
                      <FileExcelIcon className="file-excel-icon" />
                    ) : (
                      <FileIcon className="file-other-icon" />
                    )}
                    <span
                      // href={`${cloudDriveUrl}/${file.cloud_id}`}
                      className="file-info-text"
                      style={{
                        fontSize: "12px",
                        marginLeft: "7px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "70%",
                      }}
                    >
                      {file.originalName + "." + file.extension}
                    </span>
                    {!file.cloud_id ? (
                      <LoadIcon className="load-file-icon" />
                    ) : null}
                  </div>
                ) : null
              )
            : null}

          {comment.comment ? (
            <div className="only-comment">{comment.comment}</div>
          ) : null}
        </div>
      </div>

      {!hideActions ? (
        <div className="comment-reply text-secondary">
          {!hideReply && (
            <span
              onClick={() => {
                setShowReplies(true);
                getReplies(comment);
                setCommentToReply(comment);
                setComment({
                  text: `@${comment.employeeName.replace(/ /g, "")} `,
                });
              }}
            >
              Reply
            </span>
          )}

          {!hideShowReplies && (
            <span
              onClick={() => {
                if (!showReplies) getReplies(comment);
                setShowReplies(!showReplies);
              }}
            >
              {showReplies ? "Hide" : "Show"} replies
            </span>
          )}
        </div>
      ) : null}

      {showReplies && comment?.taskCommentReplies ? (
        <div className="comment-replies mt-3">
          {comment.taskCommentReplies.map((commentReply) => {
            commentReply._id = comment._id;
            return (
              <SingleComment
                key={shortid.generate() + commentReply._id}
                handleOpenFileViewer={handleOpenFileViewer}
                comment={commentReply}
                setEmployeeInitials={setEmployeeInitials}
                setCommentToReply={setCommentToReply}
                setComment={setComment}
                addCommentReply={addCommentReply}
                hideShowReplies={true}
                isParent={false}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
