import PaletteIcon from "mdi-react/PaletteIcon";
import React, { useRef, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import colorImage from "../../../assets/test-img/color-image.png";
import eventBus from "../../../helper/EventBus";
import { darkTaskColors, lightTaskColors } from "../utils";
import { ColorCheckedIcon, ColorPickerIcon } from "./Icons";

export default function ColorPicker({
  type,
  handleSelectedColor,
  hidden,
  selectedColor = "",
  pickerIconStyle,
  usage = "",
}) {
  const toggleBtn = useRef({});
  const [colors, setColors] = useState(
    type === "light" ? lightTaskColors : darkTaskColors
  );
  const [show, setShow] = useState(false);
  eventBus.useCustomEventListener("show-color-picker", () => setShow(true));

  return (
    <Dropdown drop={"up"} show={show} onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        ref={toggleBtn}
        bsPrefix={`p-0 m-0 select-color-box`}
        style={{
          ...(selectedColor && usage === "task"
            ? { background: selectedColor }
            : { background: `url("${colorImage}")`, backgroundSize: "cover" }),
          ...(hidden
            ? {
                visibility: "hidden",
                height: "5px",
              }
            : {}),
          ...pickerIconStyle,
        }}
      >
        {usage !== "task" || !selectedColor ? <ColorCheckedIcon /> : null}
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={`color-picker-contents shadow`}>
        {colors.map((color, index) => (
          <div
            key={index}
            className="select-color-box"
            style={{
              background: color.colorCode,
              border: `1px solid ${color.border}`,
            }}
            onClick={() => handleSelectedColor(color.colorCode)}
          >
            {selectedColor === color.colorCode ? <ColorCheckedIcon /> : null}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
