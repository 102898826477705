import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import moment from "moment";
import { Pie } from "react-chartjs-2";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { getCompanyUsers } from "../../services/userServices";
import { getClients } from "../../services/clientServices";
import { getProjectsIBelongTo } from "../../services/projectServices";
import { getProjectsAndTasksIBelongTo } from "../../services/taskServices";
import { getTeamsIBelongTo } from "../../services/teamServices";

import "../../assets/scss/reports.scss";
import "../../assets/scss/new-ui.scss";

import Spinner from "../global/Spinner";
import FolderOpenIcon from "mdi-react/FolderOpenIcon";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import CalendarCheckIcon from "mdi-react/CalendarCheckIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";

import PieChartModel from "./PieChartModel";
import PrinterIcon from "mdi-react/PrinterIcon";
import { openPageSideBar } from "../task-update/utils";
import TextIcon from "mdi-react/TextIcon";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      clients: [],
      projects: [],
      totalTasks: 0,
      teams: [],
      tasks: new Map(),
      rates: new Map(),
      departmentEmployees: [],
      yearWiseEmployees: [],
      isLoading: true,
      data: [
        { name: "Teams", value: 0 },
        { name: "Projects", value: 0 },
        { name: "Tasks", value: 0 },
      ],
    };
  }
  groupByProjectId(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        const temp = { complete: 0, incomplete: 0, total: 0 };
        if (item.status === "COMPLETE") {
          temp.complete += 1;
          temp.total += 1;
        } else {
          temp.incomplete += 1;
          temp.total += 1;
        }
        map.set(key, temp);
      } else {
        const temp = collection;
        if (item.status === "COMPLETE") {
          temp.complete += 1;
          temp.total += 1;
        } else {
          temp.incomplete += 1;
          temp.total += 1;
        }
        map.set(key, temp);
      }
    });
    return map;
  }
  groupByEmployeeId(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const assignedTo = keyGetter(item);
      if (assignedTo) {
        assignedTo.forEach((user) => {
          const key = user._id;
          const collection = map.get(key);
          if (!collection) {
            const temp = { total: 0, delay: 0 };
            if (item.status === "COMPLETE") {
              temp.total += 1;
              const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
                moment(item.dueDate, "DD/MM/YYYY"),
                "days"
              );
              if (diff > 0) temp.delay += diff;
            }
            map.set(key, temp);
          } else {
            const temp = collection;
            if (item.status === "COMPLETE") {
              temp.total += 1;
              const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
                moment(item.dueDate, "DD/MM/YYYY"),
                "days"
              );
              if (diff > 0) temp.delay += diff;
            }
            map.set(key, temp);
          }
        });
      }
    });
    // console.log(map);
    return map;
  }

  async componentDidMount() {
    const token = this.props.token;
    let { employees, clients, projects, tasks, rates, teams, totalTasks } =
      this.state;

    const [
      usersResponse,
      clientsResponse,
      projectsResponse,
      dataResponse,
      teamResponse,
    ] = await Promise.all([
      getCompanyUsers(token),
      getClients(token),
      getProjectsIBelongTo(token),
      getProjectsAndTasksIBelongTo(token),
      getTeamsIBelongTo(token),
    ]);

    employees =
      usersResponse && usersResponse.users ? usersResponse.users : employees;
    clients =
      clientsResponse && clientsResponse.clients
        ? clientsResponse.clients
        : clients;
    projects =
      projectsResponse && projectsResponse.projects
        ? projectsResponse.projects
        : projects;
    teams = teamResponse.teams || [];

    if (dataResponse && dataResponse.tasks) {
      totalTasks = dataResponse.tasks.length;
      tasks = this.groupByProjectId(dataResponse.tasks, (task) => task.project);
      rates = this.groupByEmployeeId(
        dataResponse.tasks,
        (task) => task.assignedTo
      );
      // rates.sort((a,b) => (a.delay > b.delay) ? 1 : ((b.delay > a.delay) ? -1 : 0));
    }

    this.setState({
      employees,
      clients,
      projects,
      totalTasks,
      tasks,
      rates,
      teams,
      isLoading: false,
    });
  }
  getEmployeeRateData = (employees, rates) => {
    let empData = employees.length
      ? employees.map((employee) => {
          const rateObject = rates.get(employee._id)
            ? rates.get(employee._id)
            : { total: 0, delay: 0 };
          const rate =
            rateObject.total > 0
              ? (rateObject.total /
                  (rateObject.total + rateObject.delay * 0.1)) *
                100
              : 0;
          const name = employee.name.first + " " + employee.name.last;
          return {
            image: employee.photo,
            id: employee._id,
            rate: rate,
            name: name,
          };
        })
      : null;
    return empData
      ? empData.sort((a, b) => (a.rate > b.rate ? -1 : b.rate > a.rate ? 1 : 0))
      : null;
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  getClientInitials = (name) => {
    return name.charAt(0);
  };

  render() {
    const {
      employees,
      projects,
      clients,
      tasks,
      totalTasks,
      rates,
      teams,
      departmentEmployees,
      yearWiseEmployees,
      isLoading,
      data,
    } = this.state;
    const { user } = this.props;
    const employeeRateData = this.getEmployeeRateData(employees, rates);

    return (
      <div className="main-wrapper">
        <Topbar />
        <div className="d-flex new-wrapper sleek-scrollbar">
          <Sidebar activeSideBar="reports" />

          <div className="page-wrapper p-0 bg-white">
            <div className="reports-container p-0">
              <div className="page-top-bar">
                <div className="d-flex" onClick={() => openPageSideBar()}>
                  <button className="btn d-md-none p-0 mr-3">
                    <TextIcon />
                  </button>
                  <a href="#">Home</a>
                </div>

                <div className="hide-on-print absolute d-flex justify-content-end mr-4">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => window.print()}
                  >
                    <PrinterIcon />
                  </button>
                </div>
              </div>
              {isLoading ? (
                <Spinner spinnerHeight="calc(100vh - 10rem)" />
              ) : (
                <>
                  <div className="dashboard-row-one m-3 mt-4 mx-4">
                    <div>
                      <span>
                        <FolderOpenIcon />
                      </span>
                      <div>
                        <span>Projects</span>
                        <span>{projects.length}</span>
                      </div>
                    </div>
                    <div>
                      <span>
                        <AccountMultipleIcon />
                      </span>
                      <div>
                        <span>Clients</span>
                        <span>{clients.length}</span>
                      </div>
                    </div>
                    <div>
                      <span>
                        <CalendarCheckIcon />
                      </span>
                      <div>
                        <span>Tasks</span>
                        <span>{totalTasks}</span>
                      </div>
                    </div>
                    <div>
                      <span>
                        <AccountGroupIcon />
                      </span>
                      <div>
                        <span>Teams</span>
                        <span>{teams.length}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-row-two m-3 mx-4">
                    <div>
                      <span className="header-one">Task Completion Rate</span>
                      {employeeRateData && employeeRateData.length ? (
                        <>
                          <div>
                            <span>
                              {user.account_type === 1
                                ? "Collaborators"
                                : "Employee"}
                            </span>
                            <span>Completion Rate</span>
                          </div>
                          <div>
                            {employeeRateData && employeeRateData.length
                              ? employeeRateData.map((employee, index) => {
                                  const rate = employee.rate.toFixed(2);
                                  const image = employee.image;
                                  if (index < 4)
                                    return (
                                      <div>
                                        {image ? (
                                          <img
                                            src={
                                              URLS.backendStatic + "/" + image
                                            }
                                            className="sp-image"
                                          />
                                        ) : (
                                          <span className="sp-initial">
                                            {this.setEmployeeInitials(employee)}
                                          </span>
                                        )}
                                        <span>
                                          {this.setEmployeeName(employee)}
                                        </span>
                                        <span>
                                          <Progress
                                            percent={parseFloat(rate)}
                                          />
                                        </span>
                                        {/* <span>100%</span> */}
                                      </div>
                                    );
                                })
                              : null}
                          </div>
                          <Link
                            to={`${URLS.performance}`}
                            className="sp-view-more"
                          >
                            <span>View More</span>
                          </Link>{" "}
                        </>
                      ) : (
                        <span className="none-found-text">None found</span>
                      )}
                    </div>
                    <div>
                      <span>Overall Stats</span>
                      <div>
                        <PieChartModel
                          data={[
                            { name: "Teams", value: teams.length },
                            { name: "Projects", value: projects.length },
                            { name: "Tasks", value: tasks.size },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-row-three m-3 mx-4">
                    <div>
                      <span className="header-one">Clients</span>
                      {clients && clients.length ? (
                        <>
                          <div>
                            <span>Client Name</span>
                            <span>Email Address</span>
                            <span>Phone Number</span>
                          </div>
                          <div>
                            {clients && clients.length
                              ? clients.map((client, index) => {
                                  if (index < 4)
                                    return (
                                      <div>
                                        {client.image ? (
                                          <img
                                            src={
                                              URLS.backendStatic +
                                              "/" +
                                              client.image
                                            }
                                            className="sp-image"
                                          />
                                        ) : (
                                          <span className="sp-initial">
                                            <span>
                                              {this.getClientInitials(
                                                client.companyName
                                              )}
                                            </span>
                                          </span>
                                        )}
                                        <span>{client.companyName}</span>
                                        <span>{client.contactPersonEmail}</span>
                                        <span>{client.companyPhone}</span>
                                      </div>
                                    );
                                })
                              : null}
                          </div>
                          <Link to={`${URLS.clients}`} className="sp-view-more">
                            <span>View More</span>
                          </Link>
                        </>
                      ) : (
                        <span className="none-found-text">None found</span>
                      )}
                    </div>
                    <div>
                      <span className="header-one">Recent Projects</span>
                      {projects && projects.length ? (
                        <>
                          <div>
                            <span>Project Name</span>
                            <span>Progress</span>
                          </div>
                          <div>
                            {projects && projects.length
                              ? projects.map((project, index) => {
                                  const task = tasks.get(project._id)
                                    ? tasks.get(project._id)
                                    : { complete: 0, total: 0, incomplete: 0 };
                                  const progress = task
                                    ? task.total > 0
                                      ? (
                                          (task.complete * 100) /
                                          task.total
                                        ).toFixed(2)
                                      : 0
                                    : 0;

                                  if (index < 3)
                                    return (
                                      <div>
                                        <div>
                                          <span>{project.name}</span>
                                          <div>
                                            <span>
                                              {task ? task.incomplete : null}
                                            </span>
                                            open task,
                                            <span>
                                              {task ? task.complete : null}
                                            </span>
                                            completed
                                          </div>
                                        </div>
                                        <span>
                                          <Progress
                                            percent={Math.trunc(progress)}
                                          />
                                        </span>
                                        {/* <span>{Math.trunc(progress)}</span> */}
                                      </div>
                                    );
                                })
                              : null}
                          </div>
                          <Link
                            to={`${URLS.projects}`}
                            className="sp-view-more"
                          >
                            <span>View More</span>
                          </Link>
                        </>
                      ) : (
                        <span className="none-found-text">None found</span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/*<!-- /Page Wrapper --> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
