import { combineReducers } from "redux";
import userReducer from "./userReducer";
import clientReducer from "./clientReducer";
import topBarReducer from "./topBarReducer";
import projectReducer from "./projectReducer";
import taskReducer from "./taskReducer";
import boardSettingsReducer from "./boardSettingsReducer";
import uploadFileReducer from "./uploadFileReducer";
import commentReducer from "./commentReducer";

const appReducer = combineReducers({
  userReducer: userReducer,
  clientReducer: clientReducer,
  topBarReducer: topBarReducer,
  projectReducer: projectReducer,
  taskReducer: taskReducer,
  boardSettingsReducer: boardSettingsReducer,
  uploadFileReducer: uploadFileReducer,
  commentReducer: commentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
