import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import Spinner from "../global/Spinner";
import Project from "../projects/Project";
import AllTypeTasksClient from "../tasks/AllTypeTasksClient";
import "../../assets/scss/global.scss";
import "../../assets/scss/teams.scss";
import { URLS } from "../urls";
import HSBar from "react-horizontal-stacked-bar-chart";
import moment from "moment";
import {
  getTeamById,
  getDateWiseCompletedTasksByTeamId,
  getProjectsByTeamId,
  getTasksByTeamId,
} from "../../services/teamServices";
import { getUser } from "../../services/userServices";
import { getProjectsByEmployeeId } from "../../services/projectServices";
import CompletionTaskChart from "./CompletionTaskChart";
import PerfomanceTaskChart from "./PerfomanceTaskChart";

// const completionTaskData = [
//     {date : '21 Mar', tasks : 150 },
//     {date : '22 Mar', tasks : 50 },
//     {date : '23 Mar', tasks : 450 },
//     {date : '24 Mar', tasks : 300 },
//     {date : '25 Mar', tasks : 250 },
//     {date : '26 Mar', tasks : 350 },
//     {date : '27 Mar', tasks : 100 }
// ];
// const perfomanceTaskData = {
//     Assigned : [{type : 'Assigned', tasks : 100 }],
//     Completed : [{type : 'Completed', tasks : 75 }],
//     Active : [{type : 'Active', tasks :  60}],
// }

class MemberView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {},
      editTeam: "",
      projects: {},
      tasks: {},
      completedTasks: {},
      completionRate: {},
      activeTasks: {},
      overDueTasks: {},
      underReviewTasks: {},
      perfomanceTaskData: {
        Assigned: [{ type: "Assigned", tasks: 0 }],
        Completed: [{ type: "Completed", tasks: 0 }],
        Active: [{ type: "Active", tasks: 0 }],
      },
      completionTaskData: [],
      isLoading: true,
      activeView: "projects",
      memberProjects: [],
      memberTasks: [],
      memberInfo: {},
    };
  }

  selectClient = (_id) => {
    this.setState({ selectedProjectId: _id });
  };

  editProject = async (project) => {
    await this.setState({ editProjectData: project });
    this.handleModal();
  };

  async componentDidMount() {
    const teamId = window.location.pathname.split("/")[2];
    const userId = window.location.pathname.split("/")[3];
    const token = this.props.token;

    let {
      projects,
      team,
      tasks,
      completedTasks,
      activeTasks,
      overDueTasks,
      underReviewTasks,
      perfomanceTaskData,
      completionTaskData,
      memberProjects,
      memberTasks,
      memberInfo,
    } = this.state;

    const userResponse = await getUser(token, userId);
    memberInfo = userResponse && userResponse.user ? userResponse.user : {};
    const dateWiseTasksResponse = await getDateWiseCompletedTasksByTeamId(
      token,
      teamId
    );

    const projectsResponse = await getProjectsByEmployeeId(token, userId);

    // const projectsResponse = await getProjectsByTeamId(token, teamId);
    const memberTasksResponse = await getTasksByTeamId(token, teamId);

    // team = teamResponse && teamResponse.team ? teamResponse.team : {};
    // completionTaskData =
    //   dateWiseTasksResponse && dateWiseTasksResponse.team
    //     ? dateWiseTasksResponse.tasks
    //     : [];
    // memberProjects =
    //   projectsResponse && projectsResponse.projects
    //     ? projectsResponse.projects
    //     : [];
    // memberTasks =
    //   memberTasksResponse && memberTasksResponse.tasks
    //     ? memberTasksResponse.tasks
    //     : [];

    // if (
    //   projectsResponse &&
    //   projectsResponse.projects &&
    //   Object.entries(team).length
    // ) {
    //   const pl = projectsResponse.projects.length;
    //   projects["open"] = pl;
    //   projects["complete"] = 0;
    //   team.leaders.forEach((leader) => {
    //     projects[leader.id] = pl;
    //     tasks[leader.id] = 0;
    //     completedTasks[leader.id] = 0;
    //     underReviewTasks[leader.id] = 0;
    //     activeTasks[leader.id] = 0;
    //     overDueTasks[leader.id] = 0;
    //   });
    //   team.members.forEach((member) => {
    //     projects[member.id] = pl;
    //     tasks[member.id] = 0;
    //     completedTasks[member.id] = 0;
    //     underReviewTasks[member.id] = 0;
    //     activeTasks[member.id] = 0;
    //     overDueTasks[member.id] = 0;
    //   });
    // }

    // if (memberTasks.length) {
    //   tasks["open"] = 0;
    //   tasks["complete"] = 0;
    //   tasks["total"] = 0;
    //   tasks["assigned"] = 0;
    //   if (memberTasks) {
    //     memberTasks.forEach((task) => {
    //       tasks["total"] += 1;
    //       let assignedUser = task.assignedTo;
    //       if (assignedUser && assignedUser !== "") {
    //         tasks["assigned"] += 1;
    //         tasks[assignedUser] += 1;
    //         if (task.completeDate) {
    //           if (task.status === "UNDER_REVIEW") {
    //             underReviewTasks[assignedUser] += 1;
    //             tasks["open"] += 1;
    //           } else {
    //             completedTasks[assignedUser] += 1;
    //             tasks["complete"] += 1;
    //           }
    //         } else {
    //           tasks["open"] += 1;
    //           const diff = moment(new Date(), "DD/MM/YYYY").diff(
    //             moment(task.dueDate, "DD/MM/YYYY"),
    //             "days"
    //           );
    //           if (diff > 0) overDueTasks[assignedUser] += 1;

    //           activeTasks[assignedUser] += 1;
    //         }
    //       }
    //     });
    //   }
    //   if (tasks["total"]) {
    //     perfomanceTaskData = {
    //       Assigned: [
    //         {
    //           type: "Assigned",
    //           tasks: parseFloat(
    //             ((tasks["assigned"] * 100) / tasks["total"]).toFixed(2)
    //           ),
    //         },
    //       ],
    //       Completed: [
    //         {
    //           type: "Completed",
    //           tasks: parseFloat(
    //             ((tasks["complete"] * 100) / tasks["total"]).toFixed(2)
    //           ),
    //         },
    //       ],
    //       Active: [
    //         {
    //           type: "Active",
    //           tasks: parseFloat(
    //             ((tasks["open"] * 100) / tasks["total"]).toFixed(2)
    //           ),
    //         },
    //       ],
    //     };
    //   }
    // }

    this.setState({
      //   team,
      //   completionTaskData,
      //   projects,
      //   tasks,
      //   completedTasks,
      //   activeTasks,
      //   overDueTasks,
      //   underReviewTasks,
      //   perfomanceTaskData,
      //   memberProjects: memberProjects,
      //   memberTasks: memberTasks,
      memberInfo: memberInfo,
      isLoading: false,
    });
  }

  dateInFormat(date) {
    if (date) date = date.split("T")[0];
    return date;
  }

  setEmployeeName = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleActiveView = (activeView) => {
    this.setState({ activeView });
  };

  render() {
    const {
      team,
      projects,
      tasks,
      completedTasks,
      overDueTasks,
      activeTasks,
      underReviewTasks,
      perfomanceTaskData,
      completionTaskData,
      isLoading,
      activeView,
      memberProjects,
      memberTasks,
      memberInfo,
    } = this.state;
    return (
      <div className="main-wrapper">
        <Topbar />
        <Sidebar activeSideBar="teams" />

        {isLoading ? (
          <Spinner spinnerHeight="600px" />
        ) : (
          <div className="team-view-layout">
            <div className="team-view-header">
              <span></span>
              <div>
                <div>
                  <div>
                    <span>{tasks["open"] || 0}</span>
                    <span>Tasks Open</span>
                  </div>
                  ,
                  <div>
                    <span>{tasks["complete"] || 0}</span>
                    <span>Tasks Completed</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>{projects["open"] || 0}</span>
                    <span>Projects Open</span>
                  </div>
                  ,
                  <div>
                    <span>{projects["complete"] || 0}</span>
                    <span>Projects Completed</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="member-view-body sleek-scrollbar">
              <div>
                <div>
                  <span className="header-title">Team member</span>
                  <div>
                    {memberInfo && memberInfo.photo ? (
                      <img src={URLS.backendStatic + "/" + memberInfo.photo} />
                    ) : (
                      <span className="av-alternative">
                        {this.setEmployeeInitials(memberInfo)}
                      </span>
                    )}
                    <div className="team-created-by-name">
                      <span>{this.setEmployeeName(memberInfo)}</span>
                      <span>{memberInfo.email}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <span className="header-title">Task Performance</span>
                    <div>
                      <div>
                        <span className="bg-assigned-legend"></span>
                        <span>Assigned</span>
                        <span>(100%)</span>
                      </div>
                      <div>
                        <span className="bg-completed-legend"></span>
                        <span>Completed</span>
                        <span>(100%)</span>
                      </div>
                      <div>
                        <span className="bg-active-legend"></span>
                        <span>Active</span>
                        <span>(100%)</span>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>

              <div className="team-view-tab">
                <span
                  className={`${
                    activeView === "projects" ? "task-active-view" : ""
                  }`}
                  onClick={() => this.handleActiveView("projects")}
                >
                  Projects
                </span>
                <span
                  className={`${
                    activeView === "tasks" ? "task-active-view" : ""
                  }`}
                  onClick={() => this.handleActiveView("tasks")}
                >
                  Tasks
                </span>
              </div>

              {activeView === "projects" && (
                <div className="team-view-projects">
                  {memberProjects && memberProjects.length ? (
                    memberProjects.map((project) => {
                      let projectTeam = [];
                      let projectLeader = [];
                      if (project.team && project.team.length) {
                        projectTeam = project.team.map((employee) => {
                          let name = employee.name.first;
                          name += employee.name.middle
                            ? " " + employee.name.middle
                            : "";
                          name += employee.name.last
                            ? " " + employee.name.last
                            : "";
                          const id = employee._id;
                          const image = employee.photo;
                          return { name: name, id: id, image: image };
                        });
                      }
                      if (
                        project.projectLeader &&
                        project.projectLeader.length
                      ) {
                        projectLeader = project.projectLeader.map(
                          (employee) => {
                            let name = employee.name.first;
                            name += employee.name.middle
                              ? " " + employee.name.middle
                              : "";
                            name += employee.name.last
                              ? " " + employee.name.last
                              : "";
                            const id = employee._id;
                            const image = employee.photo;
                            return {
                              name: name,
                              id: id,
                              image: image,
                            };
                          }
                        );
                      }
                      return (
                        <Project
                          key={project._id}
                          project={project}
                          projectTeamList={projectTeam}
                          projectLeaderList={projectLeader}
                          onEdit={this.editProject}
                          onDelete={this.selectClient}
                        />
                      );
                    })
                  ) : (
                    <div className="team-view-none">
                      <h5>No Project Found</h5>
                    </div>
                  )}
                </div>
              )}
              {activeView === "tasks" && (
                <div className="team-view-tasks">
                  {memberTasks && memberTasks.length ? (
                    <div>
                      <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#all_tasks"
                            data-toggle="tab"
                            aria-expanded="true"
                          >
                            All Tasks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#pending_tasks"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            Pending Tasks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#completed_tasks"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            Completed Tasks
                          </a>
                        </li>
                      </ul>
                      <AllTypeTasksClient projectTasks={memberTasks} />
                    </div>
                  ) : (
                    <div className="team-view-none">
                      <h5>No Task Found</h5>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* <div className="team-view-body sleek-scrollbar">
              
              {activeView === "members" && (
                <div className="team-view-members">
                  <div>
                    <span>Employee</span>
                    <span>Projects</span>
                    <span>Tasks</span>
                    <span>Completed Tasks</span>
                    <span>Progress</span>
                  </div>

                  <div>
                    {team.members && team.members.length ? (
                      team.members.map((member) => {
                        const id = member.id;
                        return (
                          <div>
                            <div>
                              {member && member.photo ? (
                                <img
                                  src={URLS.backendStatic + "/" + member.photo}
                                />
                              ) : (
                                <span className="av-alternative2">
                                  {this.setEmployeeInitials(member)}
                                </span>
                              )}
                              <span>{this.setEmployeeName(member)}</span>
                            </div>
                            <span>{projects[id]}</span>
                            <span>{tasks[id]}</span>
                            <span>{completedTasks[id]}</span>
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-center">
                        <h4>No Task Found</h4>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {activeView === "projects" && (
                <div className="team-view-projects">
                  {memberProjects.length ? (
                    memberProjects.map((project) => {
                      let projectTeam = [];
                      let projectLeader = [];
                      if (project.team && project.team.length) {
                        projectTeam = project.team.map((employee) => {
                          let name = employee.name.first;
                          name += employee.name.middle
                            ? " " + employee.name.middle
                            : "";
                          name += employee.name.last
                            ? " " + employee.name.last
                            : "";
                          const id = employee._id;
                          const image = employee.photo;
                          return { name: name, id: id, image: image };
                        });
                      }
                      if (
                        project.projectLeader &&
                        project.projectLeader.length
                      ) {
                        projectLeader = project.projectLeader.map(
                          (employee) => {
                            let name = employee.name.first;
                            name += employee.name.middle
                              ? " " + employee.name.middle
                              : "";
                            name += employee.name.last
                              ? " " + employee.name.last
                              : "";
                            const id = employee._id;
                            const image = employee.photo;
                            return {
                              name: name,
                              id: id,
                              image: image,
                            };
                          }
                        );
                      }
                      return (
                        <Project
                          key={project._id}
                          project={project}
                          projectTeamList={projectTeam}
                          projectLeaderList={projectLeader}
                          onEdit={this.editProject}
                          onDelete={this.selectClient}
                        />
                      );
                    })
                  ) : (
                    <div className="team-view-none">
                      <h5>No Project Found</h5>
                    </div>
                  )}
                </div>
              )}
              {activeView === "tasks" && (
                <div className="team-view-tasks">
                  {memberTasks && memberTasks.length ? (
                    <div>
                      <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#all_tasks"
                            data-toggle="tab"
                            aria-expanded="true"
                          >
                            All Tasks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#pending_tasks"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            Pending Tasks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#completed_tasks"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            Completed Tasks
                          </a>
                        </li>
                      </ul>
                      <AllTypeTasksClient projectTasks={memberTasks} />
                    </div>
                  ) : (
                    <div className="team-view-none">
                      <h5>No Task Found</h5>
                    </div>
                  )}
                </div>
              )}
            </div> */}
          </div>
        )}

        {/* <div className="page-wrapper">
          <div className="content container-fluid">
            
            <div className="row">
              <div className="col-sm-7 col-5">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`${URLS.teams}`}>Teams</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Team Profile</BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5">
                <div className="profile-info-left">
                  <h3 className="user-name m-t-0 mb-0">{team.name}</h3>
                  <small
                    className="block text-ellipsis"
                    style={{ marginBottom: "5px" }}
                  >
                    <span
                      className="text-xl"
                      style={{ verticalAlign: "middle" }}
                    >
                      <i style={{ fontSize: "24px" }} className="la la-group" />{" "}
                    </span>{" "}
                    <span class="text-xs">{team.totalSize}</span>{" "}
                    <span className="text-muted"> Members </span>
                    <span
                      className="text-xs"
                      style={{ verticalAlign: "middle" }}
                    >
                      <i
                        style={{ fontSize: "24px" }}
                        className="la la-calendar"
                      ></i>{" "}
                    </span>{" "}
                    <span className="text-muted">
                      {" "}
                      Created: {this.dateInFormat(team.createdAt)}
                    </span>
                  </small>
                  <small
                    className="block text-ellipsis"
                    style={{ marginBottom: "5px" }}
                  >
                    <span
                      className="text-xl"
                      style={{ verticalAlign: "middle" }}
                    >
                      <i
                        style={{ fontSize: "24px" }}
                        className="la la-rocket"
                      />{" "}
                    </span>
                    <span class="text-xs">{projects["open"]}</span>{" "}
                    <span class="text-muted">Open Projects, </span>
                    <span class="text-xs">{projects["complete"]}</span>{" "}
                    <span class="text-muted">Completed Projects</span>
                  </small>
                  <small
                    className="block text-ellipsis"
                    style={{ marginBottom: "5px" }}
                  >
                    <span
                      className="text-xl"
                      style={{ verticalAlign: "middle" }}
                    >
                      <i
                        style={{ fontSize: "24px" }}
                        className="la la-clock-o"
                      ></i>{" "}
                    </span>
                    <span class="text-xs">{tasks["open"]}</span>{" "}
                    <span class="text-muted">Open Tasks, </span>
                    <span class="text-xs">{tasks["complete"]}</span>{" "}
                    <span class="text-muted">Completed Tasks</span>
                  </small>
                  <div class="text-muted">{team.description}</div>
                  <br />
                  <div className="project-members m-b-15">
                    <div>Team Leader:</div>
                    <ul style={{ listStyle: "none", padding: "0 20px" }}>
                      {team.leaders &&
                        team.leaders.map((leader) => {
                          const id = leader.id;
                          return (
                            <div
                              style={{ display: "flex", padding: "4px 0px" }}
                            >
                              <div
                                className="title"
                                style={{ flex: "1", textAlign: "center" }}
                              >
                                <a href="" title={leader.name} class="avatar">
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={
                                      leader.photo
                                        ? URLS.backendStatic +
                                          "/" +
                                          leader.photo
                                        : "/assets/img/profiles/avatar-03.jpg"
                                    }
                                    alt={"picokdone"}
                                  />
                                </a>
                                <div>{leader.name}</div>
                              </div>
                              <div
                                className="text"
                                style={{ flex: "2", textAlign: "center" }}
                              >
                                <HSBar
                                  height={15}
                                  data={[
                                    {
                                      name: "Completed",
                                      value: completedTasks[id],
                                      color: "#9c27b0",
                                    },
                                    {
                                      name: "Under Review",
                                      value: underReviewTasks[id],
                                      color: "#8884d8",
                                    },
                                    {
                                      name: "Active",
                                      value: activeTasks[id],
                                      color: "#00b386",
                                    },
                                    {
                                      name: "Overdue",
                                      value: overDueTasks[id],
                                      color: "#f23602",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-6">
                    Completion Tasks
                    <CompletionTaskChart data={completionTaskData} />
                  </div>
                  <div className="col-md-6">
                    Tasks Perfomance
                    <PerfomanceTaskChart data={perfomanceTaskData} />
                    <div>
                      <div style={{ display: "inline-block" }}>
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            borderRadius: "10px",
                            backgroundColor: "#8884d8",
                            display: "inline-block",
                            margin: "0 8px",
                          }}
                        ></div>
                        <span>
                          Assigned{" "}
                          {tasks["total"]
                            ? (
                                (tasks["assigned"] * 100) /
                                tasks["total"]
                              ).toFixed(2)
                            : 0}
                          %
                        </span>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            borderRadius: "10px",
                            backgroundColor: "#00b386",
                            display: "inline-block",
                            margin: "0 8px",
                          }}
                        ></div>
                        <span>
                          Active{" "}
                          {tasks["total"]
                            ? ((tasks["open"] * 100) / tasks["total"]).toFixed(
                                2
                              )
                            : 0}
                          %
                        </span>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            borderRadius: "10px",
                            backgroundColor: "#9c27b0",
                            display: "inline-block",
                            margin: "0 8px",
                          }}
                        ></div>
                        <span id="name-completed">
                          Completed{" "}
                          {tasks["total"]
                            ? (
                                (tasks["complete"] * 100) /
                                tasks["total"]
                              ).toFixed(2)
                            : 0}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-box tab-box">
              <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item">
                      <a
                        href="#members_board"
                        data-toggle="tab"
                        className="nav-link active"
                      >
                        Members Board
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#team_projects"
                        data-toggle="tab"
                        className="nav-link"
                      >
                        Projects
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#team_tasks"
                        data-toggle="tab"
                        className="nav-link"
                      >
                        Tasks
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div
                id="members_board"
                className="pro-overview tab-pane fade show active"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <table className="table table-striped custom-table mb-0 datatable">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Projects</th>
                            <th>Tasks</th>
                            <th>Completed Tasks</th>
                            <th>Progress</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              Tasks
                              <div style={{ display: "inline-block" }}>
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#9c27b0",
                                    display: "inline-block",
                                    margin: "0 8px",
                                  }}
                                ></div>
                                <span id="name-completed">Completed</span>
                              </div>
                              <div style={{ display: "inline-block" }}>
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#8884d8",
                                    display: "inline-block",
                                    margin: "0 8px",
                                  }}
                                ></div>
                                <span>Under Review</span>
                              </div>
                              <div style={{ display: "inline-block" }}>
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#00b386",
                                    display: "inline-block",
                                    margin: "0 8px",
                                  }}
                                ></div>
                                <span>Active</span>
                              </div>
                              <div style={{ display: "inline-block" }}>
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#f23602",
                                    display: "inline-block",
                                    margin: "0 8px",
                                  }}
                                ></div>
                                <span>Overdue</span>
                              </div>
                            </td>
                          </tr>
                          {team.members
                            ? team.members.map((member, index) => {
                                const id = member._id;
                                const image = member.photo;
                                
                                return (
                                  <tr>
                                    <td>
                                      <Link to={`${URLS.profile}/${id}`}>
                                        <span className="avatar">
                                          <img
                                            style={{ objectFit: "cover" }}
                                            alt=""
                                            src={
                                              image
                                                ? URLS.backendStatic +
                                                  "/" +
                                                  image
                                                : "assets/img/profiles/avatar-09.jpg"
                                            }
                                          />
                                        </span>
                                        {member.name}{" "}
                                      </Link>
                                    </td>
                                    <td>{projects[id]}</td>
                                    <td>{tasks[id]}</td>
                                    <td>{completedTasks[id]}</td>
                                    <td>
                                      <HSBar
                                        height={15}
                                        fontColor="rgb(50,20,100)"
                                        data={[
                                          {
                                            name: "Completed",
                                            value: completedTasks[id],
                                            color: "#9c27b0",
                                          },
                                          {
                                            name: "Under Review",
                                            value: underReviewTasks[id],
                                            color: "#8884d8",
                                          },
                                          {
                                            name: "Active",
                                            value: activeTasks[id],
                                            color: "#00b386",
                                          },
                                          {
                                            name: "Overdue",
                                            value: overDueTasks[id],
                                            color: "#f23602",
                                          },
                                        ]}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div id="team_projects" className="pro-overview tab-pane fade">
                <div className="row">
                  <div className="col-md-12"></div>
                </div>
              </div>
              <div id="team_tasks" className="pro-overview tab-pane fade">
                <div className="row">
                  <div className="col-md-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberView));
