import React from 'react';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';

import Spinner from '../global/Spinner';

import { addNewMembers } from '../../services/taskServices';
import { searchUserByName } from '../../services/userServices';
import { debounce } from '../../utils';
import { URLS } from '../urls';

import '../../assets/scss/global.scss';
import '../../assets/scss/assignTask.scss';

class AssignTaskModal extends React.Component {
	constructor(props) {
		super(props);

		const { account_type } = props.user;
		let team = props.team || [];
		let selectedTask = props.selectedTask || [];
		let assignedTo = props.selectedTask ? props.selectedTask.assignedTo : [];

		if (team && assignedTo) {
			team = team.map((member) => {
				if (assignedTo.find((assigned) => assigned._id === member._id)) {
					member.isChecked = true;
				} else {
					member.isChecked = false;
				}
				return member;
			});
		}

		this.state = {
			assignedTo,
			team: team,
			teamClone: team,
			selectedMember: '',
			projectTeamList: [],
			newTaskMembers: [],
			membersToRemove: [],
			isCreating: false,
			selectedTask: selectedTask,
			account_type,
			newProjectMembers: [],
		};
	}

	handleCloseModal = (e, wrapper) => {
		if (wrapper) {
			if (e.target !== e.currentTarget) return;
		}
		let { teamClone, team } = this.state;

		if (teamClone && teamClone.length) {
			teamClone.map((member) => {
				if (member.isChecked) {
					member.isChecked = false;
				}
				return member;
			});
			team = teamClone;
		}
		this.setState({ teamClone, team });
		this.props.closeModal();
	};

	setEmployeeName = (employee) => {
		let name;
		if (employee.name.first) {
			name = employee.name.first + ' ' + employee.name.last;
		} else {
			name = employee.name;
		}
		return name;
	};

	setEmployeeInitials = (employee) => {
		let name;
		if (employee.name.first) {
			name = employee.name.first + ' ' + employee.name.last;
		} else {
			name = employee.name;
		}
		return name.charAt(0);
	};

	handleSelectedMember = (member) => {
		let { newTaskMembers, membersToRemove, teamClone, team, newProjectMembers } = this.state;
		const memberId = member._id;
		const isChecked = member.isChecked;

		if (!isChecked) {
			if (member.status === 'addAsProjectMember') {
				teamClone.push(member);
				newProjectMembers.push(memberId);
			}
			const isAssignedTo = this.isAssignedTo(member);
			if (isAssignedTo) {
				membersToRemove = membersToRemove.filter((member) => member !== memberId);
			} else {
				newTaskMembers.push(memberId);
			}
		} else {
			if (member.status === 'addAsProjectMember') {
				teamClone = teamClone.filter((member) => member._id !== memberId);
				newProjectMembers = newProjectMembers.filter((member) => member !== memberId);
			}
			const isAssignedTo = this.isAssignedTo(member);
			if (isAssignedTo) {
				membersToRemove.push(memberId);
			} else {
				newTaskMembers = newTaskMembers.filter((member) => member !== memberId);
			}
		}

		teamClone = teamClone.map((member) => {
			if (member._id == memberId) {
				member.isChecked = !isChecked ? true : false;
			}
			return member;
		});

		team = team.map((member) => {
			if (member._id == memberId) {
				member.isChecked = !isChecked ? true : false;
			}
			return member;
		});

		this.setState({ newTaskMembers, membersToRemove, teamClone, team });
	};

	searchForTask = (searchParam) => {
		const token = this.props.token;
		let { teamClone } = this.state;

		if (searchParam) {
			searchUserByName(searchParam, token).then((response) => {
				if (response.users) {
					let usersToAdd = response.users;

					// Reset usersToAdd that are already members of the team displayed on the UI
					usersToAdd = usersToAdd.map((userToAdd) => {
						const member = teamClone.find((currentMember) => currentMember.email === userToAdd.email);
						if (member) {
							if (member.isChecked) {
								member.isChecked = true;
							}
							if (member.status === 'addAsProjectMember') {
								member.status = '';
							}

							userToAdd = member;
						}
						return userToAdd;
					});

					this.setState({ team: usersToAdd });
				}
			});
		}
	};

	handleSearch = (e) => {
		e.preventDefault();
		const searchParam = e.target.value.trim();
		let { teamClone } = this.state;

		if (searchParam) {
			debounce(this.searchForTask(searchParam), 500);
		} else {
			this.setState({ team: teamClone });
		}
	};

	handleSwitch = (e) => {
		const showOnlySelected = e.target.checked;
		let { team, teamClone } = this.state;

		if (showOnlySelected) {
			team = teamClone.filter((member) => member.isChecked === true);
		} else {
			team = teamClone;
		}
		this.setState({ team });
	};

	handleInvite = (e) => {
		let { emailToInvite } = this.state;
		emailToInvite = e.target.value.trim();

		this.setState({ emailToInvite });
	};

	handleUpdateTask = () => {
		let { newTaskMembers, membersToRemove, newProjectMembers, selectedTask, teamClone, team } = this.state;
		const { token, hasAccountExpired } = this.props;
		const taskId = selectedTask._id;
		const projectId = selectedTask.project;

		if (!hasAccountExpired) {
			if (newTaskMembers.length || membersToRemove.length) {
				this.setState({ isCreating: true });
				teamClone.map((member) => {
					if (member.isChecked) {
						member.isChecked = false;
					}
					return member;
				});
				team.map((member) => {
					if (member.isChecked) {
						member.isChecked = false;
					}
					return member;
				});
				this.setState({ teamClone, team });
				const data = { token, newTaskMembers, membersToRemove, newProjectMembers, projectId, taskId };
				addNewMembers(data).then((response) => {
					if (response.task) {
						this.props.taskUpdated(response);
					}
				});
			}
		}
	};

	isAssignedTo = (employee) => {
		const { assignedTo } = this.state;
		const isFound = assignedTo.find((assigned) => assigned._id === employee._id);

		return isFound;
	};

	render() {
		const {
			team,
			assignedTo,
			selectedMember,
			user,
			projectTeamList,
			isCreating,
			newTaskMembers,
			membersToRemove,
			account_type,
		} = this.state;

		return (
			<div
				id="assignee"
				className="modal custom-modal fade"
				role="dialog"
				onClick={(e) => this.handleCloseModal(e, 'wrapper')}
			>
				<div className="modal-dialog modal-dialog-centered modal-assign-task" role="document">
					<div className="modal-content">
						<div className="m-h-between">
							<h5 className="modal-title">Assign to this task</h5>
							<CloseIcon
								onClick={(e) => this.handleCloseModal(e)}
								data-toggle="modal"
								data-target="#assignee"
							/>
						</div>
						{!isCreating ? (
							<div className="assign-task-content">
								<div className="input-group my-4">
									<input
										placeholder="Search to add"
										className="form-control search-input spec-input mx-4"
										type="text"
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>

								<div className="users-list sleek-scrollbar">
									{team && team.length ? (
										team.map((member, index) => (
											<div
												className="user-info"
												key={index}
												onClick={() => this.handleSelectedMember(member)}
											>
												{member.photo ? (
													<img
														className="user-desc"
														src={URLS.backendStatic + '/' + member.photo}
													/>
												) : (
													<div className="user-desc user-init">
														{this.setEmployeeInitials(member)}
													</div>
												)}
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														marginBottom: '5px',
													}}
												>
													<span>{this.setEmployeeName(member)}</span>
													<span style={{ fontSize: '13px', color: '#888' }}>
														{member.email}
													</span>
												</div>
												<input type="checkbox" value={member._id} checked={member.isChecked} />
											</div>
										))
									) : (
										<div className="none-found">None found</div>
									)}
								</div>

								<div className="assign-task-layout">
									<div className="assign-task-dv-switch">
										<label className="switch">
											<input type="checkbox" onChange={(e) => this.handleSwitch(e)} />
											<span className="slider round"></span>
										</label>
										<span className="sh-only">Show only selected</span>
									</div>
									<span
										className={`assign-task-btn ${
											!newTaskMembers.length && !membersToRemove.length ? 'in-active-assign' : ''
										}`}
										onClick={this.handleUpdateTask}
									>
										Assign
									</span>
								</div>
							</div>
						) : (
							<div className="spinner-layout">
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.userReducer.token,
		user: state.userReducer.user,
		hasAccountExpired: state.userReducer.hasAccountExpired,
	};
};

export default connect(mapStateToProps, null)(AssignTaskModal);
