/*const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
*/

import React, { useEffect } from "react";

const getElement = (function () {
  const targetElement = document.createElement("div");

  return function () {
    return targetElement;
  };
})();

export function useCustomEventListener(eventName, eventHandler) {
  useEffect(() => {
    const element = getElement();
    const handleEvent = (event) => {
      const data = event.detail;
      eventHandler(data);
    };

    element.addEventListener(eventName, handleEvent, true);

    return () => {
      element.removeEventListener(eventName, handleEvent, true);
    };
  });
}

export function dispatch(eventName, data) {
  const element = getElement();
  const event = new CustomEvent(eventName, { detail: data });
  element.dispatchEvent(event);
}

const eventBus = {
  on(event, callback) {
    getElement().addEventListener(event, (e) => callback(e.detail));
  },
  remove(event, callback) {
    getElement().removeEventListener(event, callback);
  },
  useCustomEventListener,
  dispatch,
};

export default eventBus;
