export const POPULATE_CLIENTS = "POPULATE_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const SEARCH_CLIENT = "SEARCH_CLIENT";

export function populateClients(clients) {
  return {
    type: POPULATE_CLIENTS,
    payload: clients,
  };
}

export function addClient(client) {
  return {
    type: ADD_CLIENT,
    payload: client,
  };
}

export function removeClient(client) {
  return {
    type: REMOVE_CLIENT,
    payload: client,
  };
}

export function searchClient(value) {
  return {
    type: SEARCH_CLIENT,
    payload: value,
  };
}


