import React, {
  useState,
  useEffect,
  useRef,
  ref,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from "shortid";
import * as FreeDrag from "react-draggable";
import MessageIcon from "mdi-react/MessageIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import MoreIcon from "mdi-react/DotsHorizontalIcon";
import BackIcon from "mdi-react/HomeAccountIcon";
import AttachmentIcon from "mdi-react/PaperclipIcon";
import PlusIcon from "mdi-react/PlusIcon";
import CheckIcon from "mdi-react/CheckIcon";
import AddPersonIcon from "mdi-react/AccountPlusOutlineIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import SendIcon from "mdi-react/SendIcon";
import CloseIcon from "mdi-react/CloseIcon";
import FileIcon from "mdi-react/FileIcon";
import FilePdfIcon from "mdi-react/FilePdfBoxIcon";
import FileWordIcon from "mdi-react/FileWordBoxIcon";
import FileExcelIcon from "mdi-react/FileExcelBoxIcon";
import FolderIcon from "mdi-react/FolderIcon";
import LoadIcon from "mdi-react/ClockTimeThreeIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import AdjustIcon from "mdi-react/AdjustIcon";
import EmptyIcon from "mdi-react/TimerSandEmptyIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import EditIcon from "mdi-react/PencilIcon";
import ClipboardTextOutline from "mdi-react/ClipboardTextOutlineIcon";
import SwapHorizontalBold from "mdi-react/SwapHorizontalBoldIcon";
import CogIcon from "mdi-react/CogIcon";
import MoreHorizIcon from "mdi-react/MoreHorizIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PinOutlineIcon from "mdi-react/PinOutlineIcon";
import PinOffOutlineIcon from "mdi-react/PinOffOutlineIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import moment from "moment";

import "../../assets2/css/bootstrap.min.css";
import "../../assets2/css/font-awesome.min.css";
import "../../assets2/css/line-awesome.min.css";
import "../../assets2/css/select2.min.css";
import "../../assets2/css/bootstrap-datetimepicker.min.css";
import "../../assets2/css/style.css";

import "../../assets/scss/singleProject.scss";

import Topbar from "../topbar/Topbar";
import AssignTaskModal from "./AssignTaskModal";
import AddTaskModal from "./AddTaskModal";
import EditTaskModal from "./EditTaskModal";
import FileGallery from "./FileGallery";
import Spinner from "../global/Spinner";
import Toast from "../global/Toast";
import BoardSettingsContainer from "./board-settings/BoardSettingsContainer";
import { getBoardSettings } from "../../services/boardSettingsService";
import AddLeaderModal from "../project-view/AddLeaderModal";
import AddMemberModal from "../project-view/AddMemberModal";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { ColorPicker } from "./ColorPicker";

import { URLS } from "../urls";
import { socket } from "../../sockets";
import uploadClient from "../../helper/uploadClient";
import { updateFileName, getCompanyUsers } from "../../services/userServices";
import { getTasksInProject } from "../../services/projectServices";
import {
  getProjectsAndTasksIBelongTo,
  appendUploadedFileId,
  appendUploadedFileIdForCommentReply,
  deleteTask,
} from "../../services/taskServices";

import { combineData, removeModalElements, randomNumber } from "../../utils";
import arrayMove from "array-move";
import { cloneDeep, cloneDeepWith } from "lodash-es";

import DotsVertical from "mdi-react/DotsVerticalIcon";
import { toggleToastMessage } from "../../store/actions";
import { getClients } from "../../services/clientServices";
import { getTeamsICreated } from "../../services/teamServices";
import AddProjectModal from "../projects/AddProjectModal";

import BellIcon from "mdi-react/BellIcon";

// import "animate.css";
import * as tweenFunctions from "tween-functions";
// import TWEEN from "@tweenjs/tween.js";
import SingleComment from "./SingleComment";
import { conditionallyUpdateScrollbar } from "reactstrap/lib/utils";
import { microsoftOfficeFileExtensions } from "../task-update/utils";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function SingleProjectTasks({
  user,
  token,
  hasAccountExpired,
  toggleToastMessage,
}) {
  const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
  const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
  const collaborationFrontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const initialColorPickerState = {
    showColorPicker: false,
    colorPickerStyle: {
      top: 0,
      left: 0,
    },
    checkedColor: "",
    currentTaskToChangeColor: "",
    currentProjectToChangeColor: "",
    currentTaskToChangeStatus: "",
  };
  const [data, setData] = useState({
    bgImage: "",
    project: {},
    tasks: [],
    statusToSet: "AWAITING",
    stages: [
      { title: "Pending", status: "AWAITING" },
      { title: "In Progress", status: "INCOMPLETE" },
      { title: "Under Review", status: "UNDER_REVIEW" },
      { title: "Completed", status: "COMPLETE" },
    ],
    isAssignTaskModalOpen: false,
    isAddTaskModalOpen: false,
    isEditTaskModalOpen: false,
    isFileViewerOpen: false,
    isAddLeaderModalOpen: false,
    isAddMemberModalOpen: false,
    isAddProjectModalOpen: false,
    allTasks: [],
    allProjects: [],
    pinnedProject: "",
    isTaskChat: false,
    taskChatStyle: { top: 0, left: 0 },
    selectedTask: {},
    fileToOpen: "",
    employeesList: [],
    newComment: {
      employeeId: null,
      employeeImage: "",
      employeeName: "",
      date: "",
      time: "",
      comment: "",
      files: [],
    },
    clientList: [],
    teamsICreated: [],
    taskFilesDataList: [],
    watcherServerUrl,
    cloudDriveFrontEnd,
    collaborationFrontEndUrl,
  });
  const [tasksByStage, setTasksByStage] = useState({});
  const [colorPickerState, setColorPickerState] = useState(
    initialColorPickerState
  );

  const [preparedTaskToChangeStatus, setPreparedTaskToChangeStatus] = useState({
    task: {},
    status: "",
    type: "",
  });
  const [commentToReply, setCommentToReply] = useState({});
  const [comments, setComments] = useState([]);

  // const [denyDrop, setDenyDrop] = useState(false);

  const stageRefs = useRef({});
  const taskRefs = useRef({});
  const scrollTop = useRef({});

  let messagesEndRef = useRef(null),
    inputEndRef = useRef(null),
    taskTypeSelectRef = useRef(null),
    taskBySelectRef = useRef(null),
    selectAttachmentRef = useRef(null),
    searchFieldRef = useRef(null),
    wrapperRef = useRef(null),
    testRef = useRef(null),
    chatBody = useRef(null),
    colorPickerRef = useRef(null);

  useEffect(() => {
    // handleGetTasksInProject();
    handleSocketEvents();
    setUp();
    handleletGetProjectsAndTasksIBelongTo();
    handleGetEmployees();
  }, []);

  useEffect(() => {
    setupTaskByStage({ tasks: data.tasks });

    // TODO - update the thread
    socket.on("fx:task-comment-unread-count", (resp) => setUnreadCount(resp));
    return () => {
      socket.off("fx:task-comment-unread-count");
    };
  }, [data]);

  useLayoutEffect(() => {
    for (let stage of data.stages) {
      const el = document.querySelector(
        `[data-rbd-droppable-id='${stage.status}']`
      );
      el.scrollTop = scrollTop.current[stage.status];
    }
    return () => {};
  }, [tasksByStage, colorPickerState, data]);

  /*useEffect(() => {
  }, []);
  */

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        // console.log(event.target);
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !ref.current.classList.contains("d-none")
        ) {
          setColorPickerState(initialColorPickerState);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(colorPickerRef);

  const handleSetData = (obj) => {
    setData(combineData(data, obj));
  };

  const handleGetTasksInProject = async () => {
    try {
      const projectId = window.location.pathname.split("/")[2];
      if (projectId) {
        await getTasksInProject({ token, projectId }).then((response) => {
          if (response && response.success) {
            const { project, tasks } = response.payload;
            setData((data) => combineData(data, { project, tasks }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleletGetProjectsAndTasksIBelongTo = async () => {
    try {
      await getProjectsAndTasksIBelongTo(token, "status-board").then(
        (response) => {
          if (response && response.success) {
            let allTasks = response?.tasks;
            let allProjects = response?.projects;
            let pinnedProject = response?.pinnedProject;
            let project = allProjects?.find(
              (project, index) => project?._id === pinnedProject
            );
            let noOfProjects = allProjects.length;
            if (!project && noOfProjects) {
              const rndIndex = randomNumber(0, noOfProjects);
              project = allProjects[rndIndex];
            }
            const tasks = project
              ? allTasks?.filter((task) => task?.project === project?._id)
              : [];
            setData((data) =>
              combineData(data, {
                project,
                tasks,
                allTasks,
                allProjects,
                pinnedProject,
              })
            );
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const setupTaskByStage = ({ tasks }) => {
    let tasksByStage = {};
    data.stages.forEach((stage) => {
      tasksByStage[stage.status] = tasks.filter(
        (task) => task.status === stage.status
      );
      tasksByStage[stage.status].sort(function (a, b) {
        return a.task_position - b.task_position;
      });
    });
    // console.log("tigger");
    setTasksByStage(tasksByStage);
  };

  const handleGetEmployees = async () => {
    try {
      await getCompanyUsers(token).then((response) => {
        if (response && response.success) {
          const employeesList = response.users;
          console.log(employeesList);
          setData((data) => combineData(data, { employeesList }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setUp = async () => {
    let clientsResponse = getClients(token);
    let teamsICreatedResponse = getTeamsICreated(token);

    await Promise.all([clientsResponse, teamsICreatedResponse])
      .then(([res1, res2]) => {
        let clientsResponse = res1;
        let teamsICreatedResponse = res2;
        setData(
          combineData(data, {
            clientList: clientsResponse.clients || [],
            teamsICreated: teamsICreatedResponse.teams || [],
          })
        );
      })
      .catch((err) => console.error(err));
  };

  const getTaskStatus = (status) => {
    let value;
    if (status === "COMPLETE") {
      value = "kanban-success";
    } else if (status === "UNDER_REVIEW") {
      value = "kanban-info";
    } else if (status === "INCOMPLETE") {
      value = "kanban-primary";
    } else if (status === "AWAITING") {
      value = "kanban-danger";
    }
    return value;
  };

  const calculateProjectProgress = () => {
    const { tasks } = data;
    let projectProgress = tasks?.length
      ? tasks?.reduce((acc, cur) => {
          if (cur.status === "AWAITING") {
            acc += 0;
          } else if (cur.status === "INCOMPLETE") {
            acc += 25;
          } else if (cur.status === "UNDER_REVIEW") {
            acc += 50;
          } else if (cur.status === "COMPLETE") {
            acc += 100;
          }
          return acc;
        }, 0)
      : 0;

    const numberOfTasks = tasks?.length || 0;
    projectProgress = (projectProgress / (numberOfTasks * 100)) * 100;
    projectProgress = projectProgress || 0;
    projectProgress =
      typeof projectProgress === "number" && projectProgress !== 0
        ? Math.floor(projectProgress)
        : projectProgress;

    return projectProgress;
  };

  const getStageTasks = (stage) => {
    let { tasks } = data;
    tasks = tasks?.length
      ? tasks?.filter((task) => task?.status == stage?.status)
      : [];
    return tasks;
  };

  const handleBeforeCapture = (e) => {
    // console.log(e);
  };

  const handleDragEnd = async (e) => {
    if (!e.destination) {
      return;
    }
    const { destination, source, draggableId } = e || {};
    const statusFrom = source?.droppableId;
    const statusTo = destination?.droppableId;
    const indexFrom = source?.index;
    const indexTo = destination?.index;

    // let { tasks } = data || [];

    // drag in same parent

    let { allTasks, tasks } = data;
    if (destination?.droppableId === source?.droppableId) {
      let mutated = arrayMove(
        tasksByStage[destination.droppableId],
        indexFrom,
        indexTo
      );

      const task = tasksByStage[destination.droppableId][indexFrom];
      const project_id = task.project;

      allTasks = cloneDeep(allTasks).filter(
        (task) =>
          !(
            task.project === project_id &&
            task.status === destination.droppableId
          )
      );
      tasks = cloneDeep(tasks).filter(
        (task) =>
          !(
            task.project === project_id &&
            task.status === destination.droppableId
          )
      );

      const taskStatusArrangement = [];
      mutated = mutated.map((task, i) => {
        taskStatusArrangement[i] = {
          project_id: task.project,
          task_id: task._id,
          task_position: i,
          status: destination.droppableId,
        };
        return {
          ...task,
          task_position: i,
        };
      });

      setTasksByStage({
        ...tasksByStage,
        [destination.droppableId]: mutated,
      });
      setData(
        combineData(data, {
          tasks: [...tasks, ...mutated],
          allTasks: [...allTasks, ...mutated],
        })
      );

      // persist to backend
      socket.emit("fx:save-task-status-arrangement", {
        taskStatusArrangement,
        user_id: user._id,
        project_id: task.project,
        sourceStatus: destination.droppableId,
        destinationStatus: source.droppableId,
      });

      /*setTimeout(() => {
        const el = document.querySelector(
          `[data-rbd-droppable-id='${destination.droppableId}']`
        );
        el.scrollTop = destinationScroll;
      }, 0);
      */
      return;
    }

    if (draggableId && statusFrom !== null && statusTo !== null) {
      // console.log(draggableId, statusFrom, statusTo, indexFrom, indexTo);
      /*let { tasks } = data || [];
      tasks = tasks?.map((task) => {
        if (task?._id === draggableId) {
          task.status = statusTo;
        }
        return task;
      });
			*/

      const tasksByStageClone = cloneDeep(tasksByStage);
      const task = tasksByStageClone[source.droppableId][indexFrom];

      if (
        !(
          hasProjectRights() ||
          Boolean(isAssignedTo(task)) ||
          task.createdBy === user._id
        )
      ) {
        toggleToastMessage(
          "Can't change status of task you are not assigned to"
        );
        return;
      }

      if (
        (destination.droppableId === "COMPLETE" ||
          source.droppableId === "COMPLETE") &&
        !(task.createdBy === user._id || hasProjectRights())
      ) {
        toggleToastMessage(
          "Only Project Leader and the Task Creator can mark task as complete"
        );
        return;
      }

      task.status = destination.droppableId;
      let mutatedSource = tasksByStageClone[source.droppableId].filter(
        (e, i) => i !== indexFrom
      );

      const destinationTasks = cloneDeep(
        tasksByStageClone[destination.droppableId]
      );
      destinationTasks.splice(indexTo, 0, task);

      const taskStatusArrangement = [];
      const mutatedDestination = destinationTasks.map((task, i) => {
        taskStatusArrangement[i] = {
          project_id: task.project,
          task_id: task._id,
          task_position: i,
          status: destination.droppableId,
        };
        return {
          ...task,
          task_position: i,
        };
      });

      // source arrangement
      const sourceTaskStatusArrangement = [];
      mutatedSource = mutatedSource.map((task, i) => {
        sourceTaskStatusArrangement[i] = {
          project_id: task.project,
          task_id: task._id,
          task_position: i,
          status: source.droppableId,
        };
        return {
          ...task,
          task_position: i,
        };
      });

      const tasks = data.tasks;
      const foundIndex = tasks.findIndex((task) => task._id === draggableId);
      tasks[foundIndex].status = destination.droppableId;

      // add postions
      tasks.forEach((task) => {
        const foundIndex = mutatedDestination.findIndex(
          (mutatedTask) => mutatedTask._id === task._id
        );
        task.task_position =
          foundIndex !== -1 ? mutatedDestination[foundIndex].task_position : "";
      });

      setTasksByStage({
        ...tasksByStage,
        [destination.droppableId]: mutatedDestination,
        [source.droppableId]: mutatedSource,
      });

      const project_id = task.project;
      const allTasks = data.allTasks.filter(
        (task) => task.project !== project_id
      );

      setData(
        combineData(data, {
          tasks,
          allTasks: [...allTasks, ...tasks],
        })
      );
      // return;

      const payload = {
        taskId: draggableId,
        status: statusTo,
        token,
      };
      socket.emit("fx:task-status-updated", payload, (response) => {
        if (response && response.success) {
          // setData(combineData(data, { tasks }));
        }
      });
      socket.emit("fx:save-task-status-arrangement", {
        taskStatusArrangement: [
          ...sourceTaskStatusArrangement,
          ...taskStatusArrangement,
        ],
        user_id: user._id,
        project_id: task.project,
        status: destination.droppableId,
        sourceStatus: source.droppableId,
        destinationStatus: destination.droppableId,
      });
    }
  };

  const getAllProjectMembers = () => {
    const { project } = data || {};
    const projectLeader = project?.projectLeader || [];
    const team = project?.team || [];
    const arr = [...projectLeader, ...team];
    return arr;
  };

  const handleModal = (modal, statusToSet = "") => {
    removeModalElements();
    if (modal === "assignTask") {
      let isAssignTaskModalOpen = !data?.isAssignTaskModalOpen;
      setData(combineData(data, { isAssignTaskModalOpen }));
    } else if (modal === "addTask") {
      const isAddTaskModalOpen = !data.isAddTaskModalOpen;
      setData(combineData(data, { isAddTaskModalOpen, statusToSet }));
    } else if (modal === "editTask") {
      const isEditTaskModalOpen = !data.isEditTaskModalOpen;
      setData((data) => combineData(data, { isEditTaskModalOpen }));
    } else if (modal === "fileGallery") {
      const isFileGalleryOpen = !data?.isFileGalleryOpen;
      setData(combineData(data, { isFileGalleryOpen }));
    } else if (modal === "addLeader") {
      const isAddLeaderModalOpen = !data.isAddLeaderModalOpen;
      setData(combineData(data, { isAddLeaderModalOpen }));
    } else if (modal === "addMember") {
      const isAddMemberModalOpen = !data.isAddMemberModalOpen;
      setData(combineData(data, { isAddMemberModalOpen }));
    } else if (modal === "addProject") {
      const isAddProjectModalOpen = !data.isAddProjectModalOpen;
      setData(
        combineData(data, {
          isAddProjectModalOpen,
          // employeesList: data.employeesList,
        })
      );
    }
  };

  const handleSelectProject = (projectId) => {
    const { allTasks, allProjects } = data || [];
    const project = cloneDeep(allProjects).find(
      (project) => project?._id === projectId
    );
    const tasks = cloneDeep(allTasks).filter(
      (task) => task.project === projectId
    );
    setData((data) => combineData(data, { project, tasks }));
  };

  const handleTaskUpdated = (obj, str = "") => {
    const { task, updatedProject } = obj;
    let {
      allTasks,
      allProjects,
      tasks,
      isEditTaskModalOpen,
      isAssignTaskModalOpen,
    } = data;
    const projectId = updatedProject?._id;
    allProjects = allProjects.map((project) => {
      if (project?._id === projectId) {
        project = updatedProject;
      }
      return project;
    });
    allTasks = allTasks.map((param) => {
      if (param._id === task._id) {
        param = task;
      }
      return param;
    });
    tasks = tasks.map((param) => {
      if (param._id === task._id) {
        param = task;
      }
      return param;
    });
    if (str === "editTask") {
      isEditTaskModalOpen = false;
    } else if (str === "assignTask") {
      isAssignTaskModalOpen = false;
    }
    if (str) removeModalElements();

    setData((data) =>
      combineData(data, {
        allTasks,
        allProjects,
        tasks,
        isEditTaskModalOpen,
        isAssignTaskModalOpen,
      })
    );
  };

  const handleSocketEvents = () => {};

  const setUnreadCount = (updatedTask) => {
    try {
      let { allTasks, tasks, isTaskChat, selectedTask } = data;
      if (isTaskChat) {
        updatedTask.unreadCount = 0;
        // Chat is Open Mark as read
        socket.emit(
          "fx:clear-unread-task-comment",
          {
            taskId: selectedTask,
            userId: user._id,
          },
          (response) => {}
        );
      }
      const foundIndex = cloneDeep(allTasks).findIndex(
        (task) => task._id === updatedTask._id
      );
      const foundTaskIndex = cloneDeep(tasks).findIndex(
        (task) => task._id === updatedTask._id
      );

      if (foundIndex !== -1) {
        allTasks[foundIndex] = {
          ...updatedTask,
          userColor: allTasks[foundIndex].userColor,
          userTextColor: allTasks[foundIndex].userTextColor,
        };
        if (foundTaskIndex !== -1) {
          tasks[foundTaskIndex] = {
            ...updatedTask,
            userColor: tasks[foundTaskIndex].userColor,
            userTextColor: tasks[foundTaskIndex].userTextColor,
          };
        }

        setTimeout(() => {
          setData((data) =>
            combineData(data, {
              allTasks,
              tasks,
              selectedTask: isTaskChat ? updatedTask : selectedTask,
            })
          );
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTaskUpdate = (task) => {
    console.log(task);
  };

  const scrollToBottom = () => {
    /*if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
      });
    }
    */
    if (chatBody && chatBody.current) {
      chatBody.current.scrollTop = chatBody.current.scrollHeight;
    }
  };

  const handleChat = (e, task) => {
    let { isTaskChat, selectedTask, taskChatStyle, allTasks } = data;
    isTaskChat = !isTaskChat;
    if (task) {
      selectedTask = task;
      let x = e.clientX,
        y = e.clientY,
        sx = e.screenX,
        sy = e.screenY,
        w = window.innerWidth;
      taskChatStyle.top = 60;
      taskChatStyle.left = x < 300 ? 300 : w - x < 600 ? x - 600 : x;
    }
    setData(combineData(data, { isTaskChat, selectedTask, taskChatStyle }));
    setComments(selectedTask.comments);

    if (isTaskChat) {
      socket.emit(
        "fx:clear-unread-task-comment",
        {
          taskId: selectedTask,
          userId: user._id,
        },
        (response) => {
          // setTimeout(() => {
          const updatedTask = selectedTask;
          updatedTask.unreadCount = 0;
          let { allTasks, tasks } = data;
          const foundIndex = cloneDeep(allTasks).findIndex(
            (task) => task._id === updatedTask._id
          );
          const foundTaskIndex = cloneDeep(tasks).findIndex(
            (task) => task._id === updatedTask._id
          );

          if (foundIndex !== -1) {
            allTasks[foundIndex] = updatedTask;
            if (foundTaskIndex !== -1) {
              tasks[foundTaskIndex] = updatedTask;
            }

            setTimeout(() => {
              setData((data) =>
                combineData(data, {
                  allTasks,
                  tasks,
                  selectedTask: isTaskChat ? updatedTask : selectedTask,
                })
              );
            }, 1000);
          }
        }
      );
    }
  };

  const isAssignedTo = (selectedTask) => {
    const userId = user._id;
    let { assignedTo } = selectedTask;
    const isFound = assignedTo.find((assigned) => assigned._id === userId);
    return isFound;
  };

  const handleUpdateTaskStatus = () => {
    let { selectedTask } = data;
    if (!hasAccountExpired) {
      socket.emit(
        "fx:task-update-status",
        { token, selectedTask },
        (response) => {
          if (response && response.success) {
            const updatedTask = response.task;
            if (updatedTask) {
              const updatedTaskId = updatedTask?._id;
              let { tasks, allTasks } = data;
              allTasks = allTasks.map((task) => {
                if (task._id === updatedTaskId) {
                  task = updatedTask;
                }
                return task;
              });

              tasks = tasks.map((task) => {
                if (task._id === updatedTaskId) {
                  task = updatedTask;
                }
                return task;
              });

              selectedTask =
                selectedTask._id === updatedTask._id
                  ? updatedTask
                  : selectedTask;

              setData((data) =>
                combineData(data, {
                  tasks,
                  allTasks,
                  selectedTask,
                })
              );
            }
          }
        }
      );
    }
  };

  const setEmployeeName = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else if (employee.name) {
      name = employee.name;
    }
    return name;
  };

  const setEmployeeInitials = (employee) => {
    let name;
    if (employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  const showDateInFormat = (date) => {
    if (!date) return;
    date = date.split("/");
    return allMonths[date[1] - 1] + " " + date[0] + ", " + date[2];
  };

  const handleOpenFileViewer = (file) => {
    const { watcherServerUrl, cloudDriveFrontEnd } = data;
    const cloud_id = file.cloud_id;
    if (cloud_id) {
      if (microsoftOfficeFileExtensions.includes(file.extension)) {
        const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
        window.open(fileUrl, "_blank");
      } else {
        const fileToOpen = `${watcherServerUrl}/files/${cloud_id}/${file.originalName}.${file.extension}?view`;
        setData(combineData(data, { fileToOpen, isFileViewerOpen: true }));
      }
    }
  };

  const canSeeChat = (selectedTask) => {
    const { project, teamsIBelongTo } = data;
    let isVisible;
    const { _id } = user;
    let { createdBy, assignedTo } = selectedTask;
    const projectLeader =
      project && project?.projectLeader ? project?.projectLeader : [];

    const isAssignedTo = assignedTo?.find((assigned) => assigned._id === _id);
    const isProjectLeader = projectLeader?.find((leader) => leader._id === _id);
    const teamForSelectedProject = teamsIBelongTo?.find(
      (team) => team._id === project?.teamId
    );
    let isTeamLeader;
    if (teamForSelectedProject) {
      const teamLeaders = teamForSelectedProject?.leaders
        ? teamForSelectedProject?.leaders
        : [];
      isTeamLeader = teamLeaders?.find((leader) => leader._id === _id);
    }

    if (createdBy === _id || isAssignedTo || isProjectLeader || isTeamLeader) {
      isVisible = true;
    }

    return isVisible;
  };

  const removeProjectFile = (idx) => {
    const { newComment, taskFilesDataList } = data;
    newComment.files.splice(idx, 1);
    taskFilesDataList.splice(idx, 1);
    setData(
      combineData(data, {
        newComment,
        taskFilesDataList,
      })
    );
  };

  const calculateFileSize = (size) => {
    let newSize;
    if (size < 1024) {
      newSize = size + " B";
    } else if (size >= 1024 && size < 1024 * 1024) {
      newSize = (size / 1024).toFixed(2) + " KB";
    } else {
      newSize = (size / 1024 / 1024).toFixed(2) + " MB";
    }
    return newSize;
  };

  const isCreatedBy = (param) => {
    const userId = user._id;
    let { createdBy } = param;
    let isTrue;
    if (createdBy === userId) {
      isTrue = true;
    }
    return isTrue;
  };

  const handleChangeComment = (e) => {
    e.preventDefault();
    const { newComment } = data;
    newComment.comment = e.target.value;
    setData(combineData(data, { newComment }));
  };

  const setComment = ({ text }) => {
    const { newComment } = data;
    newComment.comment = text;
    console.log(newComment.comment);
    setData((data) => combineData(data, { newComment }));
    inputEndRef.current.value = text;
    inputEndRef.current.focus();
  };

  const handleSendMessage = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      submitComment(e);
    }
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();
  };

  const handleDropFile = (ev) => {
    ev.preventDefault();
    let files = ev.dataTransfer.files;
    pickFiles(files);
  };

  const pickFiles = (files) => {
    let { newComment, taskFilesDataList } = data;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const taskFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      taskFile.originalName = fileName;
      taskFile.uniqueName = currentDate;
      taskFile.extension = ext;
      taskFile.size = size;
      taskFile.file = file;
      newComment.files = newComment.files.concat(taskFile);
      taskFilesDataList = taskFilesDataList.concat(file);
      setData(
        combineData(data, {
          newComment,
          taskFilesDataList,
        })
      );
      i++;
    }
  };

  const handleSelectFiles = () => {
    selectAttachmentRef.current.click();
  };

  const submitComment = async (event) => {
    try {
      event.preventDefault();
      if (Object.keys(commentToReply).length) {
        submitCommentReply();
        return;
      }
      let { newComment } = data;
      if (!hasAccountExpired) {
        // if (true) {
        if (newComment && (newComment.comment || newComment.files.length)) {
          let { selectedTask, tasks, allTasks, project, taskFilesDataList } =
            data;
          const taskId = selectedTask._id;

          newComment.employeeId = user._id;
          newComment.employeeName = user.name.first + " " + user.name.last;
          newComment.employeeImage = user.photo;
          const date = new Date();
          newComment.date =
            date.getDate() +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear();
          newComment.time =
            date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);

          socket.emit(
            "fx:task-add-comment",
            { token, newComment, taskId, usage: "task-board" },
            async (response) => {
              if (response && response.success) {
                const updatedTask = response.task;
                const filesToUpload = newComment.files;
                const comment = response.comment;
                setComments([...comments, comment]);

                if (updatedTask) {
                  const updatedTaskId = updatedTask._id;
                  const updatedTaskProjectId = updatedTask.project;

                  allTasks = allTasks.map((task) => {
                    if (task._id === updatedTaskId) {
                      task = updatedTask;
                    }
                    return task;
                  });

                  tasks = tasks.map((task) => {
                    if (task._id === updatedTaskId) {
                      task = updatedTask;
                    }
                    return task;
                  });

                  setData((data) =>
                    combineData(data, {
                      tasks,
                      allTasks,
                      selectedTask: updatedTask,
                      newComment: {
                        employeeId: null,
                        employeeImage: "",
                        employeeName: "",
                        date: "",
                        time: "",
                        comment: "",
                        files: [],
                      },
                    })
                  );

                  //If user adds comment with files
                  if (filesToUpload.length && taskFilesDataList.length) {
                    const folder_id =
                      project && project?.task_folder_id
                        ? project?.task_folder_id
                        : "";

                    const commentId = updatedTask.comments
                      ? updatedTask.comments[updatedTask.comments.length - 1][
                          "_id"
                        ]
                      : "";

                    commentId = comment._id;

                    let id;

                    uploadClient.addEventListener("progress", (pro) => {
                      let percentage = (pro.current / pro.size) * 100;
                      // console.log(pro);
                      // prop.innerText = percentage + "%";
                      // progress.value = percentage;
                    });
                    try {
                      for (let [index, file] of taskFilesDataList.entries()) {
                        id = Date.now();

                        const res = await uploadClient.upload(file, {
                          folder_id,
                          id,
                        });

                        if (res) {
                          const { cloud_id } = res;
                          const body = {
                            name: file.name,
                            size: file.size,
                            current_directory_id: folder_id,
                            id: cloud_id,
                            type: file.type,
                            token,
                          };
                          //Rename file using it's id
                          updateFileName(body).then((response) => {
                            console.log("File renamed:", response);
                          });

                          const uniqueName =
                            index != null &&
                            newComment &&
                            filesToUpload &&
                            filesToUpload[index]
                              ? filesToUpload[index].uniqueName
                              : "";

                          const data = {
                            cloud_id,
                            uniqueName,
                            taskId,
                            commentId,
                          };

                          if (cloud_id && uniqueName && taskId && commentId) {
                            appendUploadedFileId(token, data).then(
                              (response) => {
                                if (response && response.task) {
                                  const updatedTask = response.task;
                                  const updatedTaskId = updatedTask?._id;
                                  let { tasks, allTasks } = data;
                                  allTasks = allTasks.map((task) => {
                                    if (task._id === updatedTaskId) {
                                      task = updatedTask;
                                    }
                                    return task;
                                  });

                                  tasks = tasks.map((task) => {
                                    if (task._id === updatedTaskId) {
                                      task = updatedTask;
                                    }
                                    return task;
                                  });

                                  setData((data) =>
                                    combineData(data, {
                                      tasks,
                                      allTasks,
                                    })
                                  );

                                  // handleRearrangeTasks(updatedTask);
                                }
                              }
                            );
                          }
                        }
                      }
                    } catch (err) {
                      const uploadError = uploadClient.getError();
                      if (uploadError && uploadError.limited_space) {
                        //Make Toast to alert user that space is limited
                      }
                      console.log(uploadError);
                      console.log("Upload client error:", err);
                    }
                    // }
                  }
                }
                inputEndRef.current.value = "";

                scrollToBottom();
              }
            }
          );
        }
      }
    } catch (err) {
      console.log("Task comment error:", err);
    }
  };

  const submitCommentReply = async (event) => {
    try {
      let { newComment } = data;
      if (!hasAccountExpired) {
        // if (true) {
        if (newComment && (newComment.comment || newComment.files.length)) {
          let { selectedTask, tasks, allTasks, project, taskFilesDataList } =
            data;
          const taskId = selectedTask._id;

          newComment.employeeId = user._id;
          newComment.employeeName = user.name.first + " " + user.name.last;
          newComment.employeeImage = user.photo;
          newComment.parentCommentId = commentToReply._id;
          const date = new Date();
          newComment.date =
            date.getDate() +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear();
          newComment.time =
            date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);

          socket.emit(
            "fx:task-add-reply-comment",
            { token, newComment, taskId, usage: "task-board" },
            async (response) => {
              if (response && response.success) {
                const comment = response.taskCommentReply;
                const filesToUpload = newComment.files;
                // console.log("new reply", comment);
                addNewCommentReply({
                  commentId: comment.parentCommentId,
                  taskCommentReply: comment,
                });
                setCommentToReply({});
                inputEndRef.current.value = "";

                //If user adds comment with files
                if (filesToUpload.length && taskFilesDataList.length) {
                  const folder_id =
                    project && project?.task_folder_id
                      ? project?.task_folder_id
                      : "";
                  const commentId = comment._id;

                  let id;

                  uploadClient.addEventListener("progress", (pro) => {
                    let percentage = (pro.current / pro.size) * 100;
                  });
                  try {
                    for (let [index, file] of taskFilesDataList.entries()) {
                      id = Date.now();

                      const res = await uploadClient.upload(file, {
                        folder_id,
                        id,
                      });

                      if (res) {
                        const { cloud_id } = res;
                        const body = {
                          name: file.name,
                          size: file.size,
                          current_directory_id: folder_id,
                          id: cloud_id,
                          type: file.type,
                          token,
                        };

                        //Rename file using it's id
                        updateFileName(body).then((response) => {
                          console.log("File renamed:", response);
                        });

                        const uniqueName =
                          index != null &&
                          newComment &&
                          filesToUpload &&
                          filesToUpload[index]
                            ? filesToUpload[index].uniqueName
                            : "";

                        const data = {
                          cloud_id,
                          uniqueName,
                          taskId,
                          commentId,
                        };

                        if (cloud_id && uniqueName && taskId && commentId) {
                          appendUploadedFileIdForCommentReply(token, data).then(
                            (response) => {
                              if (response && response.task) {
                                const commentReply = res.taskCommentReply;
                                addNewCommentReply({
                                  commentId: commentReply.parentCommentId,
                                  taskCommentReply: commentReply,
                                  update: true,
                                });
                              }
                            }
                          );
                        }
                      }
                    }
                  } catch (err) {
                    const uploadError = uploadClient.getError();
                    if (uploadError && uploadError.limited_space) {
                      //Make Toast to alert user that space is limited
                    }
                    console.log(uploadError);
                    console.log("Upload client error:", err);
                  }
                }

                inputEndRef.current.value = "";
                scrollToBottom();
              }
            }
          );
        }
      }
    } catch (err) {
      console.log("Task comment error:", err);
    }
  };

  const handleTaskCreated = ({ task, updatedProject }) => {
    const updatedTask = task;
    const updatedProjectId = updatedProject?._id;
    let { tasks, allTasks, allProjects } = data;
    if (updatedTask) {
      tasks.push(task);
      allTasks.push(task);
    }
    if (updatedProjectId) {
      allProjects.push(allProjects);
    }
    setData(combineData(data, { tasks, allTasks, allProjects }));
    handleModal("addTask");
  };

  const handleSelectTask = (selectedTask) => {
    setData(combineData(data, { selectedTask }));
  };

  const handleDeleteTask = async () => {
    try {
      const { selectedTask } = data;
      const taskId = selectedTask?._id;
      await deleteTask(token, taskId).then((response) => {
        if (response && response?.success) {
          let { tasks, allTasks } = data;
          tasks = tasks.filter((task) => task._id !== taskId);
          allTasks = allTasks.filter((task) => task._id !== taskId);
          setData((data) => combineData(data, { tasks, allTasks }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditTask = (task, taskField, type) => {
    const selectedTask = task;
    let isEditingTaskField;
    if (type === "close") {
      isEditingTaskField = null;
    } else {
      isEditingTaskField = taskField;
    }
    setData((data) => combineData(data, { selectedTask, isEditingTaskField }));
  };

  const handleSaveEditedTask = (e, type) => {
    const { selectedTask } = data;
    const taskId = selectedTask?._id;
    const savedText = e.target.innerText;
    if (e.key === "Enter" && !e.shiftKey) {
      const payload = {
        value: savedText,
        taskId,
        type,
        token,
      };
      socket.emit("fx:task-update-task-info", payload, (response) => {
        if (response && response.success) {
          const updatedTask = response.task;
          updatedTask.unreadCount = selectedTask.unreadCount;
          updatedTask.userColor = selectedTask.userColor;
          updatedTask.userTextColor = selectedTask.userTextColor;

          if (updatedTask) {
            let { allTasks, tasks } = data;
            allTasks = allTasks?.map((param) => {
              if (param._id === updatedTask._id) {
                param = updatedTask;
              }
              return param;
            });
            tasks = tasks?.map((param) => {
              if (param._id === updatedTask._id) {
                param = updatedTask;
              }
              return param;
            });
            setData((data) =>
              combineData(data, {
                allTasks,
                tasks,
                isEditingTaskField: null,
              })
            );
          }
        }
      });
    }
  };

  const handleCloseFileModal = () => {
    setData((data) =>
      combineData(data, {
        fileToOpen: "",
        isFileViewerOpen: false,
      })
    );
  };

  const updateCommentFile = (e) => {
    e.preventDefault();
    let fileInput = e.target;
    let files = fileInput.files;

    pickFiles(files);

    inputEndRef.current.focus();
  };

  const updateProjectsList = (updatedProject) => {
    let { allProjects, project } = data;
    if (updatedProject?._id === project?._id) {
      project = updatedProject;
    }
    allProjects = allProjects.map((project) => {
      if (updatedProject?._id === project?._id) {
        project = updatedProject;
      }
      return project;
    });
    setData((data) => combineData(data, { allProjects, project }));
  };

  const handlePinTask = (status) => {
    try {
      const { project } = data;
      let projectId = project?._id;
      const payload = { projectId, token, status };
      socket.emit("fx:pin-project", payload, (response) => {
        if (response && response.success) {
          let pinnedProject = status === "pin" ? projectId : "";
          setData((data) => combineData(data, { pinnedProject }));
        }
      });
    } catch (err) {
      console.log("Handle pinned task error:", err);
    }
  };

  const hasProjectRights = () => {
    const { project } = data;
    let isLeader, createdBy;
    const userId = user._id;
    if (project?.createdBy && isObject(project?.createdBy)) {
      createdBy = project?.createdBy._id;
    } else {
      createdBy = project?.createdBy;
    }
    const leaders = project ? project?.projectLeader : [];

    const isFound =
      leaders && leaders?.find((leader) => leader._id == userId) ? true : false;

    if (createdBy == userId || isFound) {
      isLeader = true;
    }

    return isLeader;
  };

  const isObject = (data) => {
    let value;
    if (data.constructor.name === "Object") {
      value = true;
    }
    return value;
  };

  const getUniqueListBy = (arr) => {
    const data = Array.from(new Set(arr?.map(JSON.stringify))).map(JSON.parse);
    return data;
  };

  const showColorPicker = ({
    e,
    task_id,
    project_id,
    status,
    checkedColor,
  }) => {
    let x = e.clientX,
      y = e.clientY,
      sx = e.screenX,
      sy = e.screenY,
      w = window.innerWidth,
      h = window.innerHeight,
      // task2ChatStyle.bottom = sy - y + 45;
      top = y,
      // If screen left is < 300 Else if screen left
      // left = x < 150 ? 150 : w - x < 150 ? x - 175 : x;
      left = x;

    if (h - y < 180) {
      top = y - 180;
    }
    if (w - x < 250) {
      left = x - 250;
    }

    setColorPickerState({
      ...colorPickerState,
      showColorPicker: true,
      colorPickerStyle: {
        top,
        left,
      },
      checkedColor,
      currentTaskToChangeColor: task_id,
      currentProjectToChangeColor: project_id,
      currentTaskToChangeStatus: status,
    });
  };

  const determineTextColour = (color) => {
    // alert(color);
    const isGrey = ["#ffffff", "beige", "#fff"];
    const isBlack = ["#ffc107"];
    return isGrey.includes(color)
      ? ""
      : isBlack.includes(color)
      ? "#000000"
      : "#ffffff";
  };

  const changeColor = (color) => {
    setColorPickerState({
      ...colorPickerState,
      checkedColor: color,
      // showColorPicker: false,
    });

    /*const tasks = cloneDeep(
      tasksByStage[colorPickerState.currentTaskToChangeStatus]
    );
    const foundIndex = tasks.findIndex(
      (task) => task._id === colorPickerState.currentTaskToChangeColor
    );
    tasks[foundIndex].userColor = color;
    tasks[foundIndex].userTextColor = determineTextColour(color);
    */

    const tasks = cloneDeep(data.tasks);
    const foundIndex = tasks.findIndex(
      (task) => task._id === colorPickerState.currentTaskToChangeColor
    );
    tasks[foundIndex].userColor = color;
    tasks[foundIndex].userTextColor = determineTextColour(color);

    const allTasks = cloneDeep(data.allTasks);
    const foundTaskIndex = allTasks.findIndex(
      (task) => task._id === colorPickerState.currentTaskToChangeColor
    );
    allTasks[foundTaskIndex].userColor = color;
    allTasks[foundTaskIndex].userTextColor = determineTextColour(color);
    setData(combineData(data, { allTasks, tasks }));

    /*setTasksByStage({
      ...tasksByStage,
      [colorPickerState.currentTaskToChangeStatus]: tasks,
    });
    */

    //persist to backend
    const task = allTasks[foundTaskIndex];
    socket.emit("fx:save-task-color-settings", {
      task_id: colorPickerState.currentTaskToChangeColor,
      project_id: task.project,
      user_id: user._id,
      colorSettings: {
        project_id: task.project,
        task_id: colorPickerState.currentTaskToChangeColor,
        userColor: color,
        userTextColor: determineTextColour(color),
      },
    });
  };

  const handleAddProject = (project) => {
    let { allProjects } = data;
    allProjects = cloneDeep(allProjects);
    allProjects = [...allProjects, project];
    handleModal("addProject");
    setData((data) => combineData(data, { allProjects, project, tasks: [] }));
    // handleSelectProject(project._id);
  };

  const changeTaskStatus = ({ task, status, sort = true }) => {
    const sourceStatus = task.status;
    const destinationStatus = status;
    let { allTasks, tasks } = data;
    task.status = status;
    task.task_position = 0;
    const tasksByStageClone = cloneDeep(tasksByStage);
    let mutatedSource = tasksByStageClone[sourceStatus].filter(
      (taskEl) => taskEl._id !== task._id
    );

    const destinationTasks = tasksByStageClone[status];
    destinationTasks.splice(0, 0, task);
    const taskStatusArrangement = [];
    const mutatedDestination = destinationTasks.map((task, i) => {
      taskStatusArrangement[i] = {
        project_id: task.project,
        task_id: task._id,
        task_position: i,
        status: destinationStatus,
      };
      return {
        ...task,
        task_position: i,
      };
    });

    // source arrangement
    const sourceTaskStatusArrangement = [];
    mutatedSource = mutatedSource.map((task, i) => {
      sourceTaskStatusArrangement[i] = {
        project_id: task.project,
        task_id: task._id,
        task_position: i,
        status: sourceStatus,
      };
      return {
        ...task,
        task_position: i,
      };
    });

    // add postions
    tasks.forEach((task) => {
      const foundIndex = mutatedDestination.findIndex(
        (mutatedTask) => mutatedTask._id === task._id
      );
      task.task_position =
        foundIndex !== -1 ? mutatedDestination[foundIndex].task_position : "";
    });

    /*setTasksByStage({
      ...tasksByStage,
      [sourceStatus]: mutatedSource,
      [status]: mutatedDestination,
    });
    */

    const project_id = task.project;
    allTasks = allTasks.filter((task) => task.project !== project_id);

    setData(
      combineData(data, {
        tasks,
        allTasks: [...allTasks, ...tasks],
      })
    );

    const payload = {
      taskId: task._id,
      status,
      token,
    };
    socket.emit("fx:task-status-updated", payload, (response) => {
      if (response && response.success) {
        // setData(combineData(data, { tasks }));
      }
    });

    // console.log([...sourceTaskStatusArrangement, ...taskStatusArrangement]);

    socket.emit("fx:save-task-status-arrangement", {
      taskStatusArrangement: [
        ...sourceTaskStatusArrangement,
        ...taskStatusArrangement,
      ],
      user_id: user._id,
      project_id: task.project,
      status,
      sourceStatus,
      destinationStatus,
    });
  };

  const approveTask = () => {
    changeTaskStatus({ task: data.selectedTask, status: "COMPLETE" });
    /*setPreparedTaskToChangeStatus({
      task: data.selectedTask,
      status: "COMPLETE",
      type: "leave",
    });
    */
  };

  const handleAnimationEnd = () => {
    if (preparedTaskToChangeStatus.type === "leave") {
      setPreparedTaskToChangeStatus({
        ...preparedTaskToChangeStatus,
        type: "enter",
      });
      changeTaskStatus({
        task: preparedTaskToChangeStatus.task,
        status: preparedTaskToChangeStatus.status,
      });
    } else if (preparedTaskToChangeStatus.type === "enter") {
      setPreparedTaskToChangeStatus({
        task: {},
        status: "",
        type: "",
      });
    }
  };

  let api;
  const useMyCoolSensor = (value) => {
    api = value;
  };

  const startDrag = function start({
    targetRef,
    taskRef,
    draggableId,
    e,
    status,
  }) {
    const { allTasks } = data;
    const task = cloneDeep(allTasks).find((task) => task._id === draggableId);
    const preDrag = api.tryGetLock(draggableId);
    if (!preDrag) {
      return;
    }

    if (targetRef) {
      /*targetRef.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      */
    }

    // setTimeout(() => {
    // let targetRef = testRef.current;
    const endX = targetRef && targetRef.getBoundingClientRect().x;
    const endY = targetRef && targetRef.getBoundingClientRect().y;

    console.log("scs", endX, endY);

    const start = {
      x: taskRef.getBoundingClientRect().x + window.pageXOffset,
      y: taskRef.getBoundingClientRect().y + window.pageYOffset,
    };
    const end = {
      x: endX + window.pageXOffset,
      y: endY + window.pageYOffset,
    };
    const drag = preDrag.fluidLift(start);

    const points = [];
    const maxPoints = 25;
    const middle = maxPoints / 2;

    for (let i = 0; i < maxPoints; i++) {
      /*console.log(
        middle >= i ? start.y - 5 * i : start.y - 5 * (middle - (i - middle)),
        Boolean(middle >= i)
      );
      */

      points.push({
        x: tweenFunctions.linear(i, start.x, end.x, maxPoints),
        y: tweenFunctions.linear(i, start.y, end.y, maxPoints),
      });
    }

    /*const startOne = { x: endX, y: 0 };
    const endOne = { x: endX, y: -350 };

    for (let i = 0; i < maxPoints; i++) {
      points.push({
        x: tweenFunctions.linear(i, startOne.x, endOne.x, maxPoints),
        y: tweenFunctions.linear(i, startOne.y, endOne.y, maxPoints),
      });
    }
    */
    moveStepByStep(drag, points, task, status);
    //}, 500);
  };

  const moveStepByStep = (drag, values, task, status) => {
    requestAnimationFrame(() => {
      const newPosition = values.shift();
      drag.move(newPosition);

      if (values.length) {
        moveStepByStep(drag, values, task, status);
      } else {
        drag.drop();
      }
    });
  };

  const addCommentReply = ({ commentId, taskCommentReplies }) => {
    let updatedComments = cloneDeep(comments);
    const foundIndex = updatedComments.findIndex(
      (comment) => comment._id === commentId
    );
    if (foundIndex != -1) {
      updatedComments[foundIndex].taskCommentReplies = taskCommentReplies;
      setComments(updatedComments);
    }
  };

  const addNewCommentReply = ({
    commentId,
    taskCommentReply,
    update = false,
  }) => {
    let updatedComments = cloneDeep(comments);
    const foundIndex = updatedComments.findIndex(
      (comment) => comment._id === commentId
    );
    if (foundIndex != -1) {
      const taskCommentReplies = updatedComments[foundIndex].taskCommentReplies;
      if (!update) {
        updatedComments[foundIndex].taskCommentReplies = [
          ...taskCommentReplies,
          taskCommentReply,
        ];
      } else {
        const foundCommentReplyIndex = taskCommentReplies.findIndex(
          (reply) => reply._id === taskCommentReply._id
        );
        if (foundIndex != -1) {
          // taskCommentReplies[foundCommentReplyIndex] = taskCommentReply;
        }
      }
      setComments(updatedComments);
    }
  };

  return (
    <section>
      <Topbar />
      <div className="main-wrapper">
        {/* <!-- Page Wrapper --> */}
        <div className="custom-wrapper overflow-hidden">
          {/* <!-- Page Content --> */}
          <div className="content container-fluid p-0">
            <div className="header-wrapper px-3 bg-light">
              {/* <!-- Page Header --> */}
              <div className="page-header mb-0 py-1">
                <div className="row">
                  <div className="col-sm-12 d-md-flex justify-content-between align-items-center">
                    {/* <h3 className="page-title">{data?.project?.name}</h3> */}
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <span className="fex-color">Home</span>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <span className="project-name">
                          {data?.project?.name}
                        </span>
                      </BreadcrumbItem>
                    </Breadcrumb>

                    <div className="flex row-centered" id="test" ref={testRef}>
                      <div className="pro-teams mx-3 mb-0">
                        <div className="pro-team-lead">
                          <h4>Lead</h4>
                          <div className="avatar-group">
                            {getUniqueListBy(data?.project?.projectLeader)?.map(
                              (leader, leaderIndex) => {
                                const eName =
                                  leader?.name?.first +
                                  " " +
                                  leader?.name?.last;
                                return (
                                  <div
                                    className="avatar"
                                    key={shortid.generate()}
                                    title={eName}
                                  >
                                    <img
                                      className="avatar-img rounded-circle border border-white"
                                      alt="User Image"
                                      src={
                                        URLS.backendStatic + "/" + leader.photo
                                      }
                                      key={shortid.generate()}
                                    />
                                  </div>
                                );
                              }
                            )}
                            {hasProjectRights() ? (
                              <div className="avatar">
                                <a
                                  href=""
                                  className="avatar-title rounded-circle border border-white btn-plus"
                                  data-toggle="modal"
                                  data-target="#assign_leader"
                                  onClick={() => handleModal("addLeader")}
                                  title="Add leader"
                                >
                                  <i className="fa fa-plus"></i>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="pro-team-members">
                          <h4>Team</h4>
                          <div className="avatar-group">
                            {getUniqueListBy(data?.project?.team)?.map(
                              (member, memberIndex) => {
                                const eName =
                                  member?.name?.first +
                                  " " +
                                  member?.name?.last;
                                return (
                                  <div
                                    className="avatar"
                                    key={shortid.generate()}
                                    title={eName}
                                  >
                                    <img
                                      className="avatar-img rounded-circle border border-white"
                                      alt="User Image"
                                      src={
                                        URLS.backendStatic + "/" + member.photo
                                      }
                                      key={shortid.generate()}
                                    />
                                  </div>
                                );
                              }
                            )}
                            {hasProjectRights() ? (
                              <div className="avatar">
                                <a
                                  href=""
                                  className="avatar-title rounded-circle border border-white btn-plus"
                                  data-toggle="modal"
                                  data-target="#assign_member"
                                  onClick={() => handleModal("addMember")}
                                  title="Add member"
                                >
                                  <i className="fa fa-plus"></i>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <a
                        className="icon-border"
                        title="View Project"
                        href={`${URLS.projectView}/${data?.project?._id}`}
                      >
                        <LinkVariantIcon />
                      </a>

                      {data?.project?._id === data?.pinnedProject ? (
                        <span
                          className="ml-3 icon-border"
                          title="Unpin Project"
                          onClick={() => handlePinTask("unpin")}
                        >
                          <PinOffOutlineIcon />
                        </span>
                      ) : (
                        <span
                          className="ml-3 icon-border"
                          title="Pin Project"
                          onClick={() => handlePinTask("pin")}
                        >
                          <PinOutlineIcon />
                        </span>
                      )}

                      <select
                        className="ml-3 select-project"
                        onChange={(e) => handleSelectProject(e.target.value)}
                        value={data?.project?._id}
                      >
                        <option disabled>Projects</option>
                        {data?.allProjects?.map((project) => (
                          <option key={shortid.generate()} value={project?._id}>
                            {project?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- /Page Header --> */}

              <div className="row board-view-header justify-content-between px-3">
                <div className="w-100 mt-3">
                  <div className="pro-progress">
                    <div className="pro-progress-bar">
                      <h4>Progress</h4>
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: `${calculateProjectProgress()}%` }}
                        ></div>
                      </div>
                      <span>{calculateProjectProgress()}%</span>
                    </div>
                  </div>
                </div>

                <div
                  ref={colorPickerRef}
                  className={`color-box position-absolute bg-white ${
                    !colorPickerState.showColorPicker ? "d-none" : ""
                  }`}
                  style={{
                    top: colorPickerState.colorPickerStyle.top,
                    left: colorPickerState.colorPickerStyle.left,
                    width: "250px",
                    zIndex: "101",
                  }}
                >
                  <div className="d-flex justify-content-between m-1">
                    <span>Pick a color</span>
                    <CloseIcon
                      className="p-1 p-cursor"
                      onClick={() => {
                        setColorPickerState(initialColorPickerState);
                      }}
                    />
                  </div>
                  <ColorPicker
                    checkedColor={colorPickerState.checkedColor}
                    changeColor={(color) => changeColor(color)}
                  />
                </div>
              </div>
            </div>
            <div className="kanban-board card mb-0">
              <div className="card-body px-0 py-2">
                <DragDropContext
                  onDragEnd={(e) => {
                    handleDragEnd(e);
                  }}
                  onBeforeCapture={(e) => {
                    handleBeforeCapture(e);
                  }}
                  sensors={[useMyCoolSensor]}
                >
                  <div className="kanban-cont sleek-scrollbar">
                    {data?.stages &&
                      data?.stages?.map((stage, stageIndex) => (
                        <div
                          key={stageIndex}
                          className={`kanban-list ${getTaskStatus(
                            stage?.status
                          )}`}
                        >
                          <div className="kanban-header">
                            <span className="status-title">{stage?.title}</span>
                          </div>

                          <Droppable
                            droppableId={stage?.status}
                            key={shortid.generate()}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  onScroll={(e) => {
                                    scrollTop.current[stage?.status] =
                                      e.target.scrollTop;
                                  }}
                                >
                                  <div
                                    ref={(el) =>
                                      (stageRefs.current[
                                        "DROPPABLE_" + stage?.status
                                      ] = el)
                                    }
                                    style={{
                                      height: "15px",
                                      // position: "absolute",
                                      // border: "1px solid red",
                                    }}
                                  ></div>
                                  {tasksByStage[stage?.status]?.map(
                                    (task, taskIndex) => {
                                      if (task?.status == stage?.status) {
                                        return (
                                          <div
                                            className="kanban-wrap"
                                            key={shortid.generate()}
                                          >
                                            <Draggable
                                              index={taskIndex}
                                              draggableId={task?._id}
                                              key={shortid.generate()}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    className="card panel mx-0 mb-3"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <div
                                                      className="kanban-box task-card"
                                                      style={{
                                                        backgroundColor:
                                                          task?.userColor,
                                                        color:
                                                          task?.userTextColor,
                                                      }}
                                                      ref={(el) =>
                                                        (taskRefs.current[
                                                          "TASK_" + task?._id
                                                        ] = el)
                                                      }
                                                    >
                                                      {task?._id ===
                                                        data?.selectedTask
                                                          ?._id &&
                                                      data?.isEditingTaskField ===
                                                        "taskName" ? (
                                                        <div className="task-text-wrapper">
                                                          <span
                                                            className="task-editable-textarea"
                                                            role="textbox"
                                                            contentEditable="true"
                                                            suppressContentEditableWarning={
                                                              true
                                                            }
                                                            autofocus="true"
                                                            onKeyDown={(e) =>
                                                              handleSaveEditedTask(
                                                                e,
                                                                "taskName"
                                                              )
                                                            }
                                                          >
                                                            {task?.taskName}
                                                          </span>
                                                          <span
                                                            onClick={() =>
                                                              handleEditTask(
                                                                task,
                                                                "taskName",
                                                                "close"
                                                              )
                                                            }
                                                            className="p-cursor"
                                                          >
                                                            <EditIcon className="control-icon" />
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <div className="task-text-wrapper">
                                                          <span className="task-name-text mt-1">
                                                            {task?.taskName}
                                                          </span>
                                                          <span
                                                            className="task-edit-icon p-cursor"
                                                            onClick={() =>
                                                              handleEditTask(
                                                                task,
                                                                "taskName"
                                                              )
                                                            }
                                                          >
                                                            <EditIcon className="control-icon" />
                                                          </span>
                                                          {
                                                            <span
                                                              className="task-status-more"
                                                              style={{
                                                                color:
                                                                  "#1f1f1f",
                                                              }}
                                                            >
                                                              <button className="p-cursor border-none">
                                                                {
                                                                  <DotsVertical
                                                                    className="task-status-more-icon m-0"
                                                                    style={{
                                                                      color:
                                                                        task.userTextColor,
                                                                    }}
                                                                  />
                                                                }
                                                              </button>
                                                              <div className="task-status-more-layout">
                                                                <span
                                                                  title="Pick a color"
                                                                  className="p-cursor"
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    showColorPicker(
                                                                      {
                                                                        e,
                                                                        task_id:
                                                                          task._id,
                                                                        status:
                                                                          task.status,
                                                                        project_id:
                                                                          task.project,
                                                                        checkedColor:
                                                                          task?.userColor,
                                                                      }
                                                                    )
                                                                  }
                                                                >
                                                                  <PaletteIcon className="control-icon mr-1" />
                                                                  <span>
                                                                    Color
                                                                  </span>
                                                                </span>
                                                                {hasProjectRights() ? (
                                                                  <span
                                                                    title="Edit Task"
                                                                    onClick={async () => {
                                                                      await handleSelectTask(
                                                                        task
                                                                      );
                                                                      handleModal(
                                                                        "editTask"
                                                                      );
                                                                    }}
                                                                    data-toggle="modal"
                                                                    data-target="#edit_task_modal"
                                                                    className="p-cursor"
                                                                  >
                                                                    <PencilIcon className="control-icon mr-1" />
                                                                    <span>
                                                                      Edit
                                                                    </span>
                                                                  </span>
                                                                ) : null}
                                                                {hasProjectRights() ? (
                                                                  <span
                                                                    title="Delete Task"
                                                                    data-toggle="modal"
                                                                    data-target="#delete_task"
                                                                    onClick={async () => {
                                                                      await handleSelectTask(
                                                                        task
                                                                      );
                                                                      handleModal(
                                                                        "deleteTask"
                                                                      );
                                                                    }}
                                                                    className="p-cursor"
                                                                  >
                                                                    <DeleteIcon className="control-icon mr-1" />
                                                                    <span>
                                                                      Delete
                                                                    </span>
                                                                  </span>
                                                                ) : null}
                                                              </div>
                                                            </span>
                                                          }
                                                        </div>
                                                      )}
                                                      {task?._id ===
                                                        data?.selectedTask
                                                          ?._id &&
                                                      data?.isEditingTaskField ===
                                                        "taskDetail" ? (
                                                        <div className="task-text-wrapper">
                                                          <span
                                                            className="task-editable-textarea"
                                                            role="textbox"
                                                            contentEditable="true"
                                                            suppressContentEditableWarning={
                                                              true
                                                            }
                                                            autofocus="true"
                                                            onKeyDown={(e) =>
                                                              handleSaveEditedTask(
                                                                e,
                                                                "taskDetail"
                                                              )
                                                            }
                                                          >
                                                            {task?.taskDetail}
                                                          </span>
                                                          <span
                                                            className="p-cursor"
                                                            onClick={() =>
                                                              handleEditTask(
                                                                task,
                                                                "taskDetail",
                                                                "close"
                                                              )
                                                            }
                                                          >
                                                            <EditIcon
                                                              className="control-icon pl-1"
                                                              style={{
                                                                color:
                                                                  task.userTextColor,
                                                              }}
                                                            />
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <div className="task-text-wrapper">
                                                          <span className="task-detail-text">
                                                            {task?.taskDetail}
                                                          </span>
                                                          <span
                                                            className="task-edit-icon p-cursor"
                                                            onClick={() =>
                                                              handleEditTask(
                                                                task,
                                                                "taskDetail"
                                                              )
                                                            }
                                                          >
                                                            <EditIcon
                                                              className="control-icon"
                                                              style={{
                                                                color:
                                                                  task.userTextColor,
                                                              }}
                                                            />
                                                          </span>
                                                        </div>
                                                      )}
                                                      <div className="task-board-controls">
                                                        <div className="custom-avatar-row">
                                                          {task?.assignedTo
                                                            ?.slice(0, 3)
                                                            ?.map(
                                                              (
                                                                user,
                                                                userIndex
                                                              ) => (
                                                                <img
                                                                  src={
                                                                    URLS.backendStatic +
                                                                    "/" +
                                                                    user.photo
                                                                  }
                                                                  className="custom-avatar"
                                                                  key={shortid.generate()}
                                                                />
                                                              )
                                                            )}
                                                          {hasProjectRights() && (
                                                            <span
                                                              className="btn-add-task-user p-cursor"
                                                              data-toggle="modal"
                                                              data-target="#assignee"
                                                              onClick={() =>
                                                                handleModal(
                                                                  "assignTask"
                                                                )
                                                              }
                                                            >
                                                              <i className="fa fa-plus"></i>
                                                            </span>
                                                          )}
                                                        </div>
                                                        <div className="p-cursor">
                                                          {task.status && (
                                                            <span>
                                                              {task &&
                                                              task?.status ===
                                                                "AWAITING"
                                                                ? isAssignedTo(
                                                                    task
                                                                  ) && (
                                                                    <span title="Accept">
                                                                      <AdjustIcon
                                                                        className="circle-icon ch-awaiting-btn"
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                          color:
                                                                            task?.userTextColor,
                                                                        }}
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          startDrag(
                                                                            {
                                                                              targetRef:
                                                                                stageRefs
                                                                                  .current[
                                                                                  "DROPPABLE_INCOMPLETE"
                                                                                ],
                                                                              taskRef:
                                                                                taskRefs
                                                                                  .current[
                                                                                  "TASK_" +
                                                                                    task?._id
                                                                                ],
                                                                              draggableId:
                                                                                task?._id,
                                                                              e,
                                                                              status:
                                                                                "INCOMPLETE",
                                                                            }
                                                                          )
                                                                        }
                                                                      />
                                                                    </span>
                                                                  )
                                                                : null}
                                                              {task &&
                                                              task?.status ===
                                                                "INCOMPLETE"
                                                                ? isAssignedTo(
                                                                    task
                                                                  ) && (
                                                                    <span title="Mark completed">
                                                                      <CheckCircleIcon
                                                                        className="circle-icon ch-mark-completed-btn"
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          startDrag(
                                                                            {
                                                                              targetRef:
                                                                                stageRefs
                                                                                  .current[
                                                                                  "DROPPABLE_UNDER_REVIEW"
                                                                                ],
                                                                              draggableId:
                                                                                task?._id,
                                                                              taskRef:
                                                                                taskRefs
                                                                                  .current[
                                                                                  "TASK_" +
                                                                                    task?._id
                                                                                ],
                                                                              e,
                                                                              status:
                                                                                "UNDER_REVIEW",
                                                                            }
                                                                          )
                                                                        }
                                                                      />
                                                                    </span>
                                                                  )
                                                                : null}
                                                              {task &&
                                                              task?.status ===
                                                                "UNDER_REVIEW" ? (
                                                                isCreatedBy(
                                                                  task
                                                                ) ? (
                                                                  <span title="Approve as Completed">
                                                                    <CheckCircleIcon
                                                                      className="circle-icon ch-approve-as-complete-btn"
                                                                      data-toggle="modal"
                                                                      data-target="#handle_approve_task"
                                                                      onClick={() =>
                                                                        handleSelectTask(
                                                                          task
                                                                        )
                                                                      }
                                                                    />
                                                                  </span>
                                                                ) : isAssignedTo(
                                                                    task
                                                                  ) ? (
                                                                  <span
                                                                    title="Unmark"
                                                                    style={{
                                                                      display:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    <CheckCircleIcon className="circle-icon ch-mark-completed-btn" />
                                                                  </span>
                                                                ) : null
                                                              ) : null}
                                                              {task &&
                                                              task?.status ===
                                                                "COMPLETE" ? (
                                                                <span title="Completed">
                                                                  <CheckCircleIcon className="circle-icon ch-complete-btn" />
                                                                </span>
                                                              ) : null}
                                                            </span>
                                                          )}

                                                          <span
                                                            className="p-1"
                                                            style={{
                                                              position:
                                                                "relative",
                                                              left: "8px",
                                                            }}
                                                          >
                                                            <BellIcon
                                                              className="control-icon m-0"
                                                              style={{
                                                                color:
                                                                  task.userTextColor,
                                                              }}
                                                            />
                                                            <span
                                                              className="badge badge-danger badge-pill badge-position"
                                                              style={
                                                                !(
                                                                  task?.unreadCount &&
                                                                  task?.unreadCount >
                                                                    0
                                                                )
                                                                  ? {
                                                                      display:
                                                                        "none",
                                                                    }
                                                                  : {}
                                                              }
                                                            >
                                                              {
                                                                task?.unreadCount
                                                              }
                                                            </span>
                                                          </span>

                                                          <span
                                                            className="p-1"
                                                            style={{
                                                              position:
                                                                "relative",
                                                              left: "8px",
                                                            }}
                                                            title="Thread"
                                                            onClick={(e) =>
                                                              handleChat(
                                                                e,
                                                                task
                                                              )
                                                            }
                                                          >
                                                            <MessageIcon
                                                              className="control-icon m-0"
                                                              style={{
                                                                color:
                                                                  task.userTextColor,
                                                              }}
                                                            />
                                                            <span
                                                              style={{
                                                                marginLeft:
                                                                  "2px",
                                                                color:
                                                                  "inherit",
                                                              }}
                                                            >
                                                              {
                                                                task.comments
                                                                  .length
                                                              }
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }}
                                            </Draggable>
                                          </div>
                                        );
                                      }
                                    }
                                  )}

                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                          <div>
                            {
                              /*!getStageTasks(stage).length &&*/
                              !hasProjectRights() ? (
                                <div className="add-new-task p-3"></div>
                              ) : null
                            }

                            {hasProjectRights() ? (
                              <div
                                className="add-new-task"
                                data-toggle="modal"
                                data-target="#add_task_modal"
                                onClick={() =>
                                  handleModal("addTask", stage?.status)
                                }
                              >
                                <PlusIcon className="p-cursor" />
                                <span>Add New Task</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                  </div>
                </DragDropContext>
              </div>
            </div>
            {/* {data?.showToastMessage ? <Toast message="Pinned project successfully" /> : null} */}
          </div>
          {/* <!-- /Page Content --> */}
        </div>

        {data?.isAddProjectModalOpen ? (
          <AddProjectModal
            updateProjectsList={(project) => handleAddProject(project)}
            clientList={data.clientList}
            teams={data.teamsICreated}
            employeesList={data.employeesList}
            closeModal={() => handleModal("addProject")}
          />
        ) : null}

        {data?.isAssignTaskModalOpen && (
          <AssignTaskModal
            activeProjectId={data?.project?._id}
            team={getAllProjectMembers()}
            selectedTask={data?.task}
            closeModal={() => handleModal("assignTask")}
            taskUpdated={(response) => handleTaskUpdated(response, "editTask")}
          />
        )}

        {data?.isAddTaskModalOpen ? (
          <AddTaskModal
            team={getAllProjectMembers()}
            projectId={data?.project?._id}
            closeModal={() => handleModal("addTask")}
            taskCreated={(response) => handleTaskCreated(response)}
            isAddTaskModalOpen={data?.isAddTaskModalOpen}
            statusToSet={data.statusToSet}
          />
        ) : null}

        {data?.isEditTaskModalOpen ? (
          <EditTaskModal
            projectId={data?.project?._id}
            team={getAllProjectMembers()}
            selectedTask={data?.selectedTask}
            closeModal={() => handleModal("editTask")}
            taskUpdated={(response) => handleTaskUpdated(response, "editTask")}
          />
        ) : null}

        {data?.isAddMemberModalOpen && (
          <AddMemberModal
            team={data?.employeesList}
            project={data?.project}
            updateProjectsList={(project) => updateProjectsList(project)}
            closeModal={() => handleModal("addMember")}
          />
        )}

        {data?.isAddLeaderModalOpen && (
          <AddLeaderModal
            team={data?.employeesList}
            project={data?.project}
            updateProjectsList={(project) => updateProjectsList(project)}
            closeModal={() => handleModal("addLeader")}
          />
        )}

        {data?.isFileGalleryOpen ? (
          <FileGallery
            task={data?.selectedTask}
            closeModal={() => handleModal("fileGallery")}
          />
        ) : null}

        <input
          ref={selectAttachmentRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => updateCommentFile(e)}
          multiple
        />

        {data?.isFileViewerOpen ? (
          <div
            id="view_file"
            className="modal custom-modal fade"
            role="dialog"
            onClick={() => handleCloseFileModal()}
          >
            <div className="modal-dialog modal-dialog-centered gallery-preview-modal">
              <div className="modal-content gallery-preview-content">
                <iframe
                  src={data?.fileToOpen}
                  description=""
                  style={{ height: "100%", width: "100%", border: "none" }}
                />
              </div>
            </div>
          </div>
        ) : null}

        {data?.isTaskChat && (
          <FreeDrag>
            <div
              className="task-2-chat"
              style={{
                top: data?.taskChatStyle.top,
                left: data?.taskChatStyle.left,
              }}
            >
              <div className="task-2-chat-header">
                <span className="task-2-title">
                  {data?.selectedTask?.taskName}
                </span>
                <CloseIcon
                  onClick={() => handleChat()}
                  className="task-2-close-btn"
                />
              </div>
              {data?.selectedTask && data?.selectedTask?.taskName && (
                <div className="task-2-chat-body px-2" ref={chatBody}>
                  <div>
                    <div className="task-2-chat-assigned-to">
                      <span>Assigned To</span>
                      {data?.selectedTask?.status === "AWAITING" ? (
                        isAssignedTo(data?.selectedTask) ? (
                          <button
                            className="button ts-awaiting-btn"
                            onClick={() => handleUpdateTaskStatus()}
                          >
                            <AdjustIcon /> Accept
                          </button>
                        ) : !isAssignedTo(data?.selectedTask) ? (
                          <span className="ts-pending-btn">Pending</span>
                        ) : null
                      ) : null}

                      {data?.selectedTask?.status === "INCOMPLETE" ? (
                        isAssignedTo(data?.selectedTask) ? (
                          <button
                            className="button ts-mark-completed-btn"
                            onClick={() => handleUpdateTaskStatus()}
                          >
                            <CheckIcon /> Mark completed
                          </button>
                        ) : !isAssignedTo(data?.selectedTask) ? (
                          <span className="ts-accepted-btn">Accepted</span>
                        ) : null
                      ) : null}

                      {data?.selectedTask.status === "UNDER_REVIEW" ? (
                        isCreatedBy(data?.selectedTask) ? (
                          <button
                            className="button ts-approve-as-complete-btn"
                            data-toggle="modal"
                            data-target="#handle_approve_task"
                          >
                            <CheckIcon /> Approve as Completed
                          </button>
                        ) : isAssignedTo(data?.selectedTask) ? (
                          <>
                            <button
                              className="button ts-mark-completed-btn"
                              onClick={() => handleUpdateTaskStatus()}
                            >
                              <CheckIcon /> Unmark
                            </button>
                            <span className="ts-under-review-btn">
                              Under Review
                            </span>
                          </>
                        ) : null
                      ) : null}
                      {data?.selectedTask?.status === "COMPLETE" ? (
                        <span className="ts-complete-btn">Completed</span>
                      ) : null}
                    </div>
                    {data?.selectedTask?.assignedTo && (
                      <div>
                        <div className="task-img-collection">
                          {data?.selectedTask?.assignedTo?.map(
                            (employee, index) => {
                              if (index < 3) {
                                return (
                                  <div className="avatars" key={index}>
                                    <span
                                      className="avatar"
                                      title={setEmployeeName(employee)}
                                    >
                                      {employee.photo ? (
                                        <img
                                          src={
                                            URLS.backendStatic +
                                            "/" +
                                            employee.photo
                                          }
                                        />
                                      ) : (
                                        <span className="av-alternative">
                                          {setEmployeeInitials(employee)}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                        <div>
                          {data?.selectedTask?.assignedTo?.length > 0 && (
                            <span>
                              {setEmployeeName(
                                data?.selectedTask?.assignedTo[0]
                              )}
                            </span>
                          )}
                          {data?.selectedTask?.assignedTo?.length > 1 && (
                            <div className="assigned-to-details">
                              <span className="assigned-to-others-text">
                                &amp;{" "}
                                {data?.selectedTask?.assignedTo?.length - 1}{" "}
                                other
                                {data?.selectedTask?.assignedTo?.length > 2
                                  ? "s"
                                  : null}
                              </span>

                              <div className="assigned-to-others-layout">
                                {data?.selectedTask?.assignedTo?.map(
                                  (employee, index) => {
                                    if (index !== 0) {
                                      return (
                                        <div key={index}>
                                          {employee.photo ? (
                                            <img
                                              src={
                                                URLS.backendStatic +
                                                "/" +
                                                employee.photo
                                              }
                                            />
                                          ) : (
                                            <span className="sp-initials">
                                              {setEmployeeInitials(employee)}
                                            </span>
                                          )}
                                          <span>
                                            {setEmployeeName(employee)}
                                          </span>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {data?.selectedTask?.startDate && (
                      <div>
                        <span>Start Date</span>
                        <span>
                          {showDateInFormat(data?.selectedTask?.startDate)}
                        </span>
                      </div>
                    )}
                    {data?.selectedTask?.dueDate && (
                      <div>
                        <span>End Date</span>
                        <span>
                          {data?.selectedTask.status === "COMPLETE"
                            ? showDateInFormat(data?.selectedTask?.completeDate)
                            : showDateInFormat(data?.selectedTask?.dueDate)}
                        </span>
                      </div>
                    )}
                    {data?.selectedTask?.completeDate &&
                      data?.selectedTask?.dueDate && (
                        <div>
                          <span>Completed Date</span>
                          <span>
                            {data?.selectedTask.status === "COMPLETE"
                              ? showDateInFormat(
                                  data?.selectedTask?.completeDate
                                )
                              : showDateInFormat(data?.selectedTask?.dueDate)}
                          </span>
                        </div>
                      )}
                  </div>
                  <div>
                    <span>Description</span>
                    <span>
                      {data?.selectedTask?.taskDetail
                        ? data?.selectedTask?.taskDetail
                        : ""}
                    </span>
                  </div>
                  <div>
                    <span>Conversation</span>
                    <span title="View All Files">
                      <FolderIcon
                        className="gallery-icon"
                        data-toggle="modal"
                        data-target="#file_gallery"
                        onClick={() => handleModal("fileGallery")}
                      />
                    </span>
                  </div>
                  <div ref={messagesEndRef}>
                    {data?.selectedTask && comments
                      ? comments.map((comment, index) => (
                          <SingleComment
                            key={comment._id}
                            handleOpenFileViewer={handleOpenFileViewer}
                            comment={comment}
                            setEmployeeInitials={setEmployeeInitials}
                            setCommentToReply={setCommentToReply}
                            setComment={setComment}
                            addCommentReply={addCommentReply}
                            hideShowReplies={false}
                            isParent={true}
                          />
                        ))
                      : null}
                  </div>
                </div>
              )}
              <div className="task-controls-layout">
                {Object.keys(commentToReply).length ? (
                  <div className="task-controls-attachments-layout sleek-scrollbar h-auto">
                    <span
                      className="position-absolute bg-light rounded-circle mr-2"
                      style={{ right: 0 }}
                    >
                      <CloseIcon onClick={() => setCommentToReply({})} />
                    </span>
                    <SingleComment
                      handleOpenFileViewer={handleOpenFileViewer}
                      comment={commentToReply}
                      setEmployeeInitials={setEmployeeInitials}
                      setCommentToReply={setCommentToReply}
                      hideActions={true}
                    />
                  </div>
                ) : null}
                {data?.selectedTask &&
                  data?.selectedTask?.taskName &&
                  canSeeChat(data?.selectedTask) && (
                    <>
                      {data?.newComment &&
                      data?.newComment.files &&
                      data?.newComment.files.length ? (
                        <div className="task-controls-attachments-layout sleek-scrollbar">
                          <div className="attachments-row">
                            {data?.newComment?.files?.map((file, index) => (
                              <div className="single-attachment" key={index}>
                                <div className="attach-file-close">
                                  <CloseIcon
                                    className="attach-file-close-icon"
                                    onClick={() => removeProjectFile(index)}
                                  />
                                </div>
                                <div className="attach-file-col">
                                  <FileIcon className="attach-file-icon" />
                                  <div className="attach-file-info">
                                    <span>
                                      {file.originalName + "." + file.extension}
                                    </span>
                                    <span>{calculateFileSize(file.size)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                      <div className="task-controls-message-layout">
                        <div>
                          <textarea
                            placeholder="Send a message"
                            onChange={(e) => handleChangeComment(e)}
                            onKeyDown={(e) => handleSendMessage(e)}
                            ref={inputEndRef}
                            onDrop={(e) => handleDropFile(e)}
                            onDragOver={(e) => handleDragOver(e)}
                          />
                          <span>
                            <AttachmentIcon
                              onClick={() => handleSelectFiles()}
                            />
                          </span>
                        </div>
                        <span onClick={(e) => submitComment(e)}>
                          <SendIcon />
                        </span>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </FreeDrag>
        )}

        <div className="modal custom-modal fade" id="delete_task" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Task</h3>
                  <p>Are you sure you want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <span
                        className="btn delete-btn"
                        data-dismiss="modal"
                        onClick={() => handleDeleteTask()}
                      >
                        Delete
                      </span>
                    </div>
                    <div className="col-6">
                      <span data-dismiss="modal" className="btn cancel-btn">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal custom-modal fade"
          id="handle_approve_task"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Approve Task</h3>
                  <p>Are you sure want to approve this task?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <span
                        className="btn btn-primary continue-btn"
                        data-dismiss="modal"
                        onClick={() => approveTask()}
                      >
                        Approve
                      </span>
                    </div>
                    <div className="col-6">
                      <span
                        data-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Wrapper --> */}
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleToastMessage: (value) => dispatch(toggleToastMessage(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleProjectTasks);
