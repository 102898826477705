import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseIcon from "mdi-react/CloseIcon";
import { toggleToastMessage } from "../../store/actions/topBarActions";
class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.toggleToastMessage("");
    }, 3000);
  }

  render() {
    const { message, show } = this.state;

    return (
      <Wrapper>
        <div id="snackbar" className="show">
          <span>{message}</span>
          <CloseIcon
            className="btn-close-icon"
            onClick={() => this.props.toggleToastMessage("")}
          />
        </div>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleToastMessage: (value) => dispatch(toggleToastMessage(value)),
  };
};

export default connect(null, mapDispatchToProps)(Toast);

const Wrapper = styled.div`
  #snackbar {
    display: flex;
    background-color: #2d3748;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 10px 25px;
    position: fixed;
    z-index: 100000;
    left: 50%;
    bottom: 10px;
    font-size: 13px;
    align-items: center;

    > span:nth-child(1) {
      margin-right: 40px;
    }

    .btn-close-icon {
      cursor: pointer;
    }
  }

  // #snackbar.show {
  //   display: block;
  //   -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  //   animation: fadein 0.5s, fadeout 0.5s 2.5s;
  // }

  @-webkit-keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 10px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 10px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      top: 10px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      top: 10px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }
`;
