import { find, toArray } from "lodash";
import { uploadFileTypes } from "../actions/uploadFileActions";

const INITIAL_STATE = {
  fileProgress: {
    // format will be like below
    // 1: {
    //   id: 1,
    //   file,
    //   progress: 0,
    //   cancelSource: source,
    //   status: 0,
    //   close: 0
    // },
  },
};

const uploadFileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uploadFileTypes.SET_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...action.payload,
          // ...modifyFiles(state.fileProgress, action.payload),
        },
      };

    case uploadFileTypes.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            ...action.payload.progress,
          },
        },
      };

    case uploadFileTypes.SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            cloud_id: action.payload.cloud_id,
            status: 1,
            file: null,
            error: 0,
            loading: 0,
          },
        },
      };

    case uploadFileTypes.FAILURE_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 0,
            error: 1,
            loading: 0,
          },
        },
      };

    case uploadFileTypes.RETRY_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 0,
            error: 0,
            loading: 1,
          },
        },
      };

    case uploadFileTypes.CLOSE_UPLOAD_FILE:
      /*return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            close: 1,
          },
        },
      };
      */
      const { [action.payload]: removeOne, ...restOne } = state.fileProgress;
      return {
        ...state,
        fileProgress: {
          ...restOne,
        },
      };

    case uploadFileTypes.DELETE_UPLOADED_FILE:
      return {
        ...state,
        fileProgress: Object.fromEntries(
          Object.entries(state.fileProgress).filter(
            ([key, value]) => value.cloud_id !== action.payload
          )
        ),
      };

    case uploadFileTypes.CANCEL_UPLOAD_FILE:
      //console.log(action.payload, "file id in redux");
      const { [action.payload]: remove, ...rest } = state.fileProgress;
      return {
        ...state,
        fileProgress: {
          ...rest,
        },
      };

    case uploadFileTypes.SET_FILE_STAGE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            stage: action.payload.stage,
          },
        },
      };

    case uploadFileTypes.SET_CLOUD_ID:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            cloud_id: action.payload.cloud_id,
          },
        },
      };

    case uploadFileTypes.SET_FILE_DATA:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            ...action.payload.fileData,
          },
        },
      };

    case uploadFileTypes.SET_FILE_SHOW:
      // payload is cloud_id
      const found = toArray(state.fileProgress).find(
        (el) => el.cloud_id === action.payload
      );

      if (!found?.id) return state;

      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [found.id]: {
            ...state.fileProgress[found.id],
            show: 1,
          },
        },
      };

    default:
      return state;
  }
};

export default uploadFileReducer;
