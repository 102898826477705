import { URLS } from "../components/urls";

export function signIn({ email, password }) {
  const url = URLS.backendUsers + "/signIn";
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
          reject(error.response.success);
        }
        reject(error);
      });
  });
}

export function cloudSignIn({ email, password }) {
  const url = URLS.backendCloudDrive + "/ulogin";
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
          reject(error.response.success);
        }
        reject(error);
      });
  });
}
