import React, { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { Button, Modal } from "react-bootstrap";
import "../../../assets/scss/dialog.scss";
import { Checkbox } from "../utils";

function Confirmation({
  title,
  description,
  type,
  confirmMessage,
  declineMessage,
  show,
  proceed,
  form = null,
}) {
  const [formData, setFormData] = useState({});

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => proceed(false)}
        dialogClassName="dialog-modal"
        backdropClassName="global-backdrop dialog-backdrop max-z-index-2"
        centered
        animation={false}
      >
        <Modal.Header>
          <div>
            {type === "warning" ? (
              <svg
                width="106"
                height="93"
                viewBox="0 0 106 93"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M86.021 10.589C92.3649 18.4135 90.4276 32.983 89.3856 46.5175C88.3435 60.052 88.2568 72.5539 81.9129 81.0485C75.5472 89.5495 62.9665 94.0116 51.5986 92.8055C40.2308 91.5791 30.0976 84.6643 20.0957 76.1633C10.0939 67.6623 0.209364 57.6043 0.506537 47.8275C0.80371 38.0507 11.2787 28.5843 21.2844 20.7611C31.2901 12.9379 40.8264 6.76169 52.9149 4.22406C65.0033 1.68643 79.6821 2.76584 86.021 10.589Z"
                  fill="#F3F5F7"
                />
                <path
                  d="M68.3 18.6H82.25C85.04 18.6 86.9 20.46 86.9 23.25V27.9H26.45V23.25C26.45 20.46 28.775 18.6 31.1 18.6H45.05C45.98 13.485 51.095 9.30005 56.675 9.30005C62.255 9.30005 67.37 13.485 68.3 18.6ZM49.7 18.6H63.65C62.72 15.81 59.465 13.95 56.675 13.95C53.885 13.95 50.63 15.81 49.7 18.6ZM31.1 32.55H82.25L78.065 79.515C78.065 81.84 75.74 83.7 73.415 83.7H39.935C37.61 83.7 35.75 81.84 35.285 79.515L31.1 32.55Z"
                  fill="#C8E7FD"
                />
              </svg>
            ) : null}
          </div>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {description && (
          <Modal.Body>
            <span dangerouslySetInnerHTML={createMarkup(description)}></span>

            {form ? (
              <form className="mt-3 text-xs">
                {form.map((el, index) => {
                  return (
                    el.type === "option" && (
                      <label key={index}>
                        <Checkbox
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "0.875rem",
                          }}
                          checked={formData[el.name]}
                          name={el.name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [el.name]: e.target.checked,
                            })
                          }
                        />{" "}
                        <span>{el.label}</span>
                      </label>
                    )
                  );
                })}
              </form>
            ) : (
              ""
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => proceed(false)}>
            {declineMessage}
          </Button>
          {type === "warning" ? (
            <Button
              variant="danger"
              onClick={() => proceed(form ? formData : true)}
            >
              {confirmMessage}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => proceed(form ? formData : true)}
            >
              {confirmMessage}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function DialogModal({
  title = "Delete Chat",
  description = "Are you sure you want to delete the chat with Charles Udah?",
  type = "default",
  confirmMessage = "Yes, delete",
  declineMessage = "Cancel",
  form = null,
}) {
  return createConfirmation(confirmable(Confirmation))({
    title,
    description,
    type,
    confirmMessage,
    declineMessage,
    form,
  });
}
