import { showToast } from "../../components/task-update/utils";

export const TOOGLE_RIGHT_SIDEBAR = "TOOGLE_RIGHT_SIDEBAR";
export const TOOGLE_LEFT_SIDE_BAR = "TOOGLE_LEFT_SIDE_BAR";
export const TOGGLE_PROFILE_INFO = "TOGGLE_PROFILE_INFO";
export const TOGGLE_ERP = "TOGGLE_ERP";
export const TOGGLE_INVITE_MODAL = "TOGGLE_INVITE_MODAL";
export const TOGGLE_TOAST_MESSAGE = "TOGGLE_TOAST_MESSAGE";
export const GET_COMPANY_PAGES = " GET_COMPANY_PAGES";

export function toggleRightSideBar(value) {
  return {
    type: TOOGLE_RIGHT_SIDEBAR,
    payload: value,
  };
}

export function toggleLeftSideBar(value) {
  return {
    type: TOOGLE_LEFT_SIDE_BAR,
    payload: value,
  };
}

export function toggleProfileInfo(value) {
  return {
    type: TOGGLE_PROFILE_INFO,
    payload: value,
  };
}

export function toggleErp(value) {
  return {
    type: TOGGLE_ERP,
    payload: value,
  };
}

export function toggleInviteModal(value) {
  return {
    type: TOGGLE_INVITE_MODAL,
    payload: value,
  };
}

export function toggleToastMessage(value) {
  showToast({
    message: {
      title: value,
    },
    type: "",
  });
  return {
    type: TOGGLE_TOAST_MESSAGE,
    payload: value,
  };
}
