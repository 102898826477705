import React, { useEffect, useState } from "react";

import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import BellOffOutlineIcon from "mdi-react/BellOffOutlineIcon";
import CheckCircleOutline from "mdi-react/CheckCircleOutlineIcon";
import CommentTextMultipleIcon from "mdi-react/CommentTextMultipleIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import TrashCanIcon from "mdi-react/TrashCanOutlineIcon";
import { Dropdown } from "react-bootstrap";
import { AddUserIcon, CompleteIcon, ImageRemoveOutlineIcon } from "./Icons";
import {
  allTaskMembers,
  cloudDriveBackend,
  CopyToClipboard,
  formatSlashedDate,
  isBaseGrey,
  setFileUrl,
} from "../utils";
import Avatar from "./Avatar";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import { socket } from "../../../sockets";
import {
  compareAsc,
  differenceInDays,
  differenceInHours,
  subDays,
} from "date-fns";
import PageLayoutHeaderIcon from "mdi-react/PageLayoutHeaderIcon";
import { truncate } from "lodash";

export default function TaskCard({
  task,
  handleDeleteTask,
  handleEditTask,
  setSelectedTask,
  setSelectedList,
  setShowTaskDetailsModal,
  showColorPicker,
  user,
  isProjectLeaderOrCreatedBy,
  showUsersList,
  setTaskEditStage,
  toggleTaskNotifications,
  moveTaskToList,
  list = [],
  setAsCover,
}) {
  const [usersList, setUsersList] = useState({});
  useEffect(() => {
    setUsersList({
      assignedTo: allTaskMembers(task),
    });
    return () => {};
  }, [task]);

  const showTaskDetails = () => {
    setSelectedTask();
    setSelectedList();
    setShowTaskDetailsModal();
  };

  // const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
  // const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;

  /*const handleCardClick = (e) => {
    console.log(e.target.closest(".stage-card"));
  };*/

  const getTaskShareLink = () => {
    const taskId = task._id;
    socket.emit(
      "get-sharable-task-link",
      {
        taskId,
      },
      async (response) => {
        if (response && response.success) {
          const {
            sharable: { id },
          } = response;
          CopyToClipboard({
            text: `${process.env.REACT_APP_FRONTEND_URL}/task-invite/${id}/${task.project}/${taskId}`,
            successText: "Link copied",
            successDescription:
              "Anyone with the link can request to join this Task",
          });
        }
      }
    );
  };

  const resolveDueColor = (task) => {
    let dueDate = task.dueDate;
    let startDate = task.startDate;
    let hoursBetween = 0;
    let percentageToDue = 0;
    let hoursToDue = 0;

    if (!dueDate) return {};

    dueDate = dueDate.split("/");
    dueDate = new Date(dueDate[2], dueDate[1] - 1, dueDate[0]);
    // const twoDaysToDue = subDays(new Date(dueDate), 2);

    if (startDate) {
      startDate = startDate.split("/");
      startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);

      hoursBetween = differenceInHours(dueDate, startDate);
      hoursToDue = differenceInHours(dueDate, new Date());
      percentageToDue = (hoursToDue / hoursBetween) * 100;
      // console.log(percentageToDue);
    }

    // const daysBetween = (task.startDate && task.dueDate) ? differenceInDays()

    if (task.status === "COMPLETE") {
      return {
        background: "#40D886",
        color: "#fff",
        border: "none",
      };
    } else if ([0, -1].includes(compareAsc(new Date(dueDate), new Date()))) {
      // Task Due
      return {
        background: "#FC6D6C",
        color: "#fff",
        border: "none",
      };
    } else if (
      /*[0, -1].includes(compareAsc(new Date(twoDaysToDue), new Date())) */
      percentageToDue <= 30
    ) {
      // Task Close to due
      return {
        background: "#FACF61",
        color: "#fff",
        border: "none",
      };
    }

    return {};
  };

  return (
    <div
      key={task._id}
      className="stage-card"
      style={{ background: task.color }}
    >
      {task.cover && Object.keys(task.cover).length ? (
        <div className="stage-image p-cursor" onClick={() => showTaskDetails()}>
          <div className="w-100 h-100 overflow-hidden image-holder">
            <img src={setFileUrl(task.cover)} width="100%" alt="" />
          </div>
        </div>
      ) : null}

      <div className="stage-content">
        <div className="d-flex justify-content-between align-items-center">
          <span
            className="title p-cursor"
            title={
              task.taskName && task.taskName.length > 31 ? task.taskName : ""
            }
            onClick={() => showTaskDetails()}
          >
            {truncate(task.taskName, { length: 31 })}
          </span>

          <Dropdown
            alignRight
            /*style={
              isProjectLeaderOrCreatedBy() || task.createdBy === user._id
                ? {}
                : { visibility: "hidden" } 
            } */
          >
            <Dropdown.Toggle bsPrefix={`btn bg-white p-0 toggle-btn`}>
              <DotsHorizontalIcon
                className="text-lighter"
                style={{
                  background: task.color,
                  ...(isBaseGrey(task.color)
                    ? {
                        color: "#788898",
                      }
                    : {}),
                }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix={`task-menu`} alignRight>
              {isProjectLeaderOrCreatedBy() ? (
                <>
                  <Dropdown.Item as="button" onClick={() => getTaskShareLink()}>
                    <LinkVariantIcon /> Get Sharable Link
                  </Dropdown.Item>

                  <Dropdown.Item
                    as="button"
                    onClick={() => handleEditTask({ task: { ...task } })}
                  >
                    <PencilIcon /> Edit Card
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={(e) => {
                      showColorPicker({
                        e,
                        checkedColor: task.color,
                        type: "task",
                      });
                      setSelectedTask({ ...task });
                    }}
                  >
                    <PaletteIcon /> Card Colour
                  </Dropdown.Item>
                </>
              ) : null}
              <Dropdown.Item
                as="button"
                onClick={() => toggleTaskNotifications(task)}
              >
                {!task.enabled ? (
                  <>
                    <BellOutlineIcon /> Unmute Notifications
                  </>
                ) : (
                  <>
                    <BellOffOutlineIcon /> Mute Notifications
                  </>
                )}
              </Dropdown.Item>
              {isProjectLeaderOrCreatedBy() ? (
                <>
                  {task.cover && Object.keys(task.cover).length ? (
                    <Dropdown.Item
                      as="button"
                      onClick={(e) => setAsCover(null, task)}
                    >
                      <PageLayoutHeaderIcon /> Remove Cover
                    </Dropdown.Item>
                  ) : null}
                  <Dropdown.Item
                    as="button"
                    className="text-danger"
                    onClick={() => handleDeleteTask({ task })}
                  >
                    <TrashCanIcon className="text-danger" /> Delete Card
                  </Dropdown.Item>
                </>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {task.taskDetail && (
          <p
            className="m-0 task-detail w-100 p-cursor"
            onClick={() => showTaskDetails()}
          >
            {task.taskDetail && task.taskDetail.length > 100
              ? `${task.taskDetail.slice(0, 100)}...`
              : task.taskDetail}
          </p>
        )}
      </div>

      <div className="px-3">
        {/*<button className="btn btn-warning btn-date">
          <ClockOutlineIcon /> <span>10 Jun</span>
        </button>
          */}
        {task.dueDate && (
          <button
            className={`btn btn-date btn-outline-secondary`}
            style={resolveDueColor(task)}
            title={`Due date: ${formatSlashedDate(task.dueDate, true)}`}
          >
            {task.status === "COMPLETE" ? (
              <CompleteIcon />
            ) : (
              <ClockOutlineIcon />
            )}{" "}
            <span>{formatSlashedDate(task.dueDate)}</span>
          </button>
        )}
      </div>

      <div className="px-3 py-3 d-flex justify-content-between align-items-center">
        <div className="members assigned-to">
          <div className="d-flex">
            <>
              {allTaskMembers(task, {
                includeInvited: true,
              }) &&
                allTaskMembers(task, {
                  includeInvited: true,
                })
                  .slice(0, 3)
                  .map((user, index) => (
                    <React.Fragment key={index}>
                      <Avatar
                        key={index}
                        width={32}
                        height={32}
                        user={user}
                        zIndex={10 - index}
                      />
                    </React.Fragment>
                  ))}
            </>

            <>
              {allTaskMembers(task, {
                includeInvited: true,
              }) &&
              allTaskMembers(task, {
                includeInvited: true,
              }).length > 3 ? (
                <Avatar
                  width={32}
                  height={32}
                  isCounter={true}
                  usersList={allTaskMembers(task, {
                    includeInvited: true,
                    separate: true,
                  })}
                  count={
                    allTaskMembers(task, {
                      includeInvited: true,
                    }).length - 3
                  }
                  showUsersList={showUsersList}
                  zIndex={1}
                />
              ) : null}
            </>
            {isProjectLeaderOrCreatedBy() || task.createdBy === user._id ? (
              <AddUserIcon
                className="add-user p-cursor"
                onClick={() => {
                  setTaskEditStage(2);
                  handleEditTask({ task: { ...task } });
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="filler" onClick={() => showTaskDetails()}></div>
        <div className="actions d-flex align-items-center">
          {task.unreadCount ? (
            <button
              className="btn btn-danger mr-2 border-none"
              onClick={() => showTaskDetails()}
            >
              <BellOutlineIcon /> <span>{task?.unreadCount}</span>
            </button>
          ) : null}

          {
            <Dropdown
              alignRight
              style={
                isProjectLeaderOrCreatedBy() ||
                task.assignedTo.includes(user._id) ||
                task.createdBy === user._id ||
                task.assignedTo.find((userEl) => userEl._id === user._id)
                  ? {}
                  : { visibility: "hidden" }
              }
            >
              <Dropdown.Toggle
                bsPrefix={`btn p-0 toggle-btn`}
                style={{ background: "none", border: "none" }}
              >
                <span
                  className="mr-2 text-lighter p-cursor"
                  title="Move Task"
                  style={
                    isBaseGrey(task.color)
                      ? {
                          color: "#788898",
                        }
                      : {}
                  }
                >
                  <CheckCircleOutline />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                bsPrefix={`task-menu bg-white move-menu`}
                alignRight
              >
                <p className="p-2 px-3 m-0 font-weight-bold">Move to...</p>
                {list.map((listItem, index) => (
                  <React.Fragment key={index}>
                    {listItem.status !== task.status && (
                      <Dropdown.Item
                        key={listItem._id}
                        as="button"
                        style={{ color: "#788898" }}
                        onClick={() =>
                          moveTaskToList({
                            taskToMove: { ...task, status: listItem.status },
                            oldStatus: task.status,
                            newStatus: listItem.status,
                            saveToServer: true,
                          })
                        }
                      >
                        {listItem.title}
                      </Dropdown.Item>
                    )}
                  </React.Fragment>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          }

          <span
            className="text-lighter p-cursor"
            style={
              isBaseGrey(task.color)
                ? {
                    color: "#788898",
                  }
                : {}
            }
            onClick={() => showTaskDetails()}
          >
            <CommentTextMultipleIcon />{" "}
            {task.commentsCount && task.commentsCount !== 0
              ? task.commentsCount
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}
