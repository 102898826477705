import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

import { populateUser } from "../../store/actions/userActions";
import { signIn } from "../../services/authService";
import { URLS } from "../urls";
import { verifyToken } from "../../auth/authHelper";

export class Login extends Component {
  state = {
    user: {
      email: "",
      password: "",
    },
  };

  updateUserEmail = (event) => {
    const { user } = this.state;
    user.email = event.target.value;
    this.setState({
      user,
    });
  };

  updateUserPassword = (event) => {
    const { user } = this.state;
    user.password = event.target.value;
    this.setState({
      user,
    });
  };

  showErrors = (error) => {
    // make object, key value pair
    if (error.code === "auth/invalid-email") {
      this.setState({ emailError: "Email address is badly formatted" });
    } else if (error.code === "auth/user-not-found") {
      this.setState({ emailError: "Email address not found" });
    } else if (error.code === "auth/wrong-password") {
      this.setState({ passError: "Incorrect password" });
    } else {
      console.log("HANDLE ERROR CODE", error);
    }
  };

  signIn = async (e) => {
    e && e.preventDefault && e.preventDefault();
    const { user } = this.state;

    this.setState({ buttonText: "Signing in...", isWaiting: true });
    await signIn(user)
      .then((response) => {
        if (response.success) {
          if (response.user && Object.entries(response.user).length) {
            const user = response.user;
            const token = user.token;
            const cookies = new Cookies();
            cookies.set("token", token);
            this.props.populateUser(user, true);
          }
          this.props.history.push(URLS.reports);
        } else {
          this.showErrors(response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signInTemp = () => {
    this.props.history.push(URLS.dashboard);
  };

  removeEmailError = () => this.setState({ emailError: "" });

  removePassError = (text) =>
    this.setState({ passError: "", password: text.target.value });

  showPass = () => this.setState({ showPass: true });

  hidePass = () => this.setState({ showPass: false });

  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.signIn();
    }
  };

  render() {
    const { user } = this.state;
    const token = this.props.token;

    const isValidToken = verifyToken(token);

    if (isValidToken) {
      return <Redirect to="/reports" />;
    }

    return (
      <div className="account-box">
        <div className="account-wrapper">
          <h3 className="account-title">Login</h3>
          <form onSubmit={(e) => this.signIn(e)}>
            <div className="form-group">
              <label>Email Address</label>
              <input
                className="form-control"
                type="text"
                value={user.email}
                onChange={this.updateUserEmail}
                style={{ width: "100%", height: "35px" }}
                required
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>Password</label>
                </div>
              </div>
              <input
                className="form-control"
                type="password"
                value={user.password}
                onChange={this.updateUserPassword}
                style={{ width: "100%", height: "35px" }}
                required
              />
            </div>
            <div className="form-group text-center">
              <button className="btn btn-primary account-btn" type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    populateUser: (user, shouldUpdateToken) => {
      dispatch(populateUser(user, shouldUpdateToken));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
