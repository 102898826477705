import AtIcon from "mdi-react/AtIcon";
import EmoticonHappyOutlineIcon from "mdi-react/EmoticonHappyOutlineIcon";
import React, { useRef, useEffect } from "react";
import { Overlay, Popover } from "react-bootstrap";
import EmojiPicker from "./EmojiPicker";
import Mention from "./Mention";

const EditCommentForm = React.memo(
  ({
    editCommentMode,
    resizeInput,
    handleOnchange,
    saveTheEditedComment,
    setEditCommentMode,
    editedComment,
    showUsersList,
    setShowUsersList,
    showEmoji,
    selectEmoji,
    setShowEmoji,
    projectUsersList,
    handleSelectUser,
    resizeInputRef,
    focusRef,
  }) => {
    let editCommentRef = useRef(null);
    const emojiTarget = useRef(null);
    const mentionTarget = useRef(null);

    useEffect(() => {
      if (editCommentRef?.current) {
        setTimeout(() => {
          resizeInputRef(editCommentRef.current);
          focusRef(editCommentRef.current);
        }, 0);
      }
    }, [editCommentRef]);

    return (
      <div
        className={`edit-box mt-1 position-relative ${
          editCommentMode ? "d-block" : "d-none"
        }`}
      >
        <textarea
          name="editedComment"
          ref={editCommentRef}
          className="border p-2 text-dark rounded"
          value={editedComment}
          style={{ fontSize: "0.875rem" }}
          onChange={(e) => {
            handleOnchange({
              e,
              textareaRef: editCommentRef,
              usage: "comment",
            });
          }}
          onInput={(e) => resizeInput(e)}
          onFocus={(e) => resizeInput(e)}
          spellCheck={false}
          autoFocus
        ></textarea>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              className="btn btn-sm btn-primary mr-2 px-3"
              onClick={() => {
                saveTheEditedComment();
              }}
            >
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setEditCommentMode(false);
              }}
            >
              ✖
            </button>
          </div>

          <div className="d-flex utils">
            <button
              ref={mentionTarget}
              className="btn p-0 mr-2 p-cursor"
              onClick={() => {
                setShowUsersList(!showUsersList);
                setShowEmoji(false);
              }}
            >
              <AtIcon />
            </button>

            <button
              ref={emojiTarget}
              className="btn p-0 mr-2 p-cursor"
              onClick={() => {
                setShowEmoji(!showEmoji);
                setShowUsersList(false);
              }}
            >
              <EmoticonHappyOutlineIcon />
            </button>
          </div>
        </div>
        <>
          <Overlay
            target={mentionTarget.current}
            show={showUsersList}
            // placement="top"
            rootClose={true}
            onHide={() => setShowUsersList(false)}
          >
            <Popover className="border-0">
              <Mention
                hideTitle={true}
                hideSearchInput={true}
                // key={projectUsersList}
                setShowUsersList={setShowUsersList}
                handleSelectUser={(user) =>
                  handleSelectUser({
                    user,
                    textareaRef: editCommentRef,
                    usage: "comment",
                  })
                }
                usersList={projectUsersList}
                inputFocus={false}
                style={{
                  /*  position: "absolute",
            top: "1rem",
            right: 0,
            left: "-1rem",
            marginBottom: "0.5rem", */

                  position: "relative",
                  maxWidth: "80vw",
                  // display: `${!showUsersList ? "none" : "block"}`,
                }}
                listStyle={{ maxHeight: "10rem", background: "#ffffff" }}
              />
            </Popover>
          </Overlay>

          <Overlay
            target={emojiTarget.current}
            show={showEmoji}
            placement="top"
            rootClose={true}
            onHide={() => setShowEmoji(false)}
          >
            <Popover className="border-0">
              <EmojiPicker
                style={
                  {
                    /*   position: "absolute",
                top: "1rem",
                right: 0,
                left: "-1rem",
                marginBottom: "0.5rem",
                display: `${!showEmoji ? "none" : "block"}`, */
                  }
                }
                setShowEmoji={setShowEmoji}
                selectEmoji={(emoji) =>
                  selectEmoji({
                    emoji,
                    usage: "comment",
                    textareaRef: editCommentRef,
                  })
                }
              />
            </Popover>
          </Overlay>
        </>
      </div>
    );
  }
);

export default EditCommentForm;
