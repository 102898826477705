/* eslint-disable no-loop-func */
import { showToast } from "../../components/task-update/utils";
import uploadClient from "../../helper/uploadClient";
import { updateFileName } from "../../services/userServices";
import { socket } from "../../sockets";
import { setCommentFilesUploaded } from "./commentActions";

export const uploadFileTypes = {
  SET_UPLOAD_FILE: "SET_UPLOAD_FILE",
  SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
  SUCCESS_UPLOAD_FILE: "SUCCESS_UPLOAD_FILE",
  FAILURE_UPLOAD_FILE: "FAILURE_UPLOAD_FILE",
  RETRY_UPLOAD_FILE: "RETRY_UPLOAD_FILE",
  CLOSE_UPLOAD_FILE: "CLOSE_UPLOAD_FILE",
  SET_FILE_STAGE: "SET_FILE_STAGE",
  SET_CLOUD_ID: "SET_CLOUD_ID",
  SET_FILE_DATA: "SET_FILE_DATA",
  SET_FILE_SHOW: "SET_FILE_SHOW",
  CANCEL_UPLOAD_FILE: "CANCEL_UPLOAD_FILE",
  DELETE_UPLOADED_FILE: "DELETE_UPLOADED_FILE",
};

export const setUploadFile = (data) => ({
  type: uploadFileTypes.SET_UPLOAD_FILE,
  payload: data,
});

export const setUploadProgress = (id, progress) => ({
  type: uploadFileTypes.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
});

export const successUploadFile = (id, cloud_id) => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  payload: {
    id,
    cloud_id,
  },
});

export const setFileStage = (id, stage) => ({
  type: uploadFileTypes.SET_FILE_STAGE,
  payload: {
    id,
    stage,
  },
});

export const retryUploadFile = (id) => ({
  type: uploadFileTypes.RETRY_UPLOAD_FILE,
  payload: id,
});

export const cancelUploadFile = (id) => ({
  type: uploadFileTypes.CANCEL_UPLOAD_FILE,
  payload: id,
});

export const deleteUploadFile = (id) => ({
  type: uploadFileTypes.DELETE_UPLOADED_FILE,
  payload: id,
});

export const failureUploadFile = (id) => ({
  type: uploadFileTypes.FAILURE_UPLOAD_FILE,
  payload: id,
});

export const closeUploadFile = (id) => ({
  type: uploadFileTypes.CLOSE_UPLOAD_FILE,
  payload: id,
});

export const setCloudId = (id, cloud_id) => ({
  type: uploadFileTypes.SET_CLOUD_ID,
  payload: {
    id,
    cloud_id,
  },
});

export const setFileData = (id, fileData) => ({
  type: uploadFileTypes.SET_FILE_DATA,
  payload: {
    id,
    fileData,
  },
});

export const showFile = (id) => ({
  type: uploadFileTypes.SET_FILE_SHOW,
  payload: id,
});

/*
export const saveFileToCollaboration = ({ fileToUpload, cloud_id }) => {
  return new Promise((resolve, reject) => {
    if (socket.connected) {
      socket.emit(
        "fx:add-project-file",
        {
          originalName: fileToUpload.originalName,
          uniqueName: fileToUpload.uniqueName,
          extension: fileToUpload.extension,
          size: fileToUpload.size,
          projectId: fileToUpload.projectId,
          cloud_id,
        },
        async (response) => {
          if (response.success) {
            resolve(response);
          } else {
            reject();
          }
        }
      );
    }
  });
};
*/

const saveFileToCollaboration = ({ fileToUpload, cloud_id, usage }) => {
  return new Promise((resolve, reject) => {
    if (socket.connected) {
      let namespace;

      if (usage === "comment") {
        namespace = "fx:add-comment-file";
      } else if (usage === "comment-reply") {
        namespace = "fx:add-comment-reply-file";
        fileToUpload.commentId = fileToUpload.replyId;
      } else {
        namespace = "fx:add-project-file";
      }

      console.log("emit", namespace);

      socket.emit(
        namespace,
        {
          ...fileToUpload,
          file: null,
          cloud_id,
        },
        async (response) => {
          console.log("saved", response);
          if (response.success) {
            resolve(response);
          }
        }
      );
    } else {
      alert("offline");
      reject();
    }
  });
};

export const uploadFileo = (files) => async (dispatch) => {
  // dispatch(setUploadProgress(file.id, percentageProgress))
  // console.log("upload", files);
  for (const property in files) {
    const fileToUpload = files[property];
    /*if (fileToUpload.stage === 1) {
      console.log("stage 1");
      const body = {
        name: fileToUpload.file.name,
        size: fileToUpload.file.size,
        current_directory_id:
          process.env.REACT_APP_ENV === "production"
            ? fileToUpload.folder_id
            : "5f351216c56f413004f6d9fe",
        id: fileToUpload.cloud_id,
        type: fileToUpload.file.type,
        token: fileToUpload.token,
      };
      updateFileName(body)
        .then(async (cloudRes) => {
          if (cloudRes.data) {
            dispatch(setFileStage(fileToUpload.id, 2));
            saveFileToCollaboration({
              fileToUpload,
              cloud_id: fileToUpload.cloud_id,
            })
              .then((saved) => {
                if (saved.success) {
                  dispatch(setFileStage(fileToUpload.id, 3));
                  dispatch(
                    successUploadFile(fileToUpload.id, fileToUpload.cloud_id)
                  );
                } else {
                  console.log("save to collab failed, cloud");
                  dispatch(failureUploadFile(fileToUpload.id));
                }
              })
              .catch((err) => {
                console.log("save to collab failed, cloud");
                dispatch(failureUploadFile(fileToUpload.id));
              });
          } else {
            console.log("save to cloud failed, cloud, retry, res");
            dispatch(failureUploadFile(fileToUpload.id));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(failureUploadFile(fileToUpload.id));
          console.log("save to cloud failed, cloud, retry");
        });
    } else if (fileToUpload.stage === 2) {
      console.log("stage 2");
      saveFileToCollaboration({
        fileToUpload,
        cloud_id: fileToUpload.cloud_id,
      })
        .then((saved) => {
          if (saved.success) {
            dispatch(setFileStage(fileToUpload.id, 3));
            dispatch(successUploadFile(fileToUpload.id, fileToUpload.cloud_id));
          } else {
            dispatch(failureUploadFile(fileToUpload.id));
          }
        })
        .catch((err) => {
          dispatch(failureUploadFile(fileToUpload.id));
        });
    } else if (typeof fileToUpload.stage === "undefined") {
      */

    // just upload
    // console.log("just upload");
    await uploadClient
      .upload(fileToUpload.file, {
        folder_id:
          process.env.REACT_APP_ENV === "production"
            ? fileToUpload.folder_id
            : "5f351216c56f413004f6d9fe",
        id: fileToUpload.id,
      })
      // eslint-disable-next-line no-loop-func
      .then(async (res) => {
        if (res.cloud_id) {
          console.log(res);
          dispatch(setFileStage(fileToUpload.id, 1));
          dispatch(setCloudId(fileToUpload.id, res.cloud_id));

          /*const body = {
            name: fileToUpload.file.name,
            size: fileToUpload.file.size,
            current_directory_id:
              process.env.REACT_APP_ENV === "production"
                ? fileToUpload.folder_id
                : "5f351216c56f413004f6d9fe",
            id: res.cloud_id,
            type: fileToUpload.file.type,
            token: fileToUpload.token,
          };
          */

          fileToUpload.cloud_id = res.cloud_id;
          await saveFileToCollaboration({
            fileToUpload,
            cloud_id: fileToUpload.cloud_id,
            usage: fileToUpload.usage,
          })
            .then((saved) => {
              if (saved.success) {
                // dispatch(setFileStage(fileToUpload.id, 3));
                dispatch(
                  successUploadFile(fileToUpload.id, fileToUpload.cloud_id)
                );
              } else {
                console.log("save to collab failed, cloud init");
                dispatch(failureUploadFile(fileToUpload.id));
              }
            })
            .catch((err) => {
              console.log("save to collab failed, collab init");
              dispatch(failureUploadFile(fileToUpload.id));
            });
          /*
          updateFileName(body)
            .then(async (cloudRes) => {
              if (cloudRes.data) {
                dispatch(setFileStage(fileToUpload.id, 2));
                saveFileToCollaboration({
                  fileToUpload,
                  cloud_id: fileToUpload.cloud_id,
                })
                  .then((saved) => {
                    if (saved.success) {
                      dispatch(setFileStage(fileToUpload.id, 3));
                      dispatch(
                        successUploadFile(
                          fileToUpload.id,
                          fileToUpload.cloud_id
                        )
                      );
                    } else {
                      console.log("save to collab failed, cloud init");
                      dispatch(failureUploadFile(fileToUpload.id));
                    }
                  })
                  .catch((err) => {
                    console.log("save to collab failed, collab init");
                    dispatch(failureUploadFile(fileToUpload.id));
                  });
              } else {
                console.log("save to cloud failed, cloud, init res", cloudRes);
                dispatch(failureUploadFile(fileToUpload.id));
              }
            })
            .catch((err) => {
              console.log("save to cloud failed, cloud, init");
              dispatch(failureUploadFile(fileToUpload.id));
            });
            */
        }
      })
      .catch((err) => {
        console.log(err);
        const uploadError = uploadClient.getError();
        if (uploadError && uploadError.limited_space) {
          showToast({
            message: {
              title: "Cloud Space exhausted",
            },
            type: "error",
          });
        }
        showToast({
          message: {
            title: "Upload failed",
          },
          type: "error",
        });
        console.log(uploadError);
        console.log("Upload client error:", err);
        dispatch(failureUploadFile(fileToUpload.id));
      });
    //}
  }
};

// return tasks.reduce((p, task) => p.then(task), Promise.resolve())

// stage 1 - app, stage 2 - cloud, stage 3 - collab
export const uploadFile = (files) => (dispatch) => {
  const isDev = process.env.REACT_APP_ENV !== "production";
  const uploadFunc = (fileToUpload) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("fileToUpload", fileToUpload);
        console.log("isDev", isDev);

        let folder_id = !isDev
          ? fileToUpload.folder_id
          : "5f351216c56f413004f6d9fe";

        console.log("folder_id", folder_id);

        const res = [1, 2, 3].includes(fileToUpload.stage)
          ? {
              ...fileToUpload,
            }
          : await uploadClient.upload(fileToUpload.file, {
              folder_id,
              id: fileToUpload.id,
            });

        if (res.cloud_id) {
          if (![1, 2, 3].includes(fileToUpload.stage)) {
            dispatch(setFileStage(fileToUpload.id, 1));
            dispatch(setCloudId(fileToUpload.id, res.cloud_id));
            fileToUpload.cloud_id = res.cloud_id;
          }

          // TODO - save body for retry
          const body = {
            name: fileToUpload.file?.name,
            size: fileToUpload.file?.size,
            current_directory_id: folder_id,
            id: fileToUpload.cloud_id,
            type: fileToUpload.file?.type,
            token: fileToUpload.token,
          };

          const cloudRes =
            [2, 3].includes(fileToUpload.stage) || isDev
              ? { ...fileToUpload, data: true }
              : await updateFileName(body);

          if (cloudRes.data) {
            if (![2, 3].includes(fileToUpload.stage)) {
              dispatch(setFileStage(fileToUpload.id, 2));
            }

            const saved = [3].includes(fileToUpload.stage)
              ? {
                  ...fileToUpload,
                  success: true,
                }
              : {
                  ...fileToUpload,
                  success: true,
                };
            if (saved.success) {
              // if comment push file to parent comment

              dispatch(setFileStage(fileToUpload.id, 3));
              dispatch(
                successUploadFile(fileToUpload.id, fileToUpload.cloud_id)
              );

              // save in comment
              if (["comment", "comment-reply"].includes(fileToUpload.usage)) {
                console.log("save in comment", fileToUpload);
                dispatch(
                  setCommentFilesUploaded(
                    fileToUpload.tempCommentId
                      ? fileToUpload.tempCommentId
                      : fileToUpload.tempCommentReplyId,
                    fileToUpload
                  )
                );
              }
            } else {
              throw new Error("upload failed, collab");
            }
          } else {
            throw new Error("upload failed, cloud");
          }
        } else {
          throw new Error("upload failed, client");
        }

        resolve();
      } catch (err) {
        const uploadError = uploadClient.getError();
        if (uploadError && uploadError.limited_space) {
          showToast({
            message: {
              title: "Cloud Space exhausted",
            },
            type: "error",
          });
        }
        if (err === "Upload was canceled") {
          return;
        }
        showToast({
          message: {
            title: "Upload failed",
          },
          type: "error",
        });
        console.log(uploadError);
        console.log("Upload client error:", err);
        dispatch(failureUploadFile(fileToUpload.id));
        reject();
      }
    });
  };

  if (files?.length) {
    files.reduce((p, file) => {
      return p.then(() => uploadFunc(file));
    }, Promise.resolve());
  }
};
