import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../global/Spinner";
import CloseIcon from "mdi-react/CloseIcon";
import CheckIcon from "mdi-react/CheckIcon";
import { URLS } from "../urls";
import "../../assets/scss/global.scss";
import "../../assets/scss/assignTask.scss";
import { updateProject } from "../../services/projectServices";
import { searchUserByEmail } from "../../services/userServices";
import { debounce } from "../../utils";

class AddLeaderModal extends React.Component {
  constructor(props) {
    super(props);
    const team = props.team ? props.team : [];
    const projectTeam = props.project ? props.project.team : [];
    const projectLeader = props.project ? props.project.projectLeader : [];
    const { account_type } = props.user;

    this.state = {
      team,
      teamClone: team,
      selectedMembers: [],
      projectTeam,
      projectLeader,
      account_type,
      isCreating: false,
      membersToRemove: [],
    };
  }

  handleAssign = () => {
    try {
      this.setState({ isCreating: true });
      const { projectLeader, projectTeam } = this.state;
      const { token, project } = this.props;

      project.team = projectTeam;
      project.projectLeader = projectLeader;

      updateProject(token, project).then((response) => {
        if (response.success) {
          this.props.updateProjectsList(project);
        }
      });
      this.props.closeModal();
    } catch (e) {
      // console.error(e);
    }
  };

  handleSwitch = (e) => {
    const showOnlySelected = e.target.checked;
    let { team, teamClone, projectLeader } = this.state;

    if (showOnlySelected) {
      team = projectLeader;
    } else {
      team = teamClone;
    }
    this.setState({ team });
  };

  handleCloseModal = (e, wrapper) => {
    if (wrapper) {
      if (e.target !== e.currentTarget) return;
    }
    this.props.closeModal();
  };

  setEmployeeName = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name;
  };

  setEmployeeInitials = (employee) => {
    let name;
    if (employee.name && employee.name.first) {
      name = employee.name.first + " " + employee.name.last;
    } else {
      name = employee.name;
    }
    return name.charAt(0);
  };

  handleSelectedMember = (member) => {
    let {
      selectedMembers,
      projectLeader,
      projectTeam,
      membersToRemove,
    } = this.state;
    const isChecked = this.isTeamLeader(member) ? true : false;
    const memberId = member._id;

    if (!isChecked) {
      selectedMembers.push(memberId);
      projectLeader.push(member);
      projectTeam = projectTeam.filter((member) => member._id !== memberId);
      membersToRemove.filter((member) => member._id !== memberId);
    } else {
      selectedMembers = selectedMembers.filter(
        (selectedMember) => selectedMember !== memberId
      );
      projectLeader = projectLeader.filter((member) => member._id !== memberId);
      projectTeam = projectTeam.filter((member) => member._id !== memberId);
      membersToRemove.push(memberId);
    }

    this.setState({
      selectedMembers,
      projectLeader,
      projectTeam,
      membersToRemove,
    });
  };

  searchPersonalAccount = (searchParams) => {
    const token = this.props.token;
    let { allMembersClone } = this.state;

    if (searchParams) {
      searchUserByEmail(searchParams, token).then((response) => {
        if (response.users) {
          let usersToInvite = response.users;

          usersToInvite = usersToInvite.filter(
            (userToInvite) =>
              allMembersClone.findIndex(
                (currentMember) => currentMember.email === userToInvite.email
              ) < 0
          );

          this.setState({ allMembers: usersToInvite });
        }
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value.trim();
    let { team, teamClone, account_type } = this.state;

    if (account_type === 1) {
    //   if (searchParams) {
    //     debounce(this.searchPersonalAccount(searchParams), 500);
    //   } else {
    //     allMembers = allMembersClone;
    //     this.setState({ allMembers, allMembersClone });
    //   }
    //   this.setState({ allMembers, allMembersClone });
    } else if (account_type === 2) {
      if (searchParams) {
        team = teamClone.filter((member) => {
          let name;
          if (member.name && member.name.first) {
            name = member.name.first + " " + member.name.last;
          } else {
            name = member.name;
          }

          if (name.toLowerCase().includes(searchParams)) {
            return member;
          }
        });
        this.setState({ team });
      } else {
        this.setState({ team: this.props.team });
      }
    }
  };

  isTeamMember = (member) => {
    let isFound;
    const { projectTeam } = this.state;

    const members = projectTeam ? projectTeam : [];

    if (member) {
      isFound = members
        ? members.find((leader) => leader._id === member._id)
        : false;
    }

    return isFound;
  };

  isTeamLeader = (member) => {
    let isFound;
    const { projectLeader } = this.state;

    const leaders = projectLeader ? projectLeader : [];

    if (member) {
      isFound = leaders
        ? leaders.find((leader) => leader._id === member._id)
        : false;
    }

    return isFound;
  };

  render() {
    const {
      team,
      account_type,
      selectedMembers,
      isCreating,
      membersToRemove,
    } = this.state;

    return (
      <div
        id="assign_leader"
        className="modal custom-modal fade project-view-more-options"
        role="dialog"
        onClick={(e) => this.handleCloseModal(e, "wrapper")}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-assign-task"
          role="document"
        >
          <div className="modal-content">
            <div className="m-h-between">
              <h5 className="modal-title">Add Project Leaders</h5>
              <CloseIcon onClick={(e) => this.handleCloseModal(e)} />
            </div>
            {!isCreating ? (
              <div className="assign-task-content">
                <div className="input-group my-4">
                  <input
                    placeholder="Search to add"
                    className="form-control search-input spec-input mx-4"
                    type="text"
                    onChange={(e) => this.handleSearch(e)}
                  />
                </div>

                <div className="users-list sleek-scrollbar">
                  {team && team.length ? (
                    team.map((member, index) => (
                      <div
                        className="user-info"
                        key={index}
                        onClick={() => this.handleSelectedMember(member)}
                      >
                        {member.photo ? (
                          <img
                            className="user-desc"
                            src={URLS.backendStatic + "/" + member.photo}
                          />
                        ) : (
                          <div className="user-desc user-init">
                            {this.setEmployeeInitials(member)}
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "5px",
                          }}
                        >
                          <span>{this.setEmployeeName(member)}</span>
                          <span style={{ fontSize: "13px", color: "#888" }}>
                            {member.email}
                          </span>
                        </div>
                        {this.isTeamMember(member) ? (
                          <span className="unchecked-box">
                            <CheckIcon className="check-mark-icon" />
                          </span>
                        ) : (
                          <input
                            type="checkbox"
                            checked={this.isTeamLeader(member) ? true : false}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="none-found">None found</div>
                  )}
                </div>

                <div className="assign-task-layout">
                  <div className="assign-task-dv-switch">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleSwitch(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="sh-only">Show only selected</span>
                  </div>
                  <span
                    className={`assign-task-btn ${
                      !selectedMembers.length && !membersToRemove.length
                        ? "in-active-assign"
                        : ""
                    }`}
                    onClick={this.handleAssign}
                  >
                    Assign
                  </span>
                </div>
              </div>
            ) : (
              <div className="spinner-layout">
                <div className="d-flex flex-column align-items-center">
                  <Spinner spinnerHeight="50px" />
                  <span style={{ marginTop: "20px" }}>
                    Adding Project Leaders....
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, null)(withRouter(AddLeaderModal));
