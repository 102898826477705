import CloseIcon from "mdi-react/CloseIcon";
import React, { useState, createRef, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger } from "react-bootstrap";
import Datepicker from "react-datetime";
import "../../../assets/scss/projectModal.scss";
import { Progress } from "react-sweet-progress";
import { debounce, validateEmail } from "../../../utils/index";
import "react-sweet-progress/lib/style.css";
import { connect } from "react-redux";
import {
  CheckboxIcon,
  CheckmarkIcon,
  UncheckedboxIcon,
  CalendarIcon,
  ChevronDownIcon,
  RadioCheckOnIcon,
  RadioCheckOffIcon,
  CheckmarkGreyIcon,
  PdfIcon,
  SearchIcon,
} from "./../components/Icons";
import moment from "moment";
import {
  GetFileIcon,
  truncateFileName,
  showToast,
  validationStartDate,
  getUniqueListBy,
} from "../utils";
import {
  updateFileName,
  searchUserByEmail,
} from "../../../services/userServices";
import {
  addProject,
  getProjectById,
  updateProject,
} from "../../../services/projectServices";
import uploadClient from "../../../helper/uploadClient";
import Avatar from "../components/Avatar";
import { cloneDeep } from "lodash-es";
import Spinner from "./../../global/Spinner";
import { compareAsc } from "date-fns";
import eventBus from "../../../helper/EventBus";

function ProjectModal({
  showProjectModal,
  setShowProjectModal,
  clientList,
  teamsICreated,
  employeesList,
  user: { account_type, _id, company_id, email: userEmail },
  token,
  handleProjectCreated,
  selectedProject,
  mode,
  projectUpdated,
  projectEditStage,
  setProjectEditStage,
  history,
}) {
  let selectTeamRef = createRef();
  let projectFiles = createRef();
  const [isLoadingLatest, setIsLoadingLatest] = useState(false);
  const [creationStage, setCreationStage] = useState(1);
  const [project, setProject] = useState({
    name: "",
    client: null,
    startDate: moment(new Date()).format("DD/MM/YYYY"),
    endDate: "",
    cost: 0,
    duration: 0,
    durationType: "",
    priority: "High",
    projectLeader: [],
    team: [],
    projectLeaderEmails: [],
    teamEmails: [],
    description: "",
    files: [],
    teamId: null,
    status: "Created",
    isPublic: false,
    createChannel: false,
    currency: ".",
    amount: "",
  });
  const [searchParams, setSearchParams] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    teamTemplates: [],
    employeesList: [],
    selectedTeam: "Select Team",
    status: ["Created", "Working", "Completed"],
    priority: ["Low", "Meduim", "High"],
    createProjectFolder: true,
    projectFilesDataList: [],
    ogEmployeesList: [],
    emailsToInvite: [],
    membersToJoin: [],
  });

  useEffect(() => {
    if (setProjectEditStage) setProjectEditStage(creationStage);

    return () => {
      if (setProjectEditStage) setProjectEditStage(1);
    };
  }, [creationStage, setProjectEditStage]);

  useEffect(() => {
    getLatestProject();
  }, []);

  const getLatestProject = async () => {
    if (mode !== "edit") return;
    let canUpdate = true;
    try {
      setIsLoadingLatest(true);
      // get fresh project Data
      const response = await getProjectById(token, selectedProject._id);

      if (response.project) {
        try {
          let comparison = compareAsc(
            new Date(selectedProject.updatedAt),
            new Date(response.project.updatedAt)
          );
          canUpdate = comparison !== 0;
        } catch (err) {
          console.log(err);
          canUpdate = true;
        }

        if (canUpdate) {
          response.isProjectModalOpen = true;
          projectUpdated(response);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log(canUpdate);
      setIsLoadingLatest(false);
    }
  };

  useEffect(() => {
    setUp({ selectedProject, mode, teamsICreated, employeesList });
    return () => {};
  }, [selectedProject, mode, teamsICreated, employeesList]);

  const setUp = async ({
    selectedProject,
    mode,
    teamsICreated,
    employeesList,
  }) => {
    if (mode === "edit") {
      setCreationStage(projectEditStage);
      const cost = selectedProject.cost;
      const currency = cost ? cost.substr(0, 1) : "";
      const amount = cost ? cost.substr(1, cost.length) : "";

      // -----
      const membersToJoin = selectedProject.membersToJoin
        .filter((el) => el.userId)
        .map((user) => {
          return {
            ...user,
            ...user?.userId,
            userId: user?.userId?._id,
          };
        });

      // console.log(membersToJoin);

      const leadersToJoin = membersToJoin.filter(
        (user) => user.purpose === "projectLeader"
      );
      const teamToJoin = membersToJoin.filter(
        (user) => user.purpose === "team"
      );

      const members = cloneDeep([
        ...selectedProject?.projectLeader,
        ...selectedProject?.team,
        ...leadersToJoin,
        ...teamToJoin,
      ]);

      setProject({
        ...selectedProject,
        client: selectedProject.client?._id,
        teamId: selectedProject.team?._id,
        projectLeader:
          selectedProject?.projectLeader || leadersToJoin
            ? [
                ...new Set([
                  ...selectedProject.projectLeader.map((user) => user._id),
                  ...leadersToJoin.map((user) => user._id),
                ]),
              ]
            : [],
        team:
          selectedProject?.team || membersToJoin
            ? [
                ...new Set([
                  ...selectedProject.team.map((user) => user._id),
                  ...membersToJoin.map((user) => user._id),
                ]),
              ]
            : [],
        currency,
        amount,
        teamEmails: selectedProject?.teamEmails ?? [],
        projectLeaderEmails: selectedProject?.projectLeaderEmails ?? [],
      });
      setData({
        ...data,
        selectedTeam: selectedProject.teamId,
        teamTemplates: teamsICreated,
        employeesList: getUniqueListBy([...employeesList, ...members], "_id"),
        ogEmployeesList: getUniqueListBy([...employeesList, ...members], "_id"),
        membersToJoin: getUniqueListBy(membersToJoin, "_id"),
        emailsToInvite: [
          ...(selectedProject?.teamEmails ?? []),
          ...(selectedProject?.projectLeaderEmails ?? []),
        ],
      });
    } else {
      setData({
        ...data,
        teamTemplates: teamsICreated,
        employeesList: getUniqueListBy([...employeesList], "_id"),
        ogEmployeesList: getUniqueListBy([...employeesList], "_id"),
      });

      //----------
      const searchParams = new URLSearchParams(window.location.search);
      const createProject = searchParams.get("createProject");

      if (createProject) {
        const name = searchParams.get("title");
        const description = searchParams.get("description");
        history.push({
          pathname: `/tasks-board`,
        });
        setProject((oldData) => ({ ...oldData, name, description }));
      }
    }
  };

  const handleCost = () => {
    return project.currency + project.amount;
  };

  const isInvited = (userId) => {
    return data.membersToJoin.find((user) => user._id === userId);
  };

  const updateTeamTemplateId = (event) => {
    const value = event.target.value;
    if (value !== "Select Team") {
      const { teamTemplates, ogEmployeesList } = data;
      project.teamId = value;

      const teamTemplate = teamTemplates.find(
        (tT) => tT._id === project.teamId
      );

      let teamMembers = teamTemplate.members ? teamTemplate.members : [];
      let teamLeaders = teamTemplate.leaders ? teamTemplate.leaders : [];

      let allMembers = [...teamLeaders, ...teamMembers, ...ogEmployeesList];
      let unique = {};
      allMembers = allMembers.filter((member) => {
        if (!unique[member._id]) {
          unique[member._id] = true;

          if (teamMembers.find((t) => t._id === member._id)) {
            member.isTeam = true;
          } else if (teamLeaders.find((t) => t._id === member._id)) {
            member.isLeader = true;
          }
          return true;
        } else {
          return false;
        }
      });

      setData({
        ...data,
        selectedTeam: value,
        employeesList: allMembers,
        ogEmployeesList: allMembers,
      });
    } else {
      setData({
        ...data,
        selectedTeam: value,
      });
    }
  };

  const handleDateAndDuration = (event, field) => {
    if (event) {
      let { durationType, duration, startDate, endDate } = project;

      if (field === "durationType" || field === "durationValue") {
        if (field === "durationType") {
          durationType = event.target ? event.target.value : event;
        } else if (field === "durationValue") {
          duration = event.target ? event.target.value : event;
        }

        if (
          Number(duration) &&
          durationType !== "" &&
          durationType !== "Select Unit"
        ) {
          if (durationType === "Hours") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "hours")
              .format("DD/MM/YYYY");
          } else if (durationType === "Days") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "days")
              .format("DD/MM/YYYY");
          } else if (durationType === "Months") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "months")
              .format("DD/MM/YYYY");
          } else if (durationType === "Years") {
            endDate = moment(startDate, "DD/MM/YYYY")
              .add(duration, "years")
              .format("DD/MM/YYYY");
          }
        }
      } else if (field === "startDate" || field === "endDate") {
        if (field === "startDate") {
          startDate = event.target
            ? event.target.value
            : moment(event).format("DD/MM/YYYY");
        }

        if (field === "endDate") {
          endDate = event.target
            ? event.target.value
            : moment(event).format("DD/MM/YYYY");
        }

        if (endDate !== "") {
          const diff = moment(endDate, "DD/MM/YYYY").diff(
            moment(startDate, "DD/MM/YYYY"),
            "hours"
          );

          if (diff <= 24) {
            durationType = "Hours";
            duration = Math.round(diff);
          } else if (diff > 24 && diff <= 720) {
            durationType = "Days";
            duration = Math.round(diff / 24);
          } else if (diff > 720 && diff <= 8760) {
            durationType = "Months";
            duration = Math.round(diff / 720);
          } else {
            durationType = "Years";
            duration = Math.round(diff / 8760);
          }
        }
      }

      setDateAndDuration(durationType, duration, startDate, endDate);
    }
  };

  const setDateAndDuration = (durationType, duration, startDate, endDate) => {
    project.durationType = durationType;
    project.duration = duration;
    project.startDate = startDate;
    project.endDate = endDate;

    setProject({ ...project });
  };

  const setProjectDate = (event, field) => {
    let date = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    project[field] = date;
    setProject({ ...project });
  };

  const updateProjectFile = (event) => {
    let { projectFilesDataList } = data;

    let fileInput = event.target;
    let files = fileInput.files;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const projectFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      projectFile.originalName = fileName;
      projectFile.uniqueName = currentDate;
      projectFile.extension = ext;
      if (size < 1024) {
        projectFile.size = size + " B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        projectFile.size = (size / 1024).toFixed(2) + " KB";
      } else {
        projectFile.size = (size / 1024 / 1024).toFixed(2) + " MB";
      }

      projectFile.file = file;
      project.files = project.files.concat(projectFile);
      projectFilesDataList = project.files.concat(projectFile);
      setProject({
        ...project,
      });
      setData({
        ...data,
        projectFilesDataList,
      });
      i++;
    }
  };

  const removeProjectFile = (idx) => {
    const { projectFilesDataList } = data;
    project.files.splice(idx, 1);
    projectFilesDataList.splice(idx, 1);

    setProject({
      ...project,
    });
    setData({
      ...data,
      projectFilesDataList,
    });
  };

  const addToTeam = (user) => {
    if (validateEmail(user)) {
      project.teamEmails = [...new Set([...project.teamEmails, user])];
      // addUserToEmployeesList(user);
      setProject({
        ...project,
        teamEmails: project.teamEmails,
      });
      return;
    }

    const userId = user._id;
    project.team = [...new Set([...project.team, userId])];
    addUserToEmployeesList(user);
    setProject({
      ...project,
      team: project.team,
    });
  };

  const removeFromTeam = (user) => {
    if (validateEmail(user)) {
      const teamEmails = project.teamEmails.filter((email) => email !== user);
      const projectLeaderEmails = project.projectLeaderEmails.filter(
        (email) => email !== user
      );
      // addUserToEmployeesList(user);
      setProject({
        ...project,
        teamEmails,
        projectLeaderEmails,
      });
      return;
    }

    const userId = user._id;
    const team = project.team.filter((user) => user !== userId);
    const projectLeader = project.projectLeader.filter(
      (user) => user !== userId
    );
    addUserToEmployeesList(user);
    setProject({
      ...project,
      team: team,
      projectLeader,
    });
  };

  const isLeaderOrMember = (userId) => {
    if (validateEmail(userId)) {
      return (
        project.teamEmails.includes(userId) ||
        project.projectLeaderEmails.includes(userId)
      );
    }
    return (
      project.team.includes(userId) || project.projectLeader.includes(userId)
    );
  };

  const isLeader = (userId) => {
    if (validateEmail(userId)) {
      return project.projectLeaderEmails.includes(userId);
    }
    return project.projectLeader.includes(userId);
  };

  const removeLeader = (user) => {
    if (validateEmail(user)) {
      const projectLeaderEmails = project.projectLeaderEmails.filter(
        (email) => email !== user
      );
      const teamEmails = [...new Set([...project.teamEmails, user])];
      // addUserToEmployeesList(user);
      setProject({
        ...project,
        projectLeaderEmails,
        teamEmails,
      });
      return;
    }
    const userId = user._id;
    const projectLeader = project.projectLeader.filter(
      (user) => user !== userId
    );
    const team = [...new Set([...project.team, userId])];
    addUserToEmployeesList(user);
    setProject({
      ...project,
      projectLeader,
      team,
    });
  };

  const setLeader = (user) => {
    if (validateEmail(user)) {
      const teamEmails = project.teamEmails.filter((email) => email !== user);
      const projectLeaderEmails = [
        ...new Set([...project.projectLeaderEmails, user]),
      ];
      // addUserToEmployeesList(user);

      setProject({
        ...project,
        projectLeaderEmails,
        teamEmails,
      });
      return;
    }

    const userId = user._id;
    const team = project.team.filter((user) => user !== userId);
    const projectLeader = [...new Set([...project.projectLeader, userId])];
    addUserToEmployeesList(user);

    setProject({
      ...project,
      projectLeader,
      team,
    });
  };

  const findUser = (userId, array) => {
    const found = array.findIndex((user) => user._id === userId);
    return found !== -1;
  };

  const addUserToEmployeesList = (user) => {
    const ogEmployeesList = !findUser(user._id, data.ogEmployeesList)
      ? [user, ...data.ogEmployeesList]
      : [...data.ogEmployeesList];
    setData({
      ...data,
      ogEmployeesList,
      membersToJoin: handleMembersToJoin(user),
    });
  };

  const selectAll = () => {
    let users = data.employeesList.filter(
      (user) => !isLeaderOrMember(user._id)
    );
    /*let usersToAdd = data.employeesList.filter(
      (user) => !findUser(user._id, data.ogEmployeesList)
    );
    */

    users = users.map((user) => user._id);
    const team = [...new Set([...project.team, ...users])];

    setData({
      ...data,
      ogEmployeesList: [...data.ogEmployeesList],
    });

    // Emails
    let emails = data.emailsToInvite.filter(
      (email) => !isLeaderOrMember(email)
    );
    const teamEmails = [...new Set([...project.teamEmails, ...emails])];
    setProject({
      ...project,
      team,
      teamEmails,
    });
  };

  const unSelectAll = () => {
    setProject({
      ...project,
      team: [],
      projectLeader: [],
      teamEmails: [],
      projectLeaderEmails: [],
    });
  };

  const FormProgress = () => {
    return (
      <div className="form-progress">
        <span>{creationStage} of 3</span>
        <div className="d-flex justify-content-between align-items center w-100">
          <div className="divider"></div>
          <CheckmarkIcon onClick={() => setCreationStage(1)} />
          {creationStage >= 2 ? (
            <CheckmarkIcon onClick={() => setCreationStage(2)} />
          ) : (
            <CheckmarkGreyIcon />
          )}
          {creationStage >= 3 ? (
            <CheckmarkIcon onClick={() => setCreationStage(3)} />
          ) : (
            <CheckmarkGreyIcon />
          )}
        </div>
      </div>
    );
  };

  const allUsersCheck = ({ employeesList, emailsToInvite }) => {
    const checkedUsers = employeesList.filter((user) =>
      isLeaderOrMember(user._id)
    );
    const checkedUsersEmail = emailsToInvite.filter((email) =>
      isLeaderOrMember(email)
    );

    return (
      employeesList.length === checkedUsers.length &&
      checkedUsersEmail.length === emailsToInvite.length
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value.trim();
    setSearchParams(searchParams);

    if (account_type === 1) {
      if (searchParams) {
        debounce(searchPersonalAccount(searchParams), 500);
      } else {
        setData({
          ...data,
          employeesList: data.ogEmployeesList,
        });
      }
    } else if (account_type === 2) {
      if (searchParams) {
        const users = handleFilterByName(data.ogEmployeesList, searchParams);
        setData({
          ...data,
          employeesList: users,
        });
      } else {
        setData({
          ...data,
          employeesList: data.ogEmployeesList,
        });
      }
    }
  };

  const searchPersonalAccount = (searchParams) => {
    if (searchParams) {
      searchUserByEmail(searchParams, token).then((response) => {
        if (response.users) {
          setData({
            ...data,
            employeesList: response.users,
          });
        }
      });
    }
  };

  const handleFilterByName = (arr, value) => {
    let result = [];
    result = arr.filter((member) => {
      let name;
      if (member.name.first) {
        name = member.name.first + " " + member.name.last;
      } else {
        name = member.name;
      }

      if (name.toLowerCase().includes(value)) {
        return member;
      }
    });
    return result;
  };

  const addMail = () => {
    if (searchParams === userEmail) {
      showToast({
        message: {
          title: "You cannot invite yourself",
        },
        type: "error",
      });
      return;
    }
    setData({
      ...data,
      emailsToInvite: [...new Set([...data.emailsToInvite, searchParams])],
      employeesList: data.ogEmployeesList,
    });
    const teamEmails = [...new Set([...project.teamEmails, searchParams])];
    setProject({
      ...project,
      teamEmails,
    });
    setSearchParams("");
  };

  const handleMembersToJoin = (user) => {
    // If personal user hasn't been invited
    const member = user;
    const memberId = member._id;
    const allMembersClone = data.ogEmployeesList;
    const { membersToJoin } = data;

    if (account_type === 1 && member.showInvitation) {
      if (!isLeaderOrMember(member._id)) {
        allMembersClone.push(member);
      }

      // Add or Remove user from membersToJoin
      let indexFound = membersToJoin.findIndex(
        (memberToJoin) => memberToJoin.userId === memberId
      );
      if (indexFound === -1) {
        membersToJoin.push({ userId: memberId });
      } else {
        membersToJoin.splice(indexFound, 1);
      }
    }
    return membersToJoin;
  };

  const handleCreateProject = () => {
    try {
      setIsLoading(true);

      const filesToUpload = [...(project?.files ? project?.files : [])];
      project.createdBy = _id;
      project.company_id = company_id;
      project.cost = project.currency + project.amount;
      // const { canCreateProjectFolder } = data;

      if (mode === "edit") {
        project.projectLeader = project.projectLeader.map((userId) => {
          const user = data.ogEmployeesList.find((user) => user._id == userId);
          return {
            ...user,
            isLeader: true,
            isTeam: false,
          };
        });

        project.team = project.team.map((userId) => {
          const user = data.ogEmployeesList.find((user) => user._id == userId);
          return {
            ...user,
            isLeader: false,
            isTeam: true,
          };
        });

        updateProject(token, project)
          .then((response) => {
            // setIsLoading(false);
            if (response.project) {
              showToast({
                message: {
                  title: "Project Updated",
                },
                type: "success",
              });
              projectUpdated(response);
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            showToast({
              message: {
                title: "Unable to update Project.",
              },
              type: "error",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        return;
      }

      project.list = [
        {
          title: "Pending",
          status: "AWAITING",
          color: "#FE5B52",
          createdBy: _id,
        },
        {
          title: "In Progress",
          status: "INCOMPLETE",
          color: "#3F96D1",
          createdBy: _id,
        },
        {
          title: "Under Review",
          status: "UNDER_REVIEW",
          color: "#FFB147",
          createdBy: _id,
        },
        {
          title: "Completed",
          status: "COMPLETE",
          color: "#0ACCA9",
          createdBy: _id,
        },
      ];
      project.files = [];

      addProject(token, project, true).then(async (response) => {
        setIsLoading(false);
        // console.log(response);
        if (response.success && response.project) {
          showToast({
            message: {
              title: "Project Created",
              description: "You have successfully created a new project!",
            },
            type: "success",
          });

          if (response.groupData) {
            eventBus.dispatch("group-created", response.groupData);
          }

          handleProjectCreated({
            project: { ...response.project, files: [...filesToUpload] },
            // projectFilesDataList: data.projectFilesDataList,
            folder_id: response.project_folder_id,
            response,
          });
        } else {
          showToast({
            message: {
              title: "Unable to save Project.",
            },
            type: "error",
          });
        }
      });
    } catch (e) {
      setIsLoading(false);
      console.error("handleCreateProject", e);
    }
  };

  return (
    <>
      <Modal
        key={creationStage}
        show={showProjectModal}
        onHide={() => {
          setShowProjectModal(false);
          setCreationStage(1);
        }}
        dialogClassName={`${
          creationStage === 1
            ? "stage-1"
            : creationStage === 2
            ? "stage-2"
            : "project-modal"
        }`}
        backdropClassName="global-backdrop project-backdrop max-z-index-2"
        aria-labelledby={`${
          creationStage === 1
            ? "stage-1"
              ? creationStage === 2
              : "stage-2"
            : "project-modal"
        }`}
        centered
        animation={false}
      >
        {isLoadingLatest && (
          <div
            style={{
              zIndex: "1000",
            }}
            className="position-absolute text-center w-100 h-100 bg-white d-flex flex-column align-items-center justify-content-center rounded"
          >
            <Spinner spinnerHeight={`2rem`} />
            <p className="h4">Please wait...</p>
          </div>
        )}
        {creationStage === 2 && (
          <>
            <Modal.Header>
              <Modal.Title id="title">
                {mode === "edit" ? "Edit Project" : "Create New Project"}
              </Modal.Title>

              <button id="close-btn" onClick={() => setShowProjectModal(false)}>
                <CloseIcon />
              </button>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="input-holder w-100">
                  <div className="d-flex justify-content-between">
                    <div className="client">
                      <Form.Group controlId="client">
                        <Form.Label>Client</Form.Label>
                        <div className="position-relative">
                          <select
                            className="custom-select form-control month"
                            value={project.client}
                            onChange={(e) => {
                              setProject({
                                ...project,
                                client: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select Client</option>
                            {clientList.map((client, index) => {
                              return (
                                <option value={client._id} key={index}>
                                  {client.companyName}
                                </option>
                              );
                            })}
                          </select>
                          <ChevronDownIcon className="chevron-down" />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="project-cost">
                      <Form.Group controlId="cost">
                        <Form.Label>Project Cost</Form.Label>
                        <div className="position-relative d-flex holders">
                          <Form.Control
                            type="text"
                            placeholder="$"
                            className="currency"
                            onChange={(e) =>
                              setProject({
                                ...project,
                                currency: e.target.value,
                              })
                            }
                            minLength="1"
                            maxLength="1"
                            value={project.currency}
                          />
                          <Form.Control
                            type="number"
                            placeholder="0"
                            className="amount"
                            onChange={(e) =>
                              setProject({
                                ...project,
                                amount: e.target.value,
                              })
                            }
                            value={project.amount}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group controlId="team">
                    <Form.Label>Assign a Team to Project</Form.Label>
                    <div className="position-relative">
                      <select
                        className="custom-select form-control month"
                        ref={selectTeamRef}
                        onChange={updateTeamTemplateId}
                        value={data.selectedTeam}
                      >
                        <option value="Select Team"> Select Team </option>
                        {data.teamTemplates.length > 0 &&
                          data.teamTemplates.map((teamTemplate, index) => {
                            return (
                              <option key={index} value={teamTemplate._id}>
                                {teamTemplate.name}
                              </option>
                            );
                          })}
                      </select>
                      <ChevronDownIcon className="chevron-down" />
                    </div>
                  </Form.Group>

                  <Form.Group controlId="status">
                    <Form.Label>
                      Project Status<sup className="text-danger">*</sup>
                    </Form.Label>
                    <div className="d-flex check-btns">
                      {data.status.map((status, index) => (
                        <button
                          type="button"
                          className={`btn ${
                            project.status === status ? "on" : "off"
                          }`}
                          key={index}
                          onClick={() =>
                            setProject({
                              ...project,
                              status,
                            })
                          }
                        >
                          {project.status === status ? (
                            <RadioCheckOnIcon />
                          ) : (
                            <RadioCheckOffIcon />
                          )}
                          {status}
                        </button>
                      ))}
                    </div>
                  </Form.Group>

                  <Form.Group controlId="priority">
                    <Form.Label>
                      Priority<sup className="text-danger">*</sup>
                    </Form.Label>
                    <div className="d-flex check-btns">
                      {data.priority.map((priority, index) => (
                        <button
                          type="button"
                          className={`btn ${
                            project.priority === priority ? "on" : "off"
                          }`}
                          key={index}
                          onClick={() =>
                            setProject({
                              ...project,
                              priority,
                            })
                          }
                        >
                          {project.priority === priority ? (
                            <RadioCheckOnIcon />
                          ) : (
                            <RadioCheckOffIcon />
                          )}
                          {priority}
                        </button>
                      ))}
                    </div>
                  </Form.Group>

                  <div className="d-flex justify-content-between add-file">
                    <div className="checkbox">
                      <>
                        <Form.Check className="d-flex align-items-center check-form invisible">
                          <Form.Check.Label
                            className="check-label"
                            htmlFor={`createFolder`}
                            style={{
                              color: "#102E51",
                              fontSize: "0.875rem",
                            }}
                          >
                            <Form.Check.Input
                              type="checkbox"
                              className="d-none"
                              id={`createFolder`}
                              onChange={() => {
                                setData({
                                  ...data,
                                  createProjectFolder:
                                    !data.createProjectFolder,
                                });
                              }}
                            />
                            <span className="mr-2">
                              {data.createProjectFolder ? (
                                <CheckboxIcon />
                              ) : (
                                <UncheckedboxIcon />
                              )}
                            </span>
                            Create Project Folder
                          </Form.Check.Label>
                        </Form.Check>
                      </>

                      {mode !== "edit" && !data.createProjectFolder && (
                        <p
                          style={{
                            width: "90%",
                            margin: 0,
                            marginLeft: "1.8rem",
                            marginTop: "-0.5rem",
                            lineHeight: "160%",
                          }}
                        >
                          Create a project folder to upload and save your files
                          to the fexspace cloud drive.
                        </p>
                      )}
                    </div>
                    <div>
                      {mode !== "edit" && data.createProjectFolder && (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => {
                            projectFiles.current.click();
                          }}
                        >
                          + Add File
                          <input
                            className="d-none"
                            ref={projectFiles}
                            id="projectfiles"
                            type="file"
                            multiple
                            label="Upload File"
                            onChange={updateProjectFile}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {data.createProjectFolder && mode !== "edit" ? (
                  <div className="project-files">
                    <div className="files">
                      {project.files.map((file, index) => (
                        <div
                          key={index}
                          className="grid-item d-flex justify-content-between align-items-center"
                        >
                          <div className="d-flex align-items-center">
                            <GetFileIcon file={file} />
                            <div className="d-flex flex-column file-details">
                              <span>{truncateFileName(file)}</span>
                              <span>{file.size}</span>
                            </div>
                          </div>

                          <span
                            className="close"
                            onClick={() => removeProjectFile(index)}
                          >
                            ✖
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <FormProgress />
              <div>
                <button
                  className="btn btn-outline-primary mr-3"
                  type="button"
                  onClick={() => setCreationStage(1)}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setCreationStage(3)}
                >
                  Next
                </button>
              </div>
            </Modal.Footer>
          </>
        )}
        {creationStage === 3 && (
          <>
            <Modal.Header>
              <Modal.Title id="title">Add Project Members</Modal.Title>

              <button id="close-btn" onClick={() => setShowProjectModal(false)}>
                <CloseIcon />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="search">
                <div className="search-box">
                  <input
                    placeholder="Search members"
                    value={searchParams}
                    onChange={(e) => handleSearch(e)}
                  />
                  <SearchIcon className="search-icon" />
                </div>
              </div>

              <div className="user-wrapper">
                <div className="action align-items-center">
                  <div className="grid-item">
                    {allUsersCheck({
                      employeesList: data.employeesList,
                      emailsToInvite: data.emailsToInvite,
                    }) ? (
                      <span onClick={() => unSelectAll()}>
                        <CheckboxIcon />
                      </span>
                    ) : (
                      <span onClick={() => selectAll()}>
                        <UncheckedboxIcon className="not-checked" />
                      </span>
                    )}
                  </div>
                  <div>
                    <span>Select All</span>
                  </div>
                </div>

                <div className="user-list">
                  {!validateEmail(searchParams) ? (
                    <>
                      {data.emailsToInvite.map((email, index) => (
                        <div className="user" key={index}>
                          <div className="grid-item">
                            {isLeaderOrMember(email) ? (
                              <span onClick={() => removeFromTeam(email)}>
                                <CheckboxIcon />
                              </span>
                            ) : (
                              <span onClick={() => addToTeam(email)}>
                                <UncheckedboxIcon className="not-checked" />
                              </span>
                            )}
                          </div>
                          <div className="grid-item">
                            <Avatar
                              key={index}
                              width={48}
                              height={48}
                              user={{ name: { first: email }, photo: "" }}
                              zIndex={1}
                              showLeader={false}
                            />
                          </div>
                          <div className="grid-item d-flex flex-column justify-content-start">
                            <span className="name">{email}</span>
                          </div>
                          <div className="grid-item">
                            {isLeader(email) ? (
                              <span
                                className="is-leader"
                                onClick={() => removeLeader(email)}
                              >
                                <span>Leader</span> <span>✖</span>
                              </span>
                            ) : (
                              <span
                                className="not-leader"
                                onClick={() => setLeader(email)}
                              >
                                Set as Leader
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                      {data.employeesList.map((user, index) => (
                        <div className="user" key={index}>
                          <div className="grid-item">
                            {isLeaderOrMember(user._id) ? (
                              <span onClick={() => removeFromTeam(user)}>
                                <CheckboxIcon />
                              </span>
                            ) : (
                              <span onClick={() => addToTeam(user)}>
                                <UncheckedboxIcon className="not-checked" />
                              </span>
                            )}
                          </div>
                          <div className="grid-item">
                            <Avatar
                              key={index}
                              width={48}
                              height={48}
                              user={user}
                              zIndex={1}
                              showLeader={false}
                            />
                          </div>
                          <div className="grid-item d-flex flex-column justify-content-start">
                            <span className="name">{`${user.name?.first} ${user.name?.last}`}</span>
                            <span className="email">
                              @{user.username}{" "}
                              {isInvited(user._id) ? (
                                <span className="badge bg-light p-1 rounded">
                                  invited
                                </span>
                              ) : null}
                            </span>
                          </div>
                          <div className="grid-item">
                            {isLeader(user._id) ? (
                              <span
                                className="is-leader"
                                onClick={() => removeLeader(user)}
                              >
                                <span>Leader</span> <span>✖</span>
                              </span>
                            ) : (
                              <span
                                className="not-leader"
                                onClick={() => setLeader(user)}
                              >
                                Set as Leader
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="add-new-email-container">
                      <div>
                        <span className="dy-text">
                          Do you want to add this email address?
                        </span>
                        "<em className="em-text">{searchParams}</em>"
                      </div>
                      <span
                        className="yes-text bg-primary p-cursor"
                        onClick={() => addMail()}
                      >
                        Yes please
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <FormProgress />
              <div>
                <button
                  className="btn btn-outline-primary mr-3"
                  type="button"
                  onClick={() => setCreationStage(2)}
                >
                  Back
                </button>
                <button
                  disabled={isLoading}
                  className="btn btn-primary final-btn"
                  onClick={() => handleCreateProject()}
                >
                  {isLoading ? (
                    <span>Please wait...</span>
                  ) : mode === "edit" ? (
                    "Save Changes"
                  ) : (
                    "Create Project"
                  )}
                </button>
              </div>
            </Modal.Footer>
          </>
        )}
        {creationStage === 1 && (
          <>
            <Modal.Header>
              <Modal.Title>
                {mode === "edit" ? "Edit Project" : "Create New Project"}
              </Modal.Title>
              <button id="close-btn" onClick={() => setShowProjectModal(false)}>
                <CloseIcon />
              </button>
            </Modal.Header>

            <Form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                setCreationStage(2);
              }}
            >
              <Modal.Body>
                <Form.Group controlId="projectName">
                  <Form.Label>
                    Project Name<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="projectName"
                    placeholder="Enter new project name"
                    onChange={(e) =>
                      setProject({
                        ...project,
                        name: e.target.value,
                      })
                    }
                    value={project.name}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    placeholder="Write description here"
                    onChange={(e) =>
                      setProject({
                        ...project,
                        description: e.target.value,
                      })
                    }
                    value={project.description}
                  />
                </Form.Group>

                <div className="date-duration d-block d-md-flex">
                  <div className="starting-date">
                    <Form.Group controlId="Start Date">
                      <Form.Label>
                        Starting date<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Datepicker
                        type="text"
                        closeOnSelect={true}
                        closeOnClickOutside={false}
                        disableOnClickOutside={false}
                        inputProps={{
                          className: "form-control datetime",
                          placeholder: "Select Date",
                        }}
                        onChange={(e) => setProjectDate(e, "startDate")}
                        isValidDate={validationStartDate}
                        required={true}
                        value={project.startDate}
                      />
                      <CalendarIcon className="form-icon" />
                    </Form.Group>
                  </div>
                  <div className="starting-date end-date">
                    <Form.Group controlId="Start Date">
                      <Form.Label>Due date</Form.Label>
                      <Datepicker
                        type="text"
                        closeOnSelect={true}
                        closeOnClickOutside={false}
                        disableOnClickOutside={false}
                        inputProps={{
                          className: "form-control datetime",
                          placeholder: "Select Date",
                        }}
                        onChange={(e) => setProjectDate(e, "endDate")}
                        isValidDate={validationStartDate}
                        required={true}
                        value={project.endDate}
                      />
                      <CalendarIcon className="form-icon" />
                    </Form.Group>
                  </div>
                  {/*<div className="d-flex set-duration">
                    <Form.Group controlId="duration">
                      <Form.Label>
                        Duration<sup className="text-danger">*</sup>
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type="number"
                          className="duration mr-2"
                          placeholder="0"
                          onChange={(e) =>
                            handleDateAndDuration(e, "durationValue")
                          }
                          value={project.duration}
                          required
                        />
                        <div className="position-relative">
                          <select
                            className="custom-select form-control month"
                            required
                            value={project.durationType}
                            onChange={(e) =>
                              handleDateAndDuration(e, "durationType")
                            }
                          >
                            <option value="Hours">Hours</option>
                            <option value="Days">Days</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                          <ChevronDownIcon className="chevron-down" />
                        </div>
                      </div>
                    </Form.Group>
                  </div>*/}
                </div>

                {mode !== "edit" ? (
                  <div className="d-flex justify-content-between">
                    <div className="checkbox position-relative tool-parent w-50">
                      <div className="label-tooltip">
                        {account_type === 1
                          ? `Anyone can see this project title and description. Tasks within this project are not visible to the public`
                          : `Anyone around your Organisation can see
                    this project title and discription on the Feed. Tasks within this
                    project is not visible`}
                      </div>
                      <Form.Check
                        id={`isPublic`}
                        className="d-flex align-items-center check-form"
                      >
                        <Form.Check.Label bsPrefix="p-cursor">
                          <Form.Check.Input
                            type="checkbox"
                            className="d-none"
                            onChange={() =>
                              setProject({
                                ...project,
                                isPublic: !project.isPublic,
                              })
                            }
                          />
                          <span className="mr-2">
                            {project.isPublic ? (
                              <CheckboxIcon />
                            ) : (
                              <UncheckedboxIcon />
                            )}
                          </span>
                          Make project public
                        </Form.Check.Label>
                      </Form.Check>
                    </div>

                    <div className="checkbox position-relative tool-parent">
                      {/*<div className="label-tooltip">
                        {account_type === 1
                          ? ``
                          : ``}
                        </div> */}
                      <Form.Check
                        id={`createChannel`}
                        className="d-flex align-items-center check-form"
                      >
                        <Form.Check.Label bsPrefix="p-cursor">
                          <Form.Check.Input
                            type="checkbox"
                            className="d-none"
                            onChange={() =>
                              setProject({
                                ...project,
                                createChannel: !project.createChannel,
                              })
                            }
                          />
                          <span className="mr-2">
                            {project.createChannel ? (
                              <CheckboxIcon />
                            ) : (
                              <UncheckedboxIcon />
                            )}
                          </span>
                          Create Channel
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <FormProgress />

                <button className="btn btn-primary">Next</button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);
