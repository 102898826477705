import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "mdi-react/CloseIcon";
import { getReferralLink, sendInvite } from "../../services/userServices";
import { connect } from "react-redux";
import { toggleToastMessage } from "../../store/actions";
import { CopyToClipboard, showToast } from "../task-update/utils";
import { CopyLinkIcon } from "../task-update/components/Icons";

function Invite({ toggleInviteModal, token, user, toggleToastMessage }) {
  const messengerUrl = process.env.REACT_APP_MESSENGER_FRONTEND;
  const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;

  const [state, setState] = useState({
    email: "",
    url: `${messengerUrl}/api/invite-from-collaboration`,
    isPublic: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleToggleInvite = (e) => {
    e.preventDefault();
    toggleInviteModal(false);
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { url, email, isPublic } = state;
    await sendInvite({ token, url, email, isPublic, userId: user._id })
      .then((response) => {
        if (response.success) {
          if (Array.isArray(response)) {
            toggleInviteModal({
              hasSentInvite: true,
              message: `Sent invites`,
            });
          } else {
            if (response.exists) {
              toggleInviteModal({
                message: `An invite link has already been sent to ${email}`,
                hasSentInvite: true,
              });
            } else if (response.isFriend) {
              toggleInviteModal({
                message: `You're already connected with ${email}`,
                hasSentInvite: true,
              });
            } else if (response.sameOrg) {
              toggleInviteModal({
                message: `You're already in the same organisation with ${email}`,
                hasSentInvite: true,
              });
            } else {
              toggleInviteModal({
                message: `Sent an invite to ${email}`,
                hasSentInvite: true,
              });
            }
          }
        } else {
          showToast({
            message: {
              title: "Unable to send invite",
            },
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("invite error", error);
        showToast({
          message: {
            title: "Unable to send invite",
          },
          type: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSetEmail = (e) => {
    const email = e.target.value;
    setState({ ...state, email });
  };

  const handleCloseModal = (e) => {
    if (e?.target?.classList.contains("invite-container")) {
      toggleInviteModal(false);
    }
  };

  const handleReferralLink = async () => {
    await getReferralLink(token)
      .then((response) => {
        if (response && response.success) {
          CopyToClipboard({
            text: `${cloudDriveFrontEnd}/create-account?ref=${response.referral_link}`,
          });
        }
      })
      .catch((err) => {});
  };

  return (
    <Wrapper>
      <div className="invite-container" onClick={(e) => handleCloseModal(e)}>
        <div className="invite-content">
          <div className="invite-header">
            <span>Invite people</span>

            <button
              className="btn invite-close-icon"
              onClick={(e) => handleToggleInvite(e)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="invite-body">
            <form onSubmit={(e) => handleSendInvite(e)}>
              <label htmlFor="email">Send invitation to:</label>
              <textarea
                type="text"
                name="email"
                placeholder="name@example.com"
                value={state.email}
                onChange={(e) => handleSetEmail(e)}
                rows="5"
                className="p-3"
                style={{ borderColor: "rgb(209, 213, 219)" }}
                required
              ></textarea>
              <p className="pt-3 pb-0 m-0">
                Use comma to separate multiple email addresses ex:
                <b> name@example.com, </b> <b>name2@example.com</b>
              </p>
              {user && user.isSuperAdmin ? (
                <div className="my-2">
                  <label className="d-flex align-items-center mt-1">
                    <input
                      type="checkbox"
                      onClick={() =>
                        setState({
                          ...state,
                          isPublic: !state.isPublic,
                        })
                      }
                      checked={state.isPublic}
                    />
                    <span className="ml-2 ">
                      Make user public to everyone in your organzition?
                    </span>
                  </label>
                </div>
              ) : (
                <div className="my-3"></div>
              )}

              <div className="invite-btn-controls mt-2">
                <button
                  type="submit"
                  className={`${
                    state.email ? "filled" : "empty"
                  } btn btn-primary`}
                  disabled={isLoading}
                >
                  Send invite
                </button>

                <button
                  onClick={() => handleReferralLink()}
                  className="btn text-primary"
                  type="button"
                >
                  <CopyLinkIcon className="mr-2" />
                  Copy Invitation Link
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .invite-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #596a73;
    font-size: 0.875rem !important;
    background-color: rgba(14, 29, 47, 0.54);
    border: none;
    box-shadow: none;

    > .invite-content {
      border-radius: 5px;
      background-color: rgb(255, 255, 255);
      border-radius: 0.375rem;
      border: none;
    box-shadow: none;
      transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      overflow: hidden;
      position: relative;
      padding: 2rem;
      width: 42.25rem;
      min-height: 25.5rem;

      > .invite-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        > span:nth-child(1) {
          font-size: 20px;
          font-weight: 700;
          color: #111111;
        }

        > .invite-close-icon {
          color: #596a73;
          cursor: pointer;
          background: #F2F7FC;
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.25rem;
        }
      }

      > .invite-body {
        form {
          display: flex;
          flex-direction: column;

          input,
          button {
            outline: none;
            border: none;
          }

          label {
            color: #596a73;
            margin-bottom: 3px;
          }

          textarea {
            padding: 0.75rem;
            border-radius: 0.5rem;
            margin-top: 0.5rem;
            border: 1px solid #A7B9CB;
          }

          textarea:focus {
            outline: none;
          }

          .bg-gray-100 {
            --tw-bg-opacity: 1;
            background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
          }

          .invite-btn-controls {
            display: flex;
            // justify-content: flex-end;

            button {
              padding 1rem 2rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    .invite-container {
      > .invite-content {
        padding: 1.25rem;
        width: calc(100vw - 1rem);
      }

      .invite-btn-controls {
        flex-direction: column;

        .btn-primary {
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleToastMessage: (value) => dispatch(toggleToastMessage(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
