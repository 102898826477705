import Cookies from "universal-cookie";
import store from "./store";
import CloseIcon from "mdi-react/CloseIcon";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import "./assets/scss/signUpModal.scss";
import {
  FexspaceLogo,
  GoogleLogo,
  LinkedinLogo,
  PasswordEyeClosed,
  PasswordEyeOpened,
} from "./components/task-update/components/Icons";
import { Checkbox, showToast } from "./components/task-update/utils";
import {
  CLOUD_DRIVE_BACKEND,
  CLOUD_DRIVE_FRONTEND,
  URLS,
} from "./components/urls";
import { socket, socketIO } from "./sockets";
import {
  populateUser,
  setShowLoginModal,
  setToken,
  toggleLoginModal,
} from "./store/actions";
import { verifyToken } from "./auth/authHelper";
import { signIn } from "./services/authService";
const cookies = new Cookies();

function LoginModal({
  showLoginModal,
  setShowLoginModal,
  populateUser,
  setToken,
}) {
  const environment = process.env.REACT_APP_ENV;
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
    keepMeLoggedIn: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("message", async (e) => {
      console.log("message received", e);
      if (e.data.success) {
        const { token } = await socketIO(store);
        setToken(token);
        await validateUser(token);
        // setShowLoginModal(false);
      }

      if (e.data.email) {
        showToast({
          message: {
            title: `Verification required`,
            description: `A verification mail has been sent to ${e.data.emailAddress}`,
          },
          type: "info",
          autoClose: false,
        });
      }
    });

    return () => window.removeEventListener("message", () => {});
  }, []);

  const handleFormInput = (e) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const validateUser = (token) => {
    console.log("validate", token);
    return new Promise((resolve, reject) => {
      const isValidToken = verifyToken(token);
      console.log("isvad", isValidToken);
      if (isValidToken) {
        fetch(`${URLS.backendUsers}/validateUser`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              if (result.user && Object.entries(result.user).length) {
                populateUser(result.user, true);
                setShowLoginModal(false);
                resolve();
              }
            } else {
              reject("login error");
            }
          });
      } else {
        reject("invalid token");
      }
    });
  };

  const getLink = async (type) => {
    setIsLoading(true);
    try {
      let res = await (
        await fetch(`${CLOUD_DRIVE_BACKEND}/get-explore-auth-login-link`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ type }),
        })
      ).json();
      // console.log(res)

      if (res.link) {
        window.open(
          res.link,
          "mywindow",
          "location=1,status=1,scrollbars=1, width=800,height=800"
        );
      }
    } catch (e) {
      showToast({
        message: {
          title: "Authentication failed",
          description: "Check your connection and try again",
        },
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (environment !== "development") {
        let res = await (
          await fetch(`${CLOUD_DRIVE_BACKEND}/ulogin`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(form),
            headers: {
              "Content-Type": "application/json",
            },
          })
        ).json();
        // console.log("login", res);
        if (res.data) {
          const { token } = await socketIO(store);
          setToken(token);
          await validateUser(token);
          // setShowLoginModal(false);
        } else {
          showToast({
            message: {
              title: res.message ? res.message : "Unable to login",
            },
            type: "error",
          });
        }
      } else {
        const response = await signIn(form);
        if (response.success) {
          if (response.user && Object.entries(response.user).length) {
            const user = response.user;
            const token = user.token;
            const cookies = new Cookies();
            cookies.set("token", token);
            await socketIO;
            setToken(token);
            await validateUser(token);
          }
          // this.props.history.push(URLS.reports);
        } else {
          this.showErrors(response.error);
        }
      }
    } catch (e) {
      console.log(e);
      showToast({
        message: {
          title: "Unable to login",
          description: "Check your connection and try again",
        },
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        dialogClassName="sign-up-modal"
        backdropClassName="global-backdrop max-z-index-2 blur"
        centered
      >
        <Modal.Body>
          <button id="close-btn" onClick={() => setShowLoginModal(false)}>
            <CloseIcon />
          </button>
          <FexspaceLogo className="logo" />
          <h2>Hi, welcome back!</h2>
          <div className="social-btns">
            <button
              disabled={isLoading}
              className="btn google"
              onClick={() => getLink("gmail")}
            >
              <GoogleLogo /> <span>Google</span>
            </button>
            <button
              disabled={isLoading}
              className="btn linkedin"
              onClick={() => getLink("linkedin")}
            >
              <LinkedinLogo /> <span>LinkedIn</span>
            </button>
          </div>

          <div className="or">
            <hr />
            <span className="px-3">Or sign in with email</span>
            <hr />
          </div>

          <form onSubmit={(e) => login(e)}>
            <div className="form-group">
              <label>Email Address</label>
              <input
                className="form-control"
                placeholder="Enter email address"
                name="email"
                value={form.email}
                onChange={(e) => handleFormInput(e)}
                required
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <div className="position-relative">
                <input
                  className="form-control"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={form.password}
                  onChange={(e) => handleFormInput(e)}
                  required
                />
                <button
                  className="btn password-eye"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword ? (
                    <PasswordEyeClosed />
                  ) : (
                    <PasswordEyeOpened />
                  )}
                </button>
              </div>
            </div>

            <div className="other-actions">
              <label>
                <Checkbox
                  checked={form.keepMeLoggedIn}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "14px",
                  }}
                  name="keepMeLoggedIn"
                  onChange={(e) => handleFormInput(e)}
                />
                <span>Keep me Logged in</span>
              </label>

              <a href={`${CLOUD_DRIVE_FRONTEND}/forgot-password`}>
                Forgot password?
              </a>
            </div>

            <button className="btn sign-in" disabled={isLoading}>
              {isLoading ? "Please wait..." : "Sign In"}
            </button>
          </form>

          <div className="more-actions">
            <span>
              Don’t have an account?{" "}
              <a href={`${CLOUD_DRIVE_FRONTEND}/create-account`}>Sign Up</a>
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  showLoginModal: state.userReducer.showLoginModal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShowLoginModal: (isShown) => dispatch(setShowLoginModal(isShown)),
    populateUser: (user, shouldUpdateToken) =>
      dispatch(populateUser(user, shouldUpdateToken)),
    setToken: (token) => dispatch(setToken(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
