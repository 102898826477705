import * as Actions from "../actions";

const initialState = {
  tasks: [],
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.POPULATE_TASKS: {
      return {
        ...state,
        tasks: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default taskReducer;
