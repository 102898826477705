import React, {Component} from 'react';
import {URLS} from '../urls';
import {Document, Page} from "react-pdf";

export default class DocumentModal extends Component {
    constructor(props) {
        super(props);
        this.setNotTruncate = this.setNotTruncate.bind(this);
        this.state = {
            employeeDocuments: {},
            truncate : -1,
        };
    }

    componentDidMount() {
        const id = window.location.pathname.split('/')[3];
        const token = 'dummy token'; // localStorage.getItem('token');
        fetch(`${URLS.backendEmployees}/documents/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => res.json()).then((result) => {
            if(result.employeeDocuments)
                this.setState({ employeeDocuments : result.employeeDocuments });
        });
    }
    setTruncate = () => {
        this.setState({ truncate : -1 });
    }
    setNotTruncate = idx => evt =>{
        this.setState({ truncate : idx });
    }

    render() {
        const { employeeDocuments, truncate } = this.state;
        console.log("employeeDocuments",employeeDocuments);
        const sfUrl = URLS.backendStatic + "/";
        return (
            <div className='row'>
                {
                    employeeDocuments.resume !== "" && <div className='col-sm-3'>
                        <a href={sfUrl+employeeDocuments.resume} target='_blank'>
                            <Document file={sfUrl+employeeDocuments.resume} noData={
                                <div style={{width:'110px',height:'120px',border: '1px solid #bebebe'}}/>}>
                                <Page width='200' heoght='200' pageNumber={1} />
                            </Document>
                            <p style={{
                                width: '200px',
                                textAlign: 'center',
                                position: 'absolute',
                                bottom: '0',
                                background: '#eaeaea',
                                height: '50px',
                                padding: '10px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                margin: '0'
                                }}>Resume</p>
                        </a>

                    </div>
                }
                {
                    employeeDocuments.educationCertificates && employeeDocuments.educationCertificates.length && employeeDocuments.educationCertificates.map((doc,idx) => {
                        if(doc.certificate && doc.certificate !== '')
                        return(<div className='col-sm-3'>
                            <a href={sfUrl+doc.certificate} target='_blank'>
                                <Document file={sfUrl+doc.certificate} noData={
                                    <div style={{width:'110px',height:'120px',border: '1px solid #bebebe'}}/>}>
                                    <Page width='200' heoght='200' pageNumber={1} />
                                </Document>
                                <p style={truncate === idx?{
                                    width: '200px',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    bottom: '0',
                                    background: '#eaeaea',
                                    height: 'auto',
                                    minHeight:'50px',
                                    padding: '10px',
                                    overflow: 'visible',
                                    textOverflow: 'ellipsis',
                                    margin: '0'
                                }:{
                                    width: '200px',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    bottom: '0',
                                    background: '#eaeaea',
                                    height: '50px',
                                    padding: '10px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    margin: '0'
                                }} onMouseOver={this.setNotTruncate(idx)}
                                onMouseOut={this.setTruncate}>{doc.institution}</p>
                            </a>
                        </div>);
                    })
                }
            </div>
        );
    }
}