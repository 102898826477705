import { isEmpty, parseInt, reduce } from "lodash";
import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

export default class TeamChart extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let tasks = this.props.tasks;
    let data = [
      {
        name: "completed",
        value: tasks["total"]
          ? parseInt(((tasks["complete"] * 100) / tasks["total"]).toFixed(0))
          : 0,
        color: "#40D886",
      },
      {
        name: "under review",
        value: tasks["total"]
          ? parseInt(
              ((tasks["under_review"] * 100) / tasks["total"]).toFixed(0)
            )
          : 0,
        color: "#FACF61",
      },
      {
        name: "active",
        value: tasks["total"]
          ? parseInt(((tasks["active"] * 100) / tasks["total"]).toFixed(0))
          : 0,
        color: "#009efb",
      },
      {
        name: "overdue",
        value: tasks["total"]
          ? parseInt(((tasks["overdue"] * 100) / tasks["total"]).toFixed(0))
          : 0,
        color: "#FC6D6C",
      },
    ];

    data.push({
      name: "fill",
      value:
        100 -
        parseInt(
          reduce(
            data.map((el) => el.value),
            (prev, cur) => (prev ? parseInt(prev) : 0) + parseInt(cur)
          )
        ),
      color: "#F3F5F7",
    });

    console.log(data);

    return (
      <>
        <PieChart width={200} height={200}>
          <Pie
            data={data}
            cx={100}
            cy={80}
            innerRadius={60}
            outerRadius={80}
            fill="#000000"
            paddingAngle={1}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} strokeWidth={16} />
            ))}
          </Pie>
        </PieChart>
      </>
    );
  }
}
