const environment = process.env.REACT_APP_ENV;

export const SERVER_BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const CLOUD_DRIVE_BACKEND = process.env.REACT_APP_CLOUD_DRIVE_BACKEND;
export const CLOUD_DRIVE_FRONTEND = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;

console.log(environment, SERVER_BASE_URL);

export const URLS = {
  landingPage: "",
  reports: "/reports",
  clients: "/clients",
  performance: "/performance",
  projects: "/projects",
  teams: "/teams",
  taskView: "/task-view",
  projectView: "/project-view",
  projectPerformance: "/project-performance",
  // projectBoard: "/project-board",
  teamView: "/team-view",
  memberView: "/member-view",
  tasks: "/tasks",
  tasksBoard: "/project-board",
  singleProjectTasks: "/tasks-board",
  createTask: "/create-task",
  login: "/login",
  profile: "/profile",
  clientProfile: "/client-profile",
  redirect: "/redirect",
  projectInvite: "/project-invite",
  taskInvite: "/task-invite",

  board: "/board",
  crm: "/crm",

  backendStatic: `${SERVER_BASE_URL}/drive`,
  backendPublic: `${SERVER_BASE_URL}/public`,

  backendBoardSettings: `${SERVER_BASE_URL}/api/board-settings`,
  colorCodes: `${SERVER_BASE_URL}/api/color-codes`,
  backendEmployees: `${SERVER_BASE_URL}/api/employees`,
  backendUsers: `${SERVER_BASE_URL}/api/users`,
  backendJobGrades: `${SERVER_BASE_URL}/api/jobGrades`,
  backendLocations: `${SERVER_BASE_URL}/api/locations`,
  backendDepartments: `${SERVER_BASE_URL}/api/Departments`,
  backendSkills: `${SERVER_BASE_URL}/api/Skills`,
  backendTrainings: `${SERVER_BASE_URL}/api/Trainings`,
  backendLifeInsurances: `${SERVER_BASE_URL}/api/lifeinsurances`,
  backendEmployeeHMOs: `${SERVER_BASE_URL}/api/employeehmos`,
  backendProjects: `${SERVER_BASE_URL}/api/projects`,
  backendClients: `${SERVER_BASE_URL}/api/clients`,
  backendTasks: `${SERVER_BASE_URL}/api/tasks`,
  backendCrm: `${SERVER_BASE_URL}/api/crm`,
  backendCustomer: `${SERVER_BASE_URL}/api/customer`,
  backendDesignations: `${SERVER_BASE_URL}/api/designations`,
  backendJobs: `${SERVER_BASE_URL}/api/jobs`,
  backendApplications: `${SERVER_BASE_URL}/api/applications`,
  backendPolicies: `${SERVER_BASE_URL}/api/policies`,
  backendLeaves: `${SERVER_BASE_URL}/api/leaves`,
  backendApplyLeaves: `${SERVER_BASE_URL}/api/applyleaves`,
  backendLeaveBalances: `${SERVER_BASE_URL}/api/leaveBalances`,
  backendLeaveCustomPolicies: `${SERVER_BASE_URL}/api/leaveCustomPolicies`,
  backendTeams: `${SERVER_BASE_URL}/api/teams`,
  backendCompanies: `${SERVER_BASE_URL}/api/companies`,
  backendNotifications: `${SERVER_BASE_URL}/api/notifications`,
  backendCloudDrive: `${CLOUD_DRIVE_BACKEND}`,
};
